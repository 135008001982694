import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import { type Loan, type LoansContext } from 'App/Loans/loans-types'

const dataToLoanObject = (data: any): Loan => ({
  id: data.id,
  siteId: data.siteId,
  startTime: new Date(data.startTime),
  endTime: new Date(data.endTime),
  status: data.status,
  profileName: data.profileName,
  profileSource: data.profileSource ?? undefined,
  tabletActiveAt: data.tabletActiveAt
    ? new Date(data.tabletActiveAt)
    : undefined,
  tabletSerialNumber: data.tabletSerialNumber,
  userName: data.userName,
  pinCode: data.pinCode,
  returnTime: data.returnTime ? new Date(data.returnTime) : undefined,
  userDefaultLanguage: data.userDefaultLanguage,
})

class LoansApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchLoans({
    organizationId,
    siteId,
  }: LoansContext): Promise<Loan[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/loans`
    const { data } = await axios.get(apiUrl)

    return data.map(dataToLoanObject)
  }
}

export default LoansApi
