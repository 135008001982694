import React, { useContext, useEffect, useMemo, useState } from 'react'
import { type Dock, type DockLocationEditable } from 'App/Docks/docks-types'
import Panel, { EditState } from 'common/components/NewPanel'
import PanelField from 'common/components/PanelField'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import {
  useGetDockLocationQuery,
  useUnassignDockLocationMutation,
  useUpdateDockLocationMutation,
  useUpdateDockServerMutation,
} from 'App/Docks/docks-rtk-api'
import FormSelect from 'common/components/FormSelect'
import useCurrentUser from 'common/hooks/useCurrentUser'
import { useGetSitesQuery } from 'App/Sites/sites-rtk-api'
import { roleShouldHaveAccess } from 'common/utils/roles'
import { requireParams } from 'common/utils/rtk'
import { useGetCustomersQuery } from 'App/Customers/customers-rtk-api'
import { useGetDistributorsQuery } from 'App/Distributors/distributors-rtk-api'
import { fetchDocks, updatedDockLocation } from 'App/Docks/docks-state'
import ActionDialog from 'common/components/ActionDialog'
import { useTranslation } from 'react-i18next'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import DocksReactContext from 'App/Docks/docks-react-context'
import { UserRole } from 'App/app-state'
import Button from 'common/components/Button'
import useApp from 'common/hooks/useApp'
import { useAppDispatch } from 'store'
import { HubletServer } from 'common/types/hublet-server'
import { type TFunction } from 'i18next'

interface FormErrors {
  distributor?: string
  customer?: string
  site?: string
}

interface LocationPanelProps {
  dock: Dock
}

const LocationPanel = ({ dock }: LocationPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const context = useContext(DocksReactContext)

  const [editState, setEditState] = useState<EditState>()

  const [distributorId, setDistributorId] = useState<undefined | number>()
  const [customerId, setCustomerId] = useState<undefined | number>()
  const [siteId, setSiteId] = useState(-1)

  const [formErrors, setFormErrors] = useState<FormErrors>({})
  const [openUpdateConfirmation, setOpenUpdateConfirmation] = useState(false)
  const [openUnassignConfirmation, setOpenUnassignConfirmation] =
    useState(false)

  const { role, isManufacturer } = useCurrentUser()
  const { organizationId: accountOrgId, siteId: accountSiteId } =
    useCurrentAccount()
  const { adminMode } = useApp()

  const { data: dockLocation, error: dockLocationError } =
    useGetDockLocationQuery({
      organizationId: accountOrgId,
      siteId: accountSiteId,
      dockId: dock.id,
      context,
    })

  const isManufacturerOrgAdmin =
    (role === UserRole.OrgAdmin || role === UserRole.DistributorAdmin) &&
    isManufacturer

  const { data: distributors, error: distributorsError } =
    useGetDistributorsQuery(undefined, {
      skip:
        !roleShouldHaveAccess(role, 'distributors') && !isManufacturerOrgAdmin,
    })

  const { data: customers, error: customersError } = useGetCustomersQuery(
    requireParams({ distributorId }),
    {
      skip: !roleShouldHaveAccess(role, 'customers') && !isManufacturerOrgAdmin,
    }
  )

  const { data: sites, error: sitesError } = useGetSitesQuery(
    requireParams({ organizationId: customerId }),
    { skip: !roleShouldHaveAccess(role, 'sites') && !isManufacturerOrgAdmin }
  )

  const [
    updateDockLocation,
    {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      error: updateDockLocationError,
    },
  ] = useUpdateDockLocationMutation()

  const [
    unassignDockLocation,
    {
      isLoading: isUnassigning,
      isSuccess: isUnassignmentSuccess,
      error: unassignDockLocationError,
    },
  ] = useUnassignDockLocationMutation()

  const apiQueryErrors = [
    dockLocationError,
    distributorsError,
    customersError,
    sitesError,
  ]

  const apiMutationErrors = [updateDockLocationError, unassignDockLocationError]
  const [hasApiMutationErrors, apiMutationErrorsMsg] =
    useApiErrors(apiMutationErrors)

  const hasPendingMutations = isUnassigning || isUpdating
  const hasMovedDockLocation = isUpdateSuccess || isUnassignmentSuccess

  useEffect(() => {
    if (dockLocation === undefined) {
      return
    }

    setDistributorId(dockLocation.distributorId)
    setCustomerId(dockLocation.customerId)
    setSiteId(dockLocation.siteId ?? -1)
  }, [dockLocation])

  useEffect(() => {
    if (hasPendingMutations) {
      setEditState(EditState.Pending)
    }

    if (hasMovedDockLocation) {
      dispatch(updatedDockLocation())
    }

    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    }
  }, [
    dispatch,
    hasPendingMutations,
    hasMovedDockLocation,
    hasApiMutationErrors,
  ])

  const distributorOptions = useMemo(() => {
    if (dockLocation === undefined) {
      return []
    }

    if (distributors === undefined) {
      return [
        {
          label: dockLocation.distributorName,
          value: dockLocation.distributorId,
        },
      ]
    }

    return distributors.map(({ id, name }) => ({ label: name, value: id }))
  }, [dockLocation, distributors])

  const customerOptions = useMemo(() => {
    if (dockLocation === undefined) {
      return []
    }

    if (customers === undefined) {
      return [
        { label: dockLocation.customerName, value: dockLocation.customerId },
      ]
    }

    return customers.map(({ id, name }) => ({ label: name, value: id }))
  }, [dockLocation, customers])

  const siteOptions = useMemo(() => {
    if (dockLocation === undefined) {
      return []
    }

    let opts = []

    if (sites === undefined) {
      opts = [{ label: dockLocation.siteName, value: dockLocation.siteId }]
    } else {
      opts = sites.map(({ id, name }) => ({ label: name, value: id }))
    }

    return [{ label: '-', value: -1 }, ...opts]
  }, [dockLocation, sites])

  const [hasApiQueryErrors, apiQueryErrorsMsg] = useApiErrors(apiQueryErrors)

  if (hasApiQueryErrors) {
    return <ErrorBox>{apiQueryErrorsMsg}</ErrorBox>
  }

  if (dockLocation === undefined) {
    return null
  }

  const handleCancelEditMode = () => {
    setEditState(undefined)
    setDistributorId(dockLocation.distributorId)
    setCustomerId(dockLocation.customerId)
    setSiteId(dockLocation.siteId ?? -1)
  }

  const handleDistributorChange = (v: number) => {
    setDistributorId(v)
    setCustomerId(undefined)
    setSiteId(-1)
  }

  const handleCustomerChange = (v: number) => {
    setCustomerId(v)
    setSiteId(-1)
  }

  const handleSiteChange = (e: any) => {
    setSiteId(e.target.value)
  }

  const handleSaveForm = () => {
    const requiredField = t('common.errors.emptyField')

    const formErrors: FormErrors = {}
    let hasError = false
    if (distributorId === undefined) {
      formErrors.distributor = requiredField
      hasError = true
    }

    if (customerId === undefined) {
      formErrors.customer = requiredField
      hasError = true
    }

    setFormErrors(formErrors)

    if (hasError) {
      return
    }

    setOpenUpdateConfirmation(true)
  }

  const handleActionUpdateConfirmDialog = () => {
    if (distributorId === undefined || customerId === undefined) {
      return
    }

    const dockLocationData: DockLocationEditable = {
      distributorId,
      customerId,
      siteId: siteId === -1 ? null : siteId,
    }

    updateDockLocation({
      organizationId: accountOrgId,
      siteId: accountSiteId,
      dockId: dock.id,
      data: dockLocationData,
      context,
    })

    setOpenUpdateConfirmation(false)
  }

  const handleActionUnassignConfirmDialog = () => {
    if (context !== 'site') return
    unassignDockLocation({
      organizationId: accountOrgId,
      siteId: accountSiteId,
      dockId: dock.id,
      context,
    })
    setOpenUpdateConfirmation(false)
  }

  const unassignDockLocationButton = (
    <Button
      onClick={() => setOpenUnassignConfirmation(true)}
      small
      negative
      disabled={editState === EditState.Pending}
    >
      {t('docks.details.location.unassignAction')}
    </Button>
  )

  const renderEditMode = () => (
    <>
      <FormSelect
        label={t('docks.details.location.fields.distributor.label')}
        options={distributorOptions}
        value={distributorId}
        onChange={(_e, v) => {
          handleDistributorChange(v as number)
        }}
        placeholder={t('docks.details.location.fields.distributor.placeholder')}
        error={formErrors.distributor}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('docks.details.location.fields.customer.label')}
        options={customerOptions}
        value={customerId}
        onChange={(_e, v) => {
          handleCustomerChange(v as number)
        }}
        placeholder={t('docks.details.location.fields.customer.placeholder')}
        error={formErrors.customer}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('docks.details.location.fields.site.label')}
        options={siteOptions}
        value={siteId ?? -1}
        onChange={handleSiteChange}
        placeholder={t('docks.details.location.fields.site.placeholder')}
        error={formErrors.site}
        disabled={editState === EditState.Pending}
      />
    </>
  )

  return (
    <>
      <Panel
        title={t('docks.details.location.location.title')}
        editable={roleShouldHaveAccess(role, 'sites')}
        editState={editState}
        onEdit={() => setEditState(EditState.Edit)}
        onCancel={handleCancelEditMode}
        onSave={handleSaveForm}
        renderEditMode={renderEditMode}
        error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
        extraAction={
          adminMode &&
          (context === 'organization' || context === 'site') &&
          unassignDockLocationButton
        }
      >
        <PanelField
          title={t('docks.details.location.fields.distributor.label')}
          value={dockLocation.distributorName}
        />
        <PanelField
          title={t('docks.details.location.fields.customer.label')}
          value={dockLocation.customerName}
        />
        <PanelField
          title={t('docks.details.location.fields.site.label')}
          value={dockLocation.siteName}
        />
      </Panel>
      <ActionDialog
        open={openUpdateConfirmation}
        title={t('docks.details.location.confirm.title')}
        description={t('docks.details.location.confirm.description', {
          dock: dock.name,
        })}
        onClose={() => setOpenUpdateConfirmation(false)}
        onAction={handleActionUpdateConfirmDialog}
        actionText={t('docks.details.location.confirm.actionText')}
      />
      <ActionDialog
        open={openUnassignConfirmation}
        title={t('docks.details.location.confirmUnassign.title')}
        description={t('docks.details.location.confirmUnassign.description', {
          dock: dock.name,
        })}
        onClose={() => setOpenUnassignConfirmation(false)}
        onAction={handleActionUnassignConfirmDialog}
        actionText={t('docks.details.location.confirmUnassign.actionText')}
      />
    </>
  )
}

interface ServerPanelProps {
  dock: Dock
}

const renderServerValue = (t: TFunction, server: HubletServer | '') => {
  return server === '' ? '-' : t(`common.server.${server}`)
}

const ServerPanel = ({ dock }: ServerPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const context = useContext(DocksReactContext)

  const [editState, setEditState] = useState<EditState>()
  const [server, setServer] = useState<HubletServer | ''>(dock.server ?? '')

  const [openUpdateConfirmation, setOpenUpdateConfirmation] = useState(false)

  const { organizationId, siteId } = useCurrentAccount()

  const [
    updateDockServer,
    { isSuccess: isUpdateSuccess, error: updateDockServerError },
  ] = useUpdateDockServerMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateDockServerError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchDocks({ organizationId, siteId, context }))
    }
  }, [
    dispatch,
    organizationId,
    siteId,
    context,
    hasApiMutationErrors,
    isUpdateSuccess,
  ])

  const handleSaveForm = () => {
    setOpenUpdateConfirmation(true)
  }

  const handleActionUpdateConfirmDialog = () => {
    if (context !== 'manufacturer') return
    updateDockServer({
      context,
      organizationId,
      siteId,
      dockId: dock.id,
      server: server === '' ? null : server,
    })
    setEditState(EditState.Pending)
    setOpenUpdateConfirmation(false)
  }

  const serverOptions = useMemo(() => {
    const disabledServerOptions = [HubletServer.Local]
    const serverOptions = Object.values(HubletServer).filter(
      (server) => !disabledServerOptions.includes(server)
    )
    const opts = serverOptions.map((value) => ({
      label: renderServerValue(t, value),
      value,
    }))
    return [{ label: renderServerValue(t, ''), value: '' }, ...opts]
  }, [t])

  const renderEditMode = () => (
    <FormSelect
      label={t('docks.details.location.fields.server.label')}
      options={serverOptions}
      value={server}
      onChange={(_e, v) => {
        setServer(v as HubletServer | '')
      }}
      placeholder={t('docks.details.location.fields.server.placeholder')}
      disabled={editState === EditState.Pending}
    />
  )

  return (
    <>
      <Panel
        title={t('docks.details.location.server.title')}
        editable
        editState={editState}
        renderEditMode={renderEditMode}
        onEdit={() => setEditState(EditState.Edit)}
        onCancel={() => setEditState(EditState.Default)}
        onSave={handleSaveForm}
        error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
      >
        <PanelField
          title={t('docks.details.location.fields.server.label')}
          value={dock.server == null ? '-' : t(`common.server.${dock.server}`)}
        />
      </Panel>
      <ActionDialog
        open={openUpdateConfirmation}
        title={t('docks.details.location.confirmServer.title')}
        description={t('docks.details.location.confirmServer.description', {
          server: renderServerValue(t, server),
        })}
        onClose={() => setOpenUpdateConfirmation(false)}
        onAction={handleActionUpdateConfirmDialog}
        actionText={t('docks.details.location.confirmServer.actionText')}
      />
    </>
  )
}

interface DockLocationProps {
  dock: Dock
}

const DockLocation = ({ dock }: DockLocationProps) => {
  const context = useContext(DocksReactContext)

  return (
    <>
      {context === 'manufacturer' && <ServerPanel dock={dock} />}
      <LocationPanel dock={dock} />
    </>
  )
}

export default DockLocation
