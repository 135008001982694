import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { type RootState, useAppDispatch } from 'store'

import Table from 'common/components/TableNew'

import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SearchIcon from '@mui/icons-material/SearchTwoTone'

import { Box, TextField, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { type QueryParams, type QueryResult } from 'api/types'

import IconMenu from 'common/components/IconMenu'
import ActionDialog from 'common/components/ActionDialog'

import EditTranslationDrawer from 'App/Translations/components/EditTranslationDrawer'
import { type Translation } from 'App/Translations/translations-types'
import {
  deleteTranslation,
  fetchTranslations,
  resetDeletedTranslation,
  applyQueryParams,
} from 'App/Translations/translations-state'

import locales from 'locales'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'

const useStyles = makeStyles()((theme: Theme) => ({
  FilterBox: {
    marginBottom: theme.spacing(4),
  },
}))

const useSearchBoxStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderRadius: 30,
    width: 320,
  },
  adornedEnd: {
    color: theme.palette.primary.main,
  },
}))

interface TranslationsRowActionsProps {
  translation: Translation
  onClickEdit: (translation: Translation) => void
  onClickDelete: (translation: Translation) => void
}

const TranslationsRowActions = ({
  translation,
  onClickEdit,
  onClickDelete,
}: TranslationsRowActionsProps) => {
  const actions = [
    {
      local: 'translations.listTranslations.actions.edit',
      fn: () => onClickEdit(translation),
      icon: EditIcon,
    },
    {
      local: 'translations.listTranslations.actions.delete',
      fn: () => onClickDelete(translation),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

interface SearchTextFieldProps {
  query?: string
  onChangeQuery?: (q?: string) => void
}
const SearchTextField = ({ query, onChangeQuery }: SearchTextFieldProps) => {
  const { classes } = useSearchBoxStyles()
  const { t } = useTranslation()

  return (
    <TextField
      id="outlined-basic"
      placeholder={t('translations.table.searchLabel')}
      variant="outlined"
      size="small"
      value={query}
      onChange={(e) => {
        if (onChangeQuery != null) onChangeQuery(e.target.value)
      }}
      InputProps={{
        classes,
        endAdornment: <SearchIcon />,
      }}
    />
  )
}

interface TranslationsTableProps {
  data: Translation[]
  queryParams: QueryParams
  queryResult: QueryResult<Translation>
  updatedTranslation: boolean
  deletedTranslation: boolean
}

const TranslationsTable = ({
  data,
  queryParams,
  queryResult,
  updatedTranslation,
  deletedTranslation,
}: TranslationsTableProps) => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [editTranslationValue, setEditTranslationValue] = useState<
    Translation | undefined
  >()
  const [deleteTranslationValue, setDeleteTranslationValue] = useState<
    Translation | undefined
  >()

  useEffect(() => {
    if (deletedTranslation) {
      dispatch(resetDeletedTranslation())
      dispatch(fetchTranslations())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deletedTranslation])

  const onClickEdit = (translation: Translation) =>
    setEditTranslationValue(translation)
  const onClickDelete = (translation: Translation) =>
    setDeleteTranslationValue(translation)

  const onCloseDrawer = () => setEditTranslationValue(undefined)
  const onActionDelete = () => {
    if (deleteTranslationValue != null) {
      dispatch(deleteTranslation(deleteTranslationValue))
      setDeleteTranslationValue(undefined)
    }
  }

  const onChangeQuery = (query?: string) => {
    dispatch(applyQueryParams(data, { query: query ?? '' }))
  }

  const columns = [
    {
      title: t('translations.table.columns.key'),
      field: 'key',
      style: { width: 'auto' },
    },
    {
      title: t('translations.table.columns.app'),
      field: 'app',
      style: { width: '120px' },
    },
    {
      title: t('translations.table.columns.translation'),
      style: { width: 'auto' },
      render: (translation: Translation) =>
        translation.translations?.find((t) => t.language === locales.language)
          ?.translation ?? '',
    },
    {
      title: t('translations.table.columns.createdAt'),
      render: ({ createdAt }: Translation) =>
        createdAt !== undefined ? (
          <DateWithRelativeTime datetime={new Date(createdAt)} />
        ) : (
          '-'
        ),
      field: 'createdAt',
      canSort: true,
      style: { width: '225px' },
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (translation: Translation) => (
        <TranslationsRowActions
          translation={translation}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      ),
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Box className={classes.FilterBox}>
        <SearchTextField
          query={queryParams.query}
          onChangeQuery={onChangeQuery}
        />
      </Box>

      <Table columns={columns} data={queryResult.rows} />

      <EditTranslationDrawer
        translation={editTranslationValue}
        open={editTranslationValue !== undefined}
        onClose={onCloseDrawer}
        updatedTranslation={updatedTranslation}
      />
      {deleteTranslationValue != null && (
        <ActionDialog
          open={deleteTranslationValue !== undefined}
          title={t('translations.deleteTranslation.title')}
          description={t('translations.deleteTranslation.description', {
            translation: deleteTranslationValue.key,
          })}
          actionText={t('translations.deleteTranslation.action')}
          onAction={onActionDelete}
          onClose={() => setDeleteTranslationValue(undefined)}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  data: state.translations.data,
  queryParams: state.translations.queryParams,
  queryResult: state.translations.queryResult,
  updatedTranslation: state.translations.updatedTranslation,
  deletedTranslation: state.translations.deletedTranslation,
})

export default connect(mapStateToProps)(TranslationsTable)
