import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import MoreIcon from '@mui/icons-material/MoreHoriz'
import DropdownMenu from 'common/components/DropdownMenu'
import IconAction from 'common/components/IconAction'
import DropdownMenuItem from 'common/components/DropdownMenuItem'
import { type SvgIconComponent } from '@mui/icons-material'

const useStyles = makeStyles()((theme: Theme) => ({
  SitesRowActions: {
    textAlign: 'right',
  },
}))

interface IconMenuAction {
  local: string
  fn: () => void
  icon: SvgIconComponent
}

interface IconMenuProps {
  actions: IconMenuAction[]
  variant?: 'white'
}

const IconMenu = ({ variant, actions }: IconMenuProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const toggleRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleClickMore: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    setOpen(true)
  }

  const handleCloseMenu = () => setOpen(false)
  const handleClickAction = (actionCb: () => void) => () => {
    setOpen(false)
    actionCb()
  }

  return (
    <Box className={classes.SitesRowActions}>
      <IconAction
        ref={toggleRef}
        variant={variant}
        onClick={handleClickMore}
        Icon={MoreIcon}
      />
      <DropdownMenu
        anchor={toggleRef.current}
        open={open}
        onClose={handleCloseMenu}
      >
        {actions.map((action) => (
          <DropdownMenuItem
            key={action.local}
            onClick={handleClickAction(action.fn)}
            Icon={action.icon}
          >
            {t(action.local)}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </Box>
  )
}

export default IconMenu
