import React, { useState, useRef } from 'react'
import Axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type CurrentUser,
  UserRole,
  switchToAdmin,
  switchToNormal,
} from 'App/app-state'
import { renderIf } from 'common/utils/render-utils'
import { Grid, Box, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import DropdownMenu from 'common/components/DropdownMenu'
import DropdownMenuItem from 'common/components/DropdownMenuItem'
import { useTranslation } from 'react-i18next'
import useApp from 'common/hooks/useApp'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  UserWrap: {
    display: 'inline-block',
    float: 'right',
  },
  User: {
    cursor: 'pointer',
    position: 'relative',
  },
  UserInfo: {
    paddingRight: '16px',
    flexGrow: 1,
    textAlign: 'right',
  },
  UserName: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '22px',
    color: theme.palette.hublet.common.white,
  },
  UserOrganization: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.hublet.common.white,
  },
  UserCircle: {
    display: 'block',
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    backgroundColor: theme.palette.hublet.common.white,
    textAlign: 'center',
    lineHeight: '40px',
    fontSize: '16px',
    letterSpacing: '1px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  UserActions: {
    '& .MuiMenu-paper': {
      width: '211px',
      marginTop: '11px',
      padding: '18px 0',
      background: theme.palette.hublet.common.white,
      boxShadow: `0px 4px 30px ${theme.palette.hublet.misc.boxShadow}`,
      borderRadius: '10px',
    },
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  UserActionsItem: {
    padding: '14px 32px',
    '&:hover': {
      backgroundColor: theme.palette.hublet.misc.menuItemHover,
    },
  },
  UserDropdown: {
    '& .MuiMenu-paper': {
      width: 232,
      padding: 0,
    },
  },
  UserProfileItem: {
    borderBottom: `1px solid ${theme.palette.hublet.misc.menuItemHover}`,
  },
  UserProfileItemCircle: {
    width: 32,
    minWidth: 32,
    height: 32,
    backgroundColor: theme.palette.hublet.primary.main,
    color: theme.palette.hublet.common.white,
    borderRadius: '100%',
    lineHeight: '32px',
    marginRight: 16,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  UserProfileItemInfo: {
    color: theme.palette.hublet.secondary.light,
    lineHeight: 1.25,
    fontSize: 12,
  },
  UserProfileItemName: {
    fontWeight: 'bold',
    color: theme.palette.hublet.primary.main,
    fontSize: 16,
    lineHeight: 1.1,
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },
}))

interface UserAccountProps {
  currentUser: CurrentUser
}

const UserAccount = ({ currentUser }: UserAccountProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { adminMode } = useApp()
  const navigate = useNavigate()

  const [showUserAction, setShowUserActions] = useState(false)
  const userRef = useRef(null)

  const handleClickUser = () => {
    setShowUserActions(!showUserAction)
  }

  const handleClickTos = () => {
    window.location.href = '/ui/terms.html'
  }

  const handleClickPrivacy = () => {
    window.location.href = '/ui/privacy-hublet.html'
  }

  const handleClickSignOut = () => {
    setShowUserActions(false)
    void Axios.post(`${API_BASE_URL}/user/logout`).then(() => {
      window.location.href = '/ui/login'
    })
  }

  const handleClickSwitchToAdmin = () => {
    setShowUserActions(false)
    dispatch(switchToAdmin())
  }

  const handleClickSwitchToNormal = () => {
    setShowUserActions(false)
    dispatch(switchToNormal())
  }

  const handleClose = () => {
    setShowUserActions(false)
  }

  const handleClickMyAccount = () => {
    setShowUserActions(false)
    navigate('/account')
  }

  const firstNameInitial = currentUser.firstName.substr(0, 1)
  const lastNameInitial = currentUser.lastName.substr(0, 1)

  return (
    <Grid>
      <Grid
        ref={userRef}
        container
        className={classes.User}
        onClick={handleClickUser}
      >
        <Box className={classes.UserCircle}>
          {firstNameInitial}
          {lastNameInitial}
        </Box>
      </Grid>

      <DropdownMenu
        className={classes.UserDropdown}
        open={showUserAction}
        anchor={userRef.current}
        onClose={handleClose}
      >
        <DropdownMenuItem className={classes.UserProfileItem} variant="info">
          <div className={classes.UserProfileItemCircle}>
            {firstNameInitial}
            {lastNameInitial}
          </div>
          <div>
            <div className={classes.UserProfileItemInfo}>
              {t('app.header.userItem.info')}
            </div>
            <div className={classes.UserProfileItemName}>
              {currentUser.firstName} {currentUser.lastName}
            </div>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleClickMyAccount}>
          {t('app.header.actions.myAccount')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleClickTos}>
          {t('app.header.actions.termsOfService')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleClickPrivacy}>
          {t('app.header.actions.privacyStatement')}
        </DropdownMenuItem>
        {renderIf(
          currentUser.role === UserRole.HubletAdmin && !adminMode,
          () => (
            <DropdownMenuItem onClick={handleClickSwitchToAdmin}>
              {t('app.header.actions.switchToAdmin')}
            </DropdownMenuItem>
          )
        )}
        {renderIf(
          currentUser.role === UserRole.HubletAdmin && adminMode,
          () => (
            <DropdownMenuItem onClick={handleClickSwitchToNormal}>
              {t('app.header.actions.switchToNormal')}
            </DropdownMenuItem>
          )
        )}
        <DropdownMenuItem onClick={handleClickSignOut}>
          {t('app.header.actions.signOut')}
        </DropdownMenuItem>
      </DropdownMenu>
    </Grid>
  )
}

export default UserAccount
