import { type CustomerSector } from 'App/CustomerSectors/customer-sector-state'
import rtkApi from 'rtk-api'

const customerSectorsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerSectors: builder.query<CustomerSector[], void>({
      query: () => '/customer-sectors',
      transformResponse: (data: { rows: any[] }) => data.rows,
    }),
  }),
})

export const { useGetCustomerSectorsQuery } = customerSectorsRtkApi

export default customerSectorsRtkApi
