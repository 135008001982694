import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { type Profile, type ProfileForm } from 'App/Profiles/profiles-types'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FormField from 'common/components/FormField'
import Button from 'common/components/Button'
import Panel from 'common/components/Panel'
import {
  updateProfile,
  fetchProfiles,
  resetUpdateProfile,
} from 'App/Profiles/profiles-state'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  GeneralSettings: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  EditForm: {
    fontSize: '16px',
  },
  EditFormBody: {
    '& > div': {
      marginBottom: theme.spacing(3),
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  EditFormLabel: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  EditFormBodyEditable: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
  },
}))

interface EditFormProps {
  profile?: Profile
  updatedProfile: boolean
}
interface FieldErrors {
  name?: string
  description?: string
}

const initialProfileForm = {
  name: '',
  description: '',
}

const EditForm = ({ profile, updatedProfile }: EditFormProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const [editable, setEditable] = useState(false)
  const [form, setForm] = useState<ProfileForm>(initialProfileForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  useEffect(() => {
    if (profile != null) {
      setForm(profile)
      if (updatedProfile) {
        dispatch(resetUpdateProfile())
        dispatch(
          fetchProfiles({
            organizationId: profile.organizationId,
            siteId: profile.siteId,
          })
        )
        setEditable(false)
      }
    }
  }, [dispatch, profile, updatedProfile])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({ ...form, [key]: e.target.value })
    }

  const onSave: React.FormEventHandler = (e) => {
    e.preventDefault()

    if (profile == null) {
      return false
    }

    let hasError = false
    const errors: FieldErrors = {}

    if (form.name === '') {
      hasError = true
      errors.name = t('common.errors.emptyField')
    }

    if (form.description === '') {
      hasError = true
      errors.description = t('common.errors.emptyField')
    }

    if (!hasError) {
      dispatch(
        updateProfile({
          context: {
            organizationId: profile.organizationId,
            siteId: profile.siteId,
          },
          form,
          profileId: profile.id,
        })
      )
    }

    setFieldErrors(errors)
  }

  return (
    <Panel
      title={
        <Grid container style={{ alignItems: 'center' }}>
          <Grid item style={{ flexGrow: 1 }}>
            {t('profiles.editProfile.general.edit.title')}
          </Grid>
          <Grid item>
            <Button small flat onClick={() => setEditable(!editable)}>
              {editable
                ? t('profiles.editProfile.general.edit.cancelButton')
                : t('profiles.editProfile.general.edit.editButton')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <div className={classes.EditForm}>
        {editable ? (
          <div className={classes.EditFormBodyEditable}>
            <FormField
              label={t('profiles.editProfile.general.edit.nameField.label')}
              placeholder={t(
                'profiles.editProfile.general.edit.nameField.placeholder'
              )}
              value={form.name}
              onChange={onChangeField('name')}
              error={fieldErrors.name}
              autoFocus
            />
            <FormField
              label={t(
                'profiles.editProfile.general.edit.descriptionField.label'
              )}
              placeholder={t(
                'profiles.editProfile.general.edit.descriptionField.placeholder'
              )}
              value={form.description}
              onChange={onChangeField('description')}
              error={fieldErrors.description}
              autoFocus
            />
            <Button small onClick={onSave}>
              {t('profiles.editProfile.general.edit.saveButton')}
            </Button>
          </div>
        ) : (
          <div className={classes.EditFormBody}>
            <div>
              <div className={classes.EditFormLabel}>
                {t('profiles.editProfile.general.edit.nameField.label')}
              </div>
              <div>{form.name}</div>
            </div>
            <div>
              <div className={classes.EditFormLabel}>
                {t('profiles.editProfile.general.edit.descriptionField.label')}
              </div>
              <div>{form.description}</div>
            </div>
          </div>
        )}
      </div>
    </Panel>
  )
}

interface GeneralSettingsProps {
  profile?: Profile
}

const GeneralSettings = ({ profile }: GeneralSettingsProps) => {
  const { classes } = useStyles()
  const updatedProfile = useAppSelector(
    (state) => state.profiles.updatedProfile
  )

  return (
    <div className={classes.GeneralSettings}>
      <EditForm profile={profile} updatedProfile={updatedProfile} />
    </div>
  )
}

export default GeneralSettings
