import React, { type PropsWithChildren } from 'react'
import { Grid, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  PageActions: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '24px',
    },
  },
}))

type PageActionsProps = PropsWithChildren

const PageActions = ({ children }: PageActionsProps) => {
  const { classes } = useStyles()

  return (
    <Grid className={classes.PageActions} item>
      {children}
    </Grid>
  )
}

export default PageActions
