import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableActionsBar from 'common/components/TableActionsBar'
import { type Tablet } from 'App/Tablets/tablets-types'

import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import LockIcon from '@mui/icons-material/LockOutlined'
import RebootIcon from '@mui/icons-material/PowerSettingsNew'
import { useTranslation } from 'react-i18next'
import ActionDialog from 'common/components/ActionDialog'
import useItemListToString from 'common/hooks/useItemListToString'
import {
  useLockTabletsMutation,
  useRebootTabletsMutation,
  useRemoveTabletsMutation,
} from 'App/Tablets/tablets-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import Toast from 'common/components/Toast'
import { fetchTablets } from 'App/Tablets/tablets-state'
import { useAppDispatch } from 'store'

interface SelectedTabletsLockActionDialogProps {
  open: boolean
  tabletsToLock: Tablet[]
  onClose: () => void
}

const SelectedTabletsLockActionDialog = ({
  open,
  tabletsToLock,
  onClose,
}: SelectedTabletsLockActionDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const tabletListToString = useItemListToString<Tablet>(
    (t) => t.serialNumber,
    'tablets.remaining'
  )

  const [
    lockTablets,
    { isSuccess: isLockTabletsSuccess, error: lockTabletsError },
  ] = useLockTabletsMutation()
  const [lockingTabletsCount, setLockingTabletsCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    lockTabletsError,
  ])

  useEffect(() => {
    if (isLockTabletsSuccess) {
      dispatch(fetchTablets({ organizationId, siteId }))
      onClose()
    }
  }, [dispatch, organizationId, siteId, isLockTabletsSuccess, onClose])

  const handleActionLockSelectedTablets = () => {
    const tabletIds = tabletsToLock.map((t) => t.id)
    lockTablets({
      organizationId,
      siteId,
      tabletIds,
    })
    setLockingTabletsCount(tabletIds.length)
  }

  return (
    <>
      <ActionDialog
        open={open}
        title={t('tablets.lockTablets.title', { count: tabletsToLock.length })}
        description={t('tablets.lockTablets.description', {
          tablets: tabletListToString(tabletsToLock),
        })}
        actionText={t('tablets.listTablets.actions.lock')}
        onAction={handleActionLockSelectedTablets}
        onClose={onClose}
        closeOnAction={false}
      >
        {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
      </ActionDialog>
      <Toast
        message={t('tablets.lockTablets.toast', { count: lockingTabletsCount })}
        open={isLockTabletsSuccess}
      />
    </>
  )
}

interface SelectedTabletsRebootActionDialogProps {
  open: boolean
  tabletsToReboot: Tablet[]
  onClose: () => void
}

const SelectedTabletsRebootActionDialog = ({
  open,
  tabletsToReboot,
  onClose,
}: SelectedTabletsRebootActionDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const tabletListToString = useItemListToString<Tablet>(
    (t) => t.serialNumber,
    'tablets.remaining'
  )

  const [
    rebootTablets,
    { isSuccess: isRebootTabletsSuccess, error: rebootTabletsError },
  ] = useRebootTabletsMutation()
  const [rebootingTabletsCount, setRebootingTabletsCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    rebootTabletsError,
  ])

  useEffect(() => {
    if (isRebootTabletsSuccess) {
      dispatch(fetchTablets({ organizationId, siteId }))
      onClose()
    }
  }, [dispatch, organizationId, siteId, isRebootTabletsSuccess, onClose])

  const handleActionRebootSelectedTablets = () => {
    const tabletIds = tabletsToReboot.map((t) => t.id)
    rebootTablets({
      organizationId,
      siteId,
      tabletIds,
    })
    setRebootingTabletsCount(tabletIds.length)
  }

  return (
    <>
      <ActionDialog
        open={open}
        title={t('tablets.rebootTablets.title', {
          count: tabletsToReboot.length,
        })}
        description={t('tablets.rebootTablets.description', {
          tablets: tabletListToString(tabletsToReboot),
        })}
        actionText={t('tablets.listTablets.actions.reboot')}
        onAction={handleActionRebootSelectedTablets}
        onClose={onClose}
        closeOnAction={false}
      >
        {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
      </ActionDialog>
      <Toast
        message={t('tablets.rebootTablets.toast', {
          count: rebootingTabletsCount,
        })}
        open={isRebootTabletsSuccess}
      />
    </>
  )
}

interface SelectedTabletsRemoveActionDialogProps {
  open: boolean
  tabletsToRemove: Tablet[]
  onClose: () => void
}

const SelectedTabletsRemoveActionDialog = ({
  open,
  tabletsToRemove,
  onClose,
}: SelectedTabletsRemoveActionDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const tabletListToString = useItemListToString<Tablet>(
    (t) => t.serialNumber,
    'tablets.remaining'
  )

  const [
    removeTablets,
    { isSuccess: isRemoveTabletsSuccess, error: removeTabletsError },
  ] = useRemoveTabletsMutation()
  const [removingTabletsCount, setRemovingTabletsCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    removeTabletsError,
  ])

  useEffect(() => {
    if (isRemoveTabletsSuccess) {
      dispatch(fetchTablets({ organizationId, siteId }))
      onClose()
    }
  }, [dispatch, organizationId, siteId, isRemoveTabletsSuccess, onClose])

  const handleActionRemoveSelectedTablets = () => {
    const tabletIds = tabletsToRemove.map((t) => t.id)
    removeTablets({
      organizationId,
      siteId,
      tabletIds,
    })
    setRemovingTabletsCount(tabletIds.length)
  }

  return (
    <>
      <ActionDialog
        open={open}
        title={t('tablets.removeTablets.title', {
          count: tabletsToRemove.length,
        })}
        description={t('tablets.removeTablets.description', {
          tablets: tabletListToString(tabletsToRemove),
        })}
        actionText={t('tablets.listTablets.actions.remove')}
        onAction={handleActionRemoveSelectedTablets}
        onClose={onClose}
        closeOnAction={false}
      >
        {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
      </ActionDialog>
      <Toast
        message={t('tablets.removeTablets.toast', {
          count: removingTabletsCount,
        })}
        open={isRemoveTabletsSuccess}
      />
    </>
  )
}

interface TabletsTableActionsBarProps {
  showDeleted: boolean
  onChangeShowDeleted: (showDeleted: boolean) => void
  selectedTablets: Tablet[]
  searchQuery: string
  onSearch: (query: string) => void
}

enum SelectedTabletsActions {
  Lock,
  Reboot,
  Remove,
}

const TabletsTableActionsBar = ({
  showDeleted,
  onChangeShowDeleted,
  selectedTablets,
  searchQuery,
  onSearch,
}: TabletsTableActionsBarProps) => {
  const { t } = useTranslation()
  const [selectedTabletsAction, setselectedTabletsAction] = useState<
    SelectedTabletsActions | undefined
  >()

  const selectedTabletsActions = useMemo(() => {
    const actions = [
      {
        local: 'tablets.listTablets.actions.lock',
        fn: () => setselectedTabletsAction(SelectedTabletsActions.Lock),
        icon: LockIcon,
      },
      {
        local: 'tablets.listTablets.actions.reboot',
        fn: () => setselectedTabletsAction(SelectedTabletsActions.Reboot),
        icon: RebootIcon,
      },
      {
        local: 'tablets.listTablets.actions.remove',
        icon: DeleteIcon,
        fn: () => setselectedTabletsAction(SelectedTabletsActions.Remove),
      },
    ]

    return actions
  }, [])

  const handleCloseAction = useCallback(() => {
    setselectedTabletsAction(undefined)
  }, [])

  return (
    <>
      <TableActionsBar
        showDeleted={showDeleted}
        onChangeShowDeleted={onChangeShowDeleted}
        searchQuery={searchQuery}
        onSearch={onSearch}
        selectedRows={selectedTablets}
        selectedRowsLabel={t('tablets.listTablets.selectedRows.label', {
          count: selectedTablets.length,
        })}
        selectedRowsActions={selectedTabletsActions}
      />
      <SelectedTabletsLockActionDialog
        open={selectedTabletsAction === SelectedTabletsActions.Lock}
        tabletsToLock={selectedTablets}
        onClose={handleCloseAction}
      />
      <SelectedTabletsRebootActionDialog
        open={selectedTabletsAction === SelectedTabletsActions.Reboot}
        tabletsToReboot={selectedTablets}
        onClose={handleCloseAction}
      />
      <SelectedTabletsRemoveActionDialog
        open={selectedTabletsAction === SelectedTabletsActions.Remove}
        tabletsToRemove={selectedTablets}
        onClose={handleCloseAction}
      />
    </>
  )
}

export default TabletsTableActionsBar
