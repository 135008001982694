import React, { useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import AddPostDrawer from 'App/Posts/components/AddPostDrawer'
import PostsTable from 'App/Posts/components/PostsTable'
import Toast from 'common/components/Toast'
import PageEmpty from 'common/components/PageEmpty'
import { renderIf } from 'common/utils/render-utils'
import usePosts from 'common/hooks/usePosts'
import useSiteEnrolled from 'common/hooks/useSiteEnrolled'
import PageRequirement from 'common/components/PageRequirement'
import { useAppSelector } from 'store'

const Posts = () => {
  const { t } = useTranslation()
  const [openAddPostDrawer, setOpenAddPostDrawer] = useState(false)

  const posts = usePosts()
  const fetchedPosts = useAppSelector((state) => state.posts.fetchedPosts)
  const addedPost = useAppSelector((state) => state.posts.addedPost)
  const updatedPost = useAppSelector((state) => state.posts.updatedPost)
  const deletedPost = useAppSelector((state) => state.posts.deletedPost)

  const siteEnrolled = useSiteEnrolled()

  const onClickAddPost = () => setOpenAddPostDrawer(true)
  const onCloseAddPostDrawer = () => setOpenAddPostDrawer(false)

  if (!fetchedPosts) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageRequirement
          criteria={siteEnrolled}
          title={t('posts.requirement.title')}
          description={t('posts.requirement.description')}
          actionText={t('posts.requirement.action')}
          actionTo="/site-settings"
        >
          {renderIf(posts.length === 0, () => (
            <PageEmpty
              title={t('posts.empty.title')}
              description={t('posts.empty.description')}
              actionText={t('posts.empty.actionText')}
              onClickAction={onClickAddPost}
            />
          ))}
          {renderIf(posts.length > 0, () => (
            <>
              <PageHeader>
                <PageTitle title={t('posts.title')} />
                <PageActions>
                  <Button onClick={onClickAddPost} small>
                    {t('posts.actions.addPost')}
                  </Button>
                </PageActions>
              </PageHeader>
              <PageBody>
                <PostsTable />
              </PageBody>
            </>
          ))}
        </PageRequirement>
      </Page>
      <AddPostDrawer open={openAddPostDrawer} onClose={onCloseAddPostDrawer} />
      <Toast open={addedPost} message={t('posts.addPost.toast.success')} />
      <Toast open={updatedPost} message={t('posts.editPost.toast.success')} />
      <Toast open={deletedPost} message={t('posts.deletePost.toast.success')} />
    </>
  )
}

export default Posts
