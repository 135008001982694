import { type Customer } from 'App/Customers/customers-types'
import rtkApi from 'rtk-api'

interface CustomersApi {
  distributorId: number
}

const customersRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<Customer[], CustomersApi>({
      query: ({ distributorId }) => `/distributors/${distributorId}/customers`,
    }),
  }),
})

export const { useGetCustomersQuery } = customersRtkApi
export default customersRtkApi
