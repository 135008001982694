import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Distributor,
  type DistributorForm,
} from 'App/Distributors/distributors-types'

class DistributorsApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchDistributors(): Promise<Distributor[]> {
    const { data } = await axios.get(`${API_BASE_URL}/distributors`)
    return data as Distributor[]
  }

  static async fetchDistributor(distributorId: number): Promise<Distributor> {
    const { data } = await axios.get(
      `${API_BASE_URL}/distributors/${distributorId}`
    )
    return data as Distributor
  }

  static async createDistributor(form: DistributorForm): Promise<Distributor> {
    const { data } = await axios.post(`${API_BASE_URL}/distributors`, form)
    return data as Distributor
  }

  static async updateDistributor(
    distributorId: number,
    form: DistributorForm
  ): Promise<Distributor> {
    const { data } = await axios.put(
      `${API_BASE_URL}/distributors/${distributorId}`,
      form
    )
    return data as Distributor
  }

  static async deleteDistributor(distributorId: number): Promise<void> {
    await axios.delete(`${API_BASE_URL}/distributors/${distributorId}`)
  }
}

export default DistributorsApi
