import React, { useMemo } from 'react'
import Panel from 'common/components/NewPanel'
import { type Tablet } from 'App/Tablets/tablets-types'
import PanelField from 'common/components/PanelField'
import { addMinutes, isPast } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'

const OUT_OF_NETWORK_TRESHOLD = 5 // minutes
const LOW_BATTERY_TRESHOLD = 20 // percentages

enum BorrowingStatus {
  CanBorrowFromDock = 'CAN_BORROW_FROM_DOCK',
  CanBorrowFromTablet = 'CAN_BORROW_FROM_TABLET',
  OutOfNetwork = 'OUT_OF_NETWORK',
  LowBattery = 'LOW_BATTERY',
  Borrowed = 'BORROWED',
}

const getTabletBorrowingStatus = (tablet: Tablet): BorrowingStatus => {
  const outOfNetworkTresholdTime =
    tablet.activeAt != null &&
    addMinutes(new Date(tablet.activeAt), OUT_OF_NETWORK_TRESHOLD)
  if (outOfNetworkTresholdTime !== false && isPast(outOfNetworkTresholdTime)) {
    // If tablet has not been active within the past 5 minutes
    return BorrowingStatus.OutOfNetwork
  } else if (tablet.loanKey) {
    // If the tablet has a loan session key associated with it
    return BorrowingStatus.Borrowed
  } else if (
    tablet.batteryLevelFromDeviceApi &&
    tablet.batteryLevelFromDeviceApi < LOW_BATTERY_TRESHOLD
  ) {
    // If the tablet's battery level (as reported by the tablet) is less than 20%
    return BorrowingStatus.LowBattery
  } else if (tablet.dockId && tablet.dockSlot) {
    // If the tablet is assigned to some slot in some dock
    return BorrowingStatus.CanBorrowFromDock
  } else {
    // If none of the previous conditions apply, we assume it is ready to be borrowed from a tablet
    return BorrowingStatus.CanBorrowFromTablet
  }
}

interface ViewTabletLoansTabProps {
  tablet: Tablet
}

const ViewTabletLoansTab = ({ tablet }: ViewTabletLoansTabProps) => {
  const { t } = useTranslation()

  const borrowingStatus = useMemo(() => {
    return getTabletBorrowingStatus(tablet)
  }, [tablet])

  const explanationProps = {
    minutes: OUT_OF_NETWORK_TRESHOLD,
    batteryLevel: LOW_BATTERY_TRESHOLD,
  }

  return (
    <Panel title={t('tablets.viewTablet.loans.borrowingStatus.title')}>
      <PanelField
        title={t('tablets.viewTablet.loans.fields.status.label')}
        value={borrowingStatus}
      />
      <Divider light />
      <em>
        {t(
          `tablets.viewTablet.loans.explanations.${borrowingStatus}`,
          explanationProps
        )}
      </em>
    </Panel>
  )
}

export default ViewTabletLoansTab
