import rtkApi from 'rtk-api'

interface LoansApi {
  organizationId: number
  siteId: number
}
type LoanApi = LoansApi & { loanId: number }

const loansRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    extendLoan: builder.mutation<null, LoanApi>({
      query: ({ organizationId, siteId, loanId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/loans/${loanId}/extend`,
        method: 'POST',
      }),
    }),
    endLoan: builder.mutation<null, LoanApi>({
      query: ({ organizationId, siteId, loanId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/loans/${loanId}/end`,
        method: 'POST',
      }),
    }),
    terminateLoan: builder.mutation<null, LoanApi>({
      query: ({ organizationId, siteId, loanId }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/loans/${loanId}/terminate`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useExtendLoanMutation,
  useEndLoanMutation,
  useTerminateLoanMutation,
} = loansRtkApi

export default loansRtkApi
