import { useEffect } from 'react'
import { fetchPosts } from 'App/Posts/posts-state'
import { type Post } from 'App/Posts/posts-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

const usePosts = (): Post[] => {
  const dispatch = useAppDispatch()

  const { organizationId, siteId } = useCurrentAccount()
  const posts = useAppSelector((state) => state.posts.posts)

  useEffect(() => {
    dispatch(fetchPosts({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  return posts
}

export default usePosts
