import type { Quarter } from '../../../types/common'
import type { Localize, LocalizeFn } from '../../../types/locale'
import buildLocalizeFn from '../../../_lib/buildLocalizeFn/index'

const eraValues = {
  nattorw: ['RC', 'AD'] as const,
  abbreviated: ['RC', 'AD'] as const,
  wide: ['Roimh Chriost', 'Anno Domini'] as const,
}

const quarterValues = {
  narrow: ['1', '2', '3', '4'] as const,
  abbreviated: ['R1', 'R2', 'R3', 'R4'] as const,
  wide: ['1ú ráithe', '2ú ráithe', '3ú ráithe', '4ú ráithe'] as const,
}

const monthValues = {
  narrow: ['E', 'F', 'M', 'A', 'B', 'M', 'I', 'L', 'M', 'D', 'S', 'N'] as const,
  abbreviated: [
    'Ean',
    'Feabh',
    'Márta',
    'Aib',
    'Beal',
    'Meith',
    'Iúil',
    'Lún',
    'MFómh',
    'DFómh',
    'Samh',
    'Noll',
  ] as const,
  wide: [
    'Eanáir',
    'Feabhra',
    'Márta',
    'Aibreán',
    'Bealtaine',
    'Meitheamh',
    'Iúil',
    'Lúnasa',
    'Meán Fómhair',
    'Deireadh Fómhair',
    'Samhain',
    'Nollaig',
  ] as const,
}

const dayValues = {
  narrow: ['D', 'L', 'M', 'C', 'D', 'A', 'S'] as const,
  short: ['Do', 'Lu', 'Má', 'Cé', 'Dé', 'Ao', 'Sa'] as const,
  abbreviated: [
    'Domh',
    'Luan',
    'Máirt',
    'Céad',
    'Déar',
    'Aoine',
    'Sath',
  ] as const,
  wide: [
    'Dé Domhnaigh',
    'Dé Luain',
    'Dé Máirt',
    'Dé Céadaoin',
    'Déardaoin',
    'Dé hAoine',
    'Dé Sathairn',
  ] as const,
}

const dayPeriodValues = {
  narrow: {
    am: 'a',
    pm: 'p',
    midnight: 'meå',
    noon: 'me',
    morning: 'am',
    afternoon: 'in',
    evening: 'eveningpm',
    night: 'oi',
  },
  abbreviated: {
    am: 'am', // or r.n.
    pm: 'pm', // or i.n
    midnight: 'meå',
    noon: 'me',
    morning: 'am',
    afternoon: 'in',
    evening: 'pm',
    night: 'oi',
  },
  wide: {
    am: 'a.m.',
    pm: 'p.m.',
    midnight: 'meán oíche',
    noon: 'meán lae',
    morning: 'maidin',
    afternoon: 'iarnóin',
    evening: 'tráthnóna',
    night: 'oíche',
  },
}

const formattingDayPeriodValues = {
  narrow: {
    am: 'a',
    pm: 'p',
    midnight: 'meå',
    noon: 'me',
    morning: 'am',
    afternoon: 'in',
    evening: 'eveningpm',
    night: 'oi',
  },
  abbreviated: {
    am: 'am', // or r.n.
    pm: 'pm', // or i.n
    midnight: 'meå',
    noon: 'me',
    morning: 'am',
    afternoon: 'in',
    evening: 'pm',
    night: 'oi',
  },
  wide: {
    am: 'a.m.',
    pm: 'p.m.',
    midnight: 'meán oíche',
    noon: 'meán lae',
    morning: 'ar maidin',
    afternoon: 'iarnóin',
    evening: 'tráthnóna',
    night: 'san oíche',
  },
}

const ordinalNumber: LocalizeFn<number, undefined> = (
  dirtyNumber,
  _options
) => {
  const number = Number(dirtyNumber)
  return `${number}ú`
}

const localize: Localize = {
  ordinalNumber,

  era: buildLocalizeFn({
    values: eraValues,
    defaultWidth: 'wide',
  }),

  quarter: buildLocalizeFn({
    values: quarterValues,
    defaultWidth: 'wide',
    argumentCallback: (quarter) => (quarter - 1) as Quarter,
  }),

  month: buildLocalizeFn({
    values: monthValues,
    defaultWidth: 'wide',
  }),

  day: buildLocalizeFn({
    values: dayValues,
    defaultWidth: 'wide',
  }),

  dayPeriod: buildLocalizeFn({
    values: dayPeriodValues,
    defaultWidth: 'wide',
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: 'wide',
  }),
}

export default localize
