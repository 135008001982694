import React from 'react'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  DeletedRowCell: {
    lineHeight: 1,
  },
}))

interface MaybeDeletedRowCellProps {
  deletedAt?: any
  content: React.ReactNode
}

const MaybeDeletedRowCell = ({
  deletedAt,
  content,
}: MaybeDeletedRowCellProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  if (deletedAt === undefined) return <>{content}</>

  return (
    <Grid container alignItems="center" className={classes.DeletedRowCell}>
      <Grid item title={t('common.deletedRowCell.iconLabel')}>
        <DeleteIcon />
      </Grid>
      <Grid item>{content}</Grid>
    </Grid>
  )
}

export default MaybeDeletedRowCell
