import rtkApi from 'rtk-api'

const myAccountRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    updateCurrentUserProfile: builder.mutation<
      null,
      { firstName: string; lastName: string }
    >({
      query: ({ firstName, lastName }) => ({
        url: '/user/profile',
        method: 'PUT',
        body: { firstName, lastName },
      }),
    }),
  }),
})

export const { useUpdateCurrentUserProfileMutation } = myAccountRtkApi

export default myAccountRtkApi
