import React, { type FormEventHandler, useEffect, useState } from 'react'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useParams } from 'react-router-dom'
import logo from 'common/hublet-manager-logo.svg'
import FormField from 'common/components/FormField'
import Button from 'common/components/Button'
import {
  acceptInvitation,
  fetchInvitedUser,
} from 'App/Invitations/invitations-state'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  Container: {
    minHeight: '100vh',
    margin: '0 auto',
    '& > div': {
      maxWidth: '324px !important',
      width: '100%',
    },
  },
  Logo: {
    marginBottom: '24px',
    textAlign: 'center',
    lineHeight: '1',
    '& > img': {
      width: '152px',
      margin: '-4px',
    },
  },
  InfoText: {
    maxWidth: '100% !important',
    marginBottom: '24px',
    textAlign: 'center',
    fontSize: '18px',
  },
  Input: {
    marginBottom: '16px',
  },
  ErrorBox: {
    marginBottom: '24px',
    padding: '20px 24px',
    borderRadius: '5px',
    backgroundColor: theme.palette.hublet.error,
    color: theme.palette.hublet.common.white,
    fontSize: '16px',
    lineHeight: '22px',
  },
}))

interface FormErrors {
  password?: string
  firstName?: string
  lastName?: string
}

const Invitations = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const [password, setPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const [errors, setErrors] = useState<FormErrors>({})

  const { token } = useParams<{ token: string }>()

  const { fetchedInvitedUser, invitedUser, acceptedInvitation } =
    useAppSelector((state) => state.invitations)

  useEffect(() => {
    if (!fetchedInvitedUser && token !== undefined) {
      dispatch(fetchInvitedUser({ token }))
    }
  }, [dispatch, fetchedInvitedUser, token])

  const validateForm = () => {
    let hasError = false
    const errors: FormErrors = {}

    if (password === '') {
      hasError = true
      errors.password = t('invitations.form.errors.requiredField')
    }

    if (firstName === '') {
      hasError = true
      errors.firstName = t('invitations.form.errors.requiredField')
    }

    if (lastName === '') {
      hasError = true
      errors.lastName = t('invitations.form.errors.requiredField')
    }

    return { hasError, errors }
  }

  const onSubmitForm: FormEventHandler = (e) => {
    e.preventDefault()

    if (token === undefined) {
      return false
    }

    const { hasError, errors } = validateForm()

    setErrors(errors)

    if (hasError) {
      return false
    }

    const userData = {
      token,
      firstName,
      lastName,
      password,
    }

    dispatch(acceptInvitation(userData))

    return false
  }

  if (!fetchedInvitedUser || token === undefined) {
    return null
  }

  if (invitedUser === undefined) {
    return <div>{t('invitations.invalidToken')}</div>
  }

  if (acceptedInvitation) {
    window.location.href = `/ui/login#${invitedUser.email}`
    return null
  }

  const { hasError } = validateForm()

  return (
    <form onSubmit={onSubmitForm}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.Container}
      >
        <Grid item xs={12} className={classes.Logo}>
          <img src={logo} alt="Hublet" />
        </Grid>
        <Grid item xs={12} className={classes.InfoText}>
          {t('invitations.info.explanation')}
          <br />
          {t('invitations.info.instruction')}
        </Grid>
        <Grid item xs={12} className={classes.Input}>
          <FormField
            placeholder={t('invitations.form.fields.email')}
            value={invitedUser.email}
            rounded
            readOnly
          />
        </Grid>
        <Grid item xs={12} className={classes.Input}>
          <FormField
            placeholder={t('invitations.form.fields.firstName')}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name="first-name"
            error={errors.firstName}
            rounded
          />
        </Grid>
        <Grid item xs={12} className={classes.Input}>
          <FormField
            placeholder={t('invitations.form.fields.lastName')}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name="last-name"
            error={errors.lastName}
            rounded
          />
        </Grid>
        <Grid item xs={12} className={classes.Input}>
          <FormField
            placeholder={t('invitations.form.fields.password')}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            error={errors.password}
            rounded
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" disabled={hasError}>
            {t('invitations.submit')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Invitations
