export const listToQueryParams = (
  key: string,
  list: Array<string | number>
): string => {
  return list.reduce((acc: string, item) => {
    if (acc !== '') acc += '&'
    acc += `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`
    return acc
  }, '')
}
