import { useEffect } from 'react'
import {
  fetchCategories,
  resetDeleteCategory,
} from 'App/Categories/categories-state'
import { type Category } from 'App/Categories/categories-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

const useCategories = (profileId: number): Category[] => {
  const dispatch = useAppDispatch()

  const { siteId, organizationId } = useCurrentAccount()

  const categories = useAppSelector((state) => state.categories.categories)

  const deletedCategory = useAppSelector(
    (state) => state.categories.deletedCategory
  )

  useEffect(() => {
    dispatch(fetchCategories({ organizationId, siteId, profileId }))
  }, [dispatch, organizationId, siteId, profileId])

  useEffect(() => {
    if (deletedCategory) {
      dispatch(resetDeleteCategory())
      dispatch(fetchCategories({ organizationId, siteId, profileId }))
    }
  }, [dispatch, deletedCategory, organizationId, siteId, profileId])

  return categories
}

export default useCategories
