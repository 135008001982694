import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { type Theme, LinearProgress } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import FormField from 'common/components/FormField'
import DrawerForm from 'common/components/DrawerForm'

import { type Translation } from 'App/Translations/translations-types'
import FormSelect from 'common/components/FormSelect'
import { useAppDispatch, useAppSelector } from 'store'

import {
  translate,
  resetSuggestedTranslations,
} from 'App/Translations/translations-state'

const useStyles = makeStyles()((theme: Theme) => ({
  FormField: {
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: '0',
    },
  },
}))

interface FieldErrors {
  key?: string
}

interface TranslationFormProps {
  canEditKey?: boolean
  open: boolean
  loading?: boolean
  onClose: () => void
  onSubmitTranslation: (translation: Translation) => void
  translation: Translation | null
}

const TranslationForm = ({
  canEditKey = false,
  open,
  loading = false,
  onClose,
  onSubmitTranslation,
  translation,
}: TranslationFormProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const languages = useAppSelector((state) => state.translations.allLanguages)

  const suggestedTranslations = useAppSelector(
    (state) => state.translations.suggestedTranslations
  )

  const [currentTranslation, setCurrentTranslation] = useState<Translation>({
    app: 'web',
    key: '',
    translations: [],
  })

  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const appOptions = [
    {
      value: 'web',
      label: 'Web',
    },
    {
      value: 'android',
      label: 'Android',
    },
    {
      value: 'dock',
      label: t('translations.editTranslation.environments.dock'),
    },
  ]

  const onChangeTranslation = (l: string, value: string) => {
    const translations = currentTranslation.translations?.map((tr) =>
      tr.language === l ? { ...tr, translation: value } : { ...tr }
    )
    setCurrentTranslation({ ...currentTranslation, translations })
  }

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentTranslation({ ...currentTranslation, [key]: e.target.value })
    }

  useEffect(() => {
    if (translation != null) {
      setCurrentTranslation({
        app: translation.app,
        key: translation.key,
        translations: languages.map((language) => ({
          language,
          translation:
            translation.translations?.find((t) => t.language === language)
              ?.translation ?? '',
        })),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translation])

  useEffect(() => {
    if (suggestedTranslations.length > 0) {
      setCurrentTranslation({
        ...currentTranslation,
        translations: suggestedTranslations,
      })
      dispatch(resetSuggestedTranslations())
    }
  }, [dispatch, currentTranslation, suggestedTranslations])

  const onSubmit: React.FormEventHandler = (e) => {
    e.preventDefault()

    if (translation == null) {
      return false
    }

    let hasError = false
    const errors: FieldErrors = {}

    if (currentTranslation.key === '') {
      hasError = true
      errors.key = t('translations.editTranslation.errors.emptyKey')
    }

    if (!hasError) {
      onSubmitTranslation(currentTranslation)
    }

    setFieldErrors(errors)
  }

  const onRetranslate = () => {
    const text =
      currentTranslation?.translations?.find((t) => t.language === 'en')
        ?.translation ?? ''
    if (text.length > 0) {
      dispatch(translate({ text, languages }))
    }
  }

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title={t('translations.editTranslation.title')}
      submitText={t('translations.editTranslation.buttons.submit')}
      disabledButton={loading}
      extraActions={[
        {
          text: t('translations.editTranslation.buttons.retranslate'),
          onClick: onRetranslate,
        },
      ]}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <FormField
            className={classes.FormField}
            label={t('translations.editTranslation.translationKeyField.label')}
            placeholder={t(
              'translations.editTranslation.translationKeyField.placeholder'
            )}
            value={currentTranslation.key}
            disabled={!canEditKey}
            autoFocus={canEditKey}
            onChange={onChangeField('key')}
            error={fieldErrors.key}
          />
          <FormSelect
            className={classes.FormField}
            label={t('translations.editTranslation.translationAppField.label')}
            placeholder={t(
              'translations.editTranslation.translationAppField.placeholder'
            )}
            value={currentTranslation.app}
            options={appOptions}
            onChange={onChangeField('app')}
            disabled={!canEditKey}
          />
          {currentTranslation.translations?.map((tr, i) => (
            <FormField
              key={tr.language}
              className={classes.FormField}
              label={t(`languages.${tr.language}`)}
              value={tr.translation}
              onChange={(e) => onChangeTranslation(tr.language, e.target.value)}
              readOnly={loading}
              autoFocus={!canEditKey && i === 0}
            />
          ))}
        </>
      )}
    </DrawerForm>
  )
}

export default TranslationForm
