import { useTranslation } from 'react-i18next'

const useItemListToString = <T>(
  renderItem: (item: T) => string,
  remainingTKey: string
) => {
  const { t } = useTranslation()

  return (items: T[]): string => {
    let text = items.slice(0, 3).map(renderItem).join(', ')
    const remainingUsersCount = items.length - 3
    if (remainingUsersCount > 0) {
      text += t(remainingTKey, { count: remainingUsersCount })
    }
    return text
  }
}

export default useItemListToString
