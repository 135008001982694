import type React from 'react'
import { useEffect, useState } from 'react'
import {
  type Network,
  type NetworkForm,
  SSIDVisibility,
} from 'App/Networks/networks-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useTranslation } from 'react-i18next'
import {
  addNetwork,
  fetchNetworks,
  resetAddNetwork,
  resetUpdatedNetwork,
  updateNetwork,
} from 'App/Networks/networks-state'
import { useAppDispatch, useAppSelector } from 'store'

export interface NetworkFormErrors {
  ssid?: string
  securityMode?: string
}

const initialNetworkForm = {
  ssid: '',
  visibility: SSIDVisibility.BROADCAST,
}

const useNetworkForm = (network?: Network): any => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { siteId, organizationId } = useCurrentAccount()
  const updatedNetwork = useAppSelector(
    (state) => state.networks.updatedNetwork
  )
  const addedNetwork = useAppSelector((state) => state.networks.addedNetwork)

  const [networkForm, setNetworkForm] =
    useState<NetworkForm>(initialNetworkForm)
  const [fieldErrors, setFieldErrors] = useState<NetworkFormErrors>({})

  useEffect(() => {
    if (network != null) {
      setNetworkForm(network)
    }
  }, [network])

  useEffect(() => {
    if (updatedNetwork) {
      setNetworkForm(initialNetworkForm)
      dispatch(resetUpdatedNetwork())
      dispatch(fetchNetworks({ siteId, organizationId }))
    }
  }, [dispatch, updatedNetwork, siteId, organizationId])

  useEffect(() => {
    if (addedNetwork) {
      setNetworkForm(initialNetworkForm)
      dispatch(resetAddNetwork())
      dispatch(fetchNetworks({ siteId, organizationId }))
    }
  }, [dispatch, addedNetwork, siteId, organizationId])

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setNetworkForm({ ...networkForm, [key]: e.target.value })
    }

  const validateForm = (): boolean => {
    let hasError = false
    const errors: NetworkFormErrors = {}

    if (networkForm.ssid === '') {
      hasError = true
      errors.ssid = t('networks.addNetwork.errors.emptyField')
    }

    if (networkForm.securityMode === undefined) {
      hasError = true
      errors.securityMode = t('networks.addNetwork.errors.emptyField')
    }

    setFieldErrors(errors)

    return hasError
  }

  const handleUpdate = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (network == null) {
      return
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(
        updateNetwork({
          networkId: network.id,
          form: networkForm,
          siteId,
          organizationId,
        })
      )
    }
  }

  const handleAdd = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (!networkForm) {
      return false
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(addNetwork({ form: networkForm, siteId, organizationId }))
    }
  }

  return {
    networkForm,
    fieldErrors,
    handleFieldChange,
    handleUpdate,
    handleAdd,
    updatedNetwork,
    addedNetwork,
  }
}

export default useNetworkForm
