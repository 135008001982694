import React from 'react'
import { LoanStatus } from 'App/Loans/loans-types'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import useRenderDate from 'common/hooks/useRenderDate'

const useStyles = makeStyles()((theme: Theme) => ({
  LoanStatusGreen: {
    fontWeight: 'bold',
    color: '#64AB43',
  },
  LoanStatusRed: {
    fontWeight: 'bold',
    color: theme.palette.hublet.error,
  },
  LoanTabletActiveAt: {
    display: 'block',
    fontSize: '12px',
    color: theme.palette.hublet.secondary.main,
  },
}))

interface LoanStatusProps {
  status?: LoanStatus
  tabletActiveAt?: Date
}

const LoanStatusView = ({ status, tabletActiveAt }: LoanStatusProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const renderDate = useRenderDate()

  if (tabletActiveAt !== undefined) {
    const activeAtWithinMinutes = 5

    const outOfNetworkTreshold = new Date(tabletActiveAt)
    outOfNetworkTreshold.setMinutes(
      outOfNetworkTreshold.getMinutes() + activeAtWithinMinutes
    )

    const now = new Date()

    if (status !== LoanStatus.RETURNED && now > outOfNetworkTreshold) {
      return (
        <>
          <span className={classes.LoanStatusRed}>
            {t('loans.listLoans.values.outOfNetwork')}
          </span>
          <span
            className={classes.LoanTabletActiveAt}
            title={t('loans.listLoans.values.tabletLastActiveAt')}
          >
            {renderDate(tabletActiveAt)}
          </span>
        </>
      )
    }
  }

  switch (status) {
    case LoanStatus.EXPIRED:
      return (
        <span className={classes.LoanStatusRed}>
          {t('loans.listLoans.values.expired')}
        </span>
      )
    case LoanStatus.ACTIVE:
      return (
        <span className={classes.LoanStatusGreen}>
          {t('loans.listLoans.values.active')}
        </span>
      )
    case LoanStatus.RETURNED:
      return (
        <span className={classes.LoanStatusGreen}>
          {t('loans.listLoans.values.returned')}
        </span>
      )
    case LoanStatus.START:
      return (
        <span className={classes.LoanStatusGreen}>
          {t('loans.listLoans.values.start')}
        </span>
      )
    default:
      return (
        <span className={classes.LoanStatusRed}>
          {t('loans.listLoans.values.unknown')}
        </span>
      )
  }
}

export default LoanStatusView
