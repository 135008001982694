import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import TableActionsBar from 'common/components/TableActionsBar'
import { useTranslation } from 'react-i18next'
import { type Dock } from 'App/Docks/docks-types'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrashOutlined'
import ActionDialog from 'common/components/ActionDialog'
import {
  useDeleteDocksMutation,
  useUndeleteDocksMutation,
} from 'App/Docks/docks-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import ErrorBox from 'common/components/ErrorBox'
import useApiErrors from 'common/hooks/useApiErrors'
import Toast from 'common/components/Toast'
import useItemListToString from 'common/hooks/useItemListToString'
import DocksReactContext from 'App/Docks/docks-react-context'
import { fetchDocks } from 'App/Docks/docks-state'
import { useAppDispatch } from 'store'

interface DocksUndeleteActionDialogProps {
  open: boolean
  docksToUndelete: Dock[]
  onClose: () => void
}

const DocksUndeleteActionDialog = ({
  open,
  docksToUndelete,
  onClose,
}: DocksUndeleteActionDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const context = useContext(DocksReactContext)

  const [
    undeleteDocks,
    { isSuccess: isUndeleteDocksSuccess, error: undeleteDocksError },
  ] = useUndeleteDocksMutation()
  const [undeletingDocksCount, setDeletingDocksCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    undeleteDocksError,
  ])

  const dockListToString = useItemListToString<Dock>(
    (d) => d.name,
    'docks.undeleteDocks.remaining'
  )

  useEffect(() => {
    if (isUndeleteDocksSuccess) {
      onClose()
      dispatch(fetchDocks({ organizationId, siteId, context }))
    }
  }, [
    onClose,
    isUndeleteDocksSuccess,
    dispatch,
    organizationId,
    siteId,
    context,
  ])

  const handleActionDeleteSelectedDocks = () => {
    const dockIds = docksToUndelete.map((dock) => dock.id)
    undeleteDocks({ context, organizationId, siteId, dockIds })
    setDeletingDocksCount(docksToUndelete.length)
  }

  return (
    <>
      <ActionDialog
        open={open}
        title={t('docks.undeleteDocks.title', {
          count: docksToUndelete.length,
        })}
        description={t('docks.undeleteDocks.description', {
          docks: dockListToString(docksToUndelete),
        })}
        actionText={t('common.actions.undelete')}
        onAction={handleActionDeleteSelectedDocks}
        onClose={onClose}
        closeOnAction={false}
      >
        {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
      </ActionDialog>
      <Toast
        message={t('docks.undeleteDocks.toast', {
          count: undeletingDocksCount,
        })}
        open={isUndeleteDocksSuccess}
      />
    </>
  )
}

interface DocksDeleteActionDialogProps {
  open: boolean
  docksToDelete: Dock[]
  onClose: () => void
}

const DocksDeleteActionDialog = ({
  open,
  docksToDelete,
  onClose,
}: DocksDeleteActionDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const context = useContext(DocksReactContext)

  const [
    deleteDocks,
    { isSuccess: isDeleteDocksSuccess, error: deleteDocksError },
  ] = useDeleteDocksMutation()
  const [deletingDocksCount, setDeletingDocksCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    deleteDocksError,
  ])

  const dockListToString = useItemListToString<Dock>(
    (d) => d.name,
    'docks.remaining'
  )

  useEffect(() => {
    if (isDeleteDocksSuccess) {
      onClose()
      dispatch(fetchDocks({ organizationId, siteId, context }))
    }
  }, [onClose, isDeleteDocksSuccess, dispatch, organizationId, siteId, context])

  const handleActionDeleteSelectedDocks = () => {
    const dockIds = docksToDelete.map((dock) => dock.id)
    deleteDocks({ context, organizationId, siteId, dockIds })
    setDeletingDocksCount(docksToDelete.length)
  }

  return (
    <>
      <ActionDialog
        open={open}
        title={t('docks.deleteDocks.title', { count: docksToDelete.length })}
        description={t('docks.deleteDocks.description', {
          docks: dockListToString(docksToDelete),
        })}
        actionText={t('common.actions.delete')}
        onAction={handleActionDeleteSelectedDocks}
        onClose={onClose}
        closeOnAction={false}
      >
        {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
      </ActionDialog>
      <Toast
        message={t('docks.deleteDocks.toast', { count: deletingDocksCount })}
        open={isDeleteDocksSuccess}
      />
    </>
  )
}

enum SelectedTabletsActions {
  Delete,
  Undelete,
}

interface DocksTableActionsBarProps {
  showDeleted: boolean
  onChangeShowDeleted: (showDeleted: boolean) => void
  selectedDocks: Dock[]
  searchQuery: string
  onSearch: (query: string) => void
}

const DocksTableActionsBar = ({
  showDeleted,
  onChangeShowDeleted,
  selectedDocks,
  searchQuery,
  onSearch,
}: DocksTableActionsBarProps) => {
  const { t } = useTranslation()

  const [selectedTabletsAction, setSelectedTabletsAction] = useState<
    SelectedTabletsActions | undefined
  >()

  const selectedDocksActions = useMemo(() => {
    const actions = []

    if (showDeleted) {
      actions.push({
        local: 'common.actions.undelete',
        icon: RestoreFromTrashIcon,
        fn: () => setSelectedTabletsAction(SelectedTabletsActions.Undelete),
      })
    }

    actions.push({
      local: 'common.actions.delete',
      icon: DeleteIcon,
      fn: () => setSelectedTabletsAction(SelectedTabletsActions.Delete),
    })

    return actions
  }, [showDeleted])

  const handleCancelDeleteAction = useCallback(() => {
    setSelectedTabletsAction(undefined)
  }, [])

  const handleCancelUndeleteAction = useCallback(() => {
    setSelectedTabletsAction(undefined)
  }, [])

  return (
    <>
      <TableActionsBar
        showDeleted={showDeleted}
        onChangeShowDeleted={onChangeShowDeleted}
        searchQuery={searchQuery}
        onSearch={onSearch}
        selectedRows={selectedDocks}
        selectedRowsLabel={t('docks.listDocks.selectedRows.label', {
          count: selectedDocks.length,
        })}
        selectedRowsActions={selectedDocksActions}
      />
      <DocksDeleteActionDialog
        open={selectedTabletsAction === SelectedTabletsActions.Delete}
        docksToDelete={selectedDocks}
        onClose={handleCancelDeleteAction}
      />
      <DocksUndeleteActionDialog
        open={selectedTabletsAction === SelectedTabletsActions.Undelete}
        docksToUndelete={selectedDocks}
        onClose={handleCancelUndeleteAction}
      />
    </>
  )
}

export default DocksTableActionsBar
