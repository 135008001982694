import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import { type DashboardData } from 'App/Dashboard/dashboard-types'

class DashboardApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchGlobalLoansData(
    timeRangeStartInTz: Date,
    timeRangeEndInTz: Date,
    includeOvertime: boolean,
    timeZone: string
  ): Promise<DashboardData> {
    const url = `${API_BASE_URL}/dashboard/loans`
    const params = {
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone,
    }
    const { data } = await axios.get(url, { params })

    return data
  }

  static async fetchDistributorLoansData(
    distributorId: number,
    timeRangeStartInTz: Date,
    timeRangeEndInTz: Date,
    includeOvertime: boolean,
    timeZone: string
  ): Promise<DashboardData> {
    const url = `${API_BASE_URL}/distributors/${distributorId}/dashboard/loans`
    const params = {
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone,
    }
    const { data } = await axios.get(url, { params })

    return data
  }

  static async fetchOrgLoansData(
    organizationId: number,
    timeRangeStartInTz: Date,
    timeRangeEndInTz: Date,
    includeOvertime: boolean,
    timeZone: string
  ): Promise<DashboardData> {
    const url = `${API_BASE_URL}/organizations/${organizationId}/dashboard/loans`
    const params = {
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone,
    }
    const { data } = await axios.get(url, { params })

    return data
  }

  static async fetchSiteLoansData(
    organizationId: number,
    siteId: number,
    timeRangeStartInTz: Date,
    timeRangeEndInTz: Date,
    includeOvertime: boolean,
    timeZone: string
  ): Promise<DashboardData> {
    const url = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/dashboard/loans`
    const params = {
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone,
    }
    const { data } = await axios.get(url, { params })

    return data
  }
}

export default DashboardApi
