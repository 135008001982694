import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'

import { connect } from 'react-redux'
import { type RootState, useAppDispatch } from 'store'
import Toast from 'common/components/Toast'
import DistributorsTable from 'App/Distributors/components/DistributorsTable'
import AddDistributorDrawer from 'App/Distributors/components/AddDistributorDrawer'
import { renderIf } from 'common/utils/render-utils'
import PageEmpty from 'common/components/PageEmpty'
import { fetchDistributors } from 'App/Distributors/distributors-state'
import { type Distributor } from 'App/Distributors/distributors-types'

interface DistributorsProps {
  fetchedDistributors: boolean
  distributors: Distributor[]
  addedDistributor: boolean
  updatedDistributor: boolean
  deletedDistributor: boolean
}

const Distributors = ({
  fetchedDistributors,
  distributors,
  addedDistributor,
  updatedDistributor,
  deletedDistributor,
}: DistributorsProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    dispatch(fetchDistributors())
  }, [dispatch])

  const onClickAddDistributor = () => setOpenDrawer(true)
  const onCloseDrawer = () => setOpenDrawer(false)

  if (!fetchedDistributors) {
    return <Page />
  }

  return (
    <>
      {renderIf(distributors.length === 0, () => (
        <Page>
          <PageEmpty
            title={t('distributors.empty.title')}
            description={t('distributors.empty.description')}
            actionText={t('distributors.empty.title')}
            onClickAction={onClickAddDistributor}
          />
        </Page>
      ))}

      {renderIf(distributors.length > 0, () => (
        <Page>
          <PageHeader>
            <PageTitle title={t('distributors.title')} />
            <PageActions>
              <Button onClick={onClickAddDistributor} small>
                {t('distributors.actions.addDistributor')}
              </Button>
            </PageActions>
          </PageHeader>
          <PageBody>
            <DistributorsTable distributors={distributors} />
          </PageBody>
        </Page>
      ))}

      <AddDistributorDrawer
        open={openDrawer}
        onClose={onCloseDrawer}
        addedDistributor={addedDistributor}
      />
      <Toast
        open={addedDistributor}
        message={t('distributors.addDistributor.toast.success')}
      />
      <Toast
        open={updatedDistributor}
        message={t('distributors.editDistributor.toast.success')}
      />
      <Toast
        open={deletedDistributor}
        message={t('distributors.deleteDistributor.toast.success')}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  fetchedDistributors: state.distributors.fetchedDistributors,
  distributors: state.distributors.distributors,
  addedDistributor: state.distributors.addedDistributor,
  updatedDistributor: state.distributors.updatedDistributor,
  deletedDistributor: state.distributors.deletedDistributor,
})

export default connect(mapStateToProps)(Distributors)
