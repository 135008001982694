import { type ManagedConfig } from 'App/Categories/categories-types'
import rtkApi from 'rtk-api'

interface ContentsApi {
  organizationId: number
  siteId: number
  profileId: number
  categoryId: number
}
type AppApi = ContentsApi & { appId: number }

const contentsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    updateAppManagedConfiguration: builder.mutation<
      null,
      AppApi & ManagedConfig
    >({
      query: ({
        organizationId,
        siteId,
        profileId,
        categoryId,
        appId,
        managedConfigId,
        managedConfigName,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/categories/${categoryId}/apps/${appId}`,
        method: 'PUT',
        body: { managedConfigId, managedConfigName },
      }),
    }),
  }),
})

export const { useUpdateAppManagedConfigurationMutation } = contentsRtkApi

export default contentsRtkApi
