import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type SiteForm,
  addSite,
  resetAddSite,
  fetchSites,
} from 'App/Sites/sites-state'
import DrawerForm from 'common/components/DrawerForm'
import FormField from 'common/components/FormField'
import useApp from 'common/hooks/useApp'
import { useAppDispatch } from 'store'

interface AddSiteDrawerProps {
  open: boolean
  onClose: () => void
  addedSite: boolean
}

interface FieldErrors {
  name?: string
}

const initialSiteForm = {
  name: '',
}

const AddSiteDrawer = ({ open, onClose, addedSite }: AddSiteDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organization } = useApp()

  const [siteForm, setSiteForm] = useState<SiteForm>(initialSiteForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  useEffect(() => {
    if (addedSite) {
      setSiteForm(initialSiteForm)
      onClose()
      dispatch(resetAddSite())
      dispatch(fetchSites({ organizationId: organization.id }))
    }
  }, [dispatch, onClose, addedSite, organization])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setSiteForm({ ...siteForm, [key]: e.target.value })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    let hasError = false
    const errors: FieldErrors = {}

    if (siteForm.name === '') {
      hasError = true
      errors.name = t('sites.addSite.errors.emptyField')
    }

    if (!hasError) {
      dispatch(addSite({ form: siteForm, organizationId: organization.id }))
    }

    setFieldErrors(errors)
  }

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('sites.addSite.title')}
      submitText={t('sites.addSite.buttons.submit')}
    >
      <FormField
        label={t('sites.addSite.siteNameField.label')}
        placeholder={t('sites.addSite.siteNameField.placeholder')}
        value={siteForm.name}
        onChange={onChangeField('name')}
        autoFocus
        error={fieldErrors.name}
      />
    </DrawerForm>
  )
}

export default AddSiteDrawer
