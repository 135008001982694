import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import IntegrationsApi from 'api/integrations-api'
import {
  type Integration,
  type IntegrationEditable,
  type IntegrationPlugin,
  type CommunicationConfiguration,
  type CommunicationChannel,
  type IntegrationParameter,
  type DebugLog,
} from 'App/Integrations/integrations-types'

interface IntegrationsState {
  integrations: Integration[]
  fetchedIntegrations: boolean
  addedIntegration: boolean
  addedCommunicationChannel: boolean
  updatedIntegration: boolean
  updatedCommunicationChannel: boolean
  removedCommunicationChannel: boolean
  deletedIntegration: boolean
  integrationPlugins: IntegrationPlugin[]
  fetchedIntegrationPlugins: boolean
  communicationConfigurations: CommunicationConfiguration[]
  communicationChannels: CommunicationChannel[]
  communicationConfiguration: CommunicationConfiguration | null
  integrationParameterFormFields: IntegrationParameter[]
  fetchedParametersForId: number
  debugLogs: DebugLog[]
  pilotVersion: string
}

const initialState: IntegrationsState = {
  integrations: [],
  fetchedIntegrations: false,
  addedIntegration: false,
  addedCommunicationChannel: false,
  updatedIntegration: false,
  updatedCommunicationChannel: false,
  removedCommunicationChannel: false,
  deletedIntegration: false,
  integrationPlugins: [],
  fetchedIntegrationPlugins: false,
  communicationConfigurations: [],
  communicationChannels: [],
  communicationConfiguration: null,
  integrationParameterFormFields: [],
  fetchedParametersForId: 0,
  debugLogs: [],
  pilotVersion: '',
}

export const fetchIntegrations = createAsyncThunk(
  'sites/fetchIntegrations',
  async ({
    organizationId,
    siteId,
  }: {
    organizationId: number
    siteId: number
  }) => {
    return IntegrationsApi.fetchIntegrations(organizationId, siteId)
  }
)

export const fetchPilotVersion = createAsyncThunk(
  'fetchPilotVersion',
  async () => {
    return IntegrationsApi.fetchPilotVersion()
  }
)

export const fetchDebugLogs = createAsyncThunk(
  'sites/fetchDebugLogs',
  async ({
    organizationId,
    siteId,
    integrationId,
    timestamp,
  }: {
    organizationId: number
    siteId: number
    integrationId: number
    timestamp: string
  }) => {
    return IntegrationsApi.fetchDebugLogs(
      organizationId,
      siteId,
      integrationId,
      timestamp
    )
  }
)

export const fetchCommunicationConfigurations = createAsyncThunk(
  'sites/fetchCommunicationConfigurations',
  async ({
    organizationId,
    siteId,
  }: {
    organizationId: number
    siteId: number
  }) => {
    return IntegrationsApi.fetchCommunicationConfigurations(
      organizationId,
      siteId
    )
  }
)

export const fetchIntegrationParameters = createAsyncThunk(
  'sites/fetchIntegrationParameters',
  async ({
    organizationId,
    siteId,
    integrationId,
    url,
  }: {
    organizationId: number
    siteId: number
    integrationId: number
    url: string
  }) => {
    return {
      integrationId,
      params: await IntegrationsApi.fetchIntegrationParameters(
        organizationId,
        siteId,
        integrationId,
        url
      ),
    }
  }
)

export const fetchCommunicationConfiguration = createAsyncThunk(
  'sites/fetchCommunicationConfiguration',
  async ({
    organizationId,
    siteId,
    communicationConfigurationId,
  }: {
    organizationId: number
    siteId: number
    communicationConfigurationId: number
  }) => {
    return IntegrationsApi.fetchCommunicationConfiguration(
      organizationId,
      siteId,
      communicationConfigurationId
    )
  }
)

export const updateCommunicationConfiguration = createAsyncThunk(
  'sites/updateCommunicationConfiguration',
  async ({
    organizationId,
    siteId,
    communicationConfiguration,
  }: {
    organizationId: number
    siteId: number
    communicationConfiguration: CommunicationConfiguration
  }) => {
    return IntegrationsApi.updateCommunicationConfiguration(
      organizationId,
      siteId,
      communicationConfiguration.id,
      communicationConfiguration
    )
  }
)

export const fetchCommunicationChannels = createAsyncThunk(
  'sites/fetchCommunicationChannels',
  async ({
    organizationId,
    siteId,
    communicationConfigurationId,
  }: {
    organizationId: number
    siteId: number
    communicationConfigurationId: number
  }) => {
    return IntegrationsApi.fetchCommunicationChannels(
      organizationId,
      siteId,
      communicationConfigurationId
    )
  }
)

export const fetchIntegrationPlugins = createAsyncThunk(
  'sites/fetchIntegrationPlugins',
  async ({
    organizationId,
    siteId,
  }: {
    organizationId: number
    siteId: number
  }) => {
    return IntegrationsApi.fetchIntegrationPlugins(organizationId, siteId)
  }
)

export const addCommunicationChannel = createAsyncThunk(
  'sites/addCommunicationChannel',
  async ({
    organizationId,
    siteId,
    communicationChannel,
  }: {
    organizationId: number
    siteId: number
    communicationChannel: CommunicationChannel
  }) => {
    return IntegrationsApi.createCommunicationChannel(
      organizationId,
      siteId,
      communicationChannel
    )
  }
)

export const updateCommunicationChannel = createAsyncThunk(
  'sites/updateCommunicationChannel',
  async ({
    organizationId,
    siteId,
    communicationChannel,
  }: {
    organizationId: number
    siteId: number
    communicationChannel: CommunicationChannel
  }) => {
    return IntegrationsApi.updateCommunicationChannel(
      organizationId,
      siteId,
      communicationChannel
    )
  }
)

export const removeCommunicationChannel = createAsyncThunk(
  'sites/removeCommunicationChannel',
  async ({
    organizationId,
    siteId,
    communicationChannel,
  }: {
    organizationId: number
    siteId: number
    communicationChannel: CommunicationChannel
  }) => {
    return IntegrationsApi.removeCommunicationChannel(
      organizationId,
      siteId,
      communicationChannel
    )
  }
)

export const updateIntegration = createAsyncThunk(
  'posts/updateIntegration',
  async ({
    organizationId,
    siteId,
    integrationId,
    form,
  }: {
    organizationId: number
    siteId: number
    integrationId: number
    form: IntegrationEditable
  }) =>
    IntegrationsApi.updateIntegration(
      organizationId,
      siteId,
      integrationId,
      form
    )
)

export const deleteIntegration = createAsyncThunk(
  'sites/deleteIntegration',
  async ({
    organizationId,
    siteId,
    integrationId,
  }: {
    organizationId: number
    siteId: number
    integrationId: number
  }) => {
    return IntegrationsApi.deleteIntegration(
      organizationId,
      siteId,
      integrationId
    )
  }
)

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    addedIntegration: (state) => {
      state.addedIntegration = true
      return state
    },
    resetAddIntegration: (state) => {
      state.addedIntegration = false
      return state
    },
    resetUpdateIntegration: (state) => {
      state.updatedIntegration = false
      return state
    },
    resetDeleteIntegration: (state) => {
      state.deletedIntegration = false
      return state
    },
    resetFetchedParameters: (state) => {
      return {
        ...state,
        fetchedParametersForId: 0,
        integrationParameterFormFields: [],
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIntegrations.fulfilled, (state, { payload }) => {
      state.fetchedIntegrations = true
      state.integrations = payload
      return state
    })

    builder.addCase(fetchPilotVersion.fulfilled, (state, { payload }) => {
      state.pilotVersion = payload
      return state
    })

    builder.addCase(fetchDebugLogs.fulfilled, (state, { payload }) => {
      state.debugLogs = payload
      return state
    })

    builder.addCase(
      fetchCommunicationConfigurations.fulfilled,
      (state, { payload }) => {
        state.communicationConfigurations = payload
        return state
      }
    )

    builder.addCase(
      fetchIntegrationParameters.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          integrationParameterFormFields: payload.params,
          fetchedParametersForId: payload.integrationId,
        }
      }
    )

    builder.addCase(
      fetchCommunicationChannels.fulfilled,
      (state, { payload }) => {
        state.addedCommunicationChannel = false
        state.updatedCommunicationChannel = false
        state.removedCommunicationChannel = false
        state.communicationChannels = payload
        return state
      }
    )

    builder.addCase(
      fetchCommunicationConfiguration.fulfilled,
      (state, { payload }) => {
        state.communicationConfiguration = payload
        return state
      }
    )

    builder.addCase(updateIntegration.fulfilled, (state) => {
      state.updatedIntegration = true
      return state
    })

    builder.addCase(updateCommunicationConfiguration.fulfilled, (state) => {
      state.updatedIntegration = true
      return state
    })

    builder.addCase(updateCommunicationChannel.fulfilled, (state) => {
      state.updatedCommunicationChannel = true
      return state
    })

    builder.addCase(removeCommunicationChannel.fulfilled, (state) => {
      state.removedCommunicationChannel = true
      return state
    })

    builder.addCase(addCommunicationChannel.fulfilled, (state) => {
      state.addedCommunicationChannel = true
      return state
    })

    builder.addCase(deleteIntegration.fulfilled, (state) => {
      state.deletedIntegration = true
      return state
    })

    builder.addCase(fetchIntegrationPlugins.fulfilled, (state, { payload }) => {
      state.fetchedIntegrationPlugins = true
      state.integrationPlugins = payload
      return state
    })
  },
})

export const {
  addedIntegration,
  resetAddIntegration,
  resetUpdateIntegration,
  resetDeleteIntegration,
  resetFetchedParameters,
} = integrationsSlice.actions

export default integrationsSlice.reducer
