import { useEffect } from 'react'
import { fetchSiteSettings, type SiteSettings } from 'App/Sites/sites-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

const useSiteSettings = (): SiteSettings | undefined => {
  const dispatch = useAppDispatch()

  const { organizationId, siteId } = useCurrentAccount()
  const siteSettings = useAppSelector((state) => state.sites.settings)

  useEffect(() => {
    dispatch(fetchSiteSettings({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  return siteSettings
}

export default useSiteSettings
