import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersApi from 'api/users-api'
import { type User, type UserEditable } from 'App/Users/users-types'

interface UsersState {
  users: User[]
  fetchedUsers: boolean
  updatedUser: boolean
  deletedUser: boolean
  invitedUser: boolean
  reinvitedUser: boolean
}

const initialState: UsersState = {
  users: [],
  fetchedUsers: false,
  updatedUser: false,
  deletedUser: false,
  invitedUser: false,
  reinvitedUser: false,
}

export const fetchOrganizationUsers = createAsyncThunk(
  'users/fetchOrganizationUsers',
  async (props: { organizationId: number }) =>
    UsersApi.fetchOrganizationUsers(props.organizationId)
)

export const fetchSiteUsers = createAsyncThunk(
  'users/fetchSiteUsers',
  async (props: { organizationId: number; siteId: number }) =>
    UsersApi.fetchSiteUsers(props.organizationId, props.siteId)
)

export const inviteOrganizationUser = createAsyncThunk(
  'users/inviteOrganizationUser',
  async (props: {
    organizationId: number
    email: string
    language: string
    role: string
  }) =>
    UsersApi.createOrganizationUser(
      props.organizationId,
      props.email,
      props.language,
      props.role
    )
)

export const inviteSiteUser = createAsyncThunk(
  'users/inviteSiteUser',
  async (props: {
    organizationId: number
    siteId: number
    email: string
    language: string
    role: string
  }) =>
    UsersApi.createSiteUser(
      props.organizationId,
      props.siteId,
      props.email,
      props.language,
      props.role
    )
)

export const updateOrganizationUser = createAsyncThunk(
  'users/updateOrganizationUser',
  async (props: {
    organizationId: number
    userId: number
    form: UserEditable
  }) =>
    UsersApi.updateOrganizationUser(
      props.organizationId,
      props.userId,
      props.form
    )
)

export const updateSiteUser = createAsyncThunk(
  'users/updateSiteUser',
  async (props: {
    organizationId: number
    siteId: number
    userId: number
    form: UserEditable
  }) =>
    UsersApi.updateSiteUser(
      props.organizationId,
      props.siteId,
      props.userId,
      props.form
    )
)

export const deleteOrganizationUsers = createAsyncThunk(
  'users/deleteOrganizationUsers',
  async (props: { organizationId: number; userIds: number[] }) => {
    props.userIds.forEach((userId) => {
      UsersApi.deleteOrganizationUser(props.organizationId, userId)
    })
  }
)

export const deleteSiteUsers = createAsyncThunk(
  'users/deleteSiteUsers',
  async (props: {
    organizationId: number
    siteId: number
    userIds: number[]
  }) => {
    props.userIds.forEach((userId) => {
      UsersApi.deleteSiteUser(props.organizationId, props.siteId, userId)
    })
  }
)

export const reinviteOrganizationUser = createAsyncThunk(
  'users/reinviteOrganizationUser',
  async (props: { organizationId: number; userId: number }) =>
    UsersApi.reinviteOrganizationUser(props.organizationId, props.userId)
)

export const reinviteSiteUser = createAsyncThunk(
  'users/reinviteSiteUser',
  async (props: { organizationId: number; siteId: number; userId: number }) =>
    UsersApi.reinviteSiteUser(props.organizationId, props.siteId, props.userId)
)

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetInvitedUser: (state) => {
      state.invitedUser = false
      return state
    },
    resetUpdatedUser: (state) => {
      state.updatedUser = false
      return state
    },
    resetDeletedUser: (state) => {
      state.deletedUser = false
      return state
    },
    resetReinviteduser: (state) => {
      state.reinvitedUser = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizationUsers.fulfilled, (state, { payload }) => {
      state.users = payload
      state.fetchedUsers = true
      return state
    })

    builder.addCase(fetchSiteUsers.fulfilled, (state, { payload }) => {
      state.users = payload
      state.fetchedUsers = true
      return state
    })

    builder.addCase(inviteOrganizationUser.fulfilled, (state) => {
      state.invitedUser = true
      return state
    })

    builder.addCase(inviteSiteUser.fulfilled, (state) => {
      state.invitedUser = true
      return state
    })

    builder.addCase(updateOrganizationUser.fulfilled, (state) => {
      state.updatedUser = true
      return state
    })

    builder.addCase(updateSiteUser.fulfilled, (state) => {
      state.updatedUser = true
      return state
    })

    builder.addCase(deleteOrganizationUsers.fulfilled, (state) => {
      state.deletedUser = true
      return state
    })

    builder.addCase(deleteSiteUsers.fulfilled, (state) => {
      state.deletedUser = true
      return state
    })

    builder.addCase(reinviteOrganizationUser.fulfilled, (state) => {
      state.reinvitedUser = true
      return state
    })

    builder.addCase(reinviteSiteUser.fulfilled, (state) => {
      state.reinvitedUser = true
      return state
    })
  },
})

export const {
  resetInvitedUser,
  resetUpdatedUser,
  resetDeletedUser,
  resetReinviteduser,
} = usersSlice.actions

export default usersSlice.reducer
