import React, { useEffect, useMemo, useState } from 'react'

import FormField from 'common/components/FormField'

import { type CodeEditable, PermissionType } from 'App/Codes/codes-types'
import { addCode, fetchCodes, resetAddCode } from 'App/Codes/codes-state'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import FormSelect from 'common/components/FormSelect'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useProfiles from 'common/hooks/useProfiles'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  AddCodeDrawerFields: {
    '& > *': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}))

interface AddCodeDrawerProps {
  open: boolean
  onClose: () => void
}

interface FieldErrors {
  name?: string
  expiration?: string
  profileId?: string
}

const initialCodeForm: CodeEditable = {
  name: '',
  permissionType: PermissionType.Single,
  expiration: (() => {
    // Set "tomorrow" as the default expiration date
    const d = new Date()
    d.setDate(d.getDate() + 1)
    return d
  })(),
}

const AddCodeDrawer = ({ open, onClose }: AddCodeDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()

  const profiles = useProfiles(open)

  const [codeForm, setCodeForm] = useState<CodeEditable>(initialCodeForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const { organizationId, siteId } = useCurrentAccount()
  const addedCode = useAppSelector((state) => state.codes.addedCode)

  useEffect(() => {
    if (addedCode) {
      onClose()
      setCodeForm(initialCodeForm)
      dispatch(resetAddCode())
      dispatch(fetchCodes({ organizationId, siteId }))
    }
  }, [dispatch, onClose, addedCode, organizationId, siteId])

  const onChangeField =
    (key: string | number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCodeForm({ ...codeForm, [key]: e.target.value })
    }

  const onChangePermissionType = (value: string) => {
    let permissionType

    switch (value) {
      case 'single':
        permissionType = PermissionType.Single
        break
      case 'multi':
        permissionType = PermissionType.Multi
        break
      case 'reusable':
        permissionType = PermissionType.Reusable
        break
      default:
        permissionType = undefined
    }

    setCodeForm({ ...codeForm, permissionType })
  }

  const onChangeExpiration = (value: string) => {
    let expiration: Date | undefined = new Date(value)

    // If `value` is invalid datetime string, `expiration.getTime()` will return `NaN`
    if (isNaN(expiration.getTime())) {
      expiration = initialCodeForm.expiration
    }

    setCodeForm({ ...codeForm, expiration })
  }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    let hasError = false
    const errors: FieldErrors = {}

    if (codeForm.name === '') {
      hasError = true
      errors.name = t('codes.addCode.errors.emptyField')
    }

    if (
      codeForm.expiration === undefined ||
      codeForm.expiration.getTime() < Date.now()
    ) {
      hasError = true
      errors.expiration = t('codes.addCode.errors.invalidField')
    }

    if (!hasError) {
      dispatch(addCode({ context: { siteId, organizationId }, data: codeForm }))
    }

    setFieldErrors(errors)
  }

  const toDateFieldValue = (date?: Date) => {
    if (date === undefined) {
      return undefined
    }

    const parts = [
      date.getFullYear(),
      // .getMonth() returns a value between 0-11, so we add 1 to get standard numeric months
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getDate().toString().padStart(2, '0'),
    ]

    return parts.join('-')
  }

  const profileOptions = useMemo(() => {
    const opts = profiles.map((p) => ({ label: p.name, value: p.id }))
    return [{ label: t('common.values.useDefault'), value: -1 }, ...opts]
  }, [profiles, t])

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('codes.addCode.title')}
      submitText={t('codes.addCode.buttons.submit')}
    >
      <div className={classes.AddCodeDrawerFields}>
        <FormField
          label={t('codes.addCode.nameField.label')}
          placeholder={t('codes.addCode.nameField.placeholder')}
          value={codeForm.name}
          onChange={onChangeField('name')}
          error={fieldErrors.name}
          autoFocus
        />
        <FormField
          type="date"
          label={t('codes.addCode.expirationField.label')}
          value={toDateFieldValue(codeForm.expiration)}
          onChange={(e) => onChangeExpiration(e.target.value)}
          error={fieldErrors.expiration}
        />
        <FormSelect
          label={t('codes.addCode.profileField.label')}
          value={codeForm.profileId ?? -1}
          onChange={(_e, v) =>
            setCodeForm({
              ...codeForm,
              profileId: v === -1 ? undefined : (v as number),
            })
          }
          options={profileOptions}
          placeholder={t('codes.addCode.profileField.placeholder')}
          error={fieldErrors.profileId}
        />
        <FormSelect
          label={t('codes.addCode.permissionTypeField.label')}
          value={codeForm.permissionType}
          onChange={(e) => onChangePermissionType(e.target.value)}
          options={[
            {
              label: t('codes.addCode.permissionTypeField.options.single'),
              value: PermissionType.Single,
            },
            {
              label: t('codes.addCode.permissionTypeField.options.multi'),
              value: PermissionType.Multi,
            },
            {
              label: t('codes.addCode.permissionTypeField.options.reusable'),
              value: PermissionType.Reusable,
            },
          ]}
        />
      </div>
    </DrawerForm>
  )
}

export default AddCodeDrawer
