import { skipToken, type SkipToken } from '@reduxjs/toolkit/dist/query'

type DeepNonNullable<T> = {
  [P in keyof T]-?: NonNullable<T[P]>
}

type RequireParams = <T extends Record<string | number | symbol, unknown>>(
  obj: T
) => DeepNonNullable<T> | SkipToken

/**
 * `requireParams` can be used with a RTK query hook to ensure the query
 * will be sent *only* after each [key, value] pair in the parameters
 * has a value which is not `undefined` or `null`.
 *
 * Useful when a request is dependent on some values that are not
 * guaranteed to be available at all times.
 */
export const requireParams: RequireParams = (obj) => {
  const values = Object.values(obj)
  if (values.includes(undefined) || values.includes(null)) {
    return skipToken
  }
  return obj as DeepNonNullable<typeof obj>
}
