import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'

import { connect } from 'react-redux'
import { type RootState, useAppDispatch } from 'store'
import Toast from 'common/components/Toast'
import CustomersTable from 'App/Customers/components/CustomersTable'
import AddCustomerDrawer from 'App/Customers/components/AddCustomerDrawer'
import { renderIf } from 'common/utils/render-utils'
import PageEmpty from 'common/components/PageEmpty'
import { fetchCustomers } from 'App/Customers/customers-state'
import { type Customer } from 'App/Customers/customers-types'

interface CustomersProps {
  distributorId?: number
  fetchedCustomers: boolean
  customers: Customer[]
  addedCustomer: boolean
  updatedCustomer: boolean
  deletedCustomer: boolean
}

const Customers = ({
  distributorId,
  fetchedCustomers,
  customers,
  addedCustomer,
  updatedCustomer,
  deletedCustomer,
}: CustomersProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)

  useEffect(() => {
    if (distributorId) {
      dispatch(fetchCustomers(distributorId))
    }
  }, [dispatch, distributorId])

  const onClickAddCustomer = () => setOpenDrawer(true)
  const onCloseDrawer = () => setOpenDrawer(false)

  if (!distributorId || !fetchedCustomers) {
    return <Page />
  }

  return (
    <>
      {renderIf(customers.length === 0, () => (
        <Page>
          <PageEmpty
            title={t('customers.empty.title')}
            description={t('customers.empty.description')}
            actionText={t('customers.empty.title')}
            onClickAction={onClickAddCustomer}
          />
        </Page>
      ))}

      {renderIf(customers.length > 0, () => (
        <Page>
          <PageHeader>
            <PageTitle title={t('customers.title')} />
            <PageActions>
              <Button onClick={onClickAddCustomer} small>
                {t('customers.actions.addCustomer')}
              </Button>
            </PageActions>
          </PageHeader>
          <PageBody>
            <CustomersTable
              distributorId={distributorId}
              customers={customers}
            />
          </PageBody>
        </Page>
      ))}

      <AddCustomerDrawer
        distributorId={distributorId}
        open={openDrawer}
        onClose={onCloseDrawer}
        addedCustomer={addedCustomer}
      />
      <Toast
        open={addedCustomer}
        message={t('customers.addCustomer.toast.success')}
      />
      <Toast
        open={updatedCustomer}
        message={t('customers.editCustomer.toast.success')}
      />
      <Toast
        open={deletedCustomer}
        message={t('customers.deleteCustomer.toast.success')}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  distributorId: state.app.organization?.id,
  fetchedCustomers: state.customers.fetchedCustomers,
  customers: state.customers.customers,
  addedCustomer: state.customers.addedCustomer,
  updatedCustomer: state.customers.updatedCustomer,
  deletedCustomer: state.customers.deletedCustomer,
})

export default connect(mapStateToProps)(Customers)
