import { type FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { type SerializedError } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'

type Error = FetchBaseQueryError | SerializedError

const useApiErrors = (errors: Array<Error | undefined>): [boolean, string] => {
  const { t } = useTranslation()

  const validErrors = errors.filter((error) => error !== undefined) as Error[]
  const hasErrors = validErrors.length > 0

  let errorMsg = t('common.errors.apiError') // adds base error msg
  errorMsg += ` | T#${Date.now()}` // adds timestamp
  const errorKeys = validErrors.reduce<string[]>((acc, error) => {
    const maybeErrorKey = (error as { error?: string })?.error // Typescript hack to ensure we can do a runtime check
    if (maybeErrorKey !== undefined) {
      acc.push(maybeErrorKey)
    }

    return acc
  }, [])

  if (errorKeys.length > 0) {
    errorMsg += ` | K#(${errorKeys.join(', ')})` // adds server-side request ids for each error
  }

  return [hasErrors, errorMsg]
}

export default useApiErrors
