import React from 'react'

import {
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  type Theme,
  Tooltip,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { type SvgIconComponent } from '@mui/icons-material'

import LinkIcon from '@mui/icons-material/LinkOutlined'
import IconTooltip from 'common/components/IconTooltip'
import TooltipInfo, {
  type TooltipInfoContent,
} from 'common/components/TooltipInfo'
import useUrlStatus from 'common/hooks/useUrlStatus'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  FormController: {
    width: '100%',
  },
  FormField: {
    width: '100%',
    padding: '17px 26px',
    backgroundColor: theme.palette.hublet.common.white,
    border: `1px solid ${theme.palette.hublet.common.gray}`,
    borderRadius: '5px',
    fontSize: '16px',
    color: theme.palette.hublet.common.black,
    '& > input': {
      padding: 0,
      height: 'auto',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: '20px',
    },
    '&:focus-within': {
      borderColor: theme.palette.hublet.primary.extraLight,
    },
    '&.Mui-error': {
      borderColor: theme.palette.hublet.error,
    },
  },
  FormFieldRounded: {
    borderRadius: '100px',
  },
  FormFieldLabel: {
    marginBottom: theme.spacing(2),
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.hublet.common.black,
  },
  FormFieldErrorMessage: {
    display: 'none',
    '&.Mui-error': {
      display: 'block',
      marginTop: '6px',
      color: theme.palette.hublet.error,
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  ReadOnly: {
    background: 'transparent',
  },
  FormFieldDense: {
    padding: '8px 13px',
  },
  StatusInvalid: {
    color: theme.palette.hublet.error,
  },
  StatusValid: {
    color: '#5BC980',
  },
  DescriptionIcon: {
    display: 'inline-block',
    float: 'right',
    '& svg': {
      color: theme.palette.hublet.secondary.light,
      transition: 'color 0.15s ease',
    },
    '&:hover svg': {
      color: theme.palette.hublet.help,
    },
  },
}))

type Value = string | number | null

const UrlStatusIcon = ({ url }: { url?: Value }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const urlStatus = useUrlStatus(url)

  let statusClass
  let statusText

  switch (urlStatus) {
    case 'default':
      statusClass = undefined
      statusText = t('common.formField.url.status.default')
      break
    case 'invalid':
      statusClass = classes.StatusInvalid
      statusText = t('common.formField.url.status.invalid')
      break
    case 'valid':
      statusClass = classes.StatusValid
      statusText = t('common.formField.url.status.valid')
      break
  }

  return (
    <Tooltip title={statusText}>
      <LinkIcon className={statusClass} />
    </Tooltip>
  )
}

interface FormFieldProps {
  className?: string
  type?:
    | 'text'
    | 'number'
    | 'password'
    | 'email'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'url'
  error?: string
  label?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  value?: Value
  placeholder?: string
  Icon?: SvgIconComponent
  endIcon?: React.ReactNode
  name?: string
  autoFocus?: boolean
  rounded?: boolean
  multiline?: boolean
  readOnly?: boolean
  disabled?: boolean
  dense?: boolean
  info?: TooltipInfoContent
  autoComplete?: string
}

const FormField = ({
  className,
  type,
  error,
  label,
  onChange,
  value,
  placeholder,
  Icon,
  endIcon,
  name,
  autoFocus,
  rounded,
  multiline,
  readOnly = false,
  disabled = false,
  dense = false,
  info,
  autoComplete = 'off',
}: FormFieldProps) => {
  const { classes, cx } = useStyles()
  const formControllerClassNames = [classes.FormController]
  const formFieldClassNames = [classes.FormField]

  if (className) {
    formControllerClassNames.push(className)
  }

  if (rounded) {
    formFieldClassNames.push(classes.FormFieldRounded)
  }

  if (readOnly) {
    formFieldClassNames.push(classes.ReadOnly)
  }

  if (dense) {
    formFieldClassNames.push(classes.FormFieldDense)
  }

  return (
    <FormControl
      className={cx(formControllerClassNames)}
      error={error !== undefined}
    >
      <label>
        {label && (
          <Box className={classes.FormFieldLabel}>
            {label}
            {info != null && (
              <div className={classes.DescriptionIcon}>
                <IconTooltip title={<TooltipInfo content={info} />} />
              </div>
            )}
          </Box>
        )}
        <Input
          className={cx(formFieldClassNames)}
          type={type}
          disableUnderline
          startAdornment={
            Icon != null && (
              <InputAdornment position="start">
                <Icon />
              </InputAdornment>
            )
          }
          endAdornment={
            (endIcon && (
              <InputAdornment position="end">{endIcon}</InputAdornment>
            )) ??
            (type === 'url' && <UrlStatusIcon url={value} />)
          }
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          autoFocus={autoFocus}
          multiline={multiline}
          rows={2}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
        />
      </label>
      <FormHelperText className={classes.FormFieldErrorMessage}>
        {error}
      </FormHelperText>
    </FormControl>
  )
}

export default FormField
