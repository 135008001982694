import React from 'react'

import { updatePost } from 'App/Posts/posts-state'
import { useTranslation } from 'react-i18next'
import { type Post, type PostEditable } from 'App/Posts/posts-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import PostDrawerForm from 'App/Posts/components/PostDrawerForm'
import { useAppDispatch } from 'store'

interface EditPostDrawerProps {
  post: Post
  open: boolean
  onClose: () => void
}

const EditPostDrawer = ({ post, open, onClose }: EditPostDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const context = { organizationId, siteId }

  const onSubmit = (form: PostEditable) => {
    dispatch(updatePost({ context, postId: post.id, form }))
  }

  return (
    <PostDrawerForm
      initialForm={post}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      title={t('posts.editPost.title')}
      submitText={t('posts.editPost.buttons.submit')}
      imageThumb={post.imageThumb ?? undefined}
    />
  )
}

export default EditPostDrawer
