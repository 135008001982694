import React, { useCallback } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import IntegrationStatisticsTable from './Integrations/components/IntegrationStatisticsTable'
import {
  useGetAllIntegrationTestsQuery,
  useGetIntegrationVersionQuery,
  useGetPilotIntegrationVersionQuery,
  useGetPilotVersionQuery,
  useGetVersionQuery,
  useIntegrationTestCanBorrowMutation,
} from './Integrations/integrations-rtk-api'

const IntegrationStatistics = () => {
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const { data: integrationTests = [] } = useGetAllIntegrationTestsQuery({
    organizationId,
    siteId,
  })

  const { data: pilotVersion } = useGetPilotVersionQuery({})
  const { data: pilotIntegrationVersion } = useGetPilotIntegrationVersionQuery(
    {}
  )
  const { data: version } = useGetVersionQuery({})
  const { data: integrationVersion } = useGetIntegrationVersionQuery({})

  const [integrationTestCanBorrow] = useIntegrationTestCanBorrowMutation()

  const handleClickTestAllAction = useCallback(() => {
    for (const i of integrationTests) {
      const data = {
        organizationId,
        siteId,
        integrationId: i.authConfigId,
        integrationTestId: i.id,
      }
      integrationTestCanBorrow(data)
    }
  }, [integrationTestCanBorrow, integrationTests, organizationId, siteId])

  return (
    <>
      <Page>
        <PageHeader>
          <PageTitle title={t('integrationStatistics.title')} />
          <PageActions>
            <Button small onClick={handleClickTestAllAction}>
              {t('integrations.actions.testAll')}
            </Button>
            <Button small onClick={() => {}} disabled>
              {t('integrations.actions.testAllInPilot')}
            </Button>
          </PageActions>
        </PageHeader>
        <PageBody>
          <IntegrationStatisticsTable integrationTests={integrationTests} />
        </PageBody>
        <div style={{ marginTop: '10px' }}>
          <div>
            {t('integrationStatistics.serverVersion')}:{' '}
            {version?.version ?? version?.message ?? 'n/a'}
          </div>
          <div>
            {t('integrationStatistics.integrationServerVersion')}:{' '}
            {integrationVersion?.version ??
              integrationVersion?.message ??
              'n/a'}
          </div>
          <div>
            {t('integrationStatistics.pilotServerVersion')}:{' '}
            {pilotVersion?.version ?? pilotVersion?.message ?? 'n/a'}
          </div>
          <div>
            {t('integrationStatistics.pilotIntegrationServerVersion')}:{' '}
            {pilotIntegrationVersion?.version ??
              pilotIntegrationVersion?.message ??
              'n/a'}
          </div>
        </div>
      </Page>
    </>
  )
}

export default IntegrationStatistics
