import { MenuItem, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { type SvgIconComponent } from '@mui/icons-material'
import React, { type PropsWithChildren } from 'react'

const useStyles = makeStyles()((theme: Theme) => ({
  UserActionsItem: {
    padding: '15px 20px',
    fontSize: '14px',
    color: theme.palette.hublet.common.black,
    '&:hover': {
      backgroundColor: theme.palette.hublet.misc.menuItemHover,
    },
  },
  UserActionsItemIcon: {
    marginRight: '20px',
    verticalAlign: 'middle',
  },
  UserInfoItem: {
    opacity: '1 !important',
  },
}))

interface DropdownMenuItemProps extends PropsWithChildren {
  Icon?: SvgIconComponent
  onClick?: () => void
  className?: string
  variant?: 'action' | 'info'
}

const DropdownMenuItem = ({
  children,
  Icon,
  onClick,
  className,
  variant = 'action',
}: DropdownMenuItemProps) => {
  const { classes, cx } = useStyles()
  const menuItemClasses = [classes.UserActionsItem]

  if (className !== undefined) {
    menuItemClasses.push(className)
  }

  if (variant === 'info') {
    menuItemClasses.push(classes.UserInfoItem)
  }

  return (
    <MenuItem
      className={cx(menuItemClasses)}
      onClick={onClick}
      disabled={variant === 'info'}
    >
      {Icon != null && <Icon className={classes.UserActionsItemIcon} />}
      {children}
    </MenuItem>
  )
}

export default DropdownMenuItem
