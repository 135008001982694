import React from 'react'
import { Box, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  SitesTableImage: {
    display: 'inline-block',
    width: '40px',
    height: '40px',
    marginRight: '16px',
    cursor: 'default',
    borderRadius: '100px',
    border: `1px solid ${theme.palette.hublet.common.gray}`,
    verticalAlign: 'middle',
    lineHeight: '40px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    color: theme.palette.hublet.primary.main,
  },
}))

interface AvatarProps {
  name: string
  className?: string
}

const Avatar = ({ name, className }: AvatarProps) => {
  const { classes, cx } = useStyles()
  const firstLetter = name?.slice(0, 1) ?? ''

  const avatarClasses = [classes.SitesTableImage, className]

  return <Box className={cx(avatarClasses)}>{firstLetter}</Box>
}

export default Avatar
