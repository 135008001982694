import React, { type ReactNode, useRef, useState } from 'react'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'
import DropdownMenu from 'common/components/DropdownMenu'
import DropdownMenuItem from 'common/components/DropdownMenuItem'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { type SvgIconComponent } from '@mui/icons-material'

const useStyles = makeStyles()((theme: Theme) => ({
  ActionsButtonIcon: {
    marginTop: '-8px',
    marginBottom: '-8px',
    marginRight: '-8px',
    marginLeft: theme.spacing(1),
  },
}))

interface ButtonMenuAction {
  label: string
  onClick: () => void
  icon?: SvgIconComponent
}

interface ButtonMenuProps {
  label: ReactNode
  actions: ButtonMenuAction[]
  outlined?: boolean
}

const ButtonMenu = ({ label, actions, outlined = false }: ButtonMenuProps) => {
  const { classes } = useStyles()

  const toggleRef = useRef(null)
  const [open, setOpen] = useState(false)

  return (
    <span ref={toggleRef}>
      <Button onClick={() => setOpen(true)} small outlined={outlined}>
        {label}
        <ArrowDropDownIcon className={classes.ActionsButtonIcon} />
      </Button>
      <DropdownMenu
        anchor={toggleRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        {actions.map((action) => (
          <DropdownMenuItem
            key={action.label}
            onClick={() => {
              action.onClick()
              setOpen(false)
            }}
            Icon={action.icon}
          >
            {action.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </span>
  )
}

export default ButtonMenu
