import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Post,
  type PostEditable,
  type PostsContext,
} from 'App/Posts/posts-types'

class PostsApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchPosts({
    organizationId,
    siteId,
  }: PostsContext): Promise<Post[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/posts`
    const { data } = await axios.get(apiUrl)
    return data as Post[]
  }

  static async createPost(
    { organizationId, siteId }: PostsContext,
    form: PostEditable
  ): Promise<Post> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/posts`
    const res = await axios.post(apiUrl, form)
    return res.data as Post
  }

  static async uploadPostImage(
    { organizationId, siteId }: PostsContext,
    imageFile: File
  ): Promise<string> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/posts/image`
    const headers = { 'Content-Type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', imageFile)

    const { data } = await axios.post(apiUrl, formData, { headers })
    return data as string
  }

  static async updatePost(
    postId: number,
    { organizationId, siteId }: PostsContext,
    form: PostEditable
  ): Promise<Post> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/posts/${postId}`
    const res = await axios.put(apiUrl, form)
    return res.data as Post
  }

  static async deletePost(
    postId: number,
    { organizationId, siteId }: PostsContext
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/posts/${postId}`
    await axios.delete(apiUrl)
  }
}

export default PostsApi
