import React from 'react'
import { type Theme, Tooltip } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import HelpIcon from '@mui/icons-material/Help'

const useStyles = makeStyles()((theme: Theme) => ({
  Tooltip: {
    background: theme.palette.hublet.common.white,
    color: theme.palette.hublet.common.black,
    padding: theme.spacing(2),
    fontSize: '14px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    lineHeight: 1.5,
  },
  Arrow: {
    color: theme.palette.hublet.common.white,
  },
  Icon: {
    fontSize: '16px',
    color: theme.palette.hublet.help,
  },
}))

interface IconTooltipProps {
  title: string | React.ReactFragment | React.ReactPortal | React.ReactElement
}

const IconTooltip = ({ title }: IconTooltipProps) => {
  const { classes } = useStyles()

  return (
    <Tooltip
      title={title}
      arrow
      classes={{ tooltip: classes.Tooltip, arrow: classes.Arrow }}
    >
      <HelpIcon className={classes.Icon} />
    </Tooltip>
  )
}

export default IconTooltip
