import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { type Loan, type LoansContext } from 'App/Loans/loans-types'
import LoansApi from 'api/loans-api'

interface LoansState {
  loans: Loan[]
  fetchingLoans: boolean
  fetchedLoans: boolean
}

const initialState: LoansState = {
  loans: [],
  fetchingLoans: false,
  fetchedLoans: false,
}

export const fetchLoans = createAsyncThunk(
  'loans/fetchLoans',
  async (context: LoansContext) => LoansApi.fetchLoans(context)
)

export const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoans.pending, (state) => {
      state.fetchingLoans = true
      return state
    })

    builder.addCase(fetchLoans.fulfilled, (state, { payload }) => {
      state.loans = payload
      state.fetchingLoans = false
      state.fetchedLoans = true
      return state
    })
  },
})

export default loansSlice.reducer
