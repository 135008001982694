import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import FormField from 'common/components/FormField'
import Dialog from 'common/components/Dialog'
import Button from 'common/components/Button'
import {
  ProxyTlsVersion,
  type CommunicationChannel,
} from 'App/Integrations/integrations-types'
import {
  addCommunicationChannel,
  fetchCommunicationChannels,
} from 'App/Integrations/integrations-state'
import FormSelect from 'common/components/FormSelect'
import FormSwitch from 'common/components/FormSwitch'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

interface Props {
  open: boolean
  setAddCcModalOpen: (open: boolean) => void
  communicationConfigurationId: number
}

const useStyles = makeStyles()((theme: Theme) => ({
  EditFormBodyEditable: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
  },
}))

const AddCommunicationChannelForm = ({
  open,
  setAddCcModalOpen,
  communicationConfigurationId,
}: Props) => {
  const [proxyServerAddress, setProxyServerAddress] = useState<string>('')
  const [proxyServerPort, setProxyServerPort] = useState<string>('')
  const [protocol, setProtocol] = useState<string>('tcp')
  const [proxyRootCert, setProxyRootCert] = useState<string>('')
  const [proxyClientCert, setProxyClientCert] = useState<string>('')
  const [proxyClientKey, setProxyClientKey] = useState<string>('')
  const [proxyPass, setProxyPass] = useState<boolean>(false)
  const [proxyTlsVersion, setProxyTlsVersion] = useState<ProxyTlsVersion | ''>(
    ''
  )
  const { classes } = useStyles()
  const { organizationId, siteId } = useCurrentAccount()
  const addedCommunicationChannel = useAppSelector(
    (state) => state.integrations.addedCommunicationChannel
  )
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const proxyTlsVersionOptions = useMemo(() => {
    const options = Object.values(ProxyTlsVersion).map((value) => ({
      label: value,
      value,
    }))
    return options
  }, [])

  useEffect(() => {
    if (addedCommunicationChannel) {
      setAddCcModalOpen(false)
      dispatch(
        fetchCommunicationChannels({
          organizationId,
          siteId,
          communicationConfigurationId,
        })
      )
    }
  }, [
    addedCommunicationChannel,
    dispatch,
    communicationConfigurationId,
    organizationId,
    setAddCcModalOpen,
    siteId,
  ])

  const submit = () => {
    const cc: CommunicationChannel = {
      id: undefined,
      communicationConfigurationId,
      proxyServerAddress,
      proxyServerPort: parseInt(proxyServerPort),
      proxyPass,
      proxyRootCertificate: proxyRootCert,
      proxyClientCertificate: proxyClientCert,
      proxyClientKey,
      proxyProtocol: protocol,
      proxyTlsVersion: proxyTlsVersion === '' ? undefined : proxyTlsVersion,
      number: 0,
      createdAt: undefined,
      updatedAt: undefined,
    }
    dispatch(
      addCommunicationChannel({
        organizationId,
        siteId,
        communicationChannel: cc,
      })
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setAddCcModalOpen(false)
      }}
      title={t('integrations.channels.addChannel.title')}
      actions={
        <Grid container>
          <Grid item xs={6}>
            <Button
              small
              outlined
              onClick={() => {
                setAddCcModalOpen(false)
              }}
            >
              {t('integrations.channels.action.close')}
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button small onClick={submit}>
              {t('integrations.channels.action.add')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <div className={classes.EditFormBodyEditable}>
        <Grid container>
          <Grid item xs={7}>
            <FormField
              label={t('integrations.channels.form.fields.proxyServerAddress')}
              placeholder={''}
              value={proxyServerAddress}
              onChange={(e) => {
                setProxyServerAddress(e.target.value)
              }}
              autoFocus
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <FormField
              label={t('integrations.channels.form.fields.port')}
              placeholder={''}
              value={proxyServerPort}
              onChange={(e) => {
                setProxyServerPort(e.target.value)
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormSelect
            label={t('integrations.channels.form.fields.protocol')}
            options={[
              {
                label: t(
                  'integrations.channels.form.fields.protocol.labels.tcp'
                ),
                value: 'tcp',
              },
              {
                label: t(
                  'integrations.channels.form.fields.protocol.labels.tls'
                ),
                value: 'tls',
              },
              {
                label: t(
                  'integrations.channels.form.fields.protocol.labels.tls_insecure'
                ),
                value: 'tls_insecure',
              },
              {
                label: t(
                  'integrations.channels.form.fields.protocol.labels.http'
                ),
                value: 'http',
              },
              {
                label: t(
                  'integrations.channels.form.fields.protocol.labels.https'
                ),
                value: 'https',
              },
              {
                label: t(
                  'integrations.channels.form.fields.protocol.labels.https_insecure'
                ),
                value: 'https_insecure',
              },
            ]}
            value={protocol}
            onChange={(e) => {
              setProtocol(e.target.value)
            }}
          />
        </Grid>

        {['tls', 'https', 'tls_insecure', 'https_insecure'].includes(
          protocol
        ) && (
          <>
            <Grid item xs={12}>
              <FormField
                label={t(
                  'integrations.channels.form.fields.proxyRootCertificate'
                )}
                placeholder=""
                value={proxyRootCert}
                onChange={(e) => {
                  setProxyRootCert(e.target.value)
                }}
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <FormField
                label={t(
                  'integrations.channels.form.fields.proxyClientCertificate'
                )}
                placeholder=""
                value={proxyClientCert}
                onChange={(e) => {
                  setProxyClientCert(e.target.value)
                }}
                multiline
              />
            </Grid>

            <Grid item xs={12}>
              <FormField
                label={t('integrations.channels.form.fields.proxyClientKey')}
                placeholder=""
                value={proxyClientKey}
                onChange={(e) => {
                  setProxyClientKey(e.target.value)
                }}
                multiline
              />
            </Grid>
          </>
        )}

        {['tls', 'tls_insecure'].includes(protocol) && (
          <Grid item xs={12}>
            <FormSelect
              label={t('integrations.channels.form.fields.proxyTlsVersion')}
              options={proxyTlsVersionOptions}
              value={proxyTlsVersion}
              emptyLabel={t('common.values.useDefault')}
              onChange={(_, value) => {
                setProxyTlsVersion(value as ProxyTlsVersion | '')
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormSwitch
            title={t('integrations.channels.form.fields.proxyPass')}
            checked={proxyPass}
            onChange={() => {
              setProxyPass(!proxyPass)
            }}
          />
        </Grid>
      </div>
    </Dialog>
  )
}

export default AddCommunicationChannelForm
