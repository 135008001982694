import React from 'react'

import Table from 'common/components/TableNew'

import { useTranslation } from 'react-i18next'
import {
  type Integration,
  type IntegrationTest,
} from 'App/Integrations/integrations-types'
import IconMenu from 'common/components/IconMenu'

import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  useGetIntegrationsQuery,
  useIntegrationTestCanBorrowMutation,
} from '../integrations-rtk-api'

interface IntegrationStatisticsRowActionsProps {
  integration: Integration
  integrationTests: IntegrationTest[]
}

const IntegrationStatisticsRowActions = ({
  integration,
  integrationTests,
}: IntegrationStatisticsRowActionsProps) => {
  const { organizationId, siteId } = useCurrentAccount()
  const [integrationTestCanBorrow] = useIntegrationTestCanBorrowMutation()
  const actions = [
    {
      local: 'integrations.actions.test',
      fn: () => {
        for (const integrationTest of integrationTests.filter(
          (it) => it.authConfigId === integration.id
        )) {
          const data = {
            organizationId,
            siteId,
            integrationId: integration.id,
            integrationTestId: integrationTest.id,
          }
          integrationTestCanBorrow(data)
        }
      },
      icon: SettingsInputCompositeIcon,
    },
    // {
    //   local: 'integrations.actions.testInPilot',
    //   fn: () => {
    //     console.log('TODO: Test (in pilot) ', integrationTests.filter(it => it.authConfigId === integration.id))
    //   },
    //   icon: SettingsInputCompositeIcon,
    // }
  ]

  return <IconMenu actions={actions} />
}

interface IntegrationStatisticsTableProps {
  integrationTests: IntegrationTest[]
}
const IntegrationStatisticsTable = ({
  integrationTests,
}: IntegrationStatisticsTableProps) => {
  const { t } = useTranslation()
  const { organizationId, siteId } = useCurrentAccount()
  const { data: integrations } = useGetIntegrationsQuery({
    organizationId,
    siteId,
  })

  const columns = [
    {
      title: t('integrations.listIntegrations.columns.integrationUrl'),
      render: ({ authApiUrl }: Integration) => authApiUrl ?? '',
    },
    {
      title: t('integrations.listIntegrations.columns.activeCount'),
      render: (integration: Integration) =>
        integrations?.filter(
          (i) => i.authApiUrl === integration.authApiUrl && i.isEnabled
        ).length,
    },
    {
      title: t('integrations.listIntegrations.columns.nonActiveCount'),
      render: (integration: Integration) =>
        integrations?.filter(
          (i) => i.authApiUrl === integration.authApiUrl && !i.isEnabled
        ).length,
    },
    {
      title: t('integrations.listIntegrations.columns.lastSuccessfulCanBorrow'),
      render: ({ canBorrowSuccess }: Integration) =>
        canBorrowSuccess ? (
          <DateWithRelativeTime datetime={new Date(canBorrowSuccess ?? '0')} />
        ) : (
          '-'
        ),
    },
    {
      title: t('integrations.listIntegrations.columns.lastFailedCanBorrow'),
      render: ({ canBorrowFailed }: Integration) =>
        canBorrowFailed ? (
          <DateWithRelativeTime datetime={new Date(canBorrowFailed ?? '0')} />
        ) : (
          '-'
        ),
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (integration: Integration) => {
        return (
          <IntegrationStatisticsRowActions
            integration={integration}
            integrationTests={integrationTests}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={integrations ?? []} />
    </>
  )
}

export default IntegrationStatisticsTable
