import useUserTimeZone from 'common/hooks/useUserTimeZone'
import { renderDate } from 'common/utils/render-utils'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const useRenderDate = () => {
  const { i18n } = useTranslation()
  const tz = useUserTimeZone()

  const cb = useCallback(
    (date: Date) => {
      return renderDate(date, i18n.language, tz)
    },
    [tz, i18n.language]
  )

  return cb
}

export default useRenderDate
