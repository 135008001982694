import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { type Profile, type ProfileForm } from 'App/Profiles/profiles-types'
import { Divider, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Panel from 'common/components/Panel'
import {
  updateProfile,
  fetchProfiles,
  resetUpdateProfile,
} from 'App/Profiles/profiles-state'
import { useAppDispatch, useAppSelector } from 'store'
import Switch from 'common/components/Switch'
import SettingsEntry from 'App/Profiles/ProfilesView/SettingsEntry'
import HSlider from 'common/components/HSlider'

const useStyles = makeStyles()((theme: Theme) => ({
  TabletsSettings: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  TabletSettingsAudiVolume: {
    width: 132,

    '& .MuiInput-formControl': {
      paddingTop: 0,
    },
  },
  VolumeSlider: {
    width: 132,
  },
}))

interface TabletsSettingsProps {
  profile?: Profile
}

const TabletsSettings = ({ profile }: TabletsSettingsProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const updatedProfile = useAppSelector(
    (state) => state.profiles.updatedProfile
  )
  const updatingProfile = useAppSelector(
    (state) => state.profiles.updatingProfile
  )

  useEffect(() => {
    if (profile != null && updatedProfile) {
      dispatch(resetUpdateProfile())
      dispatch(
        fetchProfiles({
          organizationId: profile.organizationId,
          siteId: profile.siteId,
        })
      )
    }
  }, [dispatch, profile, updatedProfile])

  if (profile == null) {
    return null
  }

  const profileAudioDefaultVolume = Math.round(
    profile.audioDefaultOutputVolume / 10
  ) // scales 0-100 to 0-10
  const profileAudioMaxVolume = Math.round(profile.audioMaxOutputVolume / 10)

  const onProfileChange = (getChanges: () => ProfileForm) => {
    if (!updatingProfile) {
      dispatch(
        updateProfile({
          context: {
            organizationId: profile.organizationId,
            siteId: profile.siteId,
          },
          form: getChanges(),
          profileId: profile.id,
        })
      )
    }
  }

  const onEnableSpeakerChanged = (checked: boolean) => {
    onProfileChange(() => ({ enableSpeaker: checked }))
  }

  const onEnableMicrophoneChanged = (checked: boolean) => {
    onProfileChange(() => ({ enableMicrophone: checked }))
  }

  const onEnableCameraChanged = (checked: boolean) => {
    onProfileChange(() => ({ enableCamera: checked }))
  }

  const onDefaultAudioVolumeChange = (volume: number) => {
    const audioDefaultOutputVolume = volume * 10 // Scale 0-10 to 0-100
    onProfileChange(() => ({ audioDefaultOutputVolume }))
  }

  const onMaxAudioVolumeChange = (volume: number) => {
    const audioMaxOutputVolume = volume * 10
    onProfileChange(() => ({ audioMaxOutputVolume }))
  }

  return (
    <Grid container className={classes.TabletsSettings} direction="column">
      <Panel title={t('profiles.editProfile.tablets.audio.title')}>
        <SettingsEntry property="profiles.editProfile.tablets.audio.speakers">
          <Switch
            checked={profile.enableSpeaker}
            onChange={(e) => onEnableSpeakerChanged(e.target.checked)}
          />
        </SettingsEntry>
        <Divider light />
        <SettingsEntry property="profiles.editProfile.tablets.audio.defaultVolume">
          <div className={classes.VolumeSlider}>
            <HSlider
              onChangeCommitted={(_e, v) =>
                onDefaultAudioVolumeChange(v as number)
              }
              defaultValue={5}
              value={profileAudioDefaultVolume}
              step={1}
              min={0}
              max={10}
              virtualMax={profileAudioMaxVolume}
              disabled={!profile.enableSpeaker}
            />
          </div>
        </SettingsEntry>
        <Divider light />
        <SettingsEntry property="profiles.editProfile.tablets.audio.maxVolume">
          <div className={classes.VolumeSlider}>
            <HSlider
              onChangeCommitted={(_e, v) => onMaxAudioVolumeChange(v as number)}
              defaultValue={10}
              value={profileAudioMaxVolume}
              step={1}
              min={0}
              max={10}
              virtualMin={profileAudioDefaultVolume}
              disabled={!profile.enableSpeaker}
            />
          </div>
        </SettingsEntry>
        <Divider light />
        <SettingsEntry property="profiles.editProfile.tablets.audio.microphone">
          <Switch
            checked={profile.enableMicrophone}
            onChange={(_e, checked) => onEnableMicrophoneChanged(checked)}
          />
        </SettingsEntry>
      </Panel>
      <Panel title={t('profiles.editProfile.tablets.camera.title')}>
        <SettingsEntry property="profiles.editProfile.tablets.camera">
          <Switch
            checked={profile.enableCamera}
            onChange={(_e, checked) => onEnableCameraChanged(checked)}
          />
        </SettingsEntry>
      </Panel>
    </Grid>
  )
}

export default TabletsSettings
