import React, { useEffect, useState } from 'react'

import Table from 'common/components/TableNew'

import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'

import { useTranslation } from 'react-i18next'
import { type Customer } from 'App/Customers/customers-types'
import { type RootState, useAppDispatch } from 'store'
import { connect } from 'react-redux'
import EditCustomerDrawer from 'App/Customers/components/EditCustomerDrawer'
import ActionDialog from 'common/components/ActionDialog'
import {
  deleteCustomer,
  fetchCustomers,
  resetDeletedCustomer,
} from 'App/Customers/customers-state'
import IconMenu from 'common/components/IconMenu'
import AvatarWithText from 'common/components/AvatarWithText'

interface CustomersRowActionsProps {
  customer: Customer
  onClickEdit: (customer: Customer) => void
  onClickDelete: (customer: Customer) => void
}

const CustomersRowActions = ({
  customer,
  onClickEdit,
  onClickDelete,
}: CustomersRowActionsProps) => {
  const actions = [
    {
      local: 'customers.listCustomers.actions.edit',
      fn: () => onClickEdit(customer),
      icon: EditIcon,
    },
    {
      local: 'customers.listCustomers.actions.delete',
      fn: () => onClickDelete(customer),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

interface CustomersTableProps {
  distributorId: number
  customers: Customer[]
  updatedCustomer: boolean
  deletedCustomer: boolean
}

const CustomersTable = ({
  distributorId,
  customers,
  updatedCustomer,
  deletedCustomer,
}: CustomersTableProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [editCustomerValue, setEditCustomerValue] = useState<
    Customer | undefined
  >()
  const [deleteCustomerValue, setDeleteCustomerValue] = useState<
    Customer | undefined
  >()

  useEffect(() => {
    if (deletedCustomer) {
      dispatch(resetDeletedCustomer())
      dispatch(fetchCustomers(distributorId))
    }
  }, [dispatch, deletedCustomer, distributorId])

  const onClickEdit = (customer: Customer) => setEditCustomerValue(customer)
  const onClickDelete = (customer: Customer) => setDeleteCustomerValue(customer)

  const onCloseDrawer = () => setEditCustomerValue(undefined)
  const onCloseDialog = () => setDeleteCustomerValue(undefined)

  const onActionDelete = () => {
    if (deleteCustomerValue != null) {
      dispatch(
        deleteCustomer({ distributorId, customerId: deleteCustomerValue.id })
      )
      setDeleteCustomerValue(undefined)
    }
  }

  const columns = [
    {
      title: t('customers.table.columns.name'),
      field: 'name',
      // eslint-disable-next-line react/display-name
      render: ({ name }: Customer) => <AvatarWithText text={name} />,
      style: { width: 'auto' },
    },
    {
      title: t('customers.table.columns.sites'),
      field: 'numSites',
      style: { width: '120px' },
    },
    {
      title: t('customers.table.columns.docks'),
      field: 'numDocks',
      style: { width: '120px' },
    },
    {
      title: t('customers.table.columns.tablets'),
      field: 'numTablets',
      style: { width: '120px' },
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (customer: Customer) => {
        return (
          <CustomersRowActions
            customer={customer}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={customers} />
      {editCustomerValue != null && (
        <EditCustomerDrawer
          distributorId={distributorId}
          customer={editCustomerValue}
          open={true}
          onClose={onCloseDrawer}
          updatedCustomer={updatedCustomer}
        />
      )}
      {deleteCustomerValue != null && (
        <ActionDialog
          open={deleteCustomerValue !== undefined}
          title={t('customers.deleteCustomer.title')}
          description={t('customers.deleteCustomer.description', {
            customer: deleteCustomerValue.name,
          })}
          actionText={t('customers.deleteCustomer.action')}
          onAction={onActionDelete}
          onClose={onCloseDialog}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  updatedCustomer: state.customers.updatedCustomer,
  deletedCustomer: state.customers.deletedCustomer,
})

export default connect(mapStateToProps)(CustomersTable)
