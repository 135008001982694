import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersApi from 'api/users-api'
import { type ForgotPasswordInfo } from 'App/ForgotPassword/forgot-password-types'

interface ForgotPasswordState {
  sentForgotPasswordEmail: boolean
  forgotPasswordInfo: ForgotPasswordInfo | null
  fetchedForgotPasswordInfo: boolean
  resetedPassword: boolean
}

const initialState: ForgotPasswordState = {
  sentForgotPasswordEmail: false,
  forgotPasswordInfo: null,
  fetchedForgotPasswordInfo: false,
  resetedPassword: false,
}

export const sendForgotPasswordEmail = createAsyncThunk(
  'forgotPassword/sendForgotPasswordEmail',
  async ({ email, language }: { email: string; language: string }) =>
    UsersApi.sendForgotPasswordEmail(email, language)
)

export const fetchForgotPasswordInfo = createAsyncThunk(
  'forgotPassword/fetchForgotPasswordInfo',
  async ({ key }: { key: string }) => UsersApi.fetchPasswordInfo(key)
)

export const resetPassword = createAsyncThunk(
  'forgotPassword/resetPassword',
  async ({ key, password }: { key: string; password: string }) =>
    UsersApi.resetPassword(key, password)
)

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    resetSentForgotPasswordEmail: (state) => {
      state.sentForgotPasswordEmail = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendForgotPasswordEmail.fulfilled, (state) => {
      state.sentForgotPasswordEmail = true
      return state
    })

    builder.addCase(fetchForgotPasswordInfo.fulfilled, (state, { payload }) => {
      state.forgotPasswordInfo = payload
      state.fetchedForgotPasswordInfo = true
      return state
    })

    builder.addCase(fetchForgotPasswordInfo.rejected, (state) => {
      state.forgotPasswordInfo = null
      state.fetchedForgotPasswordInfo = true
      return state
    })

    builder.addCase(resetPassword.fulfilled, (state) => {
      state.resetedPassword = true
      return state
    })
  },
})

export const { resetSentForgotPasswordEmail } = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer
