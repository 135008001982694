import React, { useEffect, useState } from 'react'
import {
  type Integration,
  IntegrationProxyType,
} from 'App/Integrations/integrations-types'
import Panel, { EditState } from 'common/components/NewPanel'
import PanelField from 'common/components/PanelField'
import FormSelect from 'common/components/FormSelect'
import { useUpdateIntegrationCommunicationMutation } from 'App/Integrations/integrations-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useApiErrors from 'common/hooks/useApiErrors'
import {
  fetchCommunicationChannels,
  fetchIntegrations,
} from 'App/Integrations/integrations-state'
import CommunicationChannelTable from 'App/Integrations/components/CommunicationChannelTable'
import AddCommunicationChannelForm from 'App/Integrations/components/AddCommunicationChannelForm'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

interface CommunicationConfigurationPanelProps {
  integration: Integration
}

const CommunicationConfigurationPanel = ({
  integration,
}: CommunicationConfigurationPanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [editState, setEditState] = useState<EditState>()
  const [proxyType, setProxyType] = useState(integration.proxyType)

  const { organizationId, siteId } = useCurrentAccount()

  const [
    updateIntegrationCommunication,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateIntegrationCommunicationMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
      dispatch(fetchIntegrations({ organizationId, siteId }))
    }
  }, [dispatch, organizationId, siteId, isUpdateSuccess, hasApiMutationErrors])

  const handleSave = () => {
    setEditState(EditState.Pending)
    updateIntegrationCommunication({
      organizationId,
      siteId,
      integrationId: integration.id,
      proxyType,
    })
  }

  const communicationTypeOptions = [
    {
      label: t('integrations.integrationForm.fields.proxyType.values.none'),
      value: IntegrationProxyType.None,
    },
    {
      label: t('integrations.integrationForm.fields.proxyType.values.cloud'),
      value: IntegrationProxyType.Cloud,
    },
    {
      label: t('integrations.integrationForm.fields.proxyType.values.dock'),
      value: IntegrationProxyType.Dock,
    },
  ]

  const renderEditMode = () => {
    return (
      <FormSelect
        label={t('integrations.integrationForm.fields.communicationType.label')}
        options={communicationTypeOptions}
        onChange={(_, v) => setProxyType(v as IntegrationProxyType)}
        placeholder={t(
          'integrations.integrationForm.fields.paramSelOne.placeholder'
        )}
        value={proxyType}
        disabled={editState === EditState.Pending}
      />
    )
  }

  return (
    <Panel
      title={t('integrations.communication.container.label')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(undefined)}
      onSave={handleSave}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('integrations.integrationForm.fields.communicationType.label')}
        value={
          communicationTypeOptions.find(
            ({ value }) => value === integration.proxyType
          )?.label
        }
      />
    </Panel>
  )
}

interface CommunicationChannelsPanelProps {
  integration: Integration
}

const CommunicationChannelsPanel = ({
  integration,
}: CommunicationChannelsPanelProps) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { communicationConfigurationId } = integration

  const [editState, setEditState] = useState<EditState>()

  const { organizationId, siteId } = useCurrentAccount()
  const communicationChannels = useAppSelector(
    (state) => state.integrations.communicationChannels
  )

  const addedCommunicationChannel = useAppSelector(
    (state) => state.integrations.addedCommunicationChannel
  )
  const updatedCommunicationChannel = useAppSelector(
    (state) => state.integrations.updatedCommunicationChannel
  )
  const removedCommunicationChannel = useAppSelector(
    (state) => state.integrations.removedCommunicationChannel
  )

  useEffect(() => {
    if (communicationConfigurationId === undefined) return
    dispatch(
      fetchCommunicationChannels({
        organizationId,
        siteId,
        communicationConfigurationId,
      })
    )
  }, [dispatch, organizationId, siteId, communicationConfigurationId])

  useEffect(() => {
    if (addedCommunicationChannel) {
      setEditState(EditState.Success)
    }
  }, [addedCommunicationChannel])

  useEffect(() => {
    if (updatedCommunicationChannel) {
      setEditState(EditState.Success)
    }
  }, [updatedCommunicationChannel])

  useEffect(() => {
    if (removedCommunicationChannel) {
      setEditState(EditState.Success)
    }
  }, [removedCommunicationChannel])

  if (communicationConfigurationId === undefined) return null

  const renderEditMode = () => {
    return (
      <AddCommunicationChannelForm
        open={editState === EditState.Edit}
        communicationConfigurationId={communicationConfigurationId}
        setAddCcModalOpen={(open) => {
          if (!open) {
            setEditState(undefined)
          }
        }}
      />
    )
  }

  return (
    <Panel
      variant="table"
      title={t('integrations.channels.container.label')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onSuccess={() => setEditState(undefined)}
      renderEditMode={renderEditMode}
    >
      <CommunicationChannelTable
        channels={communicationChannels}
        communicationConfigurationId={communicationConfigurationId}
      />
    </Panel>
  )
}

interface IntegrationCommunicationProps {
  integration: Integration
}

const IntegrationCommunication = ({
  integration,
}: IntegrationCommunicationProps) => {
  return (
    <>
      <CommunicationConfigurationPanel integration={integration} />
      <CommunicationChannelsPanel integration={integration} />
    </>
  )
}

export default IntegrationCommunication
