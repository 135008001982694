import React, { useEffect, useState } from 'react'

import Table from 'common/components/TableNew'

import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'

import { useTranslation } from 'react-i18next'
import { type Distributor } from 'App/Distributors/distributors-types'
import { type RootState, useAppDispatch } from 'store'
import { connect } from 'react-redux'
import EditDistributorDrawer from 'App/Distributors/components/EditDistributorDrawer'
import ActionDialog from 'common/components/ActionDialog'
import {
  deleteDistributor,
  fetchDistributors,
  resetDeletedDistributor,
} from 'App/Distributors/distributors-state'
import IconMenu from 'common/components/IconMenu'
import AvatarWithText from 'common/components/AvatarWithText'

interface DistributorsRowActionsProps {
  distributor: Distributor
  onClickEdit: (distributor: Distributor) => void
  onClickDelete: (distributor: Distributor) => void
}

const DistributorsRowActions = ({
  distributor,
  onClickEdit,
  onClickDelete,
}: DistributorsRowActionsProps) => {
  const actions = [
    {
      local: 'distributors.listDistributors.actions.edit',
      fn: () => onClickEdit(distributor),
      icon: EditIcon,
    },
    {
      local: 'distributors.listDistributors.actions.delete',
      fn: () => onClickDelete(distributor),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

interface DistributorsTableProps {
  distributors: Distributor[]
  updatedDistributor: boolean
  deletedDistributor: boolean
}

const DistributorsTable = ({
  distributors,
  updatedDistributor,
  deletedDistributor,
}: DistributorsTableProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [editDistributorValue, setEditDistributorValue] = useState<
    Distributor | undefined
  >()
  const [deleteDistributorValue, setDeleteDistributorValue] = useState<
    Distributor | undefined
  >()

  useEffect(() => {
    if (deletedDistributor) {
      dispatch(resetDeletedDistributor())
      dispatch(fetchDistributors())
    }
  }, [dispatch, deletedDistributor])

  const onClickEdit = (distributor: Distributor) =>
    setEditDistributorValue(distributor)
  const onClickDelete = (distributor: Distributor) =>
    setDeleteDistributorValue(distributor)

  const onCloseDrawer = () => setEditDistributorValue(undefined)
  const onCloseDialog = () => setDeleteDistributorValue(undefined)

  const onActionDelete = () => {
    if (deleteDistributorValue != null) {
      dispatch(deleteDistributor(deleteDistributorValue))
      setDeleteDistributorValue(undefined)
    }
  }

  const columns = [
    {
      title: t('distributors.table.columns.name'),
      field: 'name',
      // eslint-disable-next-line react/display-name
      render: ({ name }: Distributor) => <AvatarWithText text={name} />,
      style: { width: 'auto' },
    },
    {
      title: t('distributors.table.columns.customers'),
      field: 'numCustomers',
      style: { width: '120px' },
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (distributor: Distributor) => {
        return (
          <DistributorsRowActions
            distributor={distributor}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={distributors} />
      <EditDistributorDrawer
        distributor={editDistributorValue}
        open={editDistributorValue !== undefined}
        onClose={onCloseDrawer}
        updatedDistributor={updatedDistributor}
      />
      {deleteDistributorValue != null && (
        <ActionDialog
          open={deleteDistributorValue !== undefined}
          title={t('distributors.deleteDistributor.title')}
          description={t('distributors.deleteDistributor.description', {
            distributor: deleteDistributorValue.name,
          })}
          actionText={t('distributors.deleteDistributor.action')}
          onAction={onActionDelete}
          onClose={onCloseDialog}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  updatedDistributor: state.distributors.updatedDistributor,
  deletedDistributor: state.distributors.deletedDistributor,
})

export default connect(mapStateToProps)(DistributorsTable)
