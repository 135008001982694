import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DashboardApi from 'api/dashboard-api'
import { type DashboardData } from 'App/Dashboard/dashboard-types'

interface DashboardState {
  dashboardData: DashboardData
  fetchedLoansData: boolean
}

const initialState: DashboardState = {
  dashboardData: {
    loansByDate: [],
    totalLoans: 0,
    totalLoanMinutes: 0,
    avgLoanMinutes: 0,
    medianLoanMinutes: 0,
  },
  fetchedLoansData: false,
}

export const fetchGlobalDashboardLoansData = createAsyncThunk(
  'dashboard/fetchGlobalDashboardLoansData',
  async ({
    timeRangeStartInTz,
    timeRangeEndInTz,
    includeOvertime,
    timeZone,
  }: {
    timeRangeStartInTz: Date
    timeRangeEndInTz: Date
    includeOvertime: boolean
    timeZone: string
  }) =>
    DashboardApi.fetchGlobalLoansData(
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone
    )
)

export const fetchDistributorDashboardLoansData = createAsyncThunk(
  'dashboard/fetchDistributorDashboardLoansData',
  async ({
    distributorId,
    timeRangeStartInTz,
    timeRangeEndInTz,
    includeOvertime,
    timeZone,
  }: {
    distributorId: number
    timeRangeStartInTz: Date
    timeRangeEndInTz: Date
    includeOvertime: boolean
    timeZone: string
  }) =>
    DashboardApi.fetchDistributorLoansData(
      distributorId,
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone
    )
)

export const fetchOrgDashboardLoansData = createAsyncThunk(
  'dashboard/fetchOrgDashboardLoansData',
  async ({
    organizationId,
    timeRangeStartInTz,
    timeRangeEndInTz,
    includeOvertime,
    timeZone,
  }: {
    organizationId: number
    timeRangeStartInTz: Date
    timeRangeEndInTz: Date
    includeOvertime: boolean
    timeZone: string
  }) =>
    DashboardApi.fetchOrgLoansData(
      organizationId,
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone
    )
)

export const fetchSiteDashboardLoansData = createAsyncThunk(
  'dashboard/fetchSiteDashboardLoansData',
  async ({
    organizationId,
    siteId,
    timeRangeStartInTz,
    timeRangeEndInTz,
    includeOvertime,
    timeZone,
  }: {
    organizationId: number
    siteId: number
    timeRangeStartInTz: Date
    timeRangeEndInTz: Date
    includeOvertime: boolean
    timeZone: string
  }) =>
    DashboardApi.fetchSiteLoansData(
      organizationId,
      siteId,
      timeRangeStartInTz,
      timeRangeEndInTz,
      includeOvertime,
      timeZone
    )
)

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGlobalDashboardLoansData.fulfilled,
      (state, { payload }) => {
        state.dashboardData = payload
        state.fetchedLoansData = true
        return state
      }
    )

    builder.addCase(
      fetchDistributorDashboardLoansData.fulfilled,
      (state, { payload }) => {
        state.dashboardData = payload
        state.fetchedLoansData = true
        return state
      }
    )

    builder.addCase(
      fetchOrgDashboardLoansData.fulfilled,
      (state, { payload }) => {
        state.dashboardData = payload
        state.fetchedLoansData = true
        return state
      }
    )

    builder.addCase(
      fetchSiteDashboardLoansData.fulfilled,
      (state, { payload }) => {
        state.dashboardData = payload
        state.fetchedLoansData = true
        return state
      }
    )
  },
})

export default dashboardSlice.reducer
