import React, { useContext, useMemo, useState } from 'react'
import { type Dock } from 'App/Docks/docks-types'
import Panel from 'common/components/NewPanel'
import FormField from 'common/components/FormField'
import { Divider, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'
import JsonBox from 'common/components/JsonBox'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import DocksReactContext from 'App/Docks/docks-react-context'
import { useTestCanBorrowMutation } from 'App/Docks/docks-rtk-api'
import ErrorBox from 'common/components/ErrorBox'
import useApiErrors from 'common/hooks/useApiErrors'
import { useTranslation } from 'react-i18next'
import FormSwitch from 'common/components/FormSwitch'
import FormFieldGroup from 'common/components/FormFieldGroup'
import FormSelect from 'common/components/FormSelect'
import useProfiles from 'common/hooks/useProfiles'

const useStyles = makeStyles()((theme: Theme) => ({
  DockTestPanelContent: {
    '& > hr': {
      marginTop: theme.spacing(3),
    },
  },
}))

interface DockTestProps {
  dock: Dock
}

const DockTest = ({ dock }: DockTestProps) => {
  const { t } = useTranslation()
  const { classes: styles } = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [profileId, setProfileId] = useState(-1)
  const [usedVirtualKeyboard, setUsedVirtualKeyboard] = useState(true)

  const [
    testCanBorrow,
    {
      data: canBorrowRes,
      isSuccess: isCanBorrowSuccess,
      error: canBorrowError,
    },
  ] = useTestCanBorrowMutation()

  const { organizationId, siteId } = useCurrentAccount()
  const context = useContext(DocksReactContext)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    canBorrowError,
  ])

  const profiles = useProfiles()

  const profileOptions = useMemo(() => {
    const noProfileOption = { label: '-', value: -1 }
    const profileOptions = profiles.map((p) => ({
      label: p.name,
      value: p.id,
    }))
    return [noProfileOption, ...profileOptions]
  }, [profiles])

  const handleSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    const data = {
      organizationId,
      siteId,
      dockId: dock.id,
      context,
      username,
      password,
      profileId: profileId === -1 ? null : profileId,
      usedVirtualKeyboard,
    }

    testCanBorrow(data)
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Panel
        title={t('dock.details.test.title')}
        classes={{ content: styles.DockTestPanelContent }}
      >
        <FormFieldGroup>
          <FormField
            label={t('dock.details.test.username.label')}
            placeholder={t('dock.details.test.username.placeholder')}
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            autoFocus
          />
          <FormField
            label={t('dock.details.test.password.label')}
            placeholder={t('dock.details.test.password.placeholder')}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <FormSelect
            label={t('dock.details.test.profile.label')}
            options={profileOptions}
            value={profileId}
            onChange={(_e, v) => setProfileId(v as number)}
          />
          <FormSwitch
            title={t('dock.details.test.usedVirtualKeyboard.label')}
            checked={usedVirtualKeyboard}
            onChange={(_e, checked) => setUsedVirtualKeyboard(checked)}
            variant="drawer"
          />
        </FormFieldGroup>
        <Divider light />
        <Button type="submit" small>
          {t('common.submit')}
        </Button>
        {hasApiMutationErrors && (
          <>
            <Divider light />
            <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>
          </>
        )}
        {isCanBorrowSuccess && (
          <>
            <Divider light />
            <JsonBox object={canBorrowRes} />
          </>
        )}
      </Panel>
    </form>
  )
}

export default DockTest
