import React, { useEffect } from 'react'
import { fetchSites } from 'App/Sites/sites-state'
import SitesList from 'App/Sites/SitesList'
import useApp from 'common/hooks/useApp'
import { useAppDispatch } from 'store'

const Sites = () => {
  const dispatch = useAppDispatch()

  const { organization } = useApp()

  useEffect(() => {
    dispatch(fetchSites({ organizationId: organization.id }))
  }, [dispatch, organization])

  return <SitesList />
}

export default Sites
