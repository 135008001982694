import useApp from 'common/hooks/useApp'

interface CurrentAccount {
  organizationDistributorId?: number
  organizationId: number
  siteId: number
}

const useCurrentAccount = (): CurrentAccount => {
  const { site, organization, organizationDistributorId } = useApp()
  const siteId = site === undefined ? -1 : site.id

  return {
    organizationDistributorId,
    organizationId: organization.id,
    siteId,
  }
}

export default useCurrentAccount
