import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Link } from 'react-router-dom'

enum PathStepKind {
  Email = 'Email',
  UserManagement = 'User management',
}

interface PathStepProps {
  step: number
  title: string
  description: string
  link: string
  stepKind: PathStepKind
  scheduledAt: string
}

const PathStep = ({
  step,
  title,
  description,
  link,
  stepKind,
  scheduledAt,
}: PathStepProps) => {
  return (
    <Box
      component={Link}
      to={link}
      sx={(theme) => ({
        padding: theme.spacing(2),
        backgroundColor: theme.palette.hublet.common.white,
        borderRadius: '10px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
        border: `1px solid ${theme.palette.hublet.common.white}`,
        '&:hover': {
          cursor: 'pointer',
          boxShadow: 'rgb(108 37 118 / 10%) 0px 4px 20px',
          borderColor: theme.palette.hublet.primary.extraLight,
        },
        display: 'block',
        color: theme.palette.hublet.common.black,
        textDecoration: 'none',
        transition: 'all 0.05s ease-in',
        position: 'relative',
      })}
    >
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: '-8px',
          left: '-8px',
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          backgroundColor: theme.palette.hublet.common.white,
          border: `1px solid ${theme.palette.hublet.primary.extraLight}`,
          color: theme.palette.hublet.primary.light,
          lineHeight: 1,
          fontSize: '12px',
          fontWeight: 600,
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        {step}
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack>
          <Box>
            <Typography
              sx={(theme) => ({
                fontSize: '10px',
                textTransform: 'uppercase',
                color: theme.palette.hublet.secondary.light,
              })}
            >
              {stepKind} · {scheduledAt}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={(theme) => ({
                fontWeight: 600,
                fontSize: '18px',
              })}
            >
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '14px' }}>{description}</Typography>
          </Box>
        </Stack>
        <Box
          sx={(theme) => ({
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
          })}
        >
          <ChevronRightIcon />
        </Box>
      </Stack>
    </Box>
  )
}

const SuperAdmin = () => {
  return (
    <Page>
      <PageHeader>
        <PageTitle title="Super-admin " />
      </PageHeader>
      <PageBody>
        <Stack gap={2}>
          <Typography variant="h2">AWS Cognito</Typography>
          <Typography variant="body1">
            Steps required to move to using AWS Cognito for authentication.
          </Typography>
          <PathStep
            step={1}
            title="Pre-release notification email"
            description='"We have upgraded our security systems. Please update your
            password." email.'
            link="/admin/aws-cognito/pre-release"
            stepKind={PathStepKind.Email}
            scheduledAt="2023-10-02"
          />
          <PathStep
            step={2}
            title="Move users to Cognito user pool"
            description="Create users in Cognito user pool from existing active users in Hublet database."
            link="/admin/aws-cognito/move-users"
            stepKind={PathStepKind.UserManagement}
            scheduledAt="2023-10-09"
          />
        </Stack>
      </PageBody>
    </Page>
  )
}

export default SuperAdmin
