import React, { type PropsWithChildren } from 'react'
import { Grid } from '@mui/material'
import Avatar from 'common/components/Avatar'

interface AvatarWithTextProps extends PropsWithChildren {
  text: string
}

const AvatarWithText = ({ text, children }: AvatarWithTextProps) => {
  return (
    <Grid container wrap="nowrap" alignItems="center">
      <Grid item>
        <Avatar name={text} />
      </Grid>
      <Grid item>{children ?? text}</Grid>
    </Grid>
  )
}

export default AvatarWithText
