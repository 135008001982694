import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { useEffect, useMemo, useState } from 'react'
import {
  DataLevel,
  type LoansByDate,
  type TimeUnit,
} from 'App/Dashboard/dashboard-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  fetchDistributorDashboardLoansData,
  fetchGlobalDashboardLoansData,
  fetchOrgDashboardLoansData,
  fetchSiteDashboardLoansData,
} from 'App/Dashboard/dashboard-state'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Bar,
  Cell,
  CartesianGrid,
  type TooltipProps,
} from 'recharts'
import { getTimeRangeEnd, getTimeRangeStart } from 'App/Dashboard'
import { addDays, format, isSameDay } from 'date-fns'
import { useTranslation } from 'react-i18next'
import getDateFnsLocale from 'common/utils/get-date-fns-locale'
import Button from 'common/components/Button'
import * as XLSX from 'xlsx'
import { type CategoricalChartFunc } from 'recharts/types/chart/generateCategoricalChart'
import Panel from 'common/components/NewPanel'
import { renderDuration } from 'common/utils/render-utils'
import { useAppDispatch, useAppSelector } from 'store'
import useUserTimeZone from 'common/hooks/useUserTimeZone'

const useStyles = makeStyles()((theme: Theme) => ({
  LoansData: {
    padding: theme.spacing(4),
    borderRadius: '10px',
    backgroundColor: theme.palette.hublet.common.white,
    boxShadow: '0px 4px 20px rgb(0 0 0 / 5%)',
    '& h3': {
      margin: 0,
      color: theme.palette.hublet.primary.main,
      fontSize: 20,
    },
  },
  LoansDataTitle: {
    margin: 0,
    color: theme.palette.hublet.primary.main,
    fontSize: 20,
  },
  LoansDataChart: {
    width: '100%',
    height: '500px',
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  LoansDataActions: {
    flexGrow: 1,
    alignSelf: 'center',
    textAlign: 'right',
  },
  LoansDataSelect: {
    width: 'auto',
    height: '39px',
    marginRight: theme.spacing(1),
    textAlign: 'left',
    fontSize: '14px',
    '& > label': {
      height: '100%',
    },
    '& .MuiInputBase-root': {
      height: '100%',
      fontSize: '14px',
    },
  },
  CustomTooltip: {
    minWidth: 81,
    height: 65,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.hublet.common.white,
    borderRadius: 10,
    textAlign: 'center',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 5%)',
  },
  CustomTooltipLabel: {
    fontSize: 10,
    lineHeight: '14px',
    color: theme.palette.hublet.secondary.light,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  CustomTooltipValue: {
    marginTop: 2,
    fontSize: 24,
    lineHeight: '33px',
    color: theme.palette.hublet.primary.main,
    fontWeight: 'bold',
  },
  DataBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 0,
    flexGrow: 1,
    textAlign: 'center',
    width: `calc(50% - ${theme.spacing(1)})`,
  },
  DataBoxTitle: {
    margin: `0 0 ${theme.spacing(2)} 0`,
    fontSize: 16,
    color: theme.palette.hublet.primary.main,
  },
  DataBoxValue: {
    lineHeight: 1,
    fontSize: 32,
    fontWeight: 'bold',
  },
  DataBoxColumnContainer: {},
  DataBoxColumn: {
    display: 'flex',
    height: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
}))

interface DataBoxProps {
  title: string
  value?: string | number
}

const DataBox = ({ title, value }: DataBoxProps) => {
  const { classes } = useStyles()

  return (
    <Panel classes={{ container: classes.DataBox }} variant="page">
      <h3 className={classes.DataBoxTitle}>{title}</h3>
      <span className={classes.DataBoxValue}>{value ?? '-'}</span>
    </Panel>
  )
}

const CustomTooltip = ({ active, payload, label }: TooltipProps<any, any>) => {
  const { classes } = useStyles()

  if (!active || payload === undefined || payload.length === 0) {
    return null
  }

  return (
    <div className={classes.CustomTooltip}>
      <div className={classes.CustomTooltipLabel}>{label}</div>
      <div className={classes.CustomTooltipValue}>{payload[0].value}</div>
    </div>
  )
}

const SimpleBarChart = ({ data }: { data: LoansByDate[] }) => {
  const { i18n } = useTranslation()

  const barColorDefault = '#8BC9D0'
  const barColorFocus = '#6C2576'

  const [focusIndex, setFocusIndex] = useState(-1)

  const validData = useMemo(
    () =>
      data.reduce((acc: any[], d) => {
        const loans = d.loans

        const formatOpts = {
          locale: getDateFnsLocale(i18n.language),
        }

        const o = {
          loans,
          date: format(new Date(d.date), 'LLL d', formatOpts),
        }
        return [...acc, o]
      }, []),
    [data, i18n.language]
  )

  const handleBarChartMouseMove: CategoricalChartFunc = ({
    isTooltipActive,
    activeTooltipIndex,
  }) => {
    if (isTooltipActive && activeTooltipIndex !== undefined) {
      setFocusIndex(activeTooltipIndex)
    } else {
      setFocusIndex(-1)
    }
  }

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        data={validData}
        margin={{ right: 0 }}
        onMouseMove={handleBarChartMouseMove}
        onMouseLeave={() => setFocusIndex(-1)}
      >
        <CartesianGrid
          stroke="#F8F8FB"
          strokeDasharray="0 0"
          vertical={false}
        />
        <XAxis
          axisLine={false}
          tickLine={false}
          dataKey="date"
          tickMargin={8}
          interval="preserveStartEnd"
          minTickGap={30}
          tick={{ fontSize: 10 }}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          orientation="right"
          tick={{ fontSize: 10 }}
        />
        <Tooltip
          content={(props) => <CustomTooltip {...props} />}
          cursor={{ fill: '#F8F8FB' }}
        />
        <Bar
          dataKey="loans"
          radius={[10, 10, 0, 0]}
          width={17}
          background={false}
        >
          {validData.map((_, i) => (
            <Cell
              key={`cell-${i}`}
              fill={i === focusIndex ? barColorFocus : barColorDefault}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

const exportLoansDataAsXlsx = (loansData: LoansByDate[]) => {
  const ws = XLSX.utils.json_to_sheet(loansData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Hublet Loans')
  XLSX.writeFile(wb, 'hublet-loans.xlsx')
}

interface LoansDataProps {
  unit: TimeUnit
  relative: number
  dataLevel: DataLevel
  includeOvertime: boolean
}

const LoansData = ({
  unit,
  relative,
  dataLevel,
  includeOvertime,
}: LoansDataProps) => {
  const dispatch = useAppDispatch()
  const { classes } = useStyles()
  const { t, i18n } = useTranslation()
  const timeZone = useUserTimeZone()

  const dashboardData = useAppSelector((state) => state.dashboard.dashboardData)
  const fetchedLoansData = useAppSelector(
    (state) => state.dashboard.fetchedLoansData
  )
  const { organizationId, siteId } = useCurrentAccount()

  const timeRangeEndInTz = useMemo(
    () => getTimeRangeEnd(unit, relative, timeZone),
    [unit, relative, timeZone]
  )
  const timeRangeStartInTz = useMemo(
    () => getTimeRangeStart(unit, timeRangeEndInTz),
    [unit, timeRangeEndInTz]
  )

  useEffect(() => {
    switch (dataLevel) {
      case DataLevel.Global:
        dispatch(
          fetchGlobalDashboardLoansData({
            timeRangeStartInTz,
            timeRangeEndInTz,
            includeOvertime,
            timeZone,
          })
        )
        break
      case DataLevel.Distributor:
        dispatch(
          fetchDistributorDashboardLoansData({
            distributorId: organizationId,
            timeRangeStartInTz,
            timeRangeEndInTz,
            includeOvertime,
            timeZone,
          })
        )
        break
      case DataLevel.Organization:
        dispatch(
          fetchOrgDashboardLoansData({
            organizationId,
            timeRangeStartInTz,
            timeRangeEndInTz,
            includeOvertime,
            timeZone,
          })
        )
        break
      case DataLevel.Site:
        dispatch(
          fetchSiteDashboardLoansData({
            organizationId,
            siteId,
            timeRangeStartInTz,
            timeRangeEndInTz,
            includeOvertime,
            timeZone,
          })
        )
        break
    }
  }, [
    dispatch,
    organizationId,
    siteId,
    dataLevel,
    timeRangeStartInTz,
    timeRangeEndInTz,
    includeOvertime,
    timeZone,
  ])

  const data: LoansByDate[] = useMemo(() => {
    let curDate = timeRangeStartInTz
    const ds = []

    while (curDate <= timeRangeEndInTz) {
      let loans = 0

      for (const ld of dashboardData.loansByDate) {
        if (isSameDay(new Date(ld.date), curDate)) {
          loans = ld.loans
          break
        }
      }

      ds.push({ date: curDate.toString(), loans })
      curDate = addDays(curDate, 1)
    }

    return ds
  }, [dashboardData.loansByDate, timeRangeStartInTz, timeRangeEndInTz])

  if (!fetchedLoansData) {
    return null
  }

  return (
    <Grid container spacing={2} width="100%">
      <Grid item width="100%">
        <Panel variant="page">
          <Grid container>
            <Grid item>
              <h3 className={classes.LoansDataTitle}>
                {t('dashboard.loansChart.dailyLoans')}
              </h3>
            </Grid>
            <Grid item className={classes.LoansDataActions}>
              <Button
                outlined
                small
                onClick={() => exportLoansDataAsXlsx(data)}
              >
                {t('dashboard.loansChart.export')}
              </Button>
            </Grid>
          </Grid>
          <div className={classes.LoansDataChart}>
            <SimpleBarChart data={data} />
          </div>
        </Panel>
      </Grid>
      <Grid item className={classes.DataBoxColumnContainer} width="100%">
        <div className={classes.DataBoxColumn}>
          <DataBox
            title={t('dashboard.loans.totalLoans')}
            value={dashboardData.totalLoans}
          />
          <DataBox
            title={t('dashboard.loans.totalLoanTime')}
            value={renderDuration(
              dashboardData.totalLoanMinutes,
              'minutes',
              i18n.language
            )}
          />
          <DataBox
            title={t('dashboard.loans.averageLoanTime')}
            value={renderDuration(
              dashboardData.avgLoanMinutes,
              'minutes',
              i18n.language
            )}
          />
          <DataBox
            title={t('dashboard.loans.medianLoanTime')}
            value={renderDuration(
              dashboardData.medianLoanMinutes,
              'minutes',
              i18n.language
            )}
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default LoansData
