import React, { type PropsWithChildren } from 'react'
import { Divider, Grid, Paper, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  Panel: {
    color: theme.palette.hublet.common.black,
    fontSize: '16px',
    marginTop: theme.spacing(4),
    '&:first-child': {
      marginTop: 0,
    },
  },
  PanelHeader: {
    padding: theme.spacing(3),
    fontSize: '18px',
    fontWeight: 'bold',
  },
  PanelBody: {
    padding: theme.spacing(3),

    '&> hr': {
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(1),
    },
  },
}))

interface PanelProps extends PropsWithChildren {
  title?: React.ReactNode
  classes?: {
    container?: string
    title?: string
    content?: string
  }
}

const Panel = ({ title, children, classes }: PanelProps) => {
  const { classes: styles, cx } = useStyles()

  const containerClasses = [styles.Panel, classes?.container ?? '']
  const titleClasses = [styles.PanelHeader, classes?.title ?? '']
  const contentClasses = [styles.PanelBody, classes?.content ?? '']

  return (
    <Paper className={cx(containerClasses)} variant="outlined">
      {title && (
        <>
          <Grid className={cx(titleClasses)}>{title}</Grid>
          <Divider light />
        </>
      )}
      <Grid className={cx(contentClasses)}>{children}</Grid>
    </Paper>
  )
}

export default Panel
