import type React from 'react'
import {
  addMinutes,
  formatDistance,
  formatDuration,
  intervalToDuration,
  intlFormat,
} from 'date-fns'
import getDateFnsLocale from 'common/utils/get-date-fns-locale'

type RenderFn<T> = (x: T, i?: number) => React.ReactNode

export const renderIf = (cond: any, render: RenderFn<any>) =>
  cond ? render(cond) : null
export const renderItems = <T>(items: T[], render: RenderFn<T>) => {
  return items.map((item, i) => render(item, i))
}

export const renderDate = (date: Date, lng: string, tz: string) => {
  return intlFormat(
    date,
    {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
      timeZone: tz,
    },
    {
      locale: lng,
    }
  )
}

export const renderRelativeDate = (
  fromDate: Date,
  toDate: Date,
  expiredText?: string,
  lng?: string
) => {
  const elapsed = toDate.getTime() - fromDate.getTime()

  if (elapsed < 0 && expiredText !== undefined) {
    return expiredText
  }

  const opts = {
    addSuffix: true,
    locale: lng === undefined ? undefined : getDateFnsLocale(lng),
  }

  return formatDistance(toDate, fromDate, opts)
}

export const renderDuration = (
  value: number,
  unit: 'minutes',
  lng?: string
) => {
  const d1 = new Date()
  let d2 = new Date(d1)

  // Special case to format e.g. `0 minutes`, as the formatter normally ignores 0 values
  const options = {
    format: value === 0 ? [unit] : undefined,
    zero: value === 0,
    locale: lng === undefined ? undefined : getDateFnsLocale(lng),
  }

  switch (unit) {
    case 'minutes':
      d2 = addMinutes(d2, value)
      break
  }

  const duration = intervalToDuration({ start: d1, end: d2 })
  return formatDuration(duration, options)
}
