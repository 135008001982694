import React, { useEffect } from 'react'

import { Select, type Theme, MenuItem, FormControl } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import { LanguageSharp } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useLanguage } from 'locales'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  Icon: {
    marginRight: theme.spacing(1),
  },
}))

const SelectLanguage = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [language, setLanguage] = useLanguage()
  const languages = useAppSelector((state) => state.app.languages)

  useEffect(() => {
    if (!languages.includes(language)) {
      setLanguage('fi')
    }
  }, [languages, language, setLanguage])

  return (
    <FormControl variant="outlined" size="small">
      <Select
        value={language}
        onChange={(e) => setLanguage(e.target.value)}
        startAdornment={<LanguageSharp className={classes.Icon} />}
      >
        {languages.map((l) => (
          <MenuItem key={l} value={l}>
            {t(`languages.${l}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectLanguage
