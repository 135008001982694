import React, { useMemo } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'

import DocksTable from 'App/Docks/components/DocksTable'
import { type DocksContext } from 'App/Docks/docks-types'
import DocksReactContext from 'App/Docks/docks-react-context'
import useCurrentAccount from 'common/hooks/useCurrentAccount'

interface DocksProps {
  context?: DocksContext
}

const Docks = ({ context }: DocksProps) => {
  const { t } = useTranslation()
  const { siteId } = useCurrentAccount()

  const docksContext = useMemo(() => {
    if (context !== undefined) return context
    return siteId === -1 ? 'organization' : 'site'
  }, [context, siteId])

  return (
    <DocksReactContext.Provider value={docksContext}>
      <Page>
        <PageHeader>
          <PageTitle title={t('docks.title')} />
        </PageHeader>
        <PageBody>
          <DocksTable />
        </PageBody>
      </Page>
    </DocksReactContext.Provider>
  )
}

export default Docks
