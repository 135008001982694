import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import DocksApi from 'api/docks-api'
import {
  type Dock,
  type DockEditable,
  type DocksContext,
} from 'App/Docks/docks-types'
import { type Tablet } from 'App/Tablets/tablets-types'

interface DocksState {
  docks: Dock[]
  fetchedDocks: boolean
  unpairedCase: boolean
  pairedCase: boolean
  unpairedTablets: Tablet[]
  updatedDock: boolean
  updatedDockError: boolean
  updatedDockLocation: boolean
  dockToDelete: Dock | null
  dockToUndelete: Dock | null
  toastMessage: string | null
}

const initialState: DocksState = {
  docks: [],
  fetchedDocks: false,
  unpairedCase: false,
  pairedCase: false,
  unpairedTablets: [],
  updatedDock: false,
  updatedDockError: false,
  updatedDockLocation: false,
  dockToDelete: null,
  dockToUndelete: null,
  toastMessage: null,
}

export const fetchDocks = createAsyncThunk(
  'docks/fetchDocks',
  async (props: {
    organizationId: number
    siteId: number
    context: DocksContext
    withDeleted?: boolean
  }) =>
    DocksApi.fetchDocks(
      props.organizationId,
      props.siteId,
      props.context,
      props.withDeleted
    )
)

export const fetchUnpairedTablets = createAsyncThunk(
  'docks/fetchUnpairedTablets',
  async (props: {
    organizationId: number
    siteId: number
    context: DocksContext
  }) =>
    DocksApi.fetchUnpairedTablets(
      props.organizationId,
      props.siteId,
      props.context
    )
)

export const pairCase = createAsyncThunk(
  'docks/pairCase',
  async (props: {
    organizationId: number
    siteId: number
    serialNumber: string
    onewireId: string
    context: DocksContext
  }) =>
    DocksApi.pairCase(
      props.organizationId,
      props.siteId,
      props.serialNumber,
      props.onewireId,
      props.context
    )
)

export const unpairCase = createAsyncThunk(
  'docks/unpairCase',
  async (props: {
    organizationId: number
    siteId: number
    serialNumber: string
    context: DocksContext
  }) =>
    DocksApi.unpairCase(
      props.organizationId,
      props.siteId,
      props.serialNumber,
      props.context
    )
)

export const updateDock = createAsyncThunk(
  'docks/updateDock',
  async ({
    organizationId,
    siteId,
    dockId,
    form,
    context,
  }: {
    organizationId: number
    siteId: number
    dockId: number
    form: DockEditable
    context: DocksContext
  }) => DocksApi.updateDock(organizationId, siteId, dockId, form, context)
)

export const docksSlice = createSlice({
  name: 'docks',
  initialState,
  reducers: {
    resetUnpairCase: (state) => {
      state.unpairedCase = false
      return state
    },
    resetPairCase: (state) => {
      state.pairedCase = false
      return state
    },
    resetUpdateDock: (state) => {
      state.updatedDock = false
      state.updatedDockError = false
      return state
    },
    resetToastMessage: (state) => {
      state.toastMessage = null
      return state
    },
    updatedDockLocation: (state) => {
      state.updatedDockLocation = true
      return state
    },
    resetUpdatedDockLocation: (state) => {
      state.updatedDockLocation = false
      return state
    },
    setDockToDelete: (state, action: { payload: Dock | null }) => {
      state.dockToDelete = action.payload
      return state
    },
    setDockToUndelete: (state, action: { payload: Dock | null }) => {
      state.dockToUndelete = action.payload
      return state
    },
    setToastMessage: (state, action: { payload: string | null }) => {
      state.toastMessage = action.payload
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDocks.fulfilled, (state, { payload }) => {
      state.fetchedDocks = true
      state.docks = payload
      return state
    })

    builder.addCase(fetchUnpairedTablets.fulfilled, (state, { payload }) => {
      state.unpairedTablets = payload
      return state
    })

    builder.addCase(unpairCase.fulfilled, (state) => {
      state.unpairedCase = true
      return state
    })

    builder.addCase(pairCase.fulfilled, (state) => {
      state.pairedCase = true
      return state
    })

    builder.addCase(updateDock.fulfilled, (state) => {
      state.updatedDock = true
      state.updatedDockError = false
      return state
    })

    builder.addCase(updateDock.rejected, (state) => {
      state.updatedDock = false
      state.updatedDockError = true
      return state
    })
  },
})

export const {
  resetUnpairCase,
  resetPairCase,
  resetUpdateDock,
  resetToastMessage,
  updatedDockLocation,
  resetUpdatedDockLocation,
  setDockToDelete,
  setDockToUndelete,
  setToastMessage,
} = docksSlice.actions

export default docksSlice.reducer
