import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import PageEmpty from 'common/components/PageEmpty'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import SitesTable from 'App/Sites/SitesList/SitesTable'
import AddSiteDrawer from 'App/Sites/SitesList/AddSiteDrawer'
import { useAppSelector } from 'store'

const SitesList = () => {
  const { t } = useTranslation()

  const sites = useAppSelector((state) => state.sites.sites)
  const addedSite = useAppSelector((state) => state.sites.addedSite)
  const updatedSite = useAppSelector((state) => state.sites.updatedSite)
  const deletedSite = useAppSelector((state) => state.sites.deletedSite)

  const fetchedSites = useAppSelector((state) => state.sites.fetchedSites)

  const [openDrawer, setOpenDrawer] = useState(false)

  const onClickAddSite = () => setOpenDrawer(true)
  const onCloseDrawer = () => setOpenDrawer(false)

  if (!fetchedSites) {
    return <Page />
  }

  return (
    <>
      {sites.length === 0 ? (
        <Page>
          <PageEmpty
            title={t('sites.empty.title')}
            description={t('sites.empty.description')}
            actionText={t('sites.empty.title')}
            onClickAction={onClickAddSite}
          />
        </Page>
      ) : (
        <Page>
          <PageHeader>
            <PageTitle title={t('sites.title')} />
            <PageActions>
              <Button onClick={onClickAddSite} small>
                {t('sites.actions.addSite')}
              </Button>
            </PageActions>
          </PageHeader>
          <PageBody>
            <SitesTable sites={sites} />
          </PageBody>
        </Page>
      )}

      <AddSiteDrawer
        open={openDrawer}
        onClose={onCloseDrawer}
        addedSite={addedSite}
      />
      <Toast open={addedSite} message={t('sites.addSite.toast.success')} />
      <Toast open={updatedSite} message={t('sites.editSite.toast.success')} />
      <Toast open={deletedSite} message={t('sites.deleteSite.toast.success')} />
    </>
  )
}

export default SitesList
