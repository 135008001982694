import React from 'react'
import { type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  JsonBox: {
    padding: theme.spacing(2),
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    backgroundColor: '#F8F8FB',
    fontSize: '12px',
    overflow: 'auto',
    '& > pre': {
      margin: 0,
    },
  },
}))

interface JsonBoxProps {
  object?: object
}

const JsonBox = ({ object }: JsonBoxProps) => {
  const { classes: styles } = useStyles()

  return (
    <div className={styles.JsonBox}>
      <pre>{JSON.stringify(object, null, 2)}</pre>
    </div>
  )
}

export default JsonBox
