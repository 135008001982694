import { type DocksContext } from 'App/Docks/docks-types'

export const getDocksPathContext = (
  organizationId: number,
  siteId: number,
  context: DocksContext
) => {
  switch (context) {
    case 'site':
      return `/organizations/${organizationId}/sites/${siteId}`
    case 'organization':
      return `/organizations/${organizationId}`
    case 'manufacturer':
      return '/manufacturer'
  }
}
