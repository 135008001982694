import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  type DistributorForm,
  type Distributor,
} from 'App/Distributors/distributors-types'
import DistributorsApi from 'api/distributors-api'

interface DistributorsState {
  addingDistributor: boolean
  addedDistributor: boolean
  updatedDistributor: boolean
  deletedDistributor: boolean
  distributors: Distributor[]
  fetchedDistributors: boolean
  distributor: Distributor | null
}

const initialState: DistributorsState = {
  addingDistributor: false,
  addedDistributor: false,
  updatedDistributor: false,
  deletedDistributor: false,
  distributors: [],
  fetchedDistributors: false,
  distributor: null,
}

export const addDistributor = createAsyncThunk(
  'distributors/addDistributor',
  async (form: DistributorForm) => DistributorsApi.createDistributor(form)
)

export const updateDistributor = createAsyncThunk(
  'distributors/updateDistributor',
  async ({
    distributorId,
    form,
  }: {
    distributorId: number
    form: DistributorForm
  }) => DistributorsApi.updateDistributor(distributorId, form)
)

export const deleteDistributor = createAsyncThunk(
  'distributors/deleteDistributor',
  async ({ id }: Distributor) => DistributorsApi.deleteDistributor(id)
)

export const fetchDistributors = createAsyncThunk(
  'distributors/fetchDistributors',
  async () => DistributorsApi.fetchDistributors()
)

export const fetchDistributor = createAsyncThunk(
  'distributors/fetchDistributor',
  async ({ distributorId }: { distributorId: number }) =>
    DistributorsApi.fetchDistributor(distributorId)
)

export const distributorsSlice = createSlice({
  name: 'distributors',
  initialState,
  reducers: {
    resetAddDistributor: (state) => {
      state.addingDistributor = false
      state.addedDistributor = false
    },
    resetUpdatedDistributor: (state) => {
      state.updatedDistributor = false
    },
    resetDeletedDistributor: (state) => {
      state.deletedDistributor = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDistributors.fulfilled, (state, { payload }) => {
      state.fetchedDistributors = true
      state.distributors = payload
      return state
    })

    builder.addCase(fetchDistributor.fulfilled, (state, { payload }) => {
      state.distributor = payload
      return state
    })

    builder.addCase(fetchDistributor.rejected, (state) => {
      state.distributor = null
      return state
    })

    builder.addCase(addDistributor.pending, (state) => {
      state.addingDistributor = true
      state.addedDistributor = false
      return state
    })

    builder.addCase(addDistributor.fulfilled, (state) => {
      state.addingDistributor = false
      state.addedDistributor = true
      return state
    })

    builder.addCase(updateDistributor.fulfilled, (state) => {
      state.updatedDistributor = true
      return state
    })

    builder.addCase(deleteDistributor.fulfilled, (state) => {
      state.deletedDistributor = true
      return state
    })
  },
})

export const {
  resetAddDistributor,
  resetUpdatedDistributor,
  resetDeletedDistributor,
} = distributorsSlice.actions

export default distributorsSlice.reducer
