import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SecurityChallenge,
  RuntimePermissionPolicy,
  SystemUpdatePolicy,
  updateSecurityChallenge,
  updateUnknownSource,
  updateDebugMode,
  updateSafeMode,
  updateFactoryReset,
  updateSystemUpdatePolicy,
  updateRuntimePermissionPolicy,
  fetchSiteSettings,
  updateUpdateStartTime,
  updateUpdateEndTime,
  resetUpdatedSiteSettings,
  type SiteSettings,
  updateAndroidSecurity,
} from 'App/Sites/sites-state'
import { FormControlLabel, Grid, RadioGroup, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Toast from 'common/components/Toast'
import useSiteSettings from 'common/hooks/useSiteSettings'
import Panel, { EditState } from 'common/components/NewPanel'
import Switch from 'common/components/Switch'
import Radio from 'common/components/Radio'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import Drawer from 'common/components/Drawer'
import FormField from 'common/components/FormField'
import { renderIf } from 'common/utils/render-utils'
import useApp from 'common/hooks/useApp'
import Button from 'common/components/Button'
import PanelField from 'common/components/PanelField'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  LabelTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  PanelTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  PanelContainer: {
    marginBottom: theme.spacing(4),
  },
  PanelContent: {
    padding: '0',
  },
  PanelItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: theme.spacing(3),

    borderBottom: '1px solid #e5e5e5',
    '&:last-child': {
      borderBottom: 0,
    },
  },
  RadioGroup: {
    marginTop: theme.spacing(1),
  },
  SwitchField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  FormSelectField: {
    marginTop: theme.spacing(2),
  },
  UpdateZoneTabletsButton: {
    marginTop: theme.spacing(2),
  },
}))

interface AndroidSecurityPanelProps {
  settings: SiteSettings
}

const AndroidSecurityPanel = ({ settings }: AndroidSecurityPanelProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [editState, setEditState] = useState<EditState>(EditState.Default)
  const [factoryResetAccounts, setFactoryResetAccounts] = useState(
    settings.factoryResetAccounts ?? ''
  )

  const updatedFactoryResetAccounts = useAppSelector(
    (state) => state.sites.updatedFactoryResetAccounts
  )
  const { organizationId, siteId } = useCurrentAccount()

  useEffect(() => {
    if (updatedFactoryResetAccounts) {
      setEditState(EditState.Success)
    }
  }, [updatedFactoryResetAccounts])

  const handleClickAccountIdHelp = () => {
    console.warn('TODO HELP LINKS')
  }

  const renderEditMode = () => (
    <>
      <div>
        <FormField
          label={t('advancedSettings.form.factoryResetAccounts.label')}
          placeholder={t(
            'advancedSettings.form.factoryResetAccounts.placeholder'
          )}
          value={factoryResetAccounts}
          onChange={(e) => setFactoryResetAccounts(e.target.value)}
          disabled={editState === EditState.Pending}
        />
        <br />
        <br />
        <Button outlined extraSmall onClick={handleClickAccountIdHelp}>
          {t('help.tablets.googleAccountId.title')}
        </Button>
      </div>
    </>
  )

  const handleSave = () => {
    setEditState(EditState.Pending)
    dispatch(
      updateAndroidSecurity({
        organizationId,
        siteId,
        factoryResetAccounts,
      })
    )
  }

  return (
    <Panel
      title={t('advancedSettings.form.androidSecurity')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSave={handleSave}
      onSuccess={() => setEditState(EditState.Default)}
      renderEditMode={renderEditMode}
    >
      <PanelField
        title={t('advancedSettings.form.factoryResetAccounts.label')}
        value={settings.factoryResetAccounts}
      />
    </Panel>
  )
}

interface LabelProps {
  title: string
  description: string
}

const Label = ({ title, description }: LabelProps) => {
  const { classes } = useStyles()

  return (
    <Grid>
      <Grid className={classes.LabelTitle}>{title}</Grid>
      <Grid>{description}</Grid>
    </Grid>
  )
}

interface EditAdvancedProps {
  open: boolean
  onClose: () => void
}

const EditAdvanced = ({ open, onClose }: EditAdvancedProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { adminMode } = useApp()

  const { organizationId, siteId } = useCurrentAccount()
  const updatedSecurityChallenge = useAppSelector(
    (state) => state.sites.updatedSecurityChallenge
  )
  const updatedUnknownSource = useAppSelector(
    (state) => state.sites.updatedUnknownSource
  )
  const updatedDebugMode = useAppSelector(
    (state) => state.sites.updatedDebugMode
  )
  const updatedSafeMode = useAppSelector((state) => state.sites.updatedSafeMode)
  const updatedFactoryReset = useAppSelector(
    (state) => state.sites.updatedFactoryReset
  )
  const updatedSystemUpdatePolicy = useAppSelector(
    (state) => state.sites.updatedSystemUpdatePolicy
  )
  const updatedRuntimePermissionPolicy = useAppSelector(
    (state) => state.sites.updatedRuntimePermissionPolicy
  )
  const updatedUpdateStartTime = useAppSelector(
    (state) => state.sites.updatedUpdateStartTime
  )
  const updatedUpdateEndTime = useAppSelector(
    (state) => state.sites.updatedUpdateEndTime
  )
  const updatedFactoryResetAccounts = useAppSelector(
    (state) => state.sites.updatedFactoryResetAccounts
  )

  const updatedSiteSettings =
    updatedSecurityChallenge ||
    updatedUnknownSource ||
    updatedDebugMode ||
    updatedSafeMode ||
    updatedFactoryReset ||
    updatedSystemUpdatePolicy ||
    updatedRuntimePermissionPolicy ||
    updatedUpdateStartTime ||
    updatedUpdateEndTime ||
    updatedFactoryResetAccounts

  const settings = useSiteSettings()
  const [localUpdateStartTime, setLocalUpdateStartTime] = useState<
    undefined | string
  >()
  const [localUpdateEndTime, setLocalUpdateEndTime] = useState<
    undefined | string
  >()
  const [updateStartTimeError, setUpdateStartTimeError] = useState<
    undefined | string
  >()
  const [updateEndTimeError, setUpdateEndTimeError] = useState<
    undefined | string
  >()

  const toClockTime = (timeInMinutes: number | undefined | null) => {
    if (timeInMinutes === undefined || timeInMinutes === null) {
      return undefined
    }

    const minutes = timeInMinutes % 60
    const hours = (timeInMinutes - minutes) / 60
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`
  }

  const handleSecurityChallengeUpdate = (
    securityChallenge: SecurityChallenge
  ) => {
    dispatch(
      updateSecurityChallenge({ organizationId, siteId, securityChallenge })
    )
  }
  const handleUnknownSourceUpdate = (unknownSource: boolean) => {
    dispatch(updateUnknownSource({ organizationId, siteId, unknownSource }))
  }
  const handleDebugModeUpdate = (debugMode: boolean) => {
    dispatch(updateDebugMode({ organizationId, siteId, debugMode }))
  }
  const handleSafeModeUpdate = (safeMode: boolean) => {
    dispatch(updateSafeMode({ organizationId, siteId, safeMode }))
  }
  const handleFactoryResetUpdate = (factoryReset: boolean) => {
    dispatch(updateFactoryReset({ organizationId, siteId, factoryReset }))
  }
  const handleSecuriSystemUpdatePolicy = (
    systemUpdatePolicy: SystemUpdatePolicy
  ) => {
    dispatch(
      updateSystemUpdatePolicy({ organizationId, siteId, systemUpdatePolicy })
    )
  }
  const handleRuntimePermissionPolicyUpdate = (
    runtimePermissionPolicy: RuntimePermissionPolicy
  ) => {
    dispatch(
      updateRuntimePermissionPolicy({
        organizationId,
        siteId,
        runtimePermissionPolicy,
      })
    )
  }
  const clockTimeToMinutes = (clockTime: string) => {
    if (/^([01]\d|2[0-3]):?([0-5]\d)$/.test(clockTime)) {
      const parts = clockTime.split(':')
      const hours = parseInt(parts[0])
      const minutes = parseInt(parts[1])

      return hours * 60 + minutes
    }

    return null
  }
  const handleUpdateStartTimeUpdate = (clockTime: string) => {
    setLocalUpdateStartTime(clockTime)

    const updateStartTime = clockTimeToMinutes(clockTime)
    if (updateStartTime !== null) {
      setUpdateStartTimeError(undefined)
      dispatch(
        updateUpdateStartTime({ organizationId, siteId, updateStartTime })
      )
    } else {
      setUpdateStartTimeError(t('advancedSettings.form.updateTimeError'))
    }
  }
  const handleUpdateEndTimeUpdate = (clockTime: string) => {
    setLocalUpdateEndTime(clockTime)

    const updateEndTime = clockTimeToMinutes(clockTime)
    if (updateEndTime !== null) {
      setUpdateEndTimeError(undefined)
      dispatch(updateUpdateEndTime({ organizationId, siteId, updateEndTime }))
    } else {
      setUpdateEndTimeError(t('advancedSettings.form.updateTimeError'))
    }
  }

  useEffect(() => {
    if (updatedSiteSettings) {
      dispatch(fetchSiteSettings({ organizationId, siteId }))
      dispatch(resetUpdatedSiteSettings())
    }
  }, [dispatch, updatedSiteSettings, organizationId, siteId])

  if (settings == null) {
    return null
  }

  return (
    <>
      <Drawer open={open} onClose={onClose} title={t('advancedSettings.title')}>
        <Panel
          title={t('advancedSettings.form.softwareUpdates')}
          classes={{
            container: classes.PanelContainer,
          }}
        >
          <Label
            title={t('advancedSettings.form.systemUpdatePolicy.label')}
            description={t(
              'advancedSettings.form.systemUpdatePolicy.description'
            )}
          />
          <RadioGroup
            className={classes.RadioGroup}
            value={settings.systemUpdatePolicy}
            onChange={(_, value) =>
              handleSecuriSystemUpdatePolicy(value as SystemUpdatePolicy)
            }
          >
            <FormControlLabel
              value={SystemUpdatePolicy.AUTOMATIC}
              control={<Radio />}
              label={t('advancedSettings.systemUpdatePolicy.automatic')}
            />
            <FormControlLabel
              value={SystemUpdatePolicy.WINDOWED}
              control={<Radio />}
              label={t('advancedSettings.systemUpdatePolicy.windowed')}
            />
            {renderIf(
              settings.systemUpdatePolicy === SystemUpdatePolicy.WINDOWED,
              () => (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <label>{t('advancedSettings.form.updateStartTime')}</label>
                    <FormField
                      value={
                        localUpdateStartTime ??
                        toClockTime(settings.updateStartTime)
                      }
                      placeholder="00:00"
                      onChange={(e) =>
                        handleUpdateStartTimeUpdate(e.target.value)
                      }
                      error={updateStartTimeError}
                      dense
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label>{t('advancedSettings.form.updateEndTime')}</label>
                    <FormField
                      value={
                        localUpdateEndTime ??
                        toClockTime(settings.updateEndTime)
                      }
                      placeholder="00:00"
                      onChange={(e) =>
                        handleUpdateEndTimeUpdate(e.target.value)
                      }
                      error={updateEndTimeError}
                      dense
                    />
                  </Grid>
                </Grid>
              )
            )}
            <FormControlLabel
              value={SystemUpdatePolicy.POSTPONE}
              control={<Radio />}
              label={t('advancedSettings.systemUpdatePolicy.postpone')}
            />
            {settings.systemUpdatePolicy === SystemUpdatePolicy.POSTPONE && (
              <em>{t('advancedSettings.systemUpdatePolicy.postpone.info')}</em>
            )}
          </RadioGroup>
        </Panel>

        <Panel
          title={t('advancedSettings.form.security')}
          classes={{
            container: classes.PanelContainer,
          }}
        >
          <Label
            title={t('advancedSettings.form.securityChallenge.label')}
            description={t(
              'advancedSettings.form.securityChallenge.description'
            )}
          />
          <RadioGroup
            className={classes.RadioGroup}
            value={settings.securityChallenge}
            onChange={(_, value) =>
              handleSecurityChallengeUpdate(value as SecurityChallenge)
            }
          >
            <FormControlLabel
              value={SecurityChallenge.UNSPECIFIED}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.unspecified')}
            />
            <FormControlLabel
              value={SecurityChallenge.BIOMETRIC}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.biometric')}
            />
            <FormControlLabel
              value={SecurityChallenge.SOMETHING}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.something')}
            />
            <FormControlLabel
              value={SecurityChallenge.NUMERIC}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.numeric')}
            />
            <FormControlLabel
              value={SecurityChallenge.NUMERIC_COMPLEX}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.numericComplex')}
            />
            <FormControlLabel
              value={SecurityChallenge.ALPHABETIC}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.alphabetic')}
            />
            <FormControlLabel
              value={SecurityChallenge.ALPHANUMERIC}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.alphanumeric')}
            />
            <FormControlLabel
              value={SecurityChallenge.COMPLEX}
              control={<Radio />}
              label={t('advancedSettings.securityChallenge.complex')}
            />
          </RadioGroup>
        </Panel>

        <Panel
          title={t('advancedSettings.form.apps')}
          classes={{
            container: classes.PanelContainer,
          }}
        >
          <Label
            title={t('advancedSettings.form.runtimePermissionPolicy.label')}
            description={t(
              'advancedSettings.form.runtimePermissionPolicy.description'
            )}
          />
          <RadioGroup
            className={classes.RadioGroup}
            value={settings.runtimePermissionPolicy}
            onChange={(_, value) =>
              handleRuntimePermissionPolicyUpdate(
                value as RuntimePermissionPolicy
              )
            }
          >
            <FormControlLabel
              value={RuntimePermissionPolicy.PROMPT}
              control={<Radio />}
              label={t('advancedSettings.runtimePermissionPolicy.prompt')}
            />
            <FormControlLabel
              value={RuntimePermissionPolicy.AUTO_GRANT}
              control={<Radio />}
              label={t('advancedSettings.runtimePermissionPolicy.autoGrant')}
            />
            <FormControlLabel
              value={RuntimePermissionPolicy.AUTO_DENY}
              control={<Radio />}
              label={t('advancedSettings.runtimePermissionPolicy.autoDeny')}
            />
          </RadioGroup>
        </Panel>

        <AndroidSecurityPanel settings={settings} />

        <>
          {renderIf(adminMode, () => (
            <Panel
              title={t('advancedSettings.form.adminOptions')}
              classes={{
                container: classes.PanelContainer,
              }}
            >
              <Grid className={classes.SwitchField}>
                <Label
                  title={t('advancedSettings.form.unknownSource.label')}
                  description={t(
                    'advancedSettings.form.unknownSource.description'
                  )}
                />
                <Switch
                  checked={settings.unknownSource}
                  onChange={(_, checked) => handleUnknownSourceUpdate(checked)}
                />
              </Grid>

              <Grid className={classes.SwitchField}>
                <Label
                  title={t('advancedSettings.form.debugMode.label')}
                  description={t('advancedSettings.form.debugMode.description')}
                />
                <Switch
                  checked={settings.debugMode}
                  onChange={(_, checked) => handleDebugModeUpdate(checked)}
                />
              </Grid>

              <Grid className={classes.SwitchField}>
                <Label
                  title={t('advancedSettings.form.safeMode.label')}
                  description={t('advancedSettings.form.safeMode.description')}
                />
                <Switch
                  checked={settings.safeMode}
                  onChange={(_, checked) => handleSafeModeUpdate(checked)}
                />
              </Grid>

              <Grid className={classes.SwitchField}>
                <Label
                  title={t('advancedSettings.form.factoryReset.label')}
                  description={t(
                    'advancedSettings.form.factoryReset.description'
                  )}
                />
                <Switch
                  checked={settings.factoryReset}
                  onChange={(_, checked) => handleFactoryResetUpdate(checked)}
                />
              </Grid>
            </Panel>
          ))}
        </>
      </Drawer>
      <Toast
        open={updatedSiteSettings}
        message={t('advancedSettings.toast.success')}
      />
    </>
  )
}

export default EditAdvanced
