import React, { type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Drawer, Grid, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import ClearIcon from '@mui/icons-material/Clear'
import Button from 'common/components/Button'
import { renderItems } from 'common/utils/render-utils'
import { type SvgIconComponent } from '@mui/icons-material'
import DrawerPaddingForHelp from 'common/components/DrawerPaddingForHelps'

const useStyles = makeStyles()((theme: Theme) => ({
  DrawerForm: {
    '& > .MuiDrawer-paper': {
      minWidth: '50%',
      width: '640px',
      maxWidth: '100%',
      boxShadow: 'none',
    },
  },
  DrawerFormForm: {
    height: '100%',
  },
  DrawerFormContainer: {
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  DrawerFormHeader: {
    flex: 'auto',
    padding: '31px 32px',
    backgroundColor: theme.palette.hublet.common.white,
    lineHeight: '1',
  },
  DrawerFormHeaderText: {
    flexGrow: 1,
    alignSelf: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    color: theme.palette.hublet.primary.main,
  },
  DrawerFormHeaderClose: {
    alignSelf: 'center',
    cursor: 'pointer',
    color: theme.palette.hublet.common.black,
    fontSize: '24px',
  },
  DrawerFormBody: {
    padding: '32px 32px',
    backgroundColor: theme.palette.background.default,
  },
  DrawerFormBodyWithMargins: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
  DrawerFormFooter: {
    flex: 'auto',
    padding: '32px 32px',
    backgroundColor: theme.palette.hublet.common.white,
  },
  DrawerFormFooterRight: {
    textAlign: 'right',
  },
  ExtraActionButton: {
    marginRight: '16px',
    '& .MuiSvgIcon-root': {
      fontSize: '19px',
      marginRight: '9px',
    },
  },
}))

interface ExtraAction {
  Icon?: SvgIconComponent
  text: string
  onClick: () => void
}

interface DrawerFormProps extends PropsWithChildren {
  title: string
  submitText: string
  open: boolean
  onClose: () => void
  onSubmit: React.FormEventHandler
  extraActions?: ExtraAction[]
  disabledButton?: boolean
  autoMargins?: boolean
}

const DrawerForm = ({
  children,
  title,
  submitText,
  open,
  onClose,
  onSubmit,
  extraActions,
  disabledButton = false,
  autoMargins = false,
}: DrawerFormProps) => {
  const { t } = useTranslation()
  const { classes, cx } = useStyles()

  const bodyClasses = [classes.DrawerFormBody]

  if (autoMargins) {
    bodyClasses.push(classes.DrawerFormBodyWithMargins)
  }

  const onClickCancel = () => onClose()
  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    if (disabledButton) {
      return false
    }

    onSubmit(e)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={classes.DrawerForm}
    >
      <form className={classes.DrawerFormForm} onSubmit={onSubmitForm}>
        <Grid className={classes.DrawerFormContainer} container>
          <Grid className={classes.DrawerFormHeader} item xs={12}>
            <Grid container>
              <Grid className={classes.DrawerFormHeaderText} item>
                {title}
              </Grid>
              <Grid className={classes.DrawerFormHeaderClose} item>
                <ClearIcon onClick={onClose} />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={cx(bodyClasses)} item xs={12}>
            {children}
          </Grid>
          <Grid className={classes.DrawerFormFooter} item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Button small outlined onClick={onClickCancel}>
                  {t('customers.addCustomer.buttons.cancel')}
                </Button>
              </Grid>
              <Grid className={classes.DrawerFormFooterRight} item xs={6}>
                {extraActions != null &&
                  renderItems(extraActions, ({ Icon, text, onClick }) => (
                    <Button
                      className={classes.ExtraActionButton}
                      small
                      outlined
                      onClick={onClick}
                    >
                      {Icon != null && <Icon />}
                      {text}
                    </Button>
                  ))}
                <Button type="submit" small disabled={disabledButton}>
                  {submitText}
                </Button>
              </Grid>
            </Grid>
            <DrawerPaddingForHelp />
          </Grid>
        </Grid>
      </form>
    </Drawer>
  )
}

export default DrawerForm
