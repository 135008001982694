import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Route, Routes, Navigate } from 'react-router-dom'
import Login from 'App/Login'
import { type RootState, type ThunkDispatchProp } from 'store'
import { connect } from 'react-redux'
import {
  type CurrentUser,
  fetchCurrentUser,
  fetchLanguages,
} from 'App/app-state'
import { renderIf } from 'common/utils/render-utils'
import Sites from 'App/Sites'
import Profiles from 'App/Profiles'
import { resetLogin } from 'App/Login/login-state'
import Dashboard from 'App/Dashboard'
import Customers from 'App/Customers'
import Distributors from 'App/Distributors'
import ProfilesView from 'App/Profiles/ProfilesView'
import Networks from 'App/Networks'
import Tablets from 'App/Tablets'
import Docks from 'App/Docks'
import Posts from 'App/Posts'
import SiteSettings from 'App/SiteSettings'
import Codes from 'App/Codes'
import Loans from 'App/Loans'
import Users from 'App/Users'
import TopApps from 'App/TopApps'
import Invitations from 'App/Invitations'
import Integrations from 'App/Integrations'
import CustomerSettings from 'App/CustomerSettings'
import DistributorSettings from 'App/DistributorSettings'
import Translations from 'App/Translations'
import ForgotPassword from 'App/ForgotPassword'
import MyAccount from 'App/MyAccount'
import IntegrationStatistics from 'App/IntegrationStatistics'
import useQuery from 'common/hooks/useQuery'
import Zendesk from 'App/Zendesk'
import SuperAdmin from 'App/SuperAdmin'
import AwsCognitoPreReleaseEmail from 'App/SuperAdmin/AwsCognitoPreReleaseEmail'

const useStyles = makeStyles()((theme: Theme) => ({
  App: {
    color: theme.palette.hublet.common.black,
    '& h1': {
      color: theme.palette.hublet.primary.main,
      margin: 0,
    },
  },
}))

interface AppProps extends ThunkDispatchProp {
  currentUser?: CurrentUser
  fetchingCurrentUser: boolean
  fetchedCurrentUser: boolean
  fetchCurrentUserError: boolean
}

const App = ({
  dispatch,
  currentUser,
  fetchingCurrentUser,
  fetchedCurrentUser,
  fetchCurrentUserError,
}: AppProps) => {
  const { classes } = useStyles()
  const query = useQuery()

  useEffect(() => {
    dispatch(fetchLanguages())
    if (!fetchingCurrentUser && !fetchedCurrentUser && !fetchCurrentUserError) {
      dispatch(fetchCurrentUser())
    }
  })

  const requireLogin = useCallback(
    (element: React.ReactNode) => {
      if (currentUser == null) {
        window.zE('webWidget', 'logout')
        let returnTo = window.location.pathname

        if (returnTo !== '/ui/zendesk') {
          /*
          TODO: Enable returning to any path.
          Eventually we'd like to support returning to any path,
          but it requires better handling of state (active org, site etc)
          that is not stored in the URL, and is cleared when user is logged out.

          Also, currently changing server preserves `returnTo` value,
          which is desirable for `/ui/zendesk`, but necessarily for other paths.
        */
          returnTo = ''
        }

        if (returnTo !== '') {
          return <Navigate to={`/login?returnTo=${returnTo}`} />
        } else {
          return <Navigate to="/login" />
        }
      } else {
        window.zE('webWidget', 'prefill', {
          name: {
            value: [currentUser.firstName, currentUser.lastName].join(' '),
          },
          email: {
            value: currentUser.login,
          },
        })
        return element
      }
    },
    [currentUser]
  )

  const returnToParam = query.get('returnTo')
  const returnTo = useMemo(() => {
    if (
      typeof returnToParam !== 'string' ||
      !returnToParam.startsWith('/ui/')
    ) {
      return '/'
    } else {
      // React router doesn't want `/ui/`
      return returnToParam.replace(/^\/ui\//, '/')
    }
  }, [returnToParam])

  if (fetchCurrentUserError) {
    dispatch(resetLogin())
  }

  return (
    <>
      <Box className={classes.App}>
        {renderIf(fetchedCurrentUser, () => (
          <Routes>
            <Route path="/invitations/:token" element={<Invitations />} />
            <Route
              path="/login"
              element={
                currentUser != null ? <Navigate to={returnTo} /> : <Login />
              }
            />

            <Route
              path="/"
              element={requireLogin(<Navigate to="/dashboard" />)}
            />
            <Route path="/dashboard" element={requireLogin(<Dashboard />)} />
            <Route
              path="/distributors"
              element={requireLogin(<Distributors />)}
            />
            <Route path="/customers" element={requireLogin(<Customers />)} />
            <Route path="/sites" element={requireLogin(<Sites />)} />
            <Route
              path="/site-settings"
              element={requireLogin(<SiteSettings />)}
            />
            <Route
              path="/distributor-settings"
              element={requireLogin(<DistributorSettings />)}
            />
            <Route
              path="/customer-settings"
              element={requireLogin(<CustomerSettings />)}
            />
            <Route
              path="/profiles/:id"
              element={requireLogin(<ProfilesView />)}
            />
            <Route path="/profiles" element={requireLogin(<Profiles />)} />
            <Route path="/loans" element={requireLogin(<Loans />)} />
            <Route path="/codes" element={requireLogin(<Codes />)} />
            <Route path="/networks" element={requireLogin(<Networks />)} />
            <Route path="/tablets" element={requireLogin(<Tablets />)} />
            <Route path="/docks" element={requireLogin(<Docks />)} />
            <Route path="/posts" element={requireLogin(<Posts />)} />
            <Route path="/users" element={requireLogin(<Users />)} />
            <Route
              path="/translations"
              element={requireLogin(<Translations />)}
            />
            <Route path="/top-apps" element={requireLogin(<TopApps />)} />
            <Route
              path="/integrations"
              element={requireLogin(<Integrations />)}
            />
            <Route
              path="/integration-statistics"
              element={requireLogin(<IntegrationStatistics />)}
            />
            <Route
              path="/manufacturer/docks"
              element={requireLogin(<Docks context="manufacturer" />)}
            />
            <Route path="/account" element={requireLogin(<MyAccount />)} />
            <Route path="/admin" element={requireLogin(<SuperAdmin />)} />
            <Route
              path="/admin/aws-cognito/pre-release"
              element={requireLogin(<AwsCognitoPreReleaseEmail />)}
            />

            <Route path="/forgot-password/*" element={<ForgotPassword />} />
            <Route path="/zendesk" element={requireLogin(<Zendesk />)} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        ))}
      </Box>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  currentUser: state.app.currentUser,
  fetchingCurrentUser: state.app.fetchingCurrentUser,
  fetchedCurrentUser: state.app.fetchedCurrentUser,
  fetchCurrentUserError: state.app.fetchCurrentUserError,
})

export default connect(mapStateToProps)(App)
