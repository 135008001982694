import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import PageEmpty from 'common/components/PageEmpty'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import NetworksTable from 'App/Networks/NetworksList/NetworksTable'
import AddNetworkDrawer from 'App/Networks/NetworksList/AddNetworkDrawer'
import useNetworks from 'common/hooks/useNetworks'
import { useAppSelector } from 'store'

const NetworksList = () => {
  const { t } = useTranslation()

  const networks = useNetworks()
  const addedNetwork = useAppSelector((state) => state.networks.addedNetwork)
  const updatedNetwork = useAppSelector(
    (state) => state.networks.updatedNetwork
  )
  const deletedNetwork = useAppSelector(
    (state) => state.networks.deletedNetwork
  )

  const fetchedNetworks = useAppSelector(
    (state) => state.networks.fetchedNetworks
  )

  const [openDrawer, setOpenDrawer] = useState(false)

  const onClickAddNetwork = () => setOpenDrawer(true)
  const onCloseDrawer = () => setOpenDrawer(false)

  if (!fetchedNetworks) {
    return <Page />
  }

  return (
    <>
      {networks.length === 0 ? (
        <Page>
          <PageEmpty
            title={t('networks.empty.title')}
            description={t('networks.empty.description')}
            actionText={t('networks.empty.title')}
            onClickAction={onClickAddNetwork}
          />
        </Page>
      ) : (
        <Page>
          <PageHeader>
            <PageTitle title={t('networks.title')} />
            <PageActions>
              <Button onClick={onClickAddNetwork} small>
                {t('networks.actions.addNetwork')}
              </Button>
            </PageActions>
          </PageHeader>
          <PageBody>
            <NetworksTable networks={networks} />
          </PageBody>
        </Page>
      )}

      <AddNetworkDrawer open={openDrawer} onClose={onCloseDrawer} />
      <Toast
        open={addedNetwork}
        message={t('networks.addNetwork.toast.success')}
      />
      <Toast
        open={updatedNetwork}
        message={t('networks.editNetwork.toast.success')}
      />
      <Toast
        open={deletedNetwork}
        message={t('networks.deleteNetwork.toast.success')}
      />
    </>
  )
}

export default NetworksList
