import type {
  FormatDistanceFn,
  FormatDistanceLocale,
} from '../../../types/locale'

type FormatDistanceTokenValue =
  | string
  | {
      one: string
      other: string
    }

const formatDistanceLocale: FormatDistanceLocale<FormatDistanceTokenValue> = {
  lessThanXSeconds: {
    one: 'níos lú ná soicind',
    other: 'níos lú ná {{count}} soicind',
  },

  xSeconds: {
    one: '1 soicind',
    other: '{{count}} soicind',
  },

  halfAMinute: 'leath nóiméad',

  lessThanXMinutes: {
    one: 'níos lú ná nóiméad',
    other: 'níos lú ná {{count}} nóiméad',
  },

  xMinutes: {
    one: '1 nóiméad',
    other: '{{count}} nóiméad',
  },

  aboutXHours: {
    one: 'thart ar 1 uair an chloig',
    other: 'thart ar {{count}} uair an chloig',
  },

  xHours: {
    one: '1 uair an chloig',
    other: '{{count}} uair an chloig',
  },

  xDays: {
    one: '1 lá',
    other: '{{count}} lá',
  },

  aboutXWeeks: {
    one: 'thart ar 1 seachtain',
    other: 'thart ar {{count}} seachtain',
  },

  xWeeks: {
    one: '1 seachtain',
    other: '{{count}} seachtain',
  },

  aboutXMonths: {
    one: 'thart ar 1 mhí',
    other: 'thart ar {{count}} mí',
  },

  xMonths: {
    one: '1 mhí',
    other: '{{count}} mí',
  },

  aboutXYears: {
    one: 'thart ar 1 bhliain',
    other: 'thart ar {{count}} bhliain',
  },

  xYears: {
    one: '1 bhliain',
    other: '{{count}} bhliain',
  },

  overXYears: {
    one: 'thar 1 bhliain',
    other: 'thar {{count}} years',
  },

  almostXYears: {
    one: 'beagnach 1 bhliain',
    other: 'beagnach {{count}} bhliain',
  },
}

const formatDistance: FormatDistanceFn = (token, count, options) => {
  let result

  const tokenValue = formatDistanceLocale[token]
  if (typeof tokenValue === 'string') {
    result = tokenValue
  } else if (count === 1) {
    result = tokenValue.one
  } else {
    result = tokenValue.other.replace('{{count}}', count.toString())
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return 'i gceann ' + result
    } else {
      return result + ' ó shin'
    }
  }

  return result
}

export default formatDistance
