import { useEffect } from 'react'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { type Profile } from 'App/Profiles/profiles-types'
import { fetchProfiles } from 'App/Profiles/profiles-state'
import { useAppDispatch, useAppSelector } from 'store'

const useProfiles = (fetch: boolean = true): Profile[] => {
  const dispatch = useAppDispatch()

  const { siteId, organizationId } = useCurrentAccount()
  const profiles = useAppSelector((state) => state.profiles.profiles)

  useEffect(() => {
    if (fetch) {
      dispatch(fetchProfiles({ siteId, organizationId }))
    }
  }, [fetch, dispatch, siteId, organizationId])

  return profiles
}

export default useProfiles
