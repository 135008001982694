import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { type CustomerForm, type Customer } from 'App/Customers/customers-types'
import CustomersApi from 'api/customers-api'

interface CustomersState {
  addingCustomer: boolean
  addedCustomer: boolean
  updatedCustomer: boolean
  deletedCustomer: boolean
  customers: Customer[]
  fetchedCustomers: boolean
  customer: Customer | null
}

const initialState: CustomersState = {
  addingCustomer: false,
  addedCustomer: false,
  updatedCustomer: false,
  deletedCustomer: false,
  customers: [],
  fetchedCustomers: false,
  customer: null,
}

interface AddCustomerProps {
  distributorId: number
  form: CustomerForm
}
export const addCustomer = createAsyncThunk(
  'customers/addCustomer',
  async ({ distributorId, form }: AddCustomerProps) =>
    CustomersApi.createCustomer(distributorId, form)
)
export const addCustomerAsAdmin = createAsyncThunk(
  'customers/addCustomer',
  async ({ distributorId, form }: AddCustomerProps) =>
    CustomersApi.createCustomerAsAdmin(distributorId, form)
)

interface UpdateCustomerProps {
  distributorId: number
  customerId: number
  form: CustomerForm
}
export const updateCustomerAsAdmin = createAsyncThunk(
  'customers/updateCustomer',
  async ({ distributorId, customerId, form }: UpdateCustomerProps) =>
    CustomersApi.updateCustomerAsAdmin(customerId, distributorId, form)
)
export const updateCustomer = createAsyncThunk(
  'customers/updateCustomer',
  async ({ distributorId, customerId, form }: UpdateCustomerProps) =>
    CustomersApi.updateCustomer(customerId, distributorId, form)
)

interface DeleteCustomerProps {
  distributorId: number
  customerId: number
}
export const deleteCustomer = createAsyncThunk(
  'customers/deleteCustomer',
  async ({ distributorId, customerId }: DeleteCustomerProps) =>
    CustomersApi.deleteCustomer(customerId, distributorId)
)

export const fetchCustomers = createAsyncThunk(
  'customers/fetchCustomers',
  async (distributorId: number) => CustomersApi.fetchCustomers(distributorId)
)

export const fetchCustomer = createAsyncThunk(
  'customers/fetchCustomer',
  async ({
    distributorId,
    customerId,
  }: {
    distributorId: number
    customerId: number
  }) => CustomersApi.fetchCustomer(distributorId, customerId)
)

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetAddCustomer: (state) => {
      state.addingCustomer = false
      state.addedCustomer = false
    },
    resetUpdatedCustomer: (state) => {
      state.updatedCustomer = false
    },
    resetDeletedCustomer: (state) => {
      state.deletedCustomer = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      state.fetchedCustomers = true
      state.customers = payload
      return state
    })

    builder.addCase(fetchCustomer.fulfilled, (state, { payload }) => {
      state.customer = payload
      return state
    })

    builder.addCase(fetchCustomer.rejected, (state) => {
      state.customer = null
      return state
    })

    builder.addCase(addCustomer.pending, (state) => {
      state.addingCustomer = true
      state.addedCustomer = false
      return state
    })

    builder.addCase(addCustomer.fulfilled, (state) => {
      state.addingCustomer = false
      state.addedCustomer = true
      return state
    })

    builder.addCase(updateCustomer.fulfilled, (state) => {
      state.updatedCustomer = true
      return state
    })

    builder.addCase(deleteCustomer.fulfilled, (state) => {
      state.deletedCustomer = true
      return state
    })
  },
})

export const { resetAddCustomer, resetUpdatedCustomer, resetDeletedCustomer } =
  customersSlice.actions

export default customersSlice.reducer
