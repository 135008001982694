import { Menu, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { type PropsWithChildren } from 'react'

const useStyles = makeStyles()((theme: Theme) => ({
  UserActions: {
    '& .MuiMenu-paper': {
      width: '211px',
      marginTop: '11px',
      padding: '18px 0',
      background: theme.palette.hublet.common.white,
      boxShadow: `0px 4px 30px ${theme.palette.hublet.misc.boxShadow}`,
      borderRadius: '10px',
    },
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}))

interface DropdownMenuProps extends PropsWithChildren {
  anchor?: Element | null
  open: boolean
  onClose?: () => void
  className?: string
}

const DropdownMenu = ({
  children,
  anchor,
  open,
  onClose,
  className,
}: DropdownMenuProps) => {
  const { classes, cx } = useStyles()
  const onClickMenu: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const menuClasses = [classes.UserActions]

  if (className !== undefined) {
    menuClasses.push(className)
  }

  return (
    <Menu
      className={cx(menuClasses)}
      anchorEl={anchor}
      open={open}
      keepMounted
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onClick={onClickMenu}
    >
      {children}
    </Menu>
  )
}

export default DropdownMenu
