import React from 'react'
import { useTranslation } from 'react-i18next'
import { type Category } from 'App/Categories/categories-types'
import CategoryFields from 'App/Categories/components/CategoryFields'
import useCategoryForm from 'common/hooks/useCategoryForm'
import DrawerWithTabs from 'common/components/DrawerWithTabs'
import Button from 'common/components/Button'
import ContentsList from 'App/Categories/components/ContentsList'
import { type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  SettingsTitle: {
    marginTop: '0',
    marginBottom: theme.spacing(5),
    fontSize: '20px',
    color: theme.palette.hublet.primary.main,
  },
}))

interface SettingsProps {
  categoryForm: any
  fieldErrors: any
  handleFieldChange: any
  handleUpdate: any
}

const Settings = ({
  categoryForm,
  fieldErrors,
  handleFieldChange,
  handleUpdate,
}: SettingsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <>
      <h1 className={classes.SettingsTitle}>
        {t('categories.editCategory.settings.title')}
      </h1>
      <CategoryFields
        form={categoryForm}
        errors={fieldErrors}
        onChangeField={handleFieldChange}
      />
      <Button small onClick={handleUpdate}>
        {t('categories.editCategory.settings.saveButton')}
      </Button>
    </>
  )
}

interface EditCategoryDrawerProps {
  category?: Category
  profileId: number
  open: boolean
  onClose: () => void
}

const EditCategoryDrawer = ({
  category,
  profileId,
  open,
  onClose,
}: EditCategoryDrawerProps) => {
  const { t } = useTranslation()

  const { categoryForm, fieldErrors, handleFieldChange, handleUpdate } =
    useCategoryForm(profileId, category)

  if (category == null) return null

  const tabs = [
    {
      title: t('categories.editCategory.tabs.content'),
      component: (
        <ContentsList profileId={profileId} categoryId={category.id} />
      ),
    },
    {
      title: t('categories.editCategory.tabs.settings'),
      component: (
        <Settings
          categoryForm={categoryForm}
          fieldErrors={fieldErrors}
          handleFieldChange={handleFieldChange}
          handleUpdate={handleUpdate}
        />
      ),
    },
  ]

  return (
    <DrawerWithTabs
      open={open}
      onClose={onClose}
      title={categoryForm.name}
      tabs={tabs}
    />
  )
}

export default EditCategoryDrawer
