import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProfileNetworksApi from 'api/profile-networks-api'
import {
  type ProfileNetwork,
  type ProfileNetworkContext,
} from 'App/Profiles/ProfilesView/NetworkSettings/network-settings-types'

type BooleanById = Record<number, boolean>

interface ProfilesState {
  profileNetworks: ProfileNetwork[]
  fetchingProfileNetworks: boolean
  fetchedProfileNetworks: boolean
  addingProfileNetwork: BooleanById
  addedProfileNetwork: BooleanById
  deletingProfileNetwork: BooleanById
  deletedProfileNetwork: BooleanById
}

const initialState: ProfilesState = {
  profileNetworks: [],
  fetchingProfileNetworks: false,
  fetchedProfileNetworks: false,
  addingProfileNetwork: {},
  addedProfileNetwork: {},
  deletingProfileNetwork: {},
  deletedProfileNetwork: {},
}

export const fetchProfileNetworks = createAsyncThunk(
  'profileNetworks/fetchProfileNetworks',
  async (context: ProfileNetworkContext) =>
    ProfileNetworksApi.fetchProfileNetworks(context)
)

export const addProfileNetwork = createAsyncThunk(
  'profileNetworks/addProfileNetwork',
  async (props: { context: ProfileNetworkContext; networkId: number }) =>
    ProfileNetworksApi.createProfileNetwork(props)
)

export const deleteProfileNetwork = createAsyncThunk(
  'profileNetworks/deleteProfileNetwork',
  async (props: { context: ProfileNetworkContext; networkId: number }) =>
    ProfileNetworksApi.deleteProfileNetwork(props)
)

export const profileNetworksSlice = createSlice({
  name: 'profileNetworks',
  initialState,
  reducers: {
    resetAddProfileNetwork: (state, { payload }) => {
      state.addingProfileNetwork[payload] = false
      state.addedProfileNetwork[payload] = false
      return state
    },
    resetDeleteProfileNetwork: (state, { payload }) => {
      state.deletingProfileNetwork[payload] = false
      state.deletedProfileNetwork[payload] = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfileNetworks.pending, (state) => {
      state.fetchingProfileNetworks = true
      return state
    })

    builder.addCase(fetchProfileNetworks.fulfilled, (state, { payload }) => {
      state.fetchingProfileNetworks = false
      state.fetchedProfileNetworks = true
      state.profileNetworks = payload
      return state
    })

    builder.addCase(addProfileNetwork.pending, (state, action) => {
      const { networkId } = action.meta.arg
      state.addingProfileNetwork[networkId] = true
      state.addedProfileNetwork[networkId] = false
      return state
    })

    builder.addCase(addProfileNetwork.fulfilled, (state, action) => {
      const { networkId } = action.meta.arg
      state.addingProfileNetwork[networkId] = false
      state.addedProfileNetwork[networkId] = true
      return state
    })

    builder.addCase(deleteProfileNetwork.pending, (state, action) => {
      const { networkId } = action.meta.arg
      state.deletingProfileNetwork[networkId] = true
      state.deletedProfileNetwork[networkId] = false
      return state
    })

    builder.addCase(deleteProfileNetwork.fulfilled, (state, action) => {
      const { networkId } = action.meta.arg
      state.deletingProfileNetwork[networkId] = false
      state.deletedProfileNetwork[networkId] = true
      return state
    })
  },
})

export const { resetAddProfileNetwork, resetDeleteProfileNetwork } =
  profileNetworksSlice.actions

export default profileNetworksSlice.reducer
