import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Table from 'common/components/TableNew'

import { useTranslation } from 'react-i18next'
import {
  type User,
  type UserRole as ServerUserRole,
} from 'App/Users/users-types'

import AccountCircleIcon from '@mui/icons-material/AccountCircleRounded'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import IconMenu from 'common/components/IconMenu'

import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrashOutlined'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import LockIcon from '@mui/icons-material/LockOutlined'
import UnlockIcon from '@mui/icons-material/LockOpenOutlined'

import ActionDialog from 'common/components/ActionDialog'
import EditUserDrawer from 'App/Users/components/EditUserDrawer'
import {
  deleteOrganizationUsers,
  deleteSiteUsers,
  reinviteOrganizationUser,
  reinviteSiteUser,
  resetDeletedUser,
  resetReinviteduser,
} from 'App/Users/users-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useApp from 'common/hooks/useApp'
import { UserRole } from 'App/app-state'
import { isGreaterOrEqRole } from 'common/utils/roles'
import UsersTableActionsBar from 'App/Users/components/UsersTableActionsBar'
import useSearch, { type UseSearchOptions } from 'common/hooks/useSearch'
import {
  useGetUsersQuery,
  useUndeleteUserMutation,
  useLazyGetUsersQuery,
  useUnlockUserMutation,
} from 'App/Users/users-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import Toast from 'common/components/Toast'
import useRenderDate from 'common/hooks/useRenderDate'
import { isPast } from 'date-fns'
import { useAppDispatch, useAppSelector } from 'store'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'

const useStyles = makeStyles()((theme: Theme) => ({
  UserIcon: {
    fontSize: '40px',
    color: theme.palette.hublet.primary.main,
    verticalAlign: 'middle',
    marginRight: '12px',
  },
  InvitedUser: {
    color: theme.palette.hublet.secondary.light,
  },
  UserStatus: {
    fontSize: 12,
    color: theme.palette.hublet.secondary.main,
  },
  UserStatusError: {
    color: theme.palette.hublet.error,
    fontWeight: 'bold',
  },
}))

interface UserUndeleteDialogProps {
  user: User | null
  onClose: () => void
}

const UserUndeleteDialog = ({ user, onClose }: UserUndeleteDialogProps) => {
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const [fetchUsers] = useLazyGetUsersQuery()

  const [undeleteUser, { isSuccess: isUndeleteSuccess, error: undeleteError }] =
    useUndeleteUserMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    undeleteError,
  ])

  useEffect(() => {
    if (isUndeleteSuccess) {
      onClose()
      fetchUsers({ organizationId, siteId, withDeleted: true })
    }
  }, [isUndeleteSuccess, onClose, fetchUsers, organizationId, siteId])

  const handleActionUndelete = () => {
    if (user !== null) {
      undeleteUser({
        organizationId,
        siteId,
        userId: user.id,
      })
    }
  }

  return (
    <>
      {user !== null && (
        <ActionDialog
          open={user !== null}
          title={t('users.undeleteUser.title')}
          description={t('users.undeleteUser.description', {
            user: user.email,
          })}
          actionText={t('common.actions.undelete')}
          onAction={handleActionUndelete}
          onClose={onClose}
          closeOnAction={false}
        >
          {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
        </ActionDialog>
      )}
      <Toast open={isUndeleteSuccess} message={t('users.undeleteUser.toast')} />
    </>
  )
}

interface UserUnlockDialogProps {
  user: User | null
  onClose: () => void
}

const UserUnlockDialog = ({ user, onClose }: UserUnlockDialogProps) => {
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const [fetchUsers] = useLazyGetUsersQuery()

  const [unlockUser, { isSuccess: isUnlockSuccess, error: unlockError }] =
    useUnlockUserMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    unlockError,
  ])

  useEffect(() => {
    if (isUnlockSuccess) {
      onClose()
      fetchUsers({ organizationId, siteId, withDeleted: true })
    }
  }, [isUnlockSuccess, onClose, fetchUsers, organizationId, siteId])

  const handleActionUnlock = () => {
    if (user !== null) {
      unlockUser({
        organizationId,
        siteId,
        userId: user.id,
      })
    }
  }

  return (
    <>
      {user !== null && (
        <ActionDialog
          open={user !== null}
          title={t('users.unlockUser.title')}
          description={t('users.unlockUser.description', { email: user.email })}
          actionText={t('users.unlockUser.action')}
          onAction={handleActionUnlock}
          onClose={onClose}
          closeOnAction={false}
        >
          {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
        </ActionDialog>
      )}
      <Toast open={isUnlockSuccess} message={t('users.unlockUser.toast')} />
    </>
  )
}

interface UsersRowActionsProps {
  currentUserRole: UserRole
  user: User
  onClickDelete: (user: User) => void
  onClickEdit: (user: User) => void
  onClickReinvite: (user: User) => void
  onClickUndelete: (user: User) => void
  onClickUnlock: (user: User) => void
}

const UsersRowActions = ({
  currentUserRole,
  user,
  onClickDelete,
  onClickEdit,
  onClickReinvite,
  onClickUndelete,
  onClickUnlock,
}: UsersRowActionsProps) => {
  const actions = []

  if (user.deletedAt !== undefined) {
    actions.push({
      local: 'users.listUsers.actions.undelete',
      fn: () => onClickUndelete(user),
      icon: RestoreFromTrashIcon,
    })
  } else {
    if (user.invitationExpiresAt !== undefined) {
      actions.push({
        local: 'users.listUsers.actions.reinvite',
        fn: () => onClickReinvite(user),
        icon: EmailIcon,
      })
    }
    actions.push({
      local: 'users.listUsers.actions.edit',
      fn: () => onClickEdit(user),
      icon: EditIcon,
    })

    if (isGreaterOrEqRole(currentUserRole, user.role)) {
      actions.push({
        local: 'users.listUsers.actions.delete',
        fn: () => onClickDelete(user),
        icon: DeleteIcon,
      })
    }
  }

  if (user.isLocked) {
    actions.push({
      local: 'user.listUsers.actions.unlock',
      fn: () => onClickUnlock(user),
      icon: UnlockIcon,
    })
  }

  return <IconMenu actions={actions} />
}

const renderUserFullName = (user: User) =>
  user.firstName || user.lastName
    ? `${user.firstName ?? ''} ${user.lastName ?? ''}`
    : '-'

const UsersTable = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const renderDate = useRenderDate()
  const { classes, cx } = useStyles()

  const [editUserValue, setEditUservalue] = useState<User | null>(null)
  const [deleteUserValue, setDeleteUserValue] = useState<User | null>(null)
  const [userToUndelete, setUserToUndelete] = useState<User | null>(null)
  const [userToUnlock, setUserToUnlock] = useState<User | null>(null)

  const { organizationId, siteId } = useCurrentAccount()
  const { role: activeRole } = useApp()

  const { data: users, refetch: refetchUsers } = useGetUsersQuery({
    organizationId,
    siteId,
    withDeleted: true,
  })

  const deletedUser = useAppSelector((state) => state.users.deletedUser)
  const reinvitedUser = useAppSelector((state) => state.users.reinvitedUser)

  const [showDeleted, setShowDeleted] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const [selectedRows, setSelectedRows] = useState<User[]>([])
  const handleSelectionChange = useCallback((rows: any[]) => {
    setSelectedRows(rows as User[])
  }, [])

  useEffect(() => {
    if (deletedUser) {
      dispatch(resetDeletedUser())
      refetchUsers()
    }
  }, [dispatch, deletedUser, refetchUsers])

  useEffect(() => {
    if (reinvitedUser) {
      dispatch(resetReinviteduser())
    }
  }, [dispatch, reinvitedUser])

  const handleClickDelete = (user: User) => setDeleteUserValue(user)
  const handleClickEdit = (user: User) => setEditUservalue(user)
  const handleClickReinvite = useCallback(
    (user: User) => {
      if (activeRole !== UserRole.SiteAdmin) {
        dispatch(reinviteOrganizationUser({ organizationId, userId: user.id }))
      } else {
        dispatch(reinviteSiteUser({ organizationId, siteId, userId: user.id }))
      }
    },
    [dispatch, activeRole, organizationId, siteId]
  )
  const handleClickUndelete = (user: User) => setUserToUndelete(user)
  const handleClickUnlock = (user: User) => setUserToUnlock(user)

  const handleActionDelete = () => {
    if (deleteUserValue !== null) {
      if (activeRole !== UserRole.SiteAdmin) {
        dispatch(
          deleteOrganizationUsers({
            organizationId,
            userIds: [deleteUserValue.id],
          })
        )
      } else {
        dispatch(
          deleteSiteUsers({
            organizationId,
            siteId,
            userIds: [deleteUserValue.id],
          })
        )
      }
    }
  }

  const handleCloseActionUndeleteDialog = useCallback(
    () => setUserToUndelete(null),
    []
  )
  const handleCloseActionDeleteDialog = () => setDeleteUserValue(null)
  const handleCloseActionUnlockDialog = useCallback(
    () => setUserToUnlock(null),
    []
  )
  const handleCloseEditUserDrawer = () => setEditUservalue(null)

  const renderUserIcon = useCallback(
    (user: User) => {
      if (user.deletedAt !== undefined) {
        return (
          <span title={t('users.listUsers.status.deleted.iconTitle')}>
            <DeleteIcon className={classes.UserIcon} />
          </span>
        )
      } else if (user.invitationExpiresAt !== undefined) {
        return (
          <span title={t('users.listUsers.status.invited.iconTitle')}>
            <EmailIcon className={classes.UserIcon} />
          </span>
        )
      } else if (user.isLocked) {
        return (
          <span title={t('users.listUsers.status.invited.iconTitle')}>
            <LockIcon className={classes.UserIcon} />
          </span>
        )
      } else {
        return (
          <span title={t('users.listUsers.status.active.iconTitle')}>
            <AccountCircleIcon className={classes.UserIcon} />
          </span>
        )
      }
    },
    [classes, t]
  )

  const renderUserStatus = useCallback(
    (user: User) => {
      if (user.deletedAt !== undefined) {
        return (
          <div className={classes.UserStatus}>
            {t('users.listUsers.status.deleted', {
              date: renderDate(new Date(user.deletedAt)),
            })}
          </div>
        )
      } else if (user.invitationExpiresAt !== undefined) {
        if (isPast(new Date(user.invitationExpiresAt))) {
          return (
            <div className={cx([classes.UserStatus, classes.UserStatusError])}>
              {t('users.listUsers.status.invitationExpired')}
            </div>
          )
        }

        return (
          <div className={classes.UserStatus}>
            {t('users.listUsers.status.invitationExpiresAt', {
              date: renderDate(new Date(user.invitationExpiresAt)),
            })}
          </div>
        )
      } else if (user.isLocked) {
        return (
          <div className={classes.UserStatus}>
            {t('users.listUsers.status.locked')}
          </div>
        )
      } else {
        return null
      }
    },
    [renderDate, classes, cx, t]
  )

  const columns = useMemo(
    () => [
      {
        title: t('users.listUsers.columns.name'),
        // eslint-disable-next-line react/display-name
        render: (user: User) => (
          <Grid container wrap="nowrap" alignItems="center">
            <Grid item>{renderUserIcon(user)}</Grid>
            <Grid item>
              <div>{renderUserFullName(user)}</div>
              <div>{renderUserStatus(user)}</div>
            </Grid>
          </Grid>
        ),
      },
      {
        title: t('users.listUsers.columns.email'),
        render: ({ email }: User) => email,
      },
      {
        title: t('users.listUsers.columns.role'),
        render: ({ role }: User) => t(`users.roles.${role}`),
      },
      {
        title: t('users.listUsers.columns.loginSuccessLast'),
        render: ({ loginSuccessLast }: User) =>
          loginSuccessLast == null ? (
            '-'
          ) : (
            <DateWithRelativeTime datetime={new Date(loginSuccessLast)} />
          ),
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (user: User) => {
          return (
            <UsersRowActions
              currentUserRole={activeRole}
              user={user}
              onClickDelete={handleClickDelete}
              onClickEdit={handleClickEdit}
              onClickReinvite={handleClickReinvite}
              onClickUndelete={handleClickUndelete}
              onClickUnlock={handleClickUnlock}
            />
          )
        },
        style: { width: '24px' },
      },
    ],
    [t, handleClickReinvite, activeRole, renderUserStatus, renderUserIcon]
  )

  const usersFilteredByShowDeleted = useMemo(() => {
    if (users === undefined) return []
    if (showDeleted) return users
    return users.filter((user) => user.deletedAt === undefined)
  }, [users, showDeleted])

  const searchOptions: UseSearchOptions<User> = useMemo(
    () => ({
      data: usersFilteredByShowDeleted,
      fields: ['email', 'role', 'loginSuccessLast'],
      query: searchQuery,
      renderers: { role: (role: ServerUserRole) => t(`users.roles.${role}`) },
      customFields: { name: (user: User) => renderUserFullName(user) },
    }),
    [usersFilteredByShowDeleted, t, searchQuery]
  )

  const usersFilteredBySearchQuery = useSearch(searchOptions)

  return (
    <>
      <UsersTableActionsBar
        selectedUsers={selectedRows}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        showDeleted={showDeleted}
        onChangeShowDeleted={setShowDeleted}
      />
      <Table
        columns={columns}
        data={usersFilteredBySearchQuery}
        selectable
        onSelectionChange={handleSelectionChange}
      />
      {editUserValue != null && (
        <EditUserDrawer
          user={editUserValue}
          open={editUserValue !== null}
          onClose={handleCloseEditUserDrawer}
        />
      )}
      {deleteUserValue != null && (
        <ActionDialog
          open={deleteUserValue !== null}
          title={t('posts.deleteUser.title')}
          description={t('posts.deleteUser.description', {
            post: deleteUserValue.email,
          })}
          actionText={t('posts.deleteUser.action')}
          onAction={handleActionDelete}
          onClose={handleCloseActionDeleteDialog}
        />
      )}
      <UserUnlockDialog
        user={userToUnlock}
        onClose={handleCloseActionUnlockDialog}
      />
      <UserUndeleteDialog
        user={userToUndelete}
        onClose={handleCloseActionUndeleteDialog}
      />
    </>
  )
}

export default UsersTable
