import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FileForm from 'App/Categories/components/FileFields'
import { resetAddFile, resetUploadFile } from 'App/Categories/contents-state'
import Button from 'common/components/Button'
import Dialog from 'common/components/Dialog'
import Toast from 'common/components/Toast'
import useFileForm from 'common/hooks/useFileForm'
import UploadFile from 'App/Categories/components/UploadFile'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  Buttons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: theme.spacing(4),
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))

interface AddFileProps {
  open: boolean
  onClose: () => void
  profileId: number
  categoryId: number
}

const AddFile = ({ open, onClose, profileId, categoryId }: AddFileProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const uploadedFilePath = useAppSelector(
    (state) => state.contents.uploadedFilePath
  )
  const addedFile = useAppSelector((state) => state.contents.addedFile)

  const {
    fileForm,
    fieldErrors,
    handleFieldChange,
    handleUploadCustomIcon,
    handleAdd,
    setFileForm,
  } = useFileForm(profileId, categoryId)

  useEffect(() => {
    if (addedFile) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedFile])

  const handleClose = () => {
    dispatch(resetUploadFile())
    dispatch(resetAddFile())
    onClose()
  }

  const [uploadOpen, setUploadOpen] = useState(false)
  const handleUploadClose = () => {
    dispatch(resetUploadFile())
    setUploadOpen(false)
  }

  useEffect(() => {
    if (uploadedFilePath) {
      setFileForm({ ...fileForm, path: uploadedFilePath })
      handleUploadClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFileForm, uploadedFilePath])

  return (
    <>
      <UploadFile
        open={open && uploadOpen}
        onClose={handleUploadClose}
        profileId={profileId}
        categoryId={categoryId}
      />

      <Dialog
        open={open && !uploadOpen}
        onClose={onClose}
        title={t('contents.add.file.title')}
      >
        <FileForm
          fileForm={fileForm}
          fieldErrors={fieldErrors}
          onFieldChange={handleFieldChange}
          onUploadCustomIcon={handleUploadCustomIcon}
          onUploadOpen={() => setUploadOpen(true)}
        />
        <Grid className={classes.Buttons}>
          <Button small outlined onClick={handleClose}>
            {t('contents.cancelButton')}
          </Button>
          <Button small onClick={handleAdd}>
            {t('contents.add.file.button')}
          </Button>
        </Grid>
      </Dialog>

      <Toast open={addedFile} message={t('contents.add.file.toast.success')} />
    </>
  )
}

export default AddFile
