import { useState } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

function getDefaultLanguageByHost() {
  const host = window.location.host

  if (host.startsWith('us-manager')) {
    return 'en'
  } else {
    return 'fi'
  }
}

function getLng(): string {
  const locationQuery = new URLSearchParams(window.location.search)
  const queryLng = locationQuery.get('lng')
  const storedLng = localStorage.getItem('i18nextLng')

  let lng = queryLng

  if (lng == null || lng.length === 0) {
    if (storedLng != null) {
      lng = storedLng
    } else {
      lng = getDefaultLanguageByHost()
    }
  }

  if (storedLng == null || storedLng.length === 0) {
    localStorage.setItem('i18nextLng', lng)
  }

  return lng
}

export function useLanguage(): [string, (lng: string) => void] {
  const [language, _setLanguage] = useState(getLng())

  return [
    language,
    (lng: string) => {
      _setLanguage(lng)
      i18n.changeLanguage(lng)
      localStorage.setItem('i18nextLng', lng)
    },
  ]
}

let loadPath = '/api/v2/web/main/strings/web/{{lng}}'
const i18nDebug = new URL(window.location.href).searchParams.get('i18nDebug')
if (i18nDebug !== null) {
  loadPath = '/api/v2/web/strings/web/{{lng}}'
}

i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: getLng(),
    fallbackLng: 'fi',
    keySeparator: '',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  })

export default i18n
