import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type SingleTranslation,
  type Translation,
} from 'App/Translations/translations-types'
import { type QueryResult } from 'api/types'

class TranslationsApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchLanguages(): Promise<string[]> {
    const { data } = await axios.get(`${API_BASE_URL}/languages`)
    return data as string[]
  }

  static async fetchAllLanguages(): Promise<string[]> {
    const { data } = await axios.get(`${API_BASE_URL}/languages/all`)
    return data as string[]
  }

  static async fetchTranslations(): Promise<QueryResult<Translation>> {
    const { data } = await axios.get(`${API_BASE_URL}/translations`)
    return data as QueryResult<Translation>
  }

  static async fetchTranslation(
    app: string,
    key: string
  ): Promise<Translation> {
    const { data } = await axios.get(`${API_BASE_URL}/translation`, {
      params: { app, key },
    })
    return data as Translation
  }

  static async createTranslation(translation: Translation): Promise<void> {
    await axios.post(`${API_BASE_URL}/translation`, translation)
  }

  static async updateTranslation(translation: Translation): Promise<void> {
    await axios.put(`${API_BASE_URL}/translation`, translation)
  }

  static async deleteTranslation(translation: Translation): Promise<void> {
    await axios.delete(`${API_BASE_URL}/translation`, {
      data: { app: translation.app, key: translation.key },
    })
  }

  static async importTranslations(language: string, file: File): Promise<void> {
    const apiUrl = `${API_BASE_URL}/translations/${language}/import`
    const headers = { 'Content-Type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', file)

    await axios.post(apiUrl, formData, { headers })
  }

  static async translate(
    text: string,
    languages: string[]
  ): Promise<SingleTranslation[]> {
    console.log(3, text, languages)
    const apiUrl = `${API_BASE_URL}/translate`

    const { data } = await axios.get(apiUrl, {
      params: {
        text,
        languages: languages.join(','),
      },
    })
    return data
  }
}

export default TranslationsApi
