export enum LoanStatus {
  EXPIRED = 0,
  ACTIVE = 1,
  RETURNED = 2,
  START = 3,
}

export enum ProfileSource {
  Dock = 'dock',
  Integration = 'integration',
  Site = 'site',
  First = 'first',
  Unknown = 'profileSource', // old implementation had added a general `profileSource` instead of actual source
}

export interface Loan {
  id: number
  siteId: number
  startTime: Date
  endTime: Date
  status?: LoanStatus
  profileName?: string
  profileSource?: ProfileSource
  tabletActiveAt?: Date
  tabletSerialNumber?: string
  userName?: string
  pinCode?: string
  returnTime?: Date
  userDefaultLanguage?: string
}

export interface LoansContext {
  organizationId: number
  siteId: number
}
