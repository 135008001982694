import {
  Box,
  List,
  ListItem,
  Radio,
  type SvgIconProps,
  type Theme,
  useTheme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
  type SvgIconComponent,
} from '@mui/icons-material'
import DocksReactContext from 'App/Docks/docks-react-context'
import { useGetDockBorrowingPrioritiesQuery } from 'App/Docks/docks-rtk-api'
import {
  fetchDocks,
  fetchUnpairedTablets,
  pairCase,
  resetPairCase,
  resetUnpairCase,
  unpairCase,
} from 'App/Docks/docks-state'
import { type Dock } from 'App/Docks/docks-types'
import { type Tablet } from 'App/Tablets/tablets-types'
import ActionDialog from 'common/components/ActionDialog'
import IconMenu from 'common/components/IconMenu'
import Table from 'common/components/TableNew'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import useUnpairedTablets from 'common/hooks/useUnpairedTablets'
import { renderItems } from 'common/utils/render-utils'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DockSlotsSlot: {
    display: 'inline-block',
    width: '32px',
    height: '32px',
    lineHeight: '32px',
    borderRadius: '100px',
    textAlign: 'center',
    backgroundColor: theme.palette.hublet.primary.main,
    color: theme.palette.hublet.common.white,
    fontWeight: 'bold',
    fontSize: '16px',
  },
  DockSlotsSlotUnknown: {
    backgroundColor: theme.palette.hublet.error,
  },
  DockSlotsSlotEmpty: {
    backgroundColor: theme.palette.hublet.secondary.light,
  },
  ActionDialogList: {
    margin: '30px 0 17px 0',
    padding: '26px',
    backgroundColor: theme.palette.hublet.common.white,
    border: `1px solid ${theme.palette.hublet.common.gray}`,
    borderRadius: '10px',
    '& > li': {
      padding: '0',
      marginBottom: '45px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  ActionDialogListLabel: {
    display: 'block',
    width: '100%',
    fontSize: '16px',
    color: theme.palette.hublet.common.black,
    '& > *': {
      verticalAlign: 'middle',
    },
  },
  ActionDialogListRadio: {
    padding: 0,
    margin: '0 16px 0 0',
    '&.MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.hublet.primary.main,
    },
  },
}))

interface PairTabletDialogProps {
  onewireId: string | null
  slot: number | null
  onClose: () => void
}

const PairTabletDialog = ({
  onewireId,
  slot,
  onClose,
}: PairTabletDialogProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const context = useContext(DocksReactContext)

  const [selectedTablet, setSelectedTablet] = useState<Tablet | null>(null)
  const unpairedTablets = useUnpairedTablets()
  const { organizationId, siteId } = useCurrentAccount()

  const onSelectTablet = (tablet: Tablet) => () => setSelectedTablet(tablet)
  const listItemOpacity = (tablet: Tablet) => {
    return selectedTablet !== null &&
      selectedTablet.serialNumber !== tablet.serialNumber
      ? 0.2
      : 1.0
  }

  const onPairTabletAction = () => {
    if (selectedTablet === null || onewireId === null) {
      return
    }

    const data = {
      organizationId,
      siteId,
      serialNumber: selectedTablet.serialNumber,
      onewireId,
      context,
    }

    dispatch(pairCase(data))
  }

  return (
    <ActionDialog
      title={t('docks.details.pairTablet.title')}
      open={onewireId !== null}
      description={t('docks.details.pairTablet.description', { slot })}
      actionText={t('docks.details.pairTablet.actionText')}
      onAction={onPairTabletAction}
      onClose={onClose}
      actionDisabled={selectedTablet === null}
      gray
    >
      <List className={classes.ActionDialogList}>
        {renderItems(unpairedTablets, (tablet) => (
          <ListItem style={{ opacity: listItemOpacity(tablet) }}>
            <label className={classes.ActionDialogListLabel}>
              <Radio
                className={classes.ActionDialogListRadio}
                name="dock-unpaired-tablet"
                value={tablet.serialNumber}
                checked={tablet.serialNumber === selectedTablet?.serialNumber}
                onChange={onSelectTablet(tablet)}
              />
              <span>{tablet.serialNumber}</span>
            </label>
          </ListItem>
        ))}
      </List>
    </ActionDialog>
  )
}

interface DockSlot {
  slot: number
  empty: boolean
  unknown: boolean
  onewireId: string | null
  serialNumber?: string
  macAddress?: string
  priority?: number
}

interface DockSlotActionsProps {
  dockSlot: DockSlot
  onClickUnpairCase: (serialNumber?: string) => void
  onClickPairCase: (onewireId: string | null) => void
}

const RedRemoveCircleIcon: SvgIconComponent = (props: SvgIconProps) => {
  const theme = useTheme()
  return (
    <RemoveCircleOutlineOutlined
      {...props}
      style={{ ...props.style, color: theme.palette.hublet.error }}
    />
  )
}

RedRemoveCircleIcon.muiName = RemoveCircleOutlineOutlined.muiName

const DockSlotActions = ({
  onClickUnpairCase,
  onClickPairCase,
  dockSlot,
}: DockSlotActionsProps) => {
  const { t } = useTranslation()

  if (dockSlot.empty) {
    return null
  }

  const actions = []

  if (dockSlot.unknown) {
    actions.push({
      local: t('docks.details.slots.actions.pairCase'),
      fn: () => onClickPairCase(dockSlot.onewireId),
      icon: AddCircleOutlineOutlined,
    })
  } else {
    actions.push({
      local: t('docks.details.slots.actions.unpairCase'),
      fn: () => onClickUnpairCase(dockSlot.serialNumber),
      icon: RedRemoveCircleIcon,
    })
  }

  return <IconMenu actions={actions} />
}

const getSlotPriority = (
  slot: number,
  borrowingPriorities: number[] | undefined
): number | undefined => {
  if (borrowingPriorities === undefined) return undefined
  const idx = borrowingPriorities.indexOf(slot)
  return idx === -1 ? undefined : idx + 1
}

interface DockConnectedTabletsProps {
  dock: Dock
}

const DockConnectedTablets = ({ dock }: DockConnectedTabletsProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes, cx } = useStyles()

  const [pairToOneWireId, setPairToOneWireId] = useState<string | null>(null)
  const [unpairSerialNumber, setUnpairSerialNumber] = useState<string | null>(
    null
  )

  const unpairedCase = useAppSelector((state) => state.docks.unpairedCase)
  const pairedCase = useAppSelector((state) => state.docks.pairedCase)

  const { organizationId, siteId } = useCurrentAccount()
  const context = useContext(DocksReactContext)

  const { data: borrowingPriorities } = useGetDockBorrowingPrioritiesQuery({
    organizationId,
    siteId,
    dockId: dock.id,
    context,
  })

  useEffect(() => {
    if (unpairedCase) {
      dispatch(resetUnpairCase())
      dispatch(fetchUnpairedTablets({ organizationId, siteId, context }))
      dispatch(fetchDocks({ organizationId, siteId, context }))
    }
  }, [dispatch, organizationId, siteId, context, unpairedCase])

  useEffect(() => {
    if (pairedCase) {
      dispatch(resetPairCase())
      dispatch(fetchUnpairedTablets({ organizationId, siteId, context }))
      dispatch(fetchDocks({ organizationId, siteId, context }))
    }
  }, [dispatch, organizationId, siteId, context, pairedCase])

  const onUnpairCase = (serialNumber?: string) => {
    if (!serialNumber) {
      return
    }

    setUnpairSerialNumber(serialNumber)
  }

  const onPairCase = (onewireId: string | null) => {
    if (!onewireId) {
      return
    }

    setPairToOneWireId(onewireId)
  }

  const onActionUnpair = () => {
    if (!unpairSerialNumber) {
      return
    }

    dispatch(
      unpairCase({
        organizationId,
        siteId,
        serialNumber: unpairSerialNumber,
        context,
      })
    )
  }

  const onCloseUnpairDialog = () => {
    setUnpairSerialNumber(null)
  }

  const onClosePairTablet = () => setPairToOneWireId(null)

  const columns = useMemo(
    () => [
      {
        title: t('docks.details.slots.fields.slot.label'),
        // eslint-disable-next-line react/display-name
        render: (dockSlot: DockSlot) => {
          const classNames = [classes.DockSlotsSlot]

          if (dockSlot.unknown && !dockSlot.empty) {
            classNames.push(classes.DockSlotsSlotUnknown)
          }

          if (dockSlot.empty) {
            classNames.push(classes.DockSlotsSlotEmpty)
          }

          return <Box className={cx(classNames)}>{dockSlot.slot}</Box>
        },
        style: { width: '78px' },
      },
      {
        title: t('docks.details.slots.fields.serialNumber.label'),
        // eslint-disable-next-line react/display-name
        render: (dockSlot: DockSlot) => {
          if (dockSlot.unknown && !dockSlot.empty) {
            return (
              <span>
                {t('docks.details.slots.fields.serialNumber.unknown')}
              </span>
            )
          }

          if (dockSlot.empty) {
            return <span>-</span>
          }

          return <span>{dockSlot.serialNumber}</span>
        },
        style: { width: '160px' },
      },
      {
        title: t('docks.details.slots.fields.macAddress.label'),
        render: ({ macAddress }: DockSlot) => {
          return macAddress ?? '-'
        },
        style: { width: 'auto' },
      },
      {
        title: t('docks.details.slots.fields.priority.label'),
        render: ({ priority }: DockSlot) => {
          return priority ?? '-'
        },
        style: { width: 'auto' },
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (dockSlot: DockSlot) => {
          return (
            <DockSlotActions
              dockSlot={dockSlot}
              onClickUnpairCase={onUnpairCase}
              onClickPairCase={onPairCase}
            />
          )
        },
      },
    ],
    [classes, cx, t]
  )

  const slots: DockSlot[] = useMemo(
    () => [
      {
        slot: 1,
        empty: dock.slot1 === null,
        unknown: dock.tabletInSlot1 === null,
        onewireId: dock.slot1,
        serialNumber: dock.tabletInSlot1?.serialNumber,
        macAddress: dock.tabletInSlot1?.macAddress,
        priority: getSlotPriority(1, borrowingPriorities),
      },
      {
        slot: 2,
        empty: dock.slot2 === null,
        unknown: dock.tabletInSlot2 === null,
        onewireId: dock.slot2,
        serialNumber: dock.tabletInSlot2?.serialNumber,
        macAddress: dock.tabletInSlot2?.macAddress,
        priority: getSlotPriority(2, borrowingPriorities),
      },
      {
        slot: 3,
        empty: dock.slot3 === null,
        unknown: dock.tabletInSlot3 === null,
        onewireId: dock.slot3,
        serialNumber: dock.tabletInSlot3?.serialNumber,
        macAddress: dock.tabletInSlot3?.macAddress,
        priority: getSlotPriority(3, borrowingPriorities),
      },
      {
        slot: 4,
        empty: dock.slot4 === null,
        unknown: dock.tabletInSlot4 === null,
        onewireId: dock.slot4,
        serialNumber: dock.tabletInSlot4?.serialNumber,
        macAddress: dock.tabletInSlot4?.macAddress,
        priority: getSlotPriority(4, borrowingPriorities),
      },
      {
        slot: 5,
        unknown: dock.tabletInSlot5 === null,
        empty: dock.slot5 === null,
        onewireId: dock.slot5,
        serialNumber: dock.tabletInSlot5?.serialNumber,
        macAddress: dock.tabletInSlot5?.macAddress,
        priority: getSlotPriority(5, borrowingPriorities),
      },
      {
        slot: 6,
        unknown: dock.tabletInSlot6 === null,
        empty: dock.slot6 === null,
        onewireId: dock.slot6,
        serialNumber: dock.tabletInSlot6?.serialNumber,
        macAddress: dock.tabletInSlot6?.macAddress,
        priority: getSlotPriority(6, borrowingPriorities),
      },
    ],
    [dock, borrowingPriorities]
  )

  const dockSlotsCount = (dock: Dock): number => {
    if (dock.model?.includes('HUBLET-2017-M3')) {
      return 3
    }

    return 6
  }

  const data = slots.slice(0, dockSlotsCount(dock))

  const slotNumberByOnewireId = (onewireId: string | null) => {
    if (!onewireId) {
      return null
    }

    const matches = slots.filter((slot) => slot.onewireId === onewireId)
    return matches.length === 1 ? matches[0].slot : null
  }

  return (
    <>
      <Table
        columns={columns}
        data={data}
        pagination={false}
        variant="drawer"
      />
      <PairTabletDialog
        onewireId={pairToOneWireId}
        slot={slotNumberByOnewireId(pairToOneWireId)}
        onClose={onClosePairTablet}
      />
      {unpairSerialNumber && (
        <ActionDialog
          open={unpairSerialNumber !== null}
          title={t('docks.details.unpairTablet.confirm.title')}
          description={t('docks.details.unpairTablet.confirm.description')}
          actionText={t('docks.details.unpairTablet.confirm.actionText')}
          onAction={onActionUnpair}
          onClose={onCloseUnpairDialog}
        />
      )}
    </>
  )
}

export default DockConnectedTablets
