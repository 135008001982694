import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Dock,
  type DockEditable,
  type DocksContext,
} from 'App/Docks/docks-types'
import { type Tablet } from 'App/Tablets/tablets-types'
import { getDocksPathContext } from 'App/Docks/docks-utils'

const dockToModel = (dock: any): Dock => ({
  ...dock,
  keepaliveLast:
    dock.keepaliveLast === undefined || dock.keepaliveLast === null
      ? undefined
      : new Date(dock.keepaliveLast),
  manufactureDate:
    dock.manufactureDate === undefined || dock.manufactureDate === null
      ? undefined
      : new Date(dock.manufactureDate),
  deletedAt: dock.deletedAt ?? undefined,
  tunnelRequestAt:
    dock.tunnelRequestAt === undefined || dock.tunnelRequestAt === null
      ? undefined
      : new Date(dock.tunnelRequestAt),
  tunnelResponseAt:
    dock.tunnelResponseAt === undefined || dock.tunnelResponseAt === null
      ? undefined
      : new Date(dock.tunnelResponseAt),
})

class DocksApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchDocks(
    organizationId: number,
    siteId: number,
    context: DocksContext,
    withDeleted: boolean = true
  ): Promise<Dock[]> {
    let apiUrl = API_BASE_URL
    apiUrl += getDocksPathContext(organizationId, siteId, context)
    apiUrl += '/docks'
    apiUrl += `?withDeleted=${String(withDeleted)}`
    const { data } = await axios.get(apiUrl)
    return data.map(dockToModel)
  }

  static async fetchUnpairedTablets(
    organizationId: number,
    siteId: number,
    context: DocksContext
  ): Promise<Tablet[]> {
    let apiUrl = API_BASE_URL
    apiUrl += getDocksPathContext(organizationId, siteId, context)
    apiUrl += '/tablets/unpaired'
    const res = await axios.get(apiUrl)
    return res.data as Tablet[]
  }

  static async pairCase(
    organizationId: number,
    siteId: number,
    serialNumber: string,
    onewireId: string,
    context: DocksContext
  ): Promise<void> {
    let apiUrl = API_BASE_URL
    apiUrl += getDocksPathContext(organizationId, siteId, context)
    apiUrl += `/tablet/${encodeURIComponent(serialNumber)}/pair-case`
    const body = { onewireId }
    await axios.put(apiUrl, body)
  }

  static async unpairCase(
    organizationId: number,
    siteId: number,
    serialNumber: string,
    context: DocksContext
  ): Promise<void> {
    let apiUrl = API_BASE_URL
    apiUrl += getDocksPathContext(organizationId, siteId, context)
    apiUrl += `/tablet/${encodeURIComponent(serialNumber)}/unpair-case`
    await axios.put(apiUrl)
  }

  static async updateDock(
    organizationId: number,
    siteId: number,
    dockId: number,
    form: DockEditable,
    context: DocksContext
  ): Promise<void> {
    let apiUrl = API_BASE_URL
    apiUrl += getDocksPathContext(organizationId, siteId, context)
    apiUrl += `/docks/${dockId}`
    await axios.put(apiUrl, form)
  }
}

export default DocksApi
