import React, { useEffect, useMemo, useState } from 'react'

import Page from 'common/components/Page'
import PageActions from 'common/components/PageActions'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Button from 'common/components/Button'
import Table from 'common/components/TableNew'
import { useAppDispatch, useAppSelector } from 'store'
import {
  deleteCode,
  fetchCodes,
  resetDeleteCode,
  resetShowCode,
} from 'App/Codes/codes-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { type Code, PermissionType } from 'App/Codes/codes-types'
import { renderIf } from 'common/utils/render-utils'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import AddCodeDrawer from 'App/Codes/components/AddCodeDrawer'
import { useTranslation } from 'react-i18next'
import Toast from 'common/components/Toast'
import ActionDialog from 'common/components/ActionDialog'
import IconMenu from 'common/components/IconMenu'
import Dialog from 'common/components/Dialog'
import PageEmpty from 'common/components/PageEmpty'

import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import ViewCodeDrawer from 'App/Codes/components/ViewCodeDrawer'
import PageRequirement from 'common/components/PageRequirement'
import useProfiles from 'common/hooks/useProfiles'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'

const useStyles = makeStyles()((theme: Theme) => ({
  CodeActive: {
    fontWeight: 'bold',
    color: '#64AB43',
  },
  CodeExpired: {
    fontWeight: 'bold',
    color: theme.palette.hublet.error,
  },
  ShowCodeDialog: {
    width: 'auto',
  },
  ShowCodeDialogContent: {
    paddingBottom: 0,
  },
  CodeChar: {
    width: '60px',
    height: '70px',
    float: 'left',
    marginRight: theme.spacing(2),
    textAlign: 'center',
    lineHeight: '70px',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    backgroundColor: theme.palette.hublet.common.white,
    fontSize: '48px',
    fontWeight: 'bold',
    color: theme.palette.hublet.common.black,
    '&:last-child': {
      marginRight: 0,
    },
  },
}))

interface ShowCodeDialogProps {
  code?: string
}

const ShowCodeDialog = ({ code }: ShowCodeDialogProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const onClose = () => dispatch(resetShowCode())

  if (code === undefined) {
    return null
  }

  return (
    <Dialog
      open={code !== undefined}
      title={t('codes.showCode.title')}
      onClose={onClose}
      classes={{
        container: classes.ShowCodeDialog,
        content: classes.ShowCodeDialogContent,
      }}
      actions={
        <Button onClick={onClose} small outlined>
          {t('codes.showCode.actions.close')}
        </Button>
      }
    >
      {code?.split('').map((c, i) => (
        <div key={i} className={classes.CodeChar}>
          {c}
        </div>
      ))}
    </Dialog>
  )
}

interface CodesTableProps {
  codes: Code[]
}

const CodesTable = ({ codes }: CodesTableProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { organizationId, siteId } = useCurrentAccount()
  const deletedCode = useAppSelector((state) => state.codes.deletedCode)

  const [viewCodeDetailsValue, setViewCodeDetailsValue] = useState<
    Code | undefined
  >()
  const [deleteCodeValue, setDeleteCodeValue] = useState<Code | undefined>()

  useEffect(() => {
    if (deletedCode) {
      dispatch(resetDeleteCode())
      dispatch(fetchCodes({ organizationId, siteId }))
    }
  }, [dispatch, deletedCode, organizationId, siteId])

  const columns = useMemo(
    () => [
      {
        title: t('codes.listCodes.columns.name'),
        field: 'name',
      },
      {
        title: t('codes.listCodes.columns.code'),
        field: 'code',
      },
      {
        title: t('codes.listCodes.columns.validUntil'),
        render: ({ expiration }: Code) => (
          <DateWithRelativeTime datetime={expiration} />
        ),
      },
      {
        title: t('codes.listCodes.columns.status'),
        render: ({ expiration, useCount, permissionType }: Code) => {
          if (useCount > 0 && permissionType === PermissionType.Single) {
            return (
              <span className={classes.CodeExpired}>
                {t('codes.listCodes.values.used')}
              </span>
            )
          } else if (expiration.getTime() < Date.now()) {
            return (
              <span className={classes.CodeExpired}>
                {t('codes.listCodes.values.expired')}
              </span>
            )
          } else {
            return (
              <span className={classes.CodeActive}>
                {t('codes.listCodes.values.active')}
              </span>
            )
          }
        },
      },
      {
        title: t('codes.listCodes.columns.profile'),
        render: ({ profileName }: Code) =>
          profileName ?? t('common.values.useDefault'),
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (code: Code) => {
          const actions = [
            {
              local: 'codes.listCodes.actions.viewDetails',
              fn: () => setViewCodeDetailsValue(code),
              icon: VisibilityIcon,
            },
            {
              local: 'codes.listCodes.actions.delete',
              fn: () => setDeleteCodeValue(code),
              icon: DeleteIcon,
            },
          ]

          return <IconMenu actions={actions} />
        },
        style: { width: '24px' },
      },
    ],
    [t, classes]
  )

  return (
    <>
      <Table columns={columns} data={codes} />
      {deleteCodeValue != null && (
        <ActionDialog
          open={deleteCodeValue !== undefined}
          title={t('codes.deleteCode.title')}
          description={t('codes.deleteCode.description', {
            code: deleteCodeValue.name,
          })}
          actionText={t('codes.deleteCode.action')}
          onAction={() => {
            dispatch(
              deleteCode({
                context: { organizationId, siteId },
                codeId: deleteCodeValue.id,
              })
            )
          }}
          onClose={() => setDeleteCodeValue(undefined)}
        />
      )}
      {viewCodeDetailsValue != null && (
        <ViewCodeDrawer
          open={viewCodeDetailsValue !== undefined}
          code={viewCodeDetailsValue}
          onClose={() => setViewCodeDetailsValue(undefined)}
        />
      )}
    </>
  )
}

const Codes = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [openAddCodeDrawer, setOpenAddCodeDrawer] = useState(false)

  const codes = useAppSelector((state) => state.codes.codes)
  const fetchedCodes = useAppSelector((state) => state.codes.fetchedCodes)
  const addedCode = useAppSelector((state) => state.codes.addedCode)
  const deletedCode = useAppSelector((state) => state.codes.deletedCode)
  const showCode = useAppSelector((state) => state.codes.showCode)

  const { organizationId, siteId } = useCurrentAccount()

  const profiles = useProfiles()
  const fetchedProfiles = useAppSelector(
    (state) => state.profiles.fetchedProfiles
  )

  useEffect(() => {
    dispatch(fetchCodes({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  if (!fetchedCodes || !fetchedProfiles) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageRequirement
          criteria={profiles.length > 0}
          title={t('codes.requirement.title')}
          description={t('codes.requirement.description')}
          actionText={t('codes.requirement.action')}
          actionTo="/profiles"
        >
          {renderIf(codes.length === 0, () => (
            <PageEmpty
              title={t('codes.empty.title')}
              description={t('codes.empty.description')}
              actionText={t('codes.empty.action')}
              onClickAction={() => setOpenAddCodeDrawer(true)}
            />
          ))}
          {renderIf(codes.length > 0, () => (
            <>
              <PageHeader>
                <PageTitle title={t('codes.title')} />
                <PageActions>
                  <Button onClick={() => setOpenAddCodeDrawer(true)} small>
                    {t('codes.actions.addCode')}
                  </Button>
                </PageActions>
              </PageHeader>
              <PageBody>
                <CodesTable codes={codes} />
              </PageBody>
            </>
          ))}
        </PageRequirement>
      </Page>
      <Toast open={addedCode} message={t('codes.addCode.toast.success')} />
      <Toast open={deletedCode} message={t('codes.deleteCode.toast.success')} />
      <AddCodeDrawer
        open={openAddCodeDrawer}
        onClose={() => setOpenAddCodeDrawer(false)}
      />
      <ShowCodeDialog code={showCode} />
    </>
  )
}

export default Codes
