import rtkApi from 'rtk-api'
import { type ScheduledTaskResult } from 'App/Dashboard/scheduled-task-result-types'

const scheduledTaskResultRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    loadLastScheduledTaskResult: builder.query<ScheduledTaskResult, null>({
      query: () => '/scheduled-task-result/last',
    }),
  }),
})

export const { useLoadLastScheduledTaskResultQuery } = scheduledTaskResultRtkApi
