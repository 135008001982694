import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type Category,
  type CategoryContext,
} from 'App/Categories/categories-types'
import { deleteCategory as deleteCategoryAction } from 'App/Categories/categories-state'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import Table from 'common/components/TableNew'
import IconMenu from 'common/components/IconMenu'
import ActionDialog from 'common/components/ActionDialog'
import EditCategoryDrawer from 'App/Categories/components/EditCategoryDrawer'
import AvatarWithText from 'common/components/AvatarWithText'
import Toast from 'common/components/Toast'
import { useAppDispatch, useAppSelector } from 'store'

interface CategoryRowActionsProps {
  category: Category
  onClickEdit: (category: Category) => void
  onClickDelete: (category: Category) => void
}

const CategoryRowActions = ({
  category,
  onClickEdit,
  onClickDelete,
}: CategoryRowActionsProps) => {
  const actions = [
    {
      local: 'categories.listCategories.actions.edit',
      fn: () => onClickEdit(category),
      icon: EditIcon,
    },
    {
      local: 'categories.listCategories.actions.delete',
      fn: () => onClickDelete(category),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

interface CategoriesTableProps {
  context: CategoryContext
  categories: Category[]
}

const CategoriesTable = ({ context, categories }: CategoriesTableProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const updatedCategory = useAppSelector(
    (state) => state.categories.updatedCategory
  )
  const deletedCategory = useAppSelector(
    (state) => state.categories.deletedCategory
  )

  const [editCategory, setEditCategory] = useState<Category | undefined>()
  const [deleteCategory, setDeleteCategory] = useState<Category | undefined>()

  const onClickEdit = (network: Category) => setEditCategory(network)
  const onClickDelete = (network: Category) => setDeleteCategory(network)

  const onCloseDrawer = () => setEditCategory(undefined)
  const onCloseDialog = () => setDeleteCategory(undefined)

  const onActionDelete = () => {
    if (deleteCategory != null) {
      dispatch(
        deleteCategoryAction({ context, categoryId: deleteCategory?.id })
      )
      setDeleteCategory(undefined)
    }
  }

  const columns = [
    {
      title: t('categories.table.columns.content'),
      // eslint-disable-next-line react/display-name
      render: ({ name, description }: Category) => {
        return (
          <AvatarWithText text={name}>
            <div>{name}</div>
            <div>{description}</div>
          </AvatarWithText>
        )
      },
    },
    {
      title: t('categories.table.columns.apps'),
      field: 'numApps',
      style: { width: '90px' },
    },
    {
      title: t('categories.table.columns.links'),
      field: 'numLinks',
      style: { width: '90px' },
    },
    {
      title: t('categories.table.columns.files'),
      field: 'numFiles',
      style: { width: '90px' },
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (category: Category) => {
        return (
          <CategoryRowActions
            category={category}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={categories} />
      <EditCategoryDrawer
        profileId={context.profileId}
        category={editCategory}
        open={editCategory !== undefined}
        onClose={onCloseDrawer}
      />
      {deleteCategory != null && (
        <ActionDialog
          open={deleteCategory !== undefined}
          title={t('categories.deleteCategory.title')}
          description={t('categories.deleteCategory.description', {
            category: deleteCategory.name,
          })}
          actionText={t('categories.deleteCategory.action')}
          onAction={onActionDelete}
          onClose={onCloseDialog}
        />
      )}
      <Toast
        open={updatedCategory}
        message={t('categories.editCategory.toast.success')}
      />
      <Toast
        open={deletedCategory}
        message={t('categories.deleteCategory.toast.success')}
      />
    </>
  )
}

export default CategoriesTable
