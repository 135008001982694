import React, { useEffect, useState } from 'react'
import { type Translation } from 'App/Translations/translations-types'
import {
  addTranslation,
  resetAddTranslation,
  fetchTranslations,
} from 'App/Translations/translations-state'

import TranslationForm from 'App/Translations/components/TranslationForm'
import { useAppDispatch } from 'store'

const initialTranslationForm = {
  app: 'web',
  key: '',
  translations: [],
}

interface AddTranslationDrawerProps {
  open: boolean
  onClose: () => void
  addedTranslation: boolean
}

const AddTranslationDrawer = ({
  open,
  onClose,
  addedTranslation,
}: AddTranslationDrawerProps) => {
  const dispatch = useAppDispatch()
  const [translationForm, setTranslationForm] = useState<Translation>(
    initialTranslationForm
  )

  useEffect(() => {
    if (addedTranslation) {
      onClose()
      setTranslationForm(initialTranslationForm)
      dispatch(resetAddTranslation())
      dispatch(fetchTranslations())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onClose, addedTranslation])

  const onSubmitTranslation = (translation: Translation) => {
    dispatch(addTranslation(translation))
  }

  return (
    <TranslationForm
      canEditKey={true}
      translation={{ ...translationForm }}
      open={open}
      onClose={onClose}
      onSubmitTranslation={onSubmitTranslation}
    />
  )
}

export default AddTranslationDrawer
