import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  type Content,
  type ContentContext,
  type AppForm,
  type WebsiteForm,
  type FileForm,
  type AppPermission,
} from 'App/Categories/categories-types'
import ContentsApi from 'api/contents-api'

interface ContentsState {
  contents: Content[]
  fetchingContents: boolean
  fetchedContents: boolean

  googleToken: string | null

  addedApp: boolean

  updatingApp: boolean
  updatedApp: boolean
  deletingApp: boolean
  deletedApp: boolean
  highlightedApp: boolean
  unhighlightedApp: boolean

  loadingAppPermissions: boolean
  appPermissions: AppPermission[]

  addingWebsite: boolean
  addedWebsite: boolean
  updatingWebsite: boolean
  updatedWebsite: boolean
  deletingWebsite: boolean
  deletedWebsite: boolean
  highlightingWebsite: boolean
  highlightedWebsite: boolean
  unhighlightingWebsite: boolean
  unhighlightedWebsite: boolean

  uploadingWebsiteCustomIcon: boolean
  uploadedWebsiteCustomIcon: boolean
  uploadedWebsiteCustomIconUrl: string | null
  uploadWebsiteCustomIconError: boolean

  uploadedFilePath: string | null

  addingFile: boolean
  addedFile: boolean
  uploadingFile: boolean
  uploadedFile: boolean
  updatingFile: boolean
  updatedFile: boolean
  deletingFile: boolean
  deletedFile: boolean
  highlightingFile: boolean
  highlightedFile: boolean
  unhighlightingFile: boolean
  unhighlightedFile: boolean

  uploadingFileCustomIcon: boolean
  uploadedFileCustomIcon: boolean
  uploadedFileCustomIconUrl: string | null
  uploadFileCustomIconError: boolean
}

const initialState: ContentsState = {
  contents: [],
  fetchingContents: false,
  fetchedContents: false,

  googleToken: null,

  addedApp: false,

  updatingApp: false,
  updatedApp: false,
  deletingApp: false,
  deletedApp: false,
  highlightedApp: false,
  unhighlightedApp: false,

  loadingAppPermissions: false,
  appPermissions: [],

  addingWebsite: false,
  addedWebsite: false,
  updatingWebsite: false,
  updatedWebsite: false,
  deletingWebsite: false,
  deletedWebsite: false,
  highlightingWebsite: false,
  highlightedWebsite: false,
  unhighlightingWebsite: false,
  unhighlightedWebsite: false,

  uploadingWebsiteCustomIcon: false,
  uploadedWebsiteCustomIcon: false,
  uploadedWebsiteCustomIconUrl: null,
  uploadWebsiteCustomIconError: false,

  uploadedFilePath: null,

  addingFile: false,
  addedFile: false,
  uploadingFile: false,
  uploadedFile: false,
  updatingFile: false,
  updatedFile: false,
  deletingFile: false,
  deletedFile: false,
  highlightingFile: false,
  highlightedFile: false,
  unhighlightingFile: false,
  unhighlightedFile: false,

  uploadingFileCustomIcon: false,
  uploadedFileCustomIcon: false,
  uploadedFileCustomIconUrl: null,
  uploadFileCustomIconError: false,
}

export const fetchContents = createAsyncThunk(
  'contents/fetchContents',
  async (context: ContentContext) => ContentsApi.fetchContents(context)
)

export const fetchGoogleToken = createAsyncThunk(
  'contents/fetchGoogletoken',
  async (context: { organizationId: number; siteId: number }) =>
    ContentsApi.fetchGoogleToken(context)
)

// apps
export const addApp = createAsyncThunk(
  'contents/addApp',
  async ({
    context,
    packageName,
  }: {
    context: ContentContext
    packageName: string
  }) => ContentsApi.createApp(context, packageName)
)

export const updateApp = createAsyncThunk(
  'contents/updateApp',
  async ({
    context,
    id,
    form,
  }: {
    context: ContentContext
    id: number
    form: AppForm
  }) => ContentsApi.updateApp(context, id, form)
)

export const deleteApp = createAsyncThunk(
  'contents/deleteApp',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.deleteApp(context, id)
)

export const highlightApp = createAsyncThunk(
  'contents/highlighApp',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.highlightApp(context, id)
)
export const unhighlightApp = createAsyncThunk(
  'contents/unhighlightApp',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.unhighlightApp(context, id)
)

export const loadAppPermissions = createAsyncThunk(
  'contents/loadAppPermissions',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.loadAppPermissions(context, id)
)

// websites
export const addWebsite = createAsyncThunk(
  'contents/addWebsite',
  async ({ context, form }: { context: ContentContext; form: WebsiteForm }) =>
    ContentsApi.createWebsite(context, form)
)
export const updateWebsite = createAsyncThunk(
  'contents/updateWebsite',
  async ({
    context,
    id,
    form,
  }: {
    context: ContentContext
    id: number
    form: WebsiteForm
  }) => ContentsApi.updateWebsite(context, id, form)
)
export const deleteWebsite = createAsyncThunk(
  'contents/deleteWebsite',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.deleteWebsite(context, id)
)
export const highlightWebsite = createAsyncThunk(
  'contents/highlightWebsite',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.highlightWebsite(context, id)
)
export const unhighlightWebsite = createAsyncThunk(
  'contents/unhighlightWebsite',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.unhighlightWebsite(context, id)
)
export const uploadWebsiteCustomIcon = createAsyncThunk(
  'contents/uploadWebsiteCustomIcon',
  async ({
    context,
    imageFile,
  }: {
    context: ContentContext
    imageFile: File
  }) => ContentsApi.uploadWebsiteCustomIcon(context, imageFile)
)

// files
export const addFile = createAsyncThunk(
  'contents/addFile',
  async ({ context, form }: { context: ContentContext; form: FileForm }) =>
    ContentsApi.createFile(context, form)
)
export const uploadFile = createAsyncThunk(
  'contents/uploadFile',
  async ({ context, file }: { context: ContentContext; file: File }) =>
    ContentsApi.uploadFile(context, file)
)
export const updateFile = createAsyncThunk(
  'contents/updateFile',
  async ({
    context,
    id,
    form,
  }: {
    context: ContentContext
    id: number
    form: FileForm
  }) => ContentsApi.updateFile(context, id, form)
)
export const deleteFile = createAsyncThunk(
  'contents/deleteFile',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.deleteFile(context, id)
)
export const highlightFile = createAsyncThunk(
  'contents/highlightFile',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.highlightFile(context, id)
)
export const unhighlightFile = createAsyncThunk(
  'contents/unhighlightFile',
  async ({ context, id }: { context: ContentContext; id: number }) =>
    ContentsApi.unhighlightFile(context, id)
)
export const uploadFileCustomIcon = createAsyncThunk(
  'contents/uploadFileCustomIcon',
  async ({
    context,
    imageFile,
  }: {
    context: ContentContext
    imageFile: File
  }) => ContentsApi.uploadFileCustomIcon(context, imageFile)
)

export const contentsSlice = createSlice({
  name: 'contents',
  initialState,
  reducers: {
    resetAddApp: (state) => {
      state.addedApp = false
      return state
    },
    resetUpdateApp: (state) => {
      state.updatingApp = false
      state.updatedApp = false
      return state
    },
    resetDeleteApp: (state) => {
      state.deletingApp = false
      state.deletedApp = false
      return state
    },
    resetHighlightApp: (state) => {
      state.highlightedApp = false
      return state
    },
    resetUnhighlightApp: (state) => {
      state.unhighlightedApp = false
      return state
    },

    resetAddWebsite: (state) => {
      state.addingWebsite = false
      state.addedWebsite = false
      return state
    },
    resetUpdateWebsite: (state) => {
      state.updatingWebsite = false
      state.updatedWebsite = false
      return state
    },
    resetDeleteWebsite: (state) => {
      state.deletingWebsite = false
      state.deletedWebsite = false
      return state
    },
    resetHighlightWebsite: (state) => {
      state.highlightingWebsite = false
      state.highlightedWebsite = false
      return state
    },
    resetUnhighlightWebsite: (state) => {
      state.unhighlightingWebsite = false
      state.unhighlightedWebsite = false
      return state
    },

    resetAddFile: (state) => {
      state.addingFile = false
      state.addedFile = false
      return state
    },
    resetUploadFile: (state) => {
      state.uploadingFile = false
      state.uploadedFile = false
      state.uploadedFilePath = null
      return state
    },
    resetUpdateFile: (state) => {
      state.updatingFile = false
      state.updatedFile = false
      return state
    },
    resetDeleteFile: (state) => {
      state.deletingFile = false
      state.deletedFile = false
      return state
    },
    resetHighlightFile: (state) => {
      state.highlightingFile = false
      state.highlightedFile = false
      return state
    },
    resetUnhighlightFile: (state) => {
      state.unhighlightingFile = false
      state.unhighlightedFile = false
      return state
    },
    resetUploadWebsiteCustomIcon: (state) => {
      state.uploadedWebsiteCustomIcon = false
      state.uploadingWebsiteCustomIcon = false
      state.uploadedWebsiteCustomIconUrl = null
      state.uploadWebsiteCustomIconError = false
      return state
    },
    resetUploadFileCustomIcon: (state) => {
      state.uploadedFileCustomIcon = false
      state.uploadingFileCustomIcon = false
      state.uploadedFileCustomIconUrl = null
      state.uploadFileCustomIconError = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContents.pending, (state) => {
      state.fetchingContents = true
      return state
    })
    builder.addCase(fetchContents.fulfilled, (state, { payload }) => {
      state.fetchingContents = false
      state.fetchedContents = true
      state.contents = payload
      return state
    })
    builder.addCase(fetchContents.rejected, (state) => {
      state.fetchingContents = false
      state.fetchedContents = false
      return state
    })
    builder.addCase(fetchGoogleToken.fulfilled, (state, { payload }) => {
      state.googleToken = payload
      return state
    })

    // apps
    builder.addCase(addApp.fulfilled, (state) => {
      state.addedApp = true
      return state
    })

    builder.addCase(updateApp.pending, (state) => {
      state.updatingApp = true
      return state
    })
    builder.addCase(updateApp.fulfilled, (state) => {
      state.updatingApp = false
      state.updatedApp = true
      return state
    })
    builder.addCase(updateApp.rejected, (state) => {
      state.updatingApp = false
      state.updatedApp = false
      return state
    })

    builder.addCase(deleteApp.pending, (state) => {
      state.deletingApp = true
      return state
    })
    builder.addCase(deleteApp.fulfilled, (state) => {
      state.deletingApp = false
      state.deletedApp = true
      return state
    })
    builder.addCase(deleteApp.rejected, (state) => {
      state.deletingApp = false
      state.deletedApp = false
      return state
    })
    builder.addCase(highlightApp.fulfilled, (state) => {
      state.highlightedApp = true
      return state
    })
    builder.addCase(unhighlightApp.fulfilled, (state) => {
      state.unhighlightedApp = true
      return state
    })

    // app permissions
    builder.addCase(loadAppPermissions.pending, (state) => {
      state.loadingAppPermissions = true
      return state
    })
    builder.addCase(loadAppPermissions.fulfilled, (state, { payload }) => {
      state.loadingAppPermissions = false
      state.appPermissions = payload
      return state
    })
    builder.addCase(loadAppPermissions.rejected, (state) => {
      state.loadingAppPermissions = false
      return state
    })

    // websites
    builder.addCase(addWebsite.pending, (state) => {
      state.addingWebsite = true
      return state
    })
    builder.addCase(addWebsite.fulfilled, (state) => {
      state.addingWebsite = false
      state.addedWebsite = true
      return state
    })
    builder.addCase(addWebsite.rejected, (state) => {
      state.addingWebsite = false
      state.addedWebsite = false
      return state
    })

    builder.addCase(updateWebsite.pending, (state) => {
      state.updatingWebsite = true
      return state
    })
    builder.addCase(updateWebsite.fulfilled, (state) => {
      state.updatingWebsite = false
      state.updatedWebsite = true
      return state
    })
    builder.addCase(updateWebsite.rejected, (state) => {
      state.updatingWebsite = false
      state.updatedWebsite = false
      return state
    })

    builder.addCase(deleteWebsite.pending, (state) => {
      state.deletingWebsite = true
      return state
    })
    builder.addCase(deleteWebsite.fulfilled, (state) => {
      state.deletingWebsite = false
      state.deletedWebsite = true
      return state
    })
    builder.addCase(deleteWebsite.rejected, (state) => {
      state.deletingWebsite = false
      state.deletedWebsite = false
      return state
    })

    builder.addCase(highlightWebsite.pending, (state) => {
      state.highlightingWebsite = true
      return state
    })
    builder.addCase(highlightWebsite.fulfilled, (state) => {
      state.highlightingWebsite = false
      state.highlightedWebsite = true
      return state
    })
    builder.addCase(highlightWebsite.rejected, (state) => {
      state.highlightingWebsite = false
      state.highlightedWebsite = false
      return state
    })

    builder.addCase(unhighlightWebsite.pending, (state) => {
      state.unhighlightingWebsite = true
      return state
    })
    builder.addCase(unhighlightWebsite.fulfilled, (state) => {
      state.unhighlightingWebsite = false
      state.unhighlightedWebsite = true
      return state
    })
    builder.addCase(unhighlightWebsite.rejected, (state) => {
      state.unhighlightingWebsite = false
      state.unhighlightedWebsite = false
      return state
    })
    builder.addCase(uploadWebsiteCustomIcon.pending, (state) => {
      state.uploadingWebsiteCustomIcon = true
      state.uploadedWebsiteCustomIcon = false
      state.uploadWebsiteCustomIconError = false
      return state
    })
    builder.addCase(uploadWebsiteCustomIcon.fulfilled, (state, { payload }) => {
      state.uploadingWebsiteCustomIcon = false
      state.uploadedWebsiteCustomIcon = true
      state.uploadedWebsiteCustomIconUrl = payload
      state.uploadWebsiteCustomIconError = false
      return state
    })
    builder.addCase(uploadWebsiteCustomIcon.rejected, (state) => {
      state.uploadingWebsiteCustomIcon = false
      state.uploadedWebsiteCustomIcon = false
      state.uploadedWebsiteCustomIconUrl = null
      state.uploadWebsiteCustomIconError = true
      return state
    })

    // files
    builder.addCase(addFile.pending, (state) => {
      state.addingFile = true
      return state
    })
    builder.addCase(addFile.fulfilled, (state) => {
      state.addingFile = false
      state.addedFile = true
      return state
    })
    builder.addCase(addFile.rejected, (state) => {
      state.addingFile = false
      state.addedFile = false
      return state
    })

    builder.addCase(uploadFile.pending, (state) => {
      state.uploadingFile = true
      return state
    })
    builder.addCase(uploadFile.fulfilled, (state, { payload }) => {
      state.uploadingFile = false
      state.uploadedFile = true
      state.uploadedFilePath = payload
      return state
    })
    builder.addCase(uploadFile.rejected, (state) => {
      state.uploadingFile = false
      state.uploadedFile = false
      return state
    })

    builder.addCase(updateFile.pending, (state) => {
      state.updatingFile = true
      return state
    })
    builder.addCase(updateFile.fulfilled, (state) => {
      state.updatingFile = false
      state.updatedFile = true
      return state
    })
    builder.addCase(updateFile.rejected, (state) => {
      state.updatingFile = false
      state.updatedFile = false
      return state
    })

    builder.addCase(deleteFile.pending, (state) => {
      state.deletingFile = true
      return state
    })
    builder.addCase(deleteFile.fulfilled, (state) => {
      state.deletingFile = false
      state.deletedFile = true
      return state
    })
    builder.addCase(deleteFile.rejected, (state) => {
      state.deletingFile = false
      state.deletedFile = false
      return state
    })

    builder.addCase(highlightFile.fulfilled, (state) => {
      state.highlightedFile = true
      state.highlightingFile = false
      return state
    })

    builder.addCase(unhighlightFile.fulfilled, (state) => {
      state.unhighlightedFile = true
      state.unhighlightingFile = false
      return state
    })

    builder.addCase(uploadFileCustomIcon.pending, (state) => {
      state.uploadingFileCustomIcon = true
      state.uploadedFileCustomIcon = false
      state.uploadFileCustomIconError = false
      return state
    })
    builder.addCase(uploadFileCustomIcon.fulfilled, (state, { payload }) => {
      state.uploadingFileCustomIcon = false
      state.uploadedFileCustomIcon = true
      state.uploadedFileCustomIconUrl = payload
      state.uploadFileCustomIconError = false
      return state
    })
    builder.addCase(uploadFileCustomIcon.rejected, (state) => {
      state.uploadingFileCustomIcon = false
      state.uploadedFileCustomIcon = false
      state.uploadedFileCustomIconUrl = null
      state.uploadFileCustomIconError = true
      return state
    })
  },
})

export const {
  resetAddApp,
  resetUpdateApp,
  resetDeleteApp,
  resetHighlightApp,
  resetUnhighlightApp,
  resetAddWebsite,
  resetUpdateWebsite,
  resetDeleteWebsite,
  resetHighlightWebsite,
  resetUnhighlightWebsite,
  resetUploadWebsiteCustomIcon,
  resetAddFile,
  resetUploadFile,
  resetUpdateFile,
  resetDeleteFile,
  resetHighlightFile,
  resetUnhighlightFile,
  resetUploadFileCustomIcon,
} = contentsSlice.actions

export default contentsSlice.reducer
