import rtkApi from 'rtk-api'

interface DistributorApi {
  distributorId: number
}

interface UpdateDistributorSettingsGeneralReq extends DistributorApi {
  name: string
  timezone?: string
}

interface UpdateDistributorSettingsSupportReq extends DistributorApi {
  supportWebsite?: string
  supportEmail?: string
}

const distributorSettingsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    updateDistributorSettingsGeneral: builder.mutation<
      void,
      UpdateDistributorSettingsGeneralReq
    >({
      query: ({ distributorId, ...body }) => ({
        url: `/distributors/${distributorId}/general`,
        method: 'PUT',
        body,
      }),
    }),
    updateDistributorSettingsSupport: builder.mutation<
      void,
      UpdateDistributorSettingsSupportReq
    >({
      query: ({ distributorId, ...body }) => ({
        url: `/distributors/${distributorId}/support`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useUpdateDistributorSettingsSupportMutation,
  useUpdateDistributorSettingsGeneralMutation,
} = distributorSettingsRtkApi

export default distributorSettingsRtkApi
