import React, { useEffect, useState } from 'react'
import useContents from 'common/hooks/useContents'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from '@mui/icons-material'
import {
  type Content,
  type App,
  type Website,
  type File,
  isApp,
  isWebsite,
} from 'App/Categories/categories-types'
import Avatar from 'common/components/Avatar'
import IconAction from 'common/components/IconAction'
import IconMenu from 'common/components/IconMenu'
import { useTranslation } from 'react-i18next'
import ButtonMenu from 'common/components/ButtonMenu'
import UploadIcon from '@mui/icons-material/BackupOutlined'
import LinkIcon from '@mui/icons-material/LinkOutlined'
import AppIcon from '@mui/icons-material/AndroidOutlined'
import ActionDialog from 'common/components/ActionDialog'
import AddWebsite from 'App/Categories/components/AddWebsite'
import {
  fetchContents,
  deleteWebsite as deleteWebsiteAction,
  resetDeleteWebsite,
  highlightWebsite,
  unhighlightWebsite,
  resetHighlightWebsite,
  resetUnhighlightWebsite,
  deleteApp as deleteAppAction,
  unhighlightApp,
  highlightApp,
  resetHighlightApp,
  resetUnhighlightApp,
  resetDeleteApp,
  resetDeleteFile,
  deleteFile as deleteFileAction,
  unhighlightFile,
  highlightFile,
  resetHighlightFile,
  resetUnhighlightFile,
} from 'App/Categories/contents-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import Toast from 'common/components/Toast'
import EditWebsite from 'App/Categories/components/EditWebsite'
import AddApp from 'App/Categories/components/AddApp'
import EditApp from 'App/Categories/components/EditApp'
import AddFile from 'App/Categories/components/AddFile'
import EditFile from 'App/Categories/components/EditFile'
import { useAppDispatch, useAppSelector } from 'store'

const contentType = (content: Content) =>
  isApp(content)
    ? 'contents.type.app'
    : isWebsite(content)
    ? 'contents.type.website'
    : 'contents.type.file'

const useStyles = makeStyles()((theme: Theme) => ({
  FilterRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    justifyContent: 'end',
  },
  ContentListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: theme.palette.hublet.common.white,
    borderRadius: '10px',
    lineHeight: '1.6',
  },
  ContentListItemIcon: {},
  ContentListItemBody: {
    flexGrow: 1,
  },
  ContentListItemBodyType: { fontSize: '12px' },
  ContentListItemBodyName: { fontSize: '16px', fontWeight: 'bold' },
  ContentListItemBodyDescription: { fontSize: '14px' },
  ContentListItemActions: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface ContentsListItemProps {
  content: Content
  onAddToHighlights: (content: Content) => void
  onEdit: (content: Content) => void
  onDelete: (content: Content) => void
}

const ContentsListItem = ({
  content,
  onAddToHighlights,
  onEdit,
  onDelete,
}: ContentsListItemProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Grid className={classes.ContentListItem}>
      <Grid className={classes.ContentListItemIcon}>
        <Avatar name={content.name} />
      </Grid>
      <Grid className={classes.ContentListItemBody}>
        <div className={classes.ContentListItemBodyType}>
          {t(contentType(content))}
        </div>
        <div className={classes.ContentListItemBodyName}>{content.name}</div>
        <div className={classes.ContentListItemBodyDescription}>
          {content.description}
        </div>
      </Grid>
      <Grid className={classes.ContentListItemActions}>
        <IconAction
          onClick={() => onAddToHighlights(content)}
          Icon={content.highlighted ? StarIcon : StarBorderIcon}
        />
        <IconMenu
          actions={[
            {
              icon: StarBorderIcon,
              local: content.highlighted
                ? 'contents.removeFromHighlightsButton'
                : 'contents.addToHighlightsButton',
              fn: () => onAddToHighlights(content),
            },
            {
              icon: EditIcon,
              local: 'contents.editButton',
              fn: () => onEdit(content),
            },
            {
              icon: DeleteIcon,
              local: 'contents.deleteButton',
              fn: () => onDelete(content),
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

interface ContentsListProps {
  profileId: number
  categoryId: number
}

const ContentsList = ({ profileId, categoryId }: ContentsListProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const { organizationId, siteId } = useCurrentAccount()
  const contents = useContents(profileId, categoryId)

  const addedApp = useAppSelector((state) => state.contents.addedApp)
  const deletedApp = useAppSelector((state) => state.contents.deletedApp)
  const highlightedApp = useAppSelector(
    (state) => state.contents.highlightedApp
  )
  const unhighlightedApp = useAppSelector(
    (state) => state.contents.unhighlightedApp
  )

  const deletedWebsite = useAppSelector(
    (state) => state.contents.deletedWebsite
  )
  const highlightedWebsite = useAppSelector(
    (state) => state.contents.highlightedWebsite
  )
  const unhighlightedWebsite = useAppSelector(
    (state) => state.contents.unhighlightedWebsite
  )

  const deletedFile = useAppSelector((state) => state.contents.deletedFile)
  const highlightedFile = useAppSelector(
    (state) => state.contents.highlightedFile
  )
  const unhighlightedFile = useAppSelector(
    (state) => state.contents.unhighlightedFile
  )

  useEffect(() => {
    if (deletedWebsite) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetDeleteWebsite())
    }

    if (deletedApp) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetDeleteApp())
    }

    if (deletedFile) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetDeleteFile())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deletedWebsite, deletedApp, deletedFile])

  useEffect(() => {
    if (highlightedWebsite) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetHighlightWebsite())
    }

    if (highlightedApp) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetHighlightApp())
    }

    if (highlightedFile) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetHighlightFile())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, highlightedWebsite, highlightedApp, highlightedFile])

  useEffect(() => {
    if (unhighlightedWebsite) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetUnhighlightWebsite())
    }

    if (unhighlightedApp) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetUnhighlightApp())
    }

    if (unhighlightedFile) {
      dispatch(fetchContents({ organizationId, siteId, profileId, categoryId }))
      dispatch(resetUnhighlightFile())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, unhighlightedWebsite, unhighlightedApp, unhighlightedFile])

  const [openAddApp, setOpenAddApp] = useState(false)
  const handleAddAppClose = () => setOpenAddApp(false)

  const [openAddWebsite, setOpenAddWebsite] = useState(false)
  const handleAddWebsiteClose = () => setOpenAddWebsite(false)

  const [openAddFile, setOpenAddFile] = useState(false)
  const handleAddFileClose = () => setOpenAddFile(false)

  const addActions = [
    {
      label: t('contents.add.app.button'),
      icon: AppIcon,
      onClick: () => setOpenAddApp(true),
    },
    {
      label: t('contents.add.website.button'),
      icon: LinkIcon,
      onClick: () => setOpenAddWebsite(true),
    },
    {
      label: t('contents.add.file.button'),
      icon: UploadIcon,
      onClick: () => setOpenAddFile(true),
    },
  ]

  const handleAddToHighlights = (content: Content) => {
    if (isApp(content)) {
      if (content.highlighted) {
        dispatch(
          unhighlightApp({
            context: { organizationId, siteId, profileId, categoryId },
            id: content.id ?? 0,
          })
        )
      } else {
        dispatch(
          highlightApp({
            context: { organizationId, siteId, profileId, categoryId },
            id: content.id ?? 0,
          })
        )
      }
    } else if (isWebsite(content)) {
      if (content.highlighted) {
        dispatch(
          unhighlightWebsite({
            context: { organizationId, siteId, profileId, categoryId },
            id: content.id ?? 0,
          })
        )
      } else {
        dispatch(
          highlightWebsite({
            context: { organizationId, siteId, profileId, categoryId },
            id: content.id ?? 0,
          })
        )
      }
    } else {
      if (content.highlighted) {
        dispatch(
          unhighlightFile({
            context: { organizationId, siteId, profileId, categoryId },
            id: content.id ?? 0,
          })
        )
      } else {
        dispatch(
          highlightFile({
            context: { organizationId, siteId, profileId, categoryId },
            id: content.id ?? 0,
          })
        )
      }
    }
  }

  const handleEdit = (content: Content) => {
    if (isApp(content)) setUpdateApp(content)
    else if (isWebsite(content)) setUpdateWebsite(content)
    else setUpdateFile(content as File)
  }

  const handleDelete = (content: Content) => {
    if (isApp(content)) setDeleteApp(content)
    else if (isWebsite(content)) setDeleteWebsite(content)
    else setDeleteFile(content as File)
  }

  // delete
  const [deleteApp, setDeleteApp] = useState<App | undefined>()
  const handleDeleteApp = (app: App) => {
    dispatch(
      deleteAppAction({
        context: { organizationId, siteId, profileId, categoryId },
        id: app.id ?? 0,
      })
    )
  }

  const [deleteWebsite, setDeleteWebsite] = useState<Website | undefined>()
  const handleDeleteWebsite = (website: Website) => {
    dispatch(
      deleteWebsiteAction({
        context: { organizationId, siteId, profileId, categoryId },
        id: website.id ?? 0,
      })
    )
  }

  const [deleteFile, setDeleteFile] = useState<File | undefined>()
  const handleDeleteFile = (file: File) => {
    dispatch(
      deleteFileAction({
        context: { organizationId, siteId, profileId, categoryId },
        id: file.id ?? 0,
      })
    )
  }

  // edit
  const [updateWebsite, setUpdateWebsite] = useState<Website | undefined>(
    undefined
  )
  const handleUpdateWebsiteClose = () => setUpdateWebsite(undefined)

  const [updateApp, setUpdateApp] = useState<App | undefined>(undefined)
  const handleUpdateAppClose = () => setUpdateApp(undefined)

  const [updateFile, setUpdateFile] = useState<File | undefined>(undefined)
  const handleUpdateFileClose = () => setUpdateFile(undefined)

  return (
    <>
      <Grid className={classes.FilterRow}>
        <ButtonMenu label={t('contents.addButton')} actions={addActions} />
      </Grid>
      {contents.map((content: Content, i) => (
        <ContentsListItem
          key={i}
          content={content}
          onAddToHighlights={handleAddToHighlights}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      ))}

      <AddApp
        open={openAddApp}
        onClose={handleAddAppClose}
        profileId={profileId}
        categoryId={categoryId}
      />
      <Toast open={addedApp} message={t('contents.add.app.toast.success')} />
      <Toast
        open={highlightedApp}
        message={t('contents.highlight.app.toast.success')}
      />
      <Toast
        open={unhighlightedApp}
        message={t('contents.unhighlight.app.toast.success')}
      />
      <Toast
        open={deletedApp}
        message={t('contents.delete.app.toast.success')}
      />

      {openAddWebsite && (
        <AddWebsite
          open={openAddWebsite}
          onClose={handleAddWebsiteClose}
          profileId={profileId}
          categoryId={categoryId}
        />
      )}
      {openAddFile && (
        <AddFile
          open={openAddFile}
          onClose={handleAddFileClose}
          profileId={profileId}
          categoryId={categoryId}
        />
      )}
      {updateWebsite !== undefined && (
        <EditWebsite
          open={updateWebsite !== undefined}
          onClose={handleUpdateWebsiteClose}
          profileId={profileId}
          categoryId={categoryId}
          website={updateWebsite}
        />
      )}
      {updateFile !== undefined && (
        <EditFile
          open={updateFile !== undefined}
          onClose={handleUpdateFileClose}
          profileId={profileId}
          categoryId={categoryId}
          file={updateFile}
        />
      )}
      {updateApp !== undefined && (
        <EditApp
          open={updateApp !== undefined}
          onClose={handleUpdateAppClose}
          profileId={profileId}
          categoryId={categoryId}
          app={updateApp}
        />
      )}
      {deleteApp != null && (
        <ActionDialog
          open={deleteApp !== undefined}
          title={t('contents.delete.app.title')}
          description={t('contents.delete.app.description', {
            app: deleteApp.name,
          })}
          actionText={t('contents.delete.app.action')}
          onAction={() => handleDeleteApp(deleteApp)}
          onClose={() => setDeleteApp(undefined)}
        />
      )}
      {deleteWebsite != null && (
        <ActionDialog
          open={deleteWebsite !== undefined}
          title={t('contents.delete.website.title')}
          description={t('contents.delete.website.description', {
            website: deleteWebsite.name,
          })}
          actionText={t('contents.delete.website.action')}
          onAction={() => handleDeleteWebsite(deleteWebsite)}
          onClose={() => setDeleteWebsite(undefined)}
        />
      )}
      {deleteFile != null && (
        <ActionDialog
          open={deleteFile !== undefined}
          title={t('contents.delete.file.title')}
          description={t('contents.delete.file.description', {
            file: deleteFile.name,
          })}
          actionText={t('contents.delete.file.action')}
          onAction={() => handleDeleteFile(deleteFile)}
          onClose={() => setDeleteFile(undefined)}
        />
      )}

      <Toast
        open={deletedWebsite}
        message={t('contents.delete.website.toast.success')}
      />
      <Toast
        open={highlightedWebsite}
        message={t('contents.highlight.website.toast.success')}
      />
      <Toast
        open={unhighlightedWebsite}
        message={t('contents.unhighlight.website.toast.success')}
      />

      <Toast
        open={deletedFile}
        message={t('contents.delete.file.toast.success')}
      />
      <Toast
        open={highlightedFile}
        message={t('contents.highlight.file.toast.success')}
      />
      <Toast
        open={unhighlightedFile}
        message={t('contents.unhighlight.file.toast.success')}
      />
    </>
  )
}

export default ContentsList
