import React, { useEffect, useState } from 'react'
import { Box, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import SelectLanguage from 'common/components/SelectLanguage'
import logo from 'common/hublet-manager-logo.svg'
import useLocationQuery from 'common/hooks/useLocationQuery'
import { useAppDispatch, useAppSelector } from 'store'
import {
  fetchForgotPasswordInfo,
  resetPassword,
} from 'App/ForgotPassword/forgot-password-state'
import FormField from 'common/components/FormField'

import LockIcon from '@mui/icons-material/LockOutlined'
import Button from 'common/components/Button'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

const useStyles = makeStyles()((theme: Theme) => ({
  ResetPasswordContainer: {
    minHeight: '100vh',
    margin: '0 auto',
    '& > div': {
      maxWidth: '324px !important',
      width: '100%',
    },
  },
  ResetPasswordLogo: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    lineHeight: '1',
    '& > img': {
      width: '152px',
      margin: '-4px',
    },
  },
  ResetPasswordTitle: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  ResetPasswordInfoText: {
    maxWidth: '100% !important',
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    fontSize: '16px',
  },
  ResetPasswordInput: {
    marginBottom: theme.spacing(2),
  },
  ResetPasswordGoBack: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  SelectLanguage: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  ResetPasswordField: {
    marginBottom: theme.spacing(2),
  },
}))

const ForgotPasswordReset = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()
  const query = useLocationQuery()

  const key = query.get('key')
  const forgotPasswordInfo = useAppSelector(
    (state) => state.forgotPassword.forgotPasswordInfo
  )
  const fetchedForgotPasswordInfo = useAppSelector(
    (state) => state.forgotPassword.fetchedForgotPasswordInfo
  )
  const resetedPassword = useAppSelector(
    (state) => state.forgotPassword.resetedPassword
  )

  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | undefined>()

  useEffect(() => {
    if (key !== null) {
      dispatch(fetchForgotPasswordInfo({ key }))
    }
  }, [dispatch, key])

  useEffect(() => {
    if (resetedPassword) {
      window.location.href = `/ui/login#${forgotPasswordInfo?.email ?? ''}`
    }
  }, [resetedPassword, forgotPasswordInfo])

  if (forgotPasswordInfo === null) {
    if (fetchedForgotPasswordInfo) {
      return <Navigate to="/ui/login" />
    }

    return null
  }

  if (key === null) {
    return <Navigate to="/ui/login" />
  }

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (password === '') {
      setError(t('forgotPassword.reset.errors.emptyPassword'))
      return
    }

    setError(undefined)
    dispatch(resetPassword({ key, password }))
  }

  return (
    <>
      <Box className={classes.SelectLanguage}>
        <SelectLanguage />
      </Box>
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={classes.ResetPasswordContainer}
        >
          <Grid item xs={12} className={classes.ResetPasswordLogo}>
            <img src={logo} alt="Hublet" />
          </Grid>
          <Grid item xs={12} className={classes.ResetPasswordTitle}>
            {t('forgotPassword.reset.title')}
          </Grid>
          <Grid item xs={12} className={classes.ResetPasswordInfoText}>
            <Trans
              i18nKey="forgotPassword.reset.info"
              t={t}
              values={{ email: forgotPasswordInfo.email }}
            />
          </Grid>
          <Grid item xs={12} className={classes.ResetPasswordField}>
            <FormField
              type="password"
              placeholder={t('forgotPassword.reset.placeholders.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              Icon={LockIcon}
              name="login-password"
              autoFocus
              error={error}
              rounded
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit">{t('forgotPassword.reset.button')}</Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ForgotPasswordReset
