import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import FormField from 'common/components/FormField'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Dialog from 'common/components/Dialog'
import Button from 'common/components/Button'
import {
  ProxyTlsVersion,
  type CommunicationChannel,
} from 'App/Integrations/integrations-types'
import {
  updateCommunicationChannel,
  fetchCommunicationChannels,
} from 'App/Integrations/integrations-state'
import FormSelect from 'common/components/FormSelect'
import FormSwitch from 'common/components/FormSwitch'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

interface Props {
  open: boolean
  setModalOpen: any
  channel: CommunicationChannel | null
}

const useStyles = makeStyles()((theme: Theme) => ({
  EditFormBodyEditable: {
    '& > div': {
      marginBottom: theme.spacing(4),
    },
  },
}))

const EditCommunicationChannelForm = ({
  open,
  setModalOpen,
  channel,
}: Props) => {
  const [proxyServerAddress, setProxyServerAddress] = useState<string>(
    channel?.proxyServerAddress ?? ''
  )
  const [proxyServerPort, setProxyServerPort] = useState<string>(
    channel?.proxyServerAddress ?? ''
  )
  const [proxyProtocol, setProxyProtocol] = useState<string>('tcp')
  const [proxyRootCert, setProxyRootCert] = useState<string>('')
  const [proxyClientCert, setProxyClientCert] = useState<string>('')
  const [proxyClientKey, setProxyClientKey] = useState<string>('')
  const [proxyPass, setProxyPass] = useState<boolean>(
    channel?.proxyPass ?? false
  )
  const [proxyTlsVersion, setProxyTlsVersion] = useState<ProxyTlsVersion | ''>(
    channel?.proxyTlsVersion ?? ''
  )
  const [number, setNumber] = useState<number>(0)
  const { classes } = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { organizationId, siteId } = useCurrentAccount()
  const updatedCommunicationChannel = useAppSelector(
    (state) => state.integrations.updatedCommunicationChannel
  )

  const proxyTlsVersionOptions = useMemo(() => {
    const options = Object.values(ProxyTlsVersion).map((value) => ({
      label: value,
      value,
    }))
    return options
  }, [])

  useEffect(() => {
    if (channel != null) {
      setProxyServerAddress(channel.proxyServerAddress)
      setProxyServerPort(channel.proxyServerPort?.toString() || '')
      setProxyProtocol(channel.proxyProtocol)
      setProxyRootCert(channel.proxyRootCertificate)
      setProxyClientCert(channel.proxyClientCertificate)
      setProxyClientKey(channel.proxyClientKey ?? '')
      setProxyPass(channel.proxyPass)
      setProxyTlsVersion(channel.proxyTlsVersion ?? '')
      setNumber(channel.number)
    }
  }, [channel])

  useEffect(() => {
    if (updatedCommunicationChannel) {
      setModalOpen(false)
      dispatch(
        fetchCommunicationChannels({
          organizationId,
          siteId,
          communicationConfigurationId:
            channel?.communicationConfigurationId ?? 0,
        })
      )
    }
  }, [
    updatedCommunicationChannel,
    dispatch,
    channel?.communicationConfigurationId,
    organizationId,
    setModalOpen,
    siteId,
  ])

  const submit = () => {
    const cc: CommunicationChannel = {
      id: channel?.id ?? 0,
      communicationConfigurationId: channel?.communicationConfigurationId ?? 0,
      proxyServerAddress: proxyServerAddress ?? '',
      proxyServerPort: parseInt(proxyServerPort),
      proxyPass,
      proxyRootCertificate: proxyRootCert,
      proxyClientCertificate: proxyClientCert,
      proxyClientKey,
      proxyProtocol,
      proxyTlsVersion: proxyTlsVersion === '' ? undefined : proxyTlsVersion,
      number,
      createdAt: undefined,
      updatedAt: undefined,
    }
    dispatch(
      updateCommunicationChannel({
        organizationId,
        siteId,
        communicationChannel: cc,
      })
    )
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setModalOpen(false)
        }}
        title={`${t('integrations.channels.editChannel.title')}: ${
          channel?.proxyServerAddress ?? '-'
        }`}
        actions={
          <Grid container>
            <Grid item xs={6}>
              <Button
                small
                outlined
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                {t('integrations.channels.action.close')}
              </Button>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Button small onClick={submit}>
                {t('integrations.channels.action.save')}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <div className={classes.EditFormBodyEditable}>
          <Grid container>
            <Grid item xs={7}>
              <FormField
                label={t(
                  'integrations.channels.form.fields.proxyServerAddress'
                )}
                placeholder={''}
                value={proxyServerAddress}
                onChange={(e) => {
                  setProxyServerAddress(e.target.value)
                }}
                autoFocus
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <FormField
                label={t('integrations.channels.form.fields.port')}
                placeholder={''}
                value={proxyServerPort}
                onChange={(e) => {
                  setProxyServerPort(e.target.value)
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormSelect
              label={t('integrations.channels.form.fields.protocol')}
              options={[
                {
                  label: t(
                    'integrations.channels.form.fields.protocol.labels.tcp'
                  ),
                  value: 'tcp',
                },
                {
                  label: t(
                    'integrations.channels.form.fields.protocol.labels.tls'
                  ),
                  value: 'tls',
                },
                {
                  label: t(
                    'integrations.channels.form.fields.protocol.labels.tls_insecure'
                  ),
                  value: 'tls_insecure',
                },
                {
                  label: t(
                    'integrations.channels.form.fields.protocol.labels.http'
                  ),
                  value: 'http',
                },
                {
                  label: t(
                    'integrations.channels.form.fields.protocol.labels.https'
                  ),
                  value: 'https',
                },
                {
                  label: t(
                    'integrations.channels.form.fields.protocol.labels.https_insecure'
                  ),
                  value: 'https_insecure',
                },
              ]}
              value={proxyProtocol}
              onChange={(e) => {
                setProxyProtocol(e.target.value)
              }}
            />
          </Grid>

          {['tls', 'https', 'tls_insecure', 'https_insecure'].includes(
            proxyProtocol
          ) && (
            <>
              <Grid item xs={12}>
                <FormField
                  label={t(
                    'integrations.channels.form.fields.proxyRootCertificate'
                  )}
                  placeholder=""
                  value={proxyRootCert}
                  onChange={(e) => {
                    setProxyRootCert(e.target.value)
                  }}
                  multiline
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label={t(
                    'integrations.channels.form.fields.proxyClientCertificate'
                  )}
                  placeholder=""
                  value={proxyClientCert}
                  onChange={(e) => {
                    setProxyClientCert(e.target.value)
                  }}
                  multiline
                />
              </Grid>

              <Grid item xs={12}>
                <FormField
                  label={t('integrations.channels.form.fields.proxyClientKey')}
                  placeholder=""
                  value={proxyClientKey}
                  onChange={(e) => {
                    setProxyClientKey(e.target.value)
                  }}
                  multiline
                />
              </Grid>
            </>
          )}

          {['tls', 'tls_insecure'].includes(proxyProtocol) && (
            <Grid item xs={12}>
              <FormSelect
                label={t('integrations.channels.form.fields.proxyTlsVersion')}
                options={proxyTlsVersionOptions}
                value={proxyTlsVersion}
                emptyLabel={t('common.values.useDefault')}
                onChange={(_, value) => {
                  setProxyTlsVersion(value as ProxyTlsVersion | '')
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormSwitch
              title={t('integrations.channels.form.fields.proxyPass')}
              checked={proxyPass}
              onChange={() => {
                setProxyPass(!proxyPass)
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormField
              label={t('integrations.channels.form.fields.number')}
              placeholder={''}
              value={number}
              disabled={true}
            />
          </Grid>
        </div>
      </Dialog>
    </>
  )
}

export default EditCommunicationChannelForm
