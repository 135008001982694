import { useEffect } from 'react'
import { fetchContents } from 'App/Categories/contents-state'
import { type Content } from 'App/Categories/categories-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

const useContents = (profileId: number, categoryId: number): Content[] => {
  const dispatch = useAppDispatch()

  const { siteId, organizationId } = useCurrentAccount()
  const contents = useAppSelector((state) => state.contents.contents)

  useEffect(() => {
    dispatch(fetchContents({ categoryId, profileId, siteId, organizationId }))
  }, [dispatch, categoryId, profileId, siteId, organizationId])

  return contents
}

export default useContents
