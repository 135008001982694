import { type Site, type SiteSettings } from 'App/Sites/sites-state'
import rtkApi from 'rtk-api'

interface SitesApi {
  organizationId: number
}
type SiteApi = SitesApi & { siteId: number }

interface UpdateSiteGeneralReq {
  name: string
  customerSectorId?: number
  street?: string
  street2?: string
  zipcode?: string
  city?: string
  country?: string
  tzdbTimezone?: string
}

interface UpdateSiteLoansReq {
  defaultLanguage?: string
  profileId?: number
  allowAuthenticateFromTablet: boolean
  enableReturnCode: boolean
  returnCode: string
}

const sitesRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getSites: builder.query<Site[], SitesApi>({
      query: ({ organizationId }) => `/organizations/${organizationId}/sites`,
      transformResponse: (data: { rows: Site[] }) => data.rows,
    }),
    getSiteSettings: builder.query<SiteSettings, SiteApi>({
      query: ({ organizationId, siteId }) =>
        `/organizations/${organizationId}/sites/${siteId}/settings`,
      transformResponse: (data: any) => ({
        ...data,
        factoryReset: !data.disableFactoryReset,
      }),
    }),
    updateSiteGeneral: builder.mutation<null, SiteApi & UpdateSiteGeneralReq>({
      query: ({ organizationId, siteId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/general`,
        method: 'PUT',
        body,
      }),
    }),
    updateSiteLoans: builder.mutation<null, SiteApi & UpdateSiteLoansReq>({
      query: ({ organizationId, siteId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/loans`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useGetSitesQuery,
  useUpdateSiteGeneralMutation,
  useUpdateSiteLoansMutation,
  useGetSiteSettingsQuery,
} = sitesRtkApi
export default sitesRtkApi
