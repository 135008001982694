import {
  type AddIntegrationData,
  type Integration,
  type IntegrationInputRule,
  type IntegrationParameter,
  type IntegrationProxyType,
  type IntegrationTemplate,
  type IntegrationTest,
  type VirtualKeyboard,
} from 'App/Integrations/integrations-types'
import rtkApi from 'rtk-api'

interface IntegrationsApi {
  organizationId: number
  siteId: number
}
type IntegrationApi = IntegrationsApi & { integrationId: number }
type IntegrationTestApi = IntegrationApi & { integrationTestId: number }
type IntegrationInputRuleApi = IntegrationApi & {
  integrationInputRuleId: number
}

interface PutIntegrationVirtualKeyboardsReq extends IntegrationApi {
  usernameKeyboard: VirtualKeyboard
  passwordKeyboard: VirtualKeyboard
}

const integrationsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrationTemplates: builder.query<
      IntegrationTemplate[],
      IntegrationsApi
    >({
      query: ({ organizationId, siteId }) =>
        `/organizations/${organizationId}/sites/${siteId}/integrations/templates`,
    }),
    getIntegrations: builder.query<Integration[], IntegrationsApi>({
      query: ({ organizationId, siteId }) => ({
        url:
          siteId > -1
            ? `/organizations/${organizationId}/sites/${siteId}/integrations`
            : `/organizations/${organizationId}/integrations`,
        method: 'GET',
      }),
    }),
    addIntegration: builder.mutation<
      null,
      IntegrationsApi & { data: AddIntegrationData }
    >({
      query: ({ organizationId, siteId, data }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations`,
        method: 'POST',
        body: data,
      }),
    }),
    updateIntegrationConfiguration: builder.mutation<
      null,
      IntegrationApi & { name: string; url: string; isEnabled: boolean }
    >({
      query: ({
        organizationId,
        siteId,
        integrationId,
        name,
        url,
        isEnabled,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/configuration`,
        method: 'PUT',
        body: { name, url, isEnabled },
      }),
    }),
    updateIntegrationDebugMode: builder.mutation<
      null,
      IntegrationApi & { debugMode: boolean; debugModeExpiration?: string }
    >({
      query: ({
        organizationId,
        siteId,
        integrationId,
        debugMode,
        debugModeExpiration,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/debug-mode`,
        method: 'PUT',
        body: { debugMode, debugModeExpiration },
      }),
    }),
    getIntegrationParameters: builder.query<
      IntegrationParameter[],
      IntegrationApi & { url: string }
    >({
      query: ({ organizationId, siteId, integrationId, url }) =>
        `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/params-for-integration/${btoa(
          url
        )}`,
    }),
    updateIntegrationParameters: builder.mutation<
      null,
      IntegrationApi & {
        params: Array<{ paramKey: string; paramValue: string }>
      }
    >({
      query: ({ organizationId, siteId, integrationId, params }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/params-for-integration`,
        method: 'PUT',
        body: { params },
      }),
    }),
    updateIntegrationCommunication: builder.mutation<
      null,
      IntegrationApi & { proxyType: IntegrationProxyType }
    >({
      query: ({ organizationId, siteId, integrationId, proxyType }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/communication`,
        method: 'PUT',
        body: { proxyType },
      }),
    }),
    getIntegrationTests: builder.query<IntegrationTest[], IntegrationApi>({
      query: ({ organizationId, siteId, integrationId }) =>
        `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/tests`,
    }),
    getAllIntegrationTests: builder.query<IntegrationTest[], IntegrationsApi>({
      query: ({ organizationId, siteId }) =>
        siteId > -1
          ? `/organizations/${organizationId}/sites/${siteId}/integrations/tests`
          : `/organizations/${organizationId}/integrations/tests`,
    }),
    addIntegrationTest: builder.mutation<
      IntegrationTest[],
      IntegrationApi & {
        username: string
        password?: string
        tabletSerialNumber?: string
        profileId?: number
        isValid: boolean
      }
    >({
      query: ({ organizationId, siteId, integrationId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/tests`,
        method: 'POST',
        body,
      }),
    }),
    updateIntegrationTest: builder.mutation<
      IntegrationTest[],
      IntegrationTestApi & {
        username: string
        password?: string
        tabletSerialNumber?: string
        profileId?: number
        isValid: boolean
      }
    >({
      query: ({
        organizationId,
        siteId,
        integrationId,
        integrationTestId,
        ...body
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/tests/${integrationTestId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteIntegrationTest: builder.mutation<null, IntegrationTestApi>({
      query: ({
        organizationId,
        siteId,
        integrationId,
        integrationTestId,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/tests/${integrationTestId}`,
        method: 'DELETE',
      }),
    }),
    integrationTestCanBorrow: builder.mutation<void, IntegrationTestApi>({
      query: ({
        organizationId,
        siteId,
        integrationId,
        integrationTestId,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/tests/${integrationTestId}/can-borrow`,
        method: 'POST',
      }),
    }),
    integrationTestDoBorrow: builder.mutation<void, IntegrationTestApi>({
      query: ({
        organizationId,
        siteId,
        integrationId,
        integrationTestId,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/tests/${integrationTestId}/do-borrow`,
        method: 'POST',
      }),
    }),
    integrationTestDoReturn: builder.mutation<string[], IntegrationTestApi>({
      query: ({
        organizationId,
        siteId,
        integrationId,
        integrationTestId,
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/tests/${integrationTestId}/do-return`,
        method: 'POST',
      }),
    }),
    getIntegrationInputRules: builder.query<
      IntegrationInputRule[],
      IntegrationApi
    >({
      query: ({ organizationId, siteId, integrationId }) =>
        `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/input-rules`,
    }),
    addIntegrationInputRule: builder.mutation<
      IntegrationInputRule[],
      IntegrationApi & {
        match: string
        replaceWith: string
      }
    >({
      query: ({ organizationId, siteId, integrationId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/input-rules`,
        method: 'POST',
        body,
      }),
    }),
    updateIntegrationInputRule: builder.mutation<
      IntegrationInputRule[],
      IntegrationInputRuleApi & {
        match: string
        replaceWith: string
      }
    >({
      query: ({
        organizationId,
        siteId,
        integrationId,
        integrationInputRuleId,
        ...body
      }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/input-rules/${integrationInputRuleId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteIntegrationInputRule: builder.mutation<null, IntegrationInputRuleApi>(
      {
        query: ({
          organizationId,
          siteId,
          integrationId,
          integrationInputRuleId,
        }) => ({
          url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/input-rules/${integrationInputRuleId}`,
          method: 'DELETE',
        }),
      }
    ),
    updateIntegrationVirtualKeyboards: builder.mutation<
      null,
      PutIntegrationVirtualKeyboardsReq
    >({
      query: ({ organizationId, siteId, integrationId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/integrations/${integrationId}/virtual-keyboards`,
        method: 'PUT',
        body,
      }),
    }),
    getPilotVersion: builder.query({
      query: () => ({
        url: '/pilot-version',
        method: 'GET',
      }),
    }),
    getPilotIntegrationVersion: builder.query({
      query: () => ({
        url: '/pilot-integration-version',
        method: 'GET',
      }),
    }),
    getVersion: builder.query({
      query: () => ({
        url: '/version',
        method: 'GET',
      }),
    }),
    getIntegrationVersion: builder.query({
      query: () => ({
        url: '../../../integrations/version',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetIntegrationTemplatesQuery,
  useGetIntegrationsQuery,
  useAddIntegrationMutation,
  useUpdateIntegrationConfigurationMutation,
  useUpdateIntegrationDebugModeMutation,
  useGetIntegrationParametersQuery,
  useUpdateIntegrationParametersMutation,
  useUpdateIntegrationCommunicationMutation,
  useGetIntegrationTestsQuery,
  useGetAllIntegrationTestsQuery,
  useAddIntegrationTestMutation,
  useUpdateIntegrationTestMutation,
  useDeleteIntegrationTestMutation,
  useIntegrationTestCanBorrowMutation,
  useIntegrationTestDoBorrowMutation,
  useIntegrationTestDoReturnMutation,
  useGetIntegrationInputRulesQuery,
  useAddIntegrationInputRuleMutation,
  useUpdateIntegrationInputRuleMutation,
  useDeleteIntegrationInputRuleMutation,
  useUpdateIntegrationVirtualKeyboardsMutation,
  useGetPilotVersionQuery,
  useGetPilotIntegrationVersionQuery,
  useGetVersionQuery,
  useGetIntegrationVersionQuery,
} = integrationsRtkApi

export default integrationsRtkApi
