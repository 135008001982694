import React from 'react'
import { useTranslation } from 'react-i18next'
import { type Profile } from 'App/Profiles/profiles-types'
import DrawerWithTabs from 'common/components/DrawerWithTabs'
import GeneralSettings from 'App/Profiles/ProfilesView/GeneralSettings'
import NetworkSettings from 'App/Profiles/ProfilesView/NetworkSettings'
import TabletsSettings from 'App/Profiles/ProfilesView/TabletsSettings'
import LoanSettings from 'App/Profiles/ProfilesView/LoanSettings'

interface EditProfileDrawerProps {
  profile?: Profile
  open: boolean
  onClose: () => void
  updatedProfile: boolean
}

const EditProfileDrawer = ({
  profile,
  open,
  onClose,
  updatedProfile,
}: EditProfileDrawerProps) => {
  const { t } = useTranslation()

  if (profile == null) {
    return null
  }

  const tabs = [
    {
      title: t('profiles.editProfile.tabs.general'),
      component: <GeneralSettings profile={profile} />,
    },
    {
      title: t('profiles.editProfile.tabs.networks'),
      component: <NetworkSettings profile={profile} />,
    },
    {
      title: t('profiles.editProfile.tabs.tablets'),
      component: <TabletsSettings profile={profile} />,
    },
    {
      title: t('profiles.editProfile.tabs.loans'),
      component: <LoanSettings profile={profile} />,
    },
  ]

  return (
    <DrawerWithTabs
      open={open}
      onClose={onClose}
      title={t('profiles.editProfile.title')}
      tabs={tabs}
    />
  )
}

export default EditProfileDrawer
