import React, { type PropsWithChildren } from 'react'
import { Box } from '@mui/material'

interface TabPanelProps extends PropsWithChildren {
  value: number
  index: number
  p?: number
  className?: string
}

const TabPanel = ({ children, value, index, p, className }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className={className}
    >
      {value === index && <Box p={p ?? 3}>{children}</Box>}
    </div>
  )
}

export default TabPanel
