import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Site,
  type SiteForm,
  type SiteSettings,
  type SiteSettingsForm,
} from 'App/Sites/sites-state'

const toModel = (remote: {
  id: number
  organizationId: number
  name: string
  customerSectorId: number
  info?: string
  addressStreet1: string
  addressStreet2: string
  addressZipcode: string
  addressCity: string
  addressRegion: string
  addressCountry: string
  numDocks: number
  numTablets: number
  googleEnterpriseId: null | string
  tzdbTimezone?: string
  defaultLanguage?: string
  profileId?: number
}): Site => ({
  id: remote.id,
  organizationId: remote.organizationId,
  name: remote.name,
  customerSectorId: remote.customerSectorId,
  info: remote.info,
  street: remote.addressStreet1,
  street2: remote.addressStreet2,
  zipcode: remote.addressZipcode,
  city: remote.addressCity,
  region: remote.addressRegion,
  country: remote.addressCountry,
  enrolled: remote.googleEnterpriseId !== null,
  googleEnterpriseId: remote.googleEnterpriseId,
  numDocks: remote.numDocks,
  numTablets: remote.numTablets,
  tzdbTimezone: remote.tzdbTimezone,
  defaultLanguage: remote.defaultLanguage,
  profileId: remote.profileId ?? undefined,
})

const toDTO = (form: SiteForm) => ({
  name: form.name,
  customerSectorId: form.customerSectorId,
  addressStreet1: form.street,
  addressStreet2: form.street2,
  addressZipcode: form.zipcode,
  addressCity: form.city,
  addressRegion: form.region,
  addressCountry: form.country,
  tzdbTimezone: form.tzdbTimezone,
  defaultLanguage: form.defaultLanguage,
  profileId: form.profileId ?? null,
})

const toSettingsModel = (remote: any): SiteSettings => ({
  securityChallenge: remote.lockPattern,
  unknownSource: remote.isUnknownSourceAllowed,
  debugMode: remote.isDebuggingModeAllowed,
  safeMode: remote.isSafeModeAllowed,
  factoryReset: !remote.disableFactoryReset,
  systemUpdatePolicy: remote.systemUpdatePolicy,
  runtimePermissionPolicy: remote.runtimePermissionPolicy,
  updateStartTime: remote.updateStartTime,
  updateEndTime: remote.updateEndTime,
  zone: remote.zone,
  factoryResetAccounts: remote.factoryResetAccounts,
  allowAuthenticateFromTablet: remote.allowAuthenticateFromTablet,
  enableReturnCode: remote.enableReturnCode,
  returnCode: remote.returnCode,
})

const toSettingsDTO = (form: SiteSettingsForm) => ({
  lockPattern: form.securityChallenge,
  isUnknownSourceAllowed: form.unknownSource,
  isDebuggingModeAllowed: form.debugMode,
  isSafeModeAllowed: form.safeMode,
  disableFactoryReset:
    form.factoryReset === undefined ? undefined : !form.factoryReset,
  systemUpdatePolicy: form.systemUpdatePolicy,
  runtimePermissionPolicy: form.runtimePermissionPolicy,
  updateStartTime: form.updateStartTime,
  updateEndTime: form.updateEndTime,
  zone: form.zone,
  factoryResetAccounts: form.factoryResetAccounts,
})

class SitesApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchSites(organizationId: number): Promise<Site[]> {
    const { data } = await axios.get(
      `${API_BASE_URL}/organizations/${organizationId}/sites`
    )
    return data?.rows.map(toModel) ?? []
  }

  static async fetchSite(
    organizationId: number,
    siteId: number
  ): Promise<Site> {
    const { data } = await axios.get(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}`
    )
    return toModel(data)
  }

  static async createSite(
    organizationId: number,
    form: SiteForm
  ): Promise<Site> {
    const { data } = await axios.post(
      `${API_BASE_URL}/organizations/${organizationId}/sites`,
      toDTO(form)
    )
    return toModel(data)
  }

  static async updateSite(
    siteId: number,
    organizationId: number,
    form: SiteForm
  ): Promise<Site> {
    const { data } = await axios.put(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}`,
      toDTO(form)
    )
    return toModel(data)
  }

  static async deleteSite(
    siteId: number,
    organizationId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}`
    )
  }

  static async fetchSiteSettings(
    organizationId: number,
    siteId: number
  ): Promise<SiteSettings> {
    const { data } = await axios.get(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/settings`
    )
    return toSettingsModel(data)
  }

  static async updateSiteSettings(
    siteId: number,
    organizationId: number,
    form: SiteSettingsForm
  ): Promise<SiteSettings> {
    const { data } = await axios.put(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/settings`,
      toSettingsDTO(form)
    )
    return toSettingsModel(data)
  }
}

export default SitesApi
