import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  type Code,
  type CodeEditable,
  type CodesContext,
} from 'App/Codes/codes-types'
import CodesApi from 'api/codes-api'

interface CodesState {
  codes: Code[]
  fetchingCodes: boolean
  fetchedCodes: boolean
  addedCode: boolean
  showCode?: string
  deletedCode: boolean
}

const initialState: CodesState = {
  codes: [],
  fetchingCodes: false,
  fetchedCodes: false,
  addedCode: false,
  deletedCode: false,
}

export const fetchCodes = createAsyncThunk(
  'codes/fetchCodes',
  async (context: CodesContext) => CodesApi.fetchCodes(context)
)

export const addCode = createAsyncThunk(
  'codes/addCode',
  async (props: { context: CodesContext; data: CodeEditable }) =>
    CodesApi.createCode(props.context, props.data)
)

export const deleteCode = createAsyncThunk(
  'profiles/deleteCode',
  async (props: { context: CodesContext; codeId: number }) =>
    CodesApi.deleteCode(props.context, props.codeId)
)

export const codesSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    resetAddCode: (state) => {
      state.addedCode = false
      return state
    },
    resetDeleteCode: (state) => {
      state.deletedCode = false
      return state
    },
    resetShowCode: (state) => {
      state.showCode = undefined
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCodes.pending, (state) => {
      state.fetchingCodes = true
      return state
    })

    builder.addCase(fetchCodes.fulfilled, (state, { payload }) => {
      state.codes = payload
      state.fetchingCodes = false
      state.fetchedCodes = true
      return state
    })

    builder.addCase(addCode.fulfilled, (state, { payload }) => {
      state.addedCode = true
      state.showCode = payload.code
      return state
    })

    builder.addCase(deleteCode.fulfilled, (state) => {
      state.deletedCode = true
      return state
    })
  },
})

export const { resetAddCode, resetDeleteCode, resetShowCode } =
  codesSlice.actions

export default codesSlice.reducer
