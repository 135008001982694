import React from 'react'
import { type Code } from 'App/Codes/codes-types'
import Drawer from 'common/components/Drawer'
import Panel from 'common/components/Panel'
import PanelField from 'common/components/PanelField'
import { useTranslation } from 'react-i18next'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import useRenderDate from 'common/hooks/useRenderDate'

interface ViewCodeDrawerProps {
  code: Code
  open: boolean
  onClose: () => void
}

const ViewCodeDrawer = ({ code, open, onClose }: ViewCodeDrawerProps) => {
  const { t } = useTranslation()
  const renderDate = useRenderDate()

  return (
    <Drawer title={t('codes.codeDetails.title')} open={open} onClose={onClose}>
      <Panel title={t('codes.codeDetails.title')}>
        <PanelField
          title={t('codes.codeDetails.fields.name')}
          value={code.name}
        />
        <PanelField
          title={t('codes.codeDetails.fields.code')}
          value={code.code}
        />
        <PanelField
          title={t('codes.codeDetails.fields.createdAt')}
          value={renderDate(code.createdAt)}
        />
        <PanelField
          title={t('codes.codeDetails.fields.profile')}
          value={code.profileName ?? t('common.values.useDefault')}
        />
        <PanelField
          title={t('codes.codeDetails.fields.permissionType')}
          value={t(
            `codes.addCode.permissionTypeField.options.${code.permissionType}`
          )}
        />
        <PanelField
          title={t('codes.codeDetails.fields.validUntil')}
          value={<DateWithRelativeTime datetime={code.expiration} />}
        />
        <PanelField
          title={t('codes.codeDetails.fields.useCount')}
          value={code.useCount}
        />
      </Panel>
    </Drawer>
  )
}

export default ViewCodeDrawer
