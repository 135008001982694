import React, { useCallback, useContext, useEffect } from 'react'
import { Divider } from '@mui/material'
import { type Dock } from 'App/Docks/docks-types'
import PanelField from 'common/components/PanelField'
import Panel from 'common/components/NewPanel'
import Button from 'common/components/Button'
import {
  setDockToDelete,
  setDockToUndelete,
  setToastMessage,
} from 'App/Docks/docks-state'
import { useTranslation } from 'react-i18next'
import {
  useRebootDocksMutation,
  useShutdownDocksMutation,
  useUpgradeDocksMutation,
} from '../docks-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import DocksReactContext from '../docks-react-context'
import { useAppDispatch } from 'store'
import useApp from 'common/hooks/useApp'

interface DockActionsProps {
  dock: Dock
}

const DockActions = ({ dock }: DockActionsProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { organizationId, siteId } = useCurrentAccount()
  const context = useContext(DocksReactContext)

  const { adminMode } = useApp()

  const [rebootDocks, { isSuccess: rebootSuccess, isError: rebootError }] =
    useRebootDocksMutation()
  const [
    shutdownDocks,
    { isSuccess: shutdownSuccess, isError: shutdownError },
  ] = useShutdownDocksMutation()
  const [upgradeDocks, { isSuccess: upgradeSuccess, isError: upgradeError }] =
    useUpgradeDocksMutation()

  const showToast = useCallback(
    (key: string) => dispatch(setToastMessage(t(key))),
    [dispatch, t]
  )

  useEffect(() => {
    if (rebootSuccess) {
      showToast('docks.details.actions.fields.reboot.success')
    } else if (rebootError) {
      showToast('docks.details.actions.fields.reboot.error')
    }
  }, [rebootSuccess, rebootError, showToast])

  useEffect(() => {
    if (shutdownSuccess) {
      showToast('docks.details.actions.fields.shutdown.success')
    } else if (shutdownError) {
      showToast('docks.details.actions.fields.shutdown.error')
    }
  }, [shutdownSuccess, shutdownError, showToast])

  useEffect(() => {
    if (upgradeSuccess) {
      showToast('docks.details.actions.fields.upgrade.success')
    } else if (upgradeError) {
      showToast('docks.details.actions.fields.upgrade.error')
    }
  }, [upgradeSuccess, upgradeError, showToast])

  const deleteDockButton = (
    <Button small flat negative onClick={() => dispatch(setDockToDelete(dock))}>
      {t('common.actions.delete')}
    </Button>
  )

  const undeleteDockButton = (
    <Button
      small
      flat
      negative
      onClick={() => dispatch(setDockToUndelete(dock))}
    >
      {t('common.actions.undelete')}
    </Button>
  )

  const reboot = async () =>
    rebootDocks({ context, organizationId, siteId, dockIds: [dock.id] })
  const shutdown = async () =>
    shutdownDocks({ context, organizationId, siteId, dockIds: [dock.id] })
  const upgrade = async () =>
    upgradeDocks({ context, organizationId, siteId, dockIds: [dock.id] })

  return (
    <Panel title={t('docks.details.actions.actions.title')}>
      <PanelField
        title={t('docks.details.actions.fields.reboot.name')}
        value={
          <Button
            small
            flat
            onClick={() => {
              reboot()
            }}
          >
            {t('docks.details.actions.fields.reboot.button')}
          </Button>
        }
      />
      <Divider light />
      <PanelField
        title={t('docks.details.actions.fields.shutdown.name')}
        value={
          <Button
            small
            flat
            onClick={() => {
              shutdown()
            }}
          >
            {t('docks.details.actions.fields.shutdown.button')}
          </Button>
        }
      />
      {adminMode && (
        <>
          <Divider light />
          <PanelField
            title={t('docks.details.actions.fields.upgrade.name')}
            value={
              <Button
                small
                flat
                onClick={() => {
                  upgrade()
                }}
              >
                {t('docks.details.actions.fields.upgrade.button')}
              </Button>
            }
          />
          <Divider light />
          {dock.deletedAt === undefined && (
            <PanelField
              title={t('docks.details.actions.fields.delete.label')}
              value={deleteDockButton}
            />
          )}
          {dock.deletedAt !== undefined && (
            <PanelField
              title={t('docks.details.actions.fields.undelete.label')}
              value={undeleteDockButton}
            />
          )}
        </>
      )}
    </Panel>
  )
}

export default DockActions
