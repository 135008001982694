import { type ContextEntity, type UserRole } from 'App/app-state'
import { useAppSelector } from 'store'

interface App {
  role: UserRole
  organizationDistributorId?: number
  organization: ContextEntity
  site?: ContextEntity
  adminMode: boolean
}

const useApp = (): App => {
  const role = useAppSelector((state) => state.app.role)
  const organizationDistributorId = useAppSelector(
    (state) => state.app.organizationDistributorId
  )
  const organization = useAppSelector((state) => state.app.organization)
  const site = useAppSelector((state) => state.app.site)
  const adminMode = useAppSelector((state) => state.app.adminMode)

  if (role == null || organization == null) {
    throw new Error('role and organization should not be undefined')
  }

  return {
    role,
    organizationDistributorId,
    organization,
    site,
    adminMode,
  }
}

export default useApp
