import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UsersApi from 'api/users-api'

export interface LoginForm {
  username: string
  password: string
}

interface LoginState {
  postingLogin: boolean
  postedLogin: boolean
  postLoginError?: string
}

const initialState: LoginState = {
  postingLogin: false,
  postedLogin: false,
  postLoginError: undefined,
}

export interface PostLoginResponse {
  isSuccess: boolean
  token?: string
  error?: string
}

export const postLogin = createAsyncThunk(
  'login/postLogin',
  async (form: LoginForm) => UsersApi.login(form)
)

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.postingLogin = false
      state.postedLogin = false
      state.postLoginError = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postLogin.pending, (state) => {
      state.postingLogin = true
      return state
    })

    builder.addCase(postLogin.fulfilled, (state, { payload }) => {
      state.postingLogin = false
      state.postedLogin = true
      state.postLoginError = payload.error
      return state
    })

    builder.addCase(postLogin.rejected, (state) => {
      state.postingLogin = false
      state.postedLogin = true
      state.postLoginError = 'invalidCredentials'
      return state
    })
  },
})

export const { resetLogin } = loginSlice.actions

export default loginSlice.reducer
