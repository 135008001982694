import React, { useContext, useEffect, useMemo, useState } from 'react'
import { type Dock } from 'App/Docks/docks-types'
import Panel, { EditState } from 'common/components/NewPanel'
import PanelField from 'common/components/PanelField'
import useProfiles from 'common/hooks/useProfiles'
import FormSelect, { type FormSelectOption } from 'common/components/FormSelect'
import { useUpdateDockLoansMutation } from 'App/Docks/docks-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import DocksReactContext from 'App/Docks/docks-react-context'
import { useTranslation } from 'react-i18next'
import useApiErrors from 'common/hooks/useApiErrors'

interface DockLoansProps {
  dock: Dock
}

const DockLoans = ({ dock }: DockLoansProps) => {
  const { t } = useTranslation()

  const [editState, setEditState] = useState<EditState>(EditState.Default)
  const profiles = useProfiles()
  const context = useContext(DocksReactContext)
  const { organizationId, siteId } = useCurrentAccount()

  const [dockProfileId, setDockProfileId] = useState(dock.profileId ?? -1)

  const [updateDockLoans, { isSuccess: isUpdateSuccess, error: updateError }] =
    useUpdateDockLoansMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      setEditState(EditState.Success)
    }
  }, [isUpdateSuccess, hasApiMutationErrors])

  const dockProfile = useMemo(() => {
    return profiles.find((p) => p.id === dockProfileId)
  }, [dockProfileId, profiles])

  const dockProfileOptions = useMemo((): FormSelectOption[] => {
    const opts = profiles.map((p) => ({
      label: p.name,
      value: p.id,
    }))
    return [
      { label: t('docks.details.loans.values.default'), value: -1 },
      ...opts,
    ]
  }, [t, profiles])

  const info = useMemo(
    () => ({
      profile: {
        description: t('docks.details.loans.profile.info.description'),
      },
    }),
    [t]
  )

  const renderEditMode = () => (
    <FormSelect
      label="Profile"
      options={dockProfileOptions}
      value={dockProfileId}
      onChange={(_e, v) => setDockProfileId(v as number)}
      disabled={editState === EditState.Pending}
      info={info.profile}
    />
  )

  const handleSave = () => {
    setEditState(EditState.Pending)

    updateDockLoans({
      organizationId,
      siteId,
      dockId: dock.id,
      context,
      profileId: dockProfileId === -1 ? undefined : dockProfileId,
    })
  }

  if (context === 'manufacturer') return null

  return (
    <Panel
      title={t('docks.details.loans.title')}
      editable
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(EditState.Default)}
      onSave={handleSave}
      onSuccess={() => setEditState(EditState.Success)}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      <PanelField
        title={t('docks.details.loans.fields.profile.label')}
        value={dockProfile?.name ?? t('docks.details.loans.values.default')}
      />
    </Panel>
  )
}

export default DockLoans
