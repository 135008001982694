import React from 'react'

import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  TooltipInfo: {
    '& h4': {
      margin: 0,
      fontSize: 14,
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: 'normal',
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
    '& p': {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
}))

export interface TooltipInfoContent {
  description?: React.ReactNode
  example?: React.ReactNode
}

export interface TooltipInfoProps {
  content: TooltipInfoContent
}

const TooltipInfo = ({ content }: TooltipInfoProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { description, example } = content

  return (
    <div className={classes.TooltipInfo}>
      {description && (
        <>
          <h4>{t('common.tooltipInfo.description')}</h4>
          <p>{description}</p>
        </>
      )}
      {example && (
        <>
          <h4>{t('common.tooltipInfo.example')}</h4>
          <p>{example}</p>
        </>
      )}
    </div>
  )
}

export default TooltipInfo
