import React, { useEffect, useMemo, useState } from 'react'
import {
  type Tablet,
  type TabletLogStream,
  type TabletLogStreamEntry,
} from 'App/Tablets/tablets-types'
import { useLazyGetTabletLogsQuery } from 'App/Tablets/tablets-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { CircularProgress, type Theme } from '@mui/material'
import Panel from 'common/components/NewPanel'
import Button from 'common/components/Button'
import { makeStyles } from 'tss-react/mui'
import { formatISO, isValid } from 'date-fns'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  TabletLogStream: {
    margin: '10px 0 4px',
  },
  TabletLogRow: {
    fontFamily: 'Courier New',
    fontSize: '10pt',
    wordBreak: 'break-all',
    '& > p': {
      margin: 0,
    },
  },
  TabletLogMeta: {
    backgroundColor: '#f0f0f0',
    marginRight: theme.spacing(1),
  },
}))

interface TabletLogsProps {
  tablet: Tablet
}

const TabletLogs = ({ tablet }: TabletLogsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { organizationId, siteId } = useCurrentAccount()
  const [fetchLogs, { data, isFetching, isUninitialized }] =
    useLazyGetTabletLogsQuery()

  const [tabletLogStream, setTabletLogStream] = useState<TabletLogStream>([])

  const fetchLogsReq = useMemo(
    () => ({
      organizationId,
      siteId,
      tabletId: tablet.id,
      count: 32,
    }),
    [organizationId, siteId, tablet.id]
  )

  useEffect(() => {
    if (!isFetching && isUninitialized) {
      fetchLogs(fetchLogsReq)
    }
  }, [fetchLogs, isFetching, isUninitialized, fetchLogsReq])

  useEffect(() => {
    if (!Array.isArray(data)) {
      return
    }

    setTabletLogStream((prevTabletLogStream) => {
      let updatedLogStream = prevTabletLogStream.concat(data)

      const updatedLogStreamKeys = updatedLogStream.map((entry) => entry.key)

      // Ensure there no duplicates
      updatedLogStream = updatedLogStream.filter(
        (entry, i) => updatedLogStreamKeys.indexOf(entry.key) === i
      )

      // Ensure they are in the correct order
      updatedLogStream.sort((a, b) => {
        if (a.key < b.key) {
          return -1
        } else if (a.key > b.key) {
          return 1
        } else {
          return 0
        }
      })

      return updatedLogStream
    })
  }, [data])

  const start = useMemo(() => {
    if (tabletLogStream.length === 0) {
      return undefined
    } else {
      return tabletLogStream[tabletLogStream.length - 1].key
    }
  }, [tabletLogStream])

  const end = useMemo(() => {
    if (tabletLogStream.length === 0) {
      return undefined
    } else {
      return tabletLogStream[0].key
    }
  }, [tabletLogStream])

  return (
    <Panel title={t('tablets.viewTablet.logs.title')}>
      {isFetching && (
        <CircularProgress style={{ display: 'block', margin: 'auto' }} />
      )}
      <Button
        extraSmall
        textOnly
        onClick={() => {
          fetchLogs({ ...fetchLogsReq, end })
        }}
      >
        {t('tablets.viewTablet.logs.fetchOlder')}
      </Button>
      <div className={classes.TabletLogStream}>
        {tabletLogStream.map(({ log }: TabletLogStreamEntry, i: number) => {
          const logTimestamp = Number(log.timestamp ?? '-')
          if (isNaN(logTimestamp)) {
            console.error(`tabletLogStream: Invalid timestamp ${logTimestamp}`)
            return null
          }
          const d = new Date(logTimestamp)
          if (!isValid(d)) {
            console.error('tabletLogStream: Invalid date')
            return null
          }
          return (
            <div key={i} className={classes.TabletLogRow}>
              <span className={classes.TabletLogMeta}>{formatISO(d)}</span>
              <span>{log.message}</span>
            </div>
          )
        })}
      </div>
      <Button
        extraSmall
        textOnly
        onClick={() => {
          fetchLogs({ ...fetchLogsReq, start })
        }}
      >
        {t('tablets.viewTablet.logs.fetchNewer')}
      </Button>
    </Panel>
  )
}

export default TabletLogs
