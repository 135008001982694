import React, { type ChangeEventHandler, type RefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { resetUploadFile, uploadFile } from 'App/Categories/contents-state'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import Dialog from 'common/components/Dialog'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  Buttons: {
    display: 'flex',
    justifyContent: 'end',
    '& > *': { marginLeft: theme.spacing(3) },
  },
  UploadField: {
    border: '1px dashed #6C2576',
    boxSizing: 'border-box',
    borderRadius: '10px',
    textAlign: 'center',
    padding: theme.spacing(4),
    background: theme.palette.hublet.common.white,
    marginBottom: theme.spacing(3),
  },
}))

interface UploadFileProps {
  open: boolean
  onClose: () => void
  profileId: number
  categoryId: number
}

const UploadFile = ({
  open,
  onClose,
  profileId,
  categoryId,
}: UploadFileProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const dispatch = useAppDispatch()

  const { organizationId, siteId } = useCurrentAccount()

  const fileInput: RefObject<HTMLInputElement> = useRef(null)
  const onClickUpload = () => fileInput.current?.click()
  const onChangeInputFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files

    if (files === null || files.length === 0) {
      return
    }

    handleUpload(files[0])
  }

  const uploadedFile = useAppSelector((state) => state.contents.uploadedFile)
  const uploadedFilePath = useAppSelector(
    (state) => state.contents.uploadedFilePath
  )

  const handleUpload = (file: File) => {
    const context = { organizationId, siteId, profileId, categoryId }
    dispatch(uploadFile({ context, file }))
  }

  const handleClose = () => {
    dispatch(resetUploadFile())
    onClose()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title={t('contents.add.file.title')}
      >
        <Grid className={classes.UploadField}>
          {uploadedFilePath ?? t('contents.add.file.dragAndDrop')}
          <input
            onChange={onChangeInputFile}
            ref={fileInput}
            type="file"
            // accept="image/png, image/jpeg"
            hidden
          />
        </Grid>

        <Grid className={classes.Buttons}>
          <Button small outlined onClick={handleClose}>
            {t('contents.cancelButton')}
          </Button>
          <Button small onClick={onClickUpload}>
            {t('contents.add.file.uploadButton')}
          </Button>
        </Grid>
      </Dialog>

      <Toast
        open={uploadedFile}
        message={t('contents.add.file.toast.uploadSuccess')}
      />
    </>
  )
}

export default UploadFile
