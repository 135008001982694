import React, { useEffect, useState } from 'react'

import FormField from 'common/components/FormField'
import Panel from 'common/components/Panel'
import Switch from 'common/components/Switch'

import { type CustomerForm } from 'App/Customers/customers-types'
import { FormControlLabel, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  addCustomer,
  addCustomerAsAdmin,
  fetchCustomers,
  resetAddCustomer,
} from 'App/Customers/customers-state'
import { renderIf } from 'common/utils/render-utils'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import useApp from 'common/hooks/useApp'
import { useAppDispatch } from 'store'

interface AddCustomerDrawerProps {
  distributorId: number
  open: boolean
  onClose: () => void
  addedCustomer: boolean
}

interface FieldErrors {
  name?: string
}

const initialCustomerForm = {
  name: '',
  distributorId: 0,
  isDistributor: false,
  isManufacturer: false,
}

const useStyles = makeStyles()((theme: Theme) => ({
  ManufacturerFlag: {
    marginTop: theme.spacing(4),
    '& > div': {
      marginBottom: theme.spacing(4),
    },
    '& > div:last-child': {
      marginBottom: '0',
    },
  },
  ManufacturerFlagDescription: {
    marginBottom: theme.spacing(2),
  },
}))

const AddCustomerDrawer = ({
  distributorId,
  open,
  onClose,
  addedCustomer,
}: AddCustomerDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { adminMode } = useApp()

  const { classes } = useStyles()

  const [customerForm, setCustomerForm] =
    useState<CustomerForm>(initialCustomerForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  useEffect(() => {
    if (addedCustomer) {
      onClose()
      setCustomerForm(initialCustomerForm)
      dispatch(resetAddCustomer())
      dispatch(fetchCustomers(distributorId))
    }
  }, [dispatch, onClose, addedCustomer, distributorId])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomerForm({ ...customerForm, [key]: e.target.value })
    }

  const onChangeSwitch =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomerForm({ ...customerForm, [key]: e.target.checked })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    let hasError = false
    const errors: FieldErrors = {}

    if (customerForm.name === '') {
      hasError = true
      errors.name = t('customers.addCustomer.errors.emptyField')
    }

    if (!hasError) {
      if (adminMode) {
        dispatch(addCustomerAsAdmin({ distributorId, form: customerForm }))
      } else {
        dispatch(addCustomer({ distributorId, form: customerForm }))
      }
    }

    setFieldErrors(errors)
  }

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('customers.addCustomer.title')}
      submitText={t('customers.addCustomer.buttons.submit')}
    >
      <FormField
        label={t('customers.addCustomer.customerNameField.label')}
        placeholder={t('customers.addCustomer.customerNameField.placeholder')}
        value={customerForm.name}
        onChange={onChangeField('name')}
        autoFocus
        error={fieldErrors.name}
      />
      <>
        {renderIf(adminMode, () => (
          <div className={classes.ManufacturerFlag}>
            <Panel title={t('distributors.manufacturerFlag.title')}>
              <div className={classes.ManufacturerFlagDescription}>
                {t('distributors.manufacturerFlag.description')}
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={customerForm.isManufacturer}
                    onChange={onChangeSwitch('isManufacturer')}
                  />
                }
                label={t('distributors.manufacturerFlag.enable')}
              />
            </Panel>
          </div>
        ))}
      </>
    </DrawerForm>
  )
}

export default AddCustomerDrawer
