import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'

const useStyles = makeStyles()(() => ({
  PageEmpty: {
    textAlign: 'center',
  },
  ActionButton: {
    marginTop: '10px',
  },
}))

interface PageEmptyProps {
  title: string
  description: string
  actionText: string
  onClickAction: React.MouseEventHandler<HTMLElement>
}

const PageEmpty = ({
  title,
  description,
  actionText,
  onClickAction,
}: PageEmptyProps) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.PageEmpty}>
      <h1>{title}</h1>
      <p>{description}</p>
      <Button className={classes.ActionButton} onClick={onClickAction} small>
        {actionText}
      </Button>
    </Box>
  )
}

export default PageEmpty
