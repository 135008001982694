import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Content,
  type ContentContext,
  type App,
  type AppForm,
  type Website,
  type WebsiteForm,
  type File as ContentFile,
  type FileForm,
  type AppPermission,
} from 'App/Categories/categories-types'

class ContentsApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchContents(context: ContentContext): Promise<Content[]> {
    const { data: appData } = await axios.get(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/apps`
    )
    const { data: websiteData } = await axios.get(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/websites`
    )
    const { data: fileData } = await axios.get(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/files`
    )

    const contents = [
      ...(appData as App[]),
      ...(websiteData as Website[]),
      ...(fileData as ContentFile[]),
    ]
      .sort((a: Content, b: Content) => a.name.localeCompare(b.name))
      .sort((a: Content, b: Content) =>
        a.highlighted === b.highlighted ? 0 : a.highlighted ? -1 : 1
      )

    return contents
  }

  static async fetchGoogleToken(context: {
    organizationId: number
    siteId: number
  }): Promise<string> {
    const apiUrl = `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/token`
    const res = await axios.get(apiUrl)
    return res.data as string
  }

  static async createApp(
    context: ContentContext,
    packageName: string
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/apps`
    await axios.post(apiUrl, { packageName })
  }

  static async updateApp(
    context: ContentContext,
    appId: number,
    form: AppForm
  ): Promise<App> {
    const res = await axios.put(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/apps/${appId}`,
      form
    )
    return res.data as App
  }

  static async deleteApp(
    context: ContentContext,
    appId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/apps/${appId}`
    )
  }

  static async highlightApp(
    context: ContentContext,
    appId: number
  ): Promise<App> {
    const res = await axios.post(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/apps/${appId}/highlight`
    )
    return res.data as App
  }

  static async unhighlightApp(
    context: ContentContext,
    appId: number
  ): Promise<App> {
    const res = await axios.delete(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/apps/${appId}/highlight`
    )
    return res.data as App
  }

  static async loadAppPermissions(
    context: ContentContext,
    appId: number
  ): Promise<AppPermission[]> {
    const res = await axios.get(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/apps/${appId}/permissions`
    )
    return res.data as AppPermission[]
  }

  // Websites

  static async createWebsite(
    context: ContentContext,
    form: WebsiteForm
  ): Promise<Website> {
    const res = await axios.post(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/websites`,
      form
    )
    return res.data as Website
  }

  static async updateWebsite(
    context: ContentContext,
    websiteId: number,
    form: WebsiteForm
  ): Promise<Website> {
    const res = await axios.put(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/websites/${websiteId}`,
      form
    )
    return res.data as Website
  }

  static async deleteWebsite(
    context: ContentContext,
    websiteId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/websites/${websiteId}`
    )
  }

  static async highlightWebsite(
    context: ContentContext,
    websiteId: number
  ): Promise<Website> {
    const res = await axios.post(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/websites/${websiteId}/highlight`
    )
    return res.data as Website
  }

  static async unhighlightWebsite(
    context: ContentContext,
    websiteId: number
  ): Promise<Website> {
    const res = await axios.delete(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/websites/${websiteId}/highlight`
    )
    return res.data as Website
  }

  static async uploadWebsiteCustomIcon(
    context: ContentContext,
    imageFile: File
  ): Promise<string> {
    const apiUrl = `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/websites/custom-icon`
    const headers = { 'Content-Type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', imageFile)

    const { data } = await axios.post(apiUrl, formData, { headers })
    return data as string
  }

  // Files

  static async createFile(
    context: ContentContext,
    form: FileForm
  ): Promise<ContentFile> {
    const res = await axios.post(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/files`,
      form
    )
    return res.data as ContentFile
  }

  static async uploadFile(
    { organizationId, siteId, profileId, categoryId }: ContentContext,
    file: File
  ): Promise<string> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/categories/${categoryId}/files/upload`
    const headers = { 'Content-Type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', file)

    const { data } = await axios.post(apiUrl, formData, { headers })
    return data as string
  }

  static async updateFile(
    context: ContentContext,
    fileId: number,
    form: FileForm
  ): Promise<ContentFile> {
    const res = await axios.put(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/files/${fileId}`,
      form
    )
    return res.data as ContentFile
  }

  static async deleteFile(
    context: ContentContext,
    fileId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/files/${fileId}`
    )
  }

  static async highlightFile(
    context: ContentContext,
    fileId: number
  ): Promise<ContentFile> {
    const res = await axios.post(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/files/${fileId}/highlight`
    )
    return res.data as ContentFile
  }

  static async unhighlightFile(
    context: ContentContext,
    fileId: number
  ): Promise<ContentFile> {
    const res = await axios.delete(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/files/${fileId}/highlight`
    )
    return res.data as ContentFile
  }

  static async uploadFileCustomIcon(
    context: ContentContext,
    imageFile: File
  ): Promise<string> {
    const apiUrl = `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${context.categoryId}/files/custom-icon`
    const headers = { 'Content-Type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', imageFile)

    const { data } = await axios.post(apiUrl, formData, { headers })
    return data as string
  }
}

export default ContentsApi
