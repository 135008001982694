import { type IntegrationTemplate } from 'App/Integrations/integrations-types'
import { type FormSelectOption } from 'common/components/FormSelect'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useAuthMethodOptions = (integrationTemplates?: IntegrationTemplate[]) => {
  const { t } = useTranslation()
  return useMemo(() => {
    const defaultOptions = [
      {
        startGroup: t(
          'integrations.integrationForm.fields.authMode.groups.custom'
        ),
        label: t('integrations.integrationForm.fields.authMode.values.custom'),
        value: 'custom',
      },
    ]

    if (integrationTemplates === undefined) return defaultOptions
    const templateOptions: FormSelectOption[] = integrationTemplates.map(
      ({ key }) => ({
        label: t(key),
        value: key,
      })
    )

    templateOptions.sort((a, b) => {
      const aLabel = a.label?.toString().toLowerCase() ?? ''
      const bLabel = b.label?.toString().toLowerCase() ?? ''
      if (aLabel < bLabel) {
        return -1
      } else if (aLabel > bLabel) {
        return 1
      } else {
        return 0
      }
    })

    if (templateOptions.length > 0) {
      templateOptions[0].startGroup = t(
        'integrations.integrationForm.fields.authMode.groups.templates'
      )
    }

    return [...templateOptions, ...defaultOptions]
  }, [t, integrationTemplates])
}

export default useAuthMethodOptions
