import React, { type PropsWithChildren } from 'react'
import { Grid } from '@mui/material'

type PageHeaderProps = PropsWithChildren

const PageHeader = ({ children }: PageHeaderProps) => {
  return <Grid container>{children}</Grid>
}

export default PageHeader
