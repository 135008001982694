import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  type PostEditable,
  type Post,
  type PostsContext,
} from 'App/Posts/posts-types'
import PostsApi from 'api/posts-api'

interface PostsState {
  addingPost: boolean
  addedPost: boolean
  updatedPost: boolean
  deletedPost: boolean
  posts: Post[]
  fetchedPosts: boolean
  uploadingPostImage: boolean
  uploadedPostImage: boolean
  uploadedPostImageFile: string | null
  uploadPostImageError: boolean
}

const initialState: PostsState = {
  addingPost: false,
  addedPost: false,
  updatedPost: false,
  deletedPost: false,
  posts: [],
  fetchedPosts: false,
  uploadingPostImage: false,
  uploadedPostImage: false,
  uploadedPostImageFile: null,
  uploadPostImageError: false,
}

interface AddPostProps {
  context: PostsContext
  form: PostEditable
}
export const addPost = createAsyncThunk(
  'posts/addPost',
  async ({ context, form }: AddPostProps) => PostsApi.createPost(context, form)
)

interface UploadPostImageProps {
  context: PostsContext
  imageFile: File
}
export const uploadPostImage = createAsyncThunk(
  'posts/uploadPostImage',
  async ({ context, imageFile }: UploadPostImageProps) =>
    PostsApi.uploadPostImage(context, imageFile)
)

interface UpdatePostProps {
  context: PostsContext
  postId: number
  form: PostEditable
}
export const updatePost = createAsyncThunk(
  'posts/updatePost',
  async ({ context, postId, form }: UpdatePostProps) =>
    PostsApi.updatePost(postId, context, form)
)

interface DeletePostProps {
  context: PostsContext
  postId: number
}
export const deletePost = createAsyncThunk(
  'posts/deletePost',
  async ({ context, postId }: DeletePostProps) =>
    PostsApi.deletePost(postId, context)
)

export const fetchPosts = createAsyncThunk(
  'posts/fetchPosts',
  async (context: PostsContext) => PostsApi.fetchPosts(context)
)

export const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    resetAddPost: (state) => {
      state.addingPost = false
      state.addedPost = false
      return state
    },
    resetUpdatePost: (state) => {
      state.updatedPost = false
      return state
    },
    resetDeletedPost: (state) => {
      state.deletedPost = false
      return state
    },
    resetUploadPostImage: (state) => {
      state.uploadedPostImage = false
      state.uploadingPostImage = false
      state.uploadedPostImageFile = null
      state.uploadPostImageError = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.fulfilled, (state, { payload }) => {
      state.fetchedPosts = true
      state.posts = payload
      return state
    })

    builder.addCase(addPost.pending, (state) => {
      state.addingPost = true
      state.addedPost = false
      return state
    })

    builder.addCase(addPost.fulfilled, (state) => {
      state.addingPost = false
      state.addedPost = true
      return state
    })

    builder.addCase(uploadPostImage.pending, (state) => {
      state.uploadingPostImage = true
      state.uploadedPostImage = false
      state.uploadPostImageError = false
      return state
    })

    builder.addCase(uploadPostImage.fulfilled, (state, { payload }) => {
      state.uploadingPostImage = false
      state.uploadedPostImage = true
      state.uploadedPostImageFile = payload
      state.uploadPostImageError = false
      return state
    })

    builder.addCase(uploadPostImage.rejected, (state) => {
      state.uploadingPostImage = false
      state.uploadedPostImage = false
      state.uploadedPostImageFile = null
      state.uploadPostImageError = true
      return state
    })

    builder.addCase(updatePost.fulfilled, (state) => {
      state.updatedPost = true
      return state
    })

    builder.addCase(deletePost.fulfilled, (state) => {
      state.deletedPost = true
      return state
    })
  },
})

export const {
  resetAddPost,
  resetUpdatePost,
  resetDeletedPost,
  resetUploadPostImage,
} = postsSlice.actions

export default postsSlice.reducer
