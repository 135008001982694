import React, { useEffect, useMemo } from 'react'
import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import { Alert, LinearProgress, Stack, Typography } from '@mui/material'
import Table, {
  type TableColumn,
  TableColumnFilter,
} from 'common/components/TableNew'
import Dialog from 'common/components/Dialog'
import { makeStyles } from 'tss-react/mui'
import PreviewIcon from '@mui/icons-material/VisibilityOutlined'
import IconMenu from 'common/components/IconMenu'
import TableActionsBar from 'common/components/TableActionsBar'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import {
  useGetAwsCognitoPreReleaseEmailUsersQuery,
  useSendAwsCognitoPreReleaseEmailMutation,
} from 'App/SuperAdmin/super-admin-rtk-api'

enum EmailTemplateFormat {
  HTML = 'HTML',
  TEXT = 'TEXT',
}

interface EmailTemplate {
  id: number
  category: string
  name: string
  variant: string
  subject?: string
  format: EmailTemplateFormat
}

const useStyles = makeStyles()((theme) => ({
  PreviewTemplateDialogContainer: {
    width: 'calc(700px + (36px * 2))', // 36px is the padding of the Dialog
    height: '100%',
    maxHeight: 'calc(100vh - 36px)',
  },
  PreviewTemplateDialogContent: {
    overflow: 'hidden',
  },
  PreviewTemplateDialogIframe: {
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.hublet.common.gray}`,
  },
}))

interface PreviewTemplateDialogProps {
  emailCategory: string
  emailName: string
  templateVariant: string
  emailUser?: EmailUser
  onClose: () => void
}

const PreviewTemplateDialog = ({
  emailCategory,
  emailName,
  templateVariant,
  emailUser,
  onClose,
}: PreviewTemplateDialogProps) => {
  const { classes } = useStyles()

  const previewUrl = useMemo(() => {
    let previewUrl = `/api/v2/web/admin/${emailCategory}/${emailName}/templates/${templateVariant}`
    if (emailUser != null) {
      previewUrl += `?userId=${emailUser.id}`
    }
    return previewUrl
  }, [emailCategory, emailName, templateVariant, emailUser])

  const title = useMemo(() => {
    if (emailUser != null) {
      return `Preview template: ${emailUser.email}`
    }
    return 'Preview template'
  }, [emailUser])

  return (
    <Dialog
      classes={{
        container: classes.PreviewTemplateDialogContainer,
        content: classes.PreviewTemplateDialogContent,
      }}
      title={title}
      open={true}
      onClose={onClose}
    >
      <iframe
        className={classes.PreviewTemplateDialogIframe}
        src={previewUrl}
      />
    </Dialog>
  )
}

interface TemplatesRowActionsProps {
  template: EmailTemplate
  onClickPreview: (templateId: number) => void
}

const TemplatesRowActions = ({
  template,
  onClickPreview,
}: TemplatesRowActionsProps) => {
  const actions = [
    {
      local: 'Preview',
      fn: () => onClickPreview(template.id),
      icon: PreviewIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const TemplatesTable = () => {
  const [previewTemplateVariant, setPreviewTemplateVariant] = React.useState<
    string | null
  >(null)

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        title: 'Category',
        field: 'category',
      },
      {
        title: 'Name',
        field: 'name',
      },
      {
        title: 'Variant',
        field: 'variant',
      },
      {
        title: 'Format',
        field: 'format',
      },
      {
        title: 'Subject',
        field: 'subject',
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (template: EmailTemplate) => (
          <TemplatesRowActions
            template={template}
            onClickPreview={() => setPreviewTemplateVariant(template.variant)}
          />
        ),
      },
    ]
  }, [])

  const data: EmailTemplate[] = useMemo(
    () => [
      {
        id: 1,
        category: 'aws-cognito',
        name: 'pre-release',
        variant: 'fi',
        subject: 'Hubletin tietoturvapäivitys ensi viikolla',
        format: EmailTemplateFormat.HTML,
      },
      {
        id: 2,
        category: 'aws-cognito',
        name: 'pre-release',
        variant: 'en',
        subject: 'Hublet security update next week',
        format: EmailTemplateFormat.HTML,
      },
    ],
    []
  )

  return (
    <>
      <Table data={data} columns={columns} pagination={false} />
      {previewTemplateVariant != null && (
        <PreviewTemplateDialog
          emailCategory="aws-cognito"
          emailName="pre-release"
          templateVariant={previewTemplateVariant}
          onClose={() => setPreviewTemplateVariant(null)}
        />
      )}
    </>
  )
}

export interface EmailUser {
  id: number
  email: string
  template: string
  sentAt?: string
}

export interface AwsCognitoPreReleaseEmailUser extends EmailUser {
  lastLoginAt?: string
  createdAt: string
  isRegistrationComplete: boolean
  hasDuplicateEmail: boolean
}

interface UsersRowActionsProps {
  user: EmailUser
  onClickPreview: (userId: number) => void
}

const UsersRowActions = ({ user, onClickPreview }: UsersRowActionsProps) => {
  const actions = [
    {
      local: 'Preview',
      fn: () => onClickPreview(user.id),
      icon: PreviewIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

interface UsersTableActionsBarProps {
  selectedUserIds: number[]
  usersTotalCount: number
  usersSentCount: number
}

function UsersTableActionsBar({
  selectedUserIds,
  usersTotalCount,
  usersSentCount,
}: UsersTableActionsBarProps) {
  const [sendEmail] = useSendAwsCognitoPreReleaseEmailMutation()
  const selectedUserIdsActions = useMemo(() => {
    const actions = [
      {
        local: 'Send email',
        fn: () => {
          sendEmail({ userIds: selectedUserIds })
        },
        icon: EmailIcon,
      },
    ]
    return actions
  }, [sendEmail, selectedUserIds])

  const severity = useMemo(() => {
    if (usersSentCount === 0) {
      return 'info'
    }
    if (usersSentCount < usersTotalCount) {
      return 'warning'
    }
    return 'success'
  }, [usersSentCount, usersTotalCount])

  return (
    <TableActionsBar
      selectedRows={selectedUserIds}
      selectedRowsActions={selectedUserIdsActions}
    >
      <Alert severity={severity}>
        Sent to {usersSentCount} / {usersTotalCount} users
      </Alert>
    </TableActionsBar>
  )
}

const UsersTable = () => {
  const [previewUserId, setPreviewUserId] = React.useState<number | null>(null)
  const [selectedUserIds, setSelectedUserIds] = React.useState<number[]>([])

  useEffect(() => {
    console.log('selectedUserIds', selectedUserIds)
  }, [selectedUserIds])

  const handleSelectUsers = React.useCallback((users: EmailUser[]) => {
    setSelectedUserIds(users.map((user) => user.id))
  }, [])

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        title: 'Email',
        field: 'email',
        filter: TableColumnFilter.Text,
        canSort: true,
      },
      {
        title: 'Template',
        field: 'template',
        filter: TableColumnFilter.Select,
        canSort: true,
      },
      {
        title: 'Sent',
        field: 'sentAt',
        render: (model: EmailUser) => (model.sentAt ? model.sentAt : '-'),
        filter: TableColumnFilter.Action,
        canSort: true,
      },
      {
        title: 'Created at',
        field: 'createdAt',
        filter: TableColumnFilter.DateRange,
        canSort: true,
      },
      {
        title: 'Activated',
        field: 'isRegistrationComplete',
        render: (model: AwsCognitoPreReleaseEmailUser) =>
          model.isRegistrationComplete ? 'Yes' : 'No',
        filter: TableColumnFilter.Boolean,
        canSort: true,
      },
      {
        title: 'Duplicate',
        field: 'hasDuplicateEmail',
        render: (model: AwsCognitoPreReleaseEmailUser) =>
          model.hasDuplicateEmail ? 'Yes' : 'No',
        filter: TableColumnFilter.Boolean,
        canSort: true,
      },
      {
        title: 'Last login',
        field: 'lastLoginAt',
        render: (model: AwsCognitoPreReleaseEmailUser) =>
          model.lastLoginAt ? model.lastLoginAt : '-',
        filter: TableColumnFilter.DateRange,
        canSort: true,
      },
      {
        id: 'actions',
        // eslint-disable-next-line react/display-name
        render: (user: EmailUser) => (
          <UsersRowActions
            user={user}
            onClickPreview={() => setPreviewUserId(user.id)}
          />
        ),
      },
    ]
  }, [])

  const { data: usersData } = useGetAwsCognitoPreReleaseEmailUsersQuery()

  const previewUser = React.useMemo(
    () => usersData?.find((user) => user.id === previewUserId),
    [usersData, previewUserId]
  )

  const usersTotalCount = usersData?.length ?? 0
  const usersSentCount = useMemo(() => {
    return usersData?.filter((user) => user.sentAt != null).length ?? 0
  }, [usersData])

  if (usersData == null) {
    return <LinearProgress />
  }

  return (
    <>
      <Stack>
        <UsersTableActionsBar
          usersTotalCount={usersTotalCount}
          usersSentCount={usersSentCount}
          selectedUserIds={selectedUserIds}
        />
        <Table
          data={usersData}
          columns={columns}
          selectable
          onSelectionChange={handleSelectUsers}
        />
      </Stack>
      {previewUser != null && (
        <PreviewTemplateDialog
          emailCategory="aws-cognito"
          emailName="pre-release"
          templateVariant={previewUser.template}
          emailUser={previewUser}
          onClose={() => setPreviewUserId(null)}
        />
      )}
    </>
  )
}

const AwsCognitoPreReleaseEmail = () => {
  return (
    <Page>
      <PageHeader>
        <Stack>
          <Typography
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.hublet.secondary.main,
            })}
          >
            Super-admin
          </Typography>
          <PageTitle title="AWS Cognito: Pre-release notification email" />
        </Stack>
      </PageHeader>
      <PageBody>
        <Stack gap={4}>
          <Stack gap={2}>
            <Typography variant="h2">Templates</Typography>
            <TemplatesTable />
          </Stack>
          <Stack gap={2}>
            <Typography variant="h2">Users</Typography>
            <UsersTable />
          </Stack>
        </Stack>
      </PageBody>
    </Page>
  )
}

export default AwsCognitoPreReleaseEmail
