import { useEffect } from 'react'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { type ProfileNetwork } from 'App/Profiles/ProfilesView/NetworkSettings/network-settings-types'
import { fetchProfileNetworks } from 'App/Profiles/ProfilesView/NetworkSettings/network-settings-state'
import { useAppDispatch, useAppSelector } from 'store'

const useProfileNetworks = (profileId?: number): ProfileNetwork[] => {
  const dispatch = useAppDispatch()

  const { siteId, organizationId } = useCurrentAccount()
  const profileNetworks = useAppSelector(
    (state) => state.profileNetworks.profileNetworks
  )

  useEffect(() => {
    if (profileId !== undefined) {
      void dispatch(fetchProfileNetworks({ siteId, organizationId, profileId }))
    }
  }, [dispatch, siteId, organizationId, profileId])

  return profileNetworks
}

export default useProfileNetworks
