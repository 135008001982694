import React from 'react'
import {
  Checkbox as MuiCheckbox,
  type CheckboxProps as MuiCheckboxProps,
  type Theme,
} from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<void, 'checked'>()(
  (theme: Theme, _params, classes) => ({
    root: {
      color: theme.palette.hublet.secondary.light,
      [`&.${classes.checked}`]: {
        color: theme.palette.hublet.primary.main,
      },
    },
    checked: {},
  })
)

const Checkbox = (props: MuiCheckboxProps) => {
  const { classes } = useStyles()

  return <MuiCheckbox color="default" {...props} classes={classes} />
}

export default Checkbox
