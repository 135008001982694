export interface Tablet {
  id: number
  organizationId: number
  siteId: number

  dockId?: number
  dockSlot?: number
  macAddress?: string
  activeAt?: number
  serialNumber: string
  modelCode: string

  onewireId?: string

  osBuildRelease?: string
  osBuildFingerprint?: string
  osBuildSdkApiLevel?: number

  hubletVersionCode?: string
  hubletVersionName?: string

  batteryStatus?: string
  lockRequested?: boolean
  resetRequested?: boolean
  googleDeviceId?: string
  googleUserId?: string
  loanStatus?: string
  loanKey?: string

  batteryLevelFromDeviceApi?: number

  createdAt: Date
  deletedAt?: Date

  numberInLms?: string

  emmDevicePolicyUpdatedAt?: number

  endOfLifeWarning?: boolean
}

export interface TabletApp {
  packageName: string
  version: string
  status: string
}

export interface TabletLog {
  timestamp: string
  message: string
}

export interface TabletLogStreamEntry {
  key: string
  log: TabletLog
}

export type TabletLogStream = TabletLogStreamEntry[]

export interface TabletAppSettings {
  id: number
  tabletId?: number
  key?: string
  value?: string
}

export interface UpdateManagedConfiguration {
  create: Array<Partial<TabletAppSettings>>
  update: Array<Partial<TabletAppSettings>>
  delete: number[]
}

export interface TabletEmmInfo {
  deviceId?: string
  status?: string
}

export enum TabletPackageKind {
  Master = 'master',
  Feature = 'feature',
}

export interface TabletPackage {
  kind: TabletPackageKind
  name: string
  modifiedAt: number
}

export interface TabletModel {
  id: number
  model: string
  endOfLife: Date
}
