import rtkApi from 'rtk-api'
import { type TopApp } from 'App/TopApps/topapps-types'

interface GetTopAppsResponse {
  topApps: TopApp[]
}

const topAppsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getTopApps: builder.query<GetTopAppsResponse, void>({
      query: () => '/top-apps',
    }),
  }),
})

export const { useGetTopAppsQuery } = topAppsRtkApi
export default topAppsRtkApi
