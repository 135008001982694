import React, { useEffect } from 'react'
import { type RootState, useAppDispatch } from 'store'

import { connect } from 'react-redux'

import { type Translation } from 'App/Translations/translations-types'
import {
  fetchTranslation,
  fetchTranslations,
  resetUpdatedTranslation,
  updateTranslation,
} from 'App/Translations/translations-state'
import TranslationForm from 'App/Translations/components/TranslationForm'

interface EditTranslationDrawerProps {
  translation?: Translation
  open: boolean
  onClose: () => void
  editingTranslation: Translation | null
  updatedTranslation: boolean
}

const EditTranslationDrawer = ({
  translation,
  open,
  onClose,
  editingTranslation,
  updatedTranslation,
}: EditTranslationDrawerProps) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (updatedTranslation) {
      onClose()
      dispatch(resetUpdatedTranslation())
      dispatch(fetchTranslations())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onClose, updatedTranslation])

  useEffect(() => {
    if (translation != null) {
      dispatch(fetchTranslation(translation))
    }
  }, [dispatch, translation])

  const onSubmitTranslations = (translation: Translation) => {
    if (!translation) {
      return false
    }
    dispatch(updateTranslation(translation))
  }

  return (
    <TranslationForm
      translation={editingTranslation}
      open={open}
      onClose={onClose}
      onSubmitTranslation={onSubmitTranslations}
    />
  )
}

const mapStateToProps = (state: RootState) => ({
  editingTranslation: state.translations.editingTranslation,
})

export default connect(mapStateToProps)(EditTranslationDrawer)
