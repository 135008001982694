import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Page from 'common/components/Page'
import { type Profile } from 'App/Profiles/profiles-types'
import { fetchProfiles } from 'App/Profiles/profiles-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import Button from 'common/components/Button'
import EditProfileDrawer from 'App/Profiles/ProfilesView/EditProfileDrawer'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import CategoriesTable from 'App/Categories/components/CategoriesTable'
import AddCategoryDrawer from 'App/Categories/components/AddCategoryDrawer'
import useCategories from 'common/hooks/useCategories'
import Toast from 'common/components/Toast'
import ChangePictureDrawer from 'App/Profiles/ProfilesView/ChangePictureDrawer'
import { fetchCategories } from 'App/Categories/categories-state'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  ProfilesViewHeader: {
    marginTop: '275px',
  },
  ProfilesViewHeaderTitle: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: `${theme.palette.hublet.common.white} !important`,
  },
  ProfilesViewHeaderSubtitle: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: theme.palette.hublet.common.white,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  ProfilesViewHeaderButton: {
    color: theme.palette.hublet.common.white,
    borderColor: theme.palette.hublet.common.white,
    marginLeft: theme.spacing(2),
  },
}))

const useContentChanged = () => {
  const addedWebsite = useAppSelector((state) => state.contents.addedWebsite)
  const addedApp = useAppSelector((state) => state.contents.addedApp)
  const addedFile = useAppSelector((state) => state.contents.addedFile)

  const deletedWebsite = useAppSelector(
    (state) => state.contents.deletedWebsite
  )
  const deletedApp = useAppSelector((state) => state.contents.deletedApp)
  const deletedFile = useAppSelector((state) => state.contents.deletedFile)

  return (
    addedWebsite ||
    addedApp ||
    addedFile ||
    deletedWebsite ||
    deletedApp ||
    deletedFile
  )
}

const getProfileById = (profiles: Profile[], id: number) => {
  const matches = profiles.filter((profile) => profile.id === id)
  return matches.length === 1 ? matches[0] : null
}

const ProfilesView = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const { id } = useParams<{ id: string }>()
  const profileId = parseInt(id ?? '')

  const [openEditProfile, setOpenEditProfile] = useState(false)
  const onCloseEditProfile = () => setOpenEditProfile(false)

  const { organizationId, siteId } = useCurrentAccount()

  useEffect(() => {
    dispatch(fetchProfiles({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  const profiles = useAppSelector((state) => state.profiles.profiles)
  const profile = getProfileById(profiles, profileId)

  const categories = useCategories(profileId)
  const addedCategory = useAppSelector(
    (state) => state.categories.addedCategory
  )

  const [openAddCategory, setOpenAddCategory] = useState(false)
  const onCloseAddCategory = () => setOpenAddCategory(false)

  const [openChangePictureDrawer, setOpenChangePictureDrawer] = useState(false)

  const contentChanged = useContentChanged()
  useEffect(() => {
    if (contentChanged) {
      dispatch(fetchCategories({ organizationId, siteId, profileId }))
    }
  }, [dispatch, organizationId, siteId, profileId, contentChanged])

  if (profile == null) return null

  return (
    <Page bgImageUrl={profile.imageBg}>
      <Grid container className={classes.ProfilesViewHeader}>
        <Grid container alignItems="center">
          <Grid item style={{ flexGrow: 1 }}>
            <h1 className={classes.ProfilesViewHeaderTitle}>{profile.name}</h1>
            <h2 className={classes.ProfilesViewHeaderSubtitle}>
              {profile.description}
            </h2>
          </Grid>
          <Grid item>
            <Button
              small
              outlined
              className={classes.ProfilesViewHeaderButton}
              onClick={() => setOpenChangePictureDrawer(true)}
            >
              {t('profiles.viewProfile.changePictureButton')}
            </Button>
            <Button
              small
              outlined
              onClick={() => setOpenEditProfile(true)}
              className={classes.ProfilesViewHeaderButton}
            >
              {t('profiles.viewProfile.settingsButton')}
            </Button>
            <Button
              small
              outlined
              onClick={() => setOpenAddCategory(true)}
              className={classes.ProfilesViewHeaderButton}
            >
              {t('profiles.viewProfile.addCategoryButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <CategoriesTable
        context={{
          organizationId,
          siteId,
          profileId,
        }}
        categories={categories}
      />
      <ChangePictureDrawer
        currentImage={profile.image}
        profileId={profileId}
        open={openChangePictureDrawer}
        onClose={() => setOpenChangePictureDrawer(false)}
      />
      <AddCategoryDrawer
        profileId={profileId}
        open={openAddCategory}
        onClose={onCloseAddCategory}
      />
      <Toast
        open={addedCategory}
        message={t('categories.addCategory.toast.success')}
      />

      <EditProfileDrawer
        profile={profile}
        open={openEditProfile}
        onClose={onCloseEditProfile}
        updatedProfile={false}
      />
    </Page>
  )
}

export default ProfilesView
