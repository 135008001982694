import React from 'react'
import { useTranslation } from 'react-i18next'
import { type Tablet } from '../tablets-types'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from '@mui/material'
import { useLoadTabletAppsQuery } from 'App/Tablets/tablets-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'

interface AppInstallTableProps {
  tablet: Tablet
}

const AppInstallTable = ({ tablet }: AppInstallTableProps) => {
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const { data: apps } = useLoadTabletAppsQuery({
    organizationId,
    siteId,
    tabletId: tablet.id,
  })

  if (apps == null) {
    return <CircularProgress style={{ display: 'block', margin: 'auto' }} />
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('tablets.viewTablet.apps.header.appName')}</TableCell>
            <TableCell>{t('tablets.viewTablet.apps.header.version')}</TableCell>
            <TableCell>{t('tablets.viewTablet.apps.header.status')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apps.map((app) => (
            <TableRow key={app.packageName}>
              <TableCell>{app.packageName}</TableCell>
              <TableCell>{app.version}</TableCell>
              <TableCell>{t(app.status)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AppInstallTable
