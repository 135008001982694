import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type EAPAuth,
  type EAPMethod,
  type Network,
  type NetworkForm,
  type NetworkSecurityMode,
} from 'App/Networks/networks-types'

const toModel = (remote: {
  id: number
  siteId: number
  organizationId: number
  wlanSsid: string
  wlanSecurityMode: NetworkSecurityMode
  wlanSsidHidden: number
  wlanUsername: string
  wlanPassword: string
  wlanEapMethod: EAPMethod
  wlanEapInnerAuth: EAPAuth
}): Network => ({
  id: remote.id,
  siteId: remote.siteId,
  organizationId: remote.organizationId,
  ssid: remote.wlanSsid,
  securityMode: remote.wlanSecurityMode,
  visibility: remote.wlanSsidHidden,
  username: remote.wlanUsername,
  password: remote.wlanPassword,
  eapMethod: remote.wlanEapMethod,
  eapAuth: remote.wlanEapInnerAuth,
})

const toDTO = (form: NetworkForm): any => ({
  wlanSsid: form.ssid,
  wlanSecurityMode: form.securityMode,
  wlanSsidHidden: form.visibility,
  wlanUsername: form.username,
  wlanPassword: form.password,
  wlanEapMethod: form.eapMethod,
  wlanEapInnerAuth: form.eapAuth,
})

class NetworksApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchNetworks(
    siteId: number,
    organizationId: number
  ): Promise<Network[]> {
    const { data } = await axios.get(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/networks`
    )

    const networks: Network[] = data.map(toModel)

    return networks
  }

  static async createNetwork(
    siteId: number,
    organizationId: number,
    form: NetworkForm
  ): Promise<Network> {
    const { data } = await axios.post(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/networks`,
      toDTO(form)
    )
    return toModel(data)
  }

  static async updateNetwork(
    networkId: number,
    siteId: number,
    organizationId: number,
    form: NetworkForm
  ): Promise<Network> {
    const { data } = await axios.put(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/networks/${networkId}`,
      toDTO(form)
    )
    return toModel(data)
  }

  static async deleteNetwork(
    networkId: number,
    siteId: number,
    organizationId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/networks/${networkId}`
    )
  }
}

export default NetworksApi
