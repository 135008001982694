import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UsersApi from 'api/users-api'
import CustomersApi from 'api/customers-api'
import DistributorsApi from 'api/distributors-api'
import SitesApi from 'api/sites-api'
import { type Customer } from 'App/Customers/customers-types'
import { type Distributor } from 'App/Distributors/distributors-types'
import { type Site } from 'App/Sites/sites-state'
import TranslationsApi from 'api/translations-api'
import { type AccountSelectPage } from 'common/components/AccountSelect'

export enum UserRole {
  HubletAdmin = 'hublet-admin',
  DistributorAdmin = 'distributor-admin',
  OrgAdmin = 'org-admin',
  SiteAdmin = 'site-admin',
  SiteUser = 'site-user',
  Translator = 'translator',
  Manufacturer = 'manufacturer',
}

export interface CurrentUser {
  id: number
  login: string
  firstName: string
  lastName: string
  organizationId: number
  orgName: string
  orgTimezone?: string
  siteId?: number
  siteTimezone?: string
  siteName?: string
  role: UserRole
  isDistributor: boolean
  distributorId?: number
  isManufacturer: boolean
  expiresAt: number // timestamp
}

export interface ContextEntity {
  id: number
  name: string
  timezone?: string
}

interface AppState {
  currentUser?: CurrentUser
  fetchingCurrentUser: boolean
  fetchedCurrentUser: boolean
  fetchCurrentUserError: boolean

  role?: UserRole
  organizationDistributorId?: number
  organization?: ContextEntity
  site?: ContextEntity

  timezone?: string

  adminMode: boolean

  distributors: Distributor[]
  customers: Customer[]
  sites: Site[]

  languages: string[]

  accountSelectActivePage?: AccountSelectPage
  accountSelectActiveDistributor?: ContextEntity
  accountSelectActiveCustomer?: ContextEntity
  accountSelectSearchQuery: string
  isAccountSelectDone: boolean
}

const initialState: AppState = {
  currentUser: undefined,
  fetchingCurrentUser: false,
  fetchedCurrentUser: false,
  fetchCurrentUserError: false,

  adminMode: false,

  distributors: [],
  customers: [],
  sites: [],

  languages: [],

  accountSelectSearchQuery: '',
  isAccountSelectDone: false,
}

export const fetchCurrentUser = createAsyncThunk(
  'app/fetchCurrentUser',
  async () => UsersApi.currentUser()
)

export const fetchDistributors = createAsyncThunk(
  'app/fetchDistributors',
  async () => DistributorsApi.fetchDistributors()
)

export const fetchCustomers = createAsyncThunk(
  'app/fetchCustomers',
  async (distributorId: number) => CustomersApi.fetchCustomers(distributorId)
)

export const fetchSites = createAsyncThunk(
  'app/fetchSites',
  async (orgId: number) => SitesApi.fetchSites(orgId)
)

export const fetchLanguages = createAsyncThunk('app/fetchLanguages', async () =>
  TranslationsApi.fetchLanguages()
)

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setOrganizationDistributorId: (
      state,
      { payload }: { payload: number | undefined }
    ) => {
      localStorage.setItem(
        'hublet-user-organization-distributor-id',
        JSON.stringify(payload)
      )
      state.organizationDistributorId = payload
      return state
    },
    setOrganization: (
      state,
      { payload }: { payload: ContextEntity | undefined }
    ) => {
      localStorage.setItem('hublet-user-organization', JSON.stringify(payload))
      state.organization = payload
      return state
    },
    setSite: (state, { payload }: { payload: ContextEntity | undefined }) => {
      localStorage.setItem('hublet-user-site', JSON.stringify(payload))
      state.site = payload
      return state
    },
    setRole: (state, { payload }) => {
      localStorage.setItem('hublet-user-role', payload)
      state.role = payload
      return state
    },
    switchToAdmin: (state) => {
      localStorage.setItem('hublet-user-admin', 'true')
      state.adminMode = true
      return state
    },
    switchToNormal: (state) => {
      localStorage.setItem('hublet-user-admin', 'false')
      state.adminMode = false
      return state
    },
    setAccountSelectActivePage: (
      state,
      { payload }: { payload: AccountSelectPage }
    ) => {
      state.accountSelectActivePage = payload
      return state
    },
    setAccountSelectActiveDistributor: (
      state,
      { payload }: { payload: ContextEntity | undefined }
    ) => {
      state.accountSelectActiveDistributor = payload
      return state
    },
    setAccountSelectActiveCustomer: (
      state,
      { payload }: { payload: ContextEntity | undefined }
    ) => {
      state.accountSelectActiveCustomer = payload
      return state
    },
    setAccountSelectSearchQuery: (state, { payload }: { payload: string }) => {
      state.accountSelectSearchQuery = payload
      return state
    },
    setIsAccountSelectDone: (state, { payload }: { payload: boolean }) => {
      state.isAccountSelectDone = payload
      return state
    },
    invalidateCurrentUser: (state) => {
      state.currentUser = undefined

      state.fetchingCurrentUser = false
      state.fetchedCurrentUser = true
      state.fetchCurrentUserError = true

      localStorage.removeItem('hublet-user-organization-distributor-id')
      localStorage.removeItem('hublet-user-organization')
      localStorage.removeItem('hublet-user-site')
      localStorage.removeItem('hublet-user-role')
      localStorage.removeItem('hublet-user-admin')

      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.pending, (state) => {
      state.fetchingCurrentUser = true
      return state
    })

    builder.addCase(fetchCurrentUser.fulfilled, (state, { payload }) => {
      state.fetchingCurrentUser = false
      state.fetchedCurrentUser = true
      state.fetchCurrentUserError = false
      state.currentUser = payload

      const storedRole = localStorage.getItem('hublet-user-role')
      const storedOrganizationDistributorId = localStorage.getItem(
        'hublet-user-organization-distributor-id'
      )
      const storedOrganization = localStorage.getItem(
        'hublet-user-organization'
      )
      const storedSite = localStorage.getItem('hublet-user-site')
      const storedAdmin = localStorage.getItem('hublet-user-admin')

      let role = payload.role

      if (storedRole !== null) {
        switch (storedRole) {
          case 'hublet-admin':
            role = UserRole.HubletAdmin
            break
          case 'distributor-admin':
            role = UserRole.DistributorAdmin
            break
          case 'org-admin':
            role = UserRole.OrgAdmin
            break
          case 'site-admin':
            role = UserRole.SiteAdmin
            break
          case 'site-user':
            role = UserRole.SiteUser
            break
          default:
            break
        }
      }

      let organizationDistributorId: number | undefined = payload.distributorId

      if (storedOrganizationDistributorId !== null) {
        try {
          organizationDistributorId = JSON.parse(
            storedOrganizationDistributorId
          )
        } catch (e) {
          console.log(
            'Invalid stored user organization distributor id',
            storedOrganizationDistributorId
          )
        }
      }

      let organization: ContextEntity = {
        id: payload.organizationId,
        name: payload.orgName,
        timezone: payload.orgTimezone,
      }

      if (storedOrganization !== null) {
        try {
          organization = JSON.parse(storedOrganization)
        } catch (e) {
          console.log('Invalid stored user organization', storedOrganization)
        }
      }

      let site: ContextEntity | undefined

      if (
        (payload.role === UserRole.SiteAdmin ||
          payload.role === UserRole.SiteUser) &&
        payload.siteId !== undefined &&
        payload.siteName !== undefined
      ) {
        // `site` should be undefined for other roles
        site = {
          id: payload.siteId,
          name: payload.siteName,
          timezone: payload.siteTimezone,
        }
      }

      if (storedSite !== null) {
        try {
          site = JSON.parse(storedSite)
        } catch (e) {
          console.log('Invalid stored user site', storedSite)
        }
      }

      const admin = storedAdmin === 'true'

      state.role = role
      state.organizationDistributorId = organizationDistributorId
      state.organization = organization
      state.site = site
      state.adminMode = admin

      return state
    })

    builder.addCase(fetchCurrentUser.rejected, (state) => {
      state.currentUser = undefined

      state.fetchingCurrentUser = false
      state.fetchedCurrentUser = true
      state.fetchCurrentUserError = true

      localStorage.removeItem('hublet-user-organization-distributor-id')
      localStorage.removeItem('hublet-user-organization')
      localStorage.removeItem('hublet-user-site')
      localStorage.removeItem('hublet-user-role')
      localStorage.removeItem('hublet-user-admin')

      return state
    })

    builder.addCase(fetchDistributors.fulfilled, (state, { payload }) => {
      state.distributors = payload
      return state
    })

    builder.addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      state.customers = payload
      return state
    })

    builder.addCase(fetchSites.fulfilled, (state, { payload }) => {
      state.sites = payload
      return state
    })

    builder.addCase(fetchLanguages.fulfilled, (state, { payload }) => {
      state.languages = payload
      return state
    })
  },
})

export const {
  setOrganizationDistributorId,
  setOrganization,
  setSite,
  setRole,
  switchToAdmin,
  switchToNormal,
  setAccountSelectActivePage,
  setAccountSelectActiveDistributor,
  setAccountSelectActiveCustomer,
  setAccountSelectSearchQuery,
  setIsAccountSelectDone,
  invalidateCurrentUser,
} = appSlice.actions

export default appSlice.reducer
