import { useMemo } from 'react'

export type UrlStatus = 'default' | 'invalid' | 'valid'

const useUrlStatus = (url: unknown): UrlStatus => {
  return useMemo(() => {
    const urlPattern = /^(http|https):\/\/[^ "]+$/
    if (url === undefined || url === null || url === '') {
      return 'default'
    } else if (typeof url !== 'string' || !urlPattern.test(url)) {
      return 'invalid'
    } else {
      return 'valid'
    }
  }, [url])
}

export default useUrlStatus
