import { useEffect } from 'react'
import { fetchNetworks } from 'App/Networks/networks-state'
import { type Network } from 'App/Networks/networks-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

const useNetworks = (): Network[] => {
  const dispatch = useAppDispatch()

  const { siteId, organizationId } = useCurrentAccount()
  const networks = useAppSelector((state) => state.networks.networks)

  useEffect(() => {
    dispatch(fetchNetworks({ siteId, organizationId }))
  }, [dispatch, siteId, organizationId])

  return networks
}

export default useNetworks
