import React from 'react'

import Table from 'common/components/TableNew'

import { useTranslation } from 'react-i18next'
import { type TopApp } from 'App/TopApps/topapps-types'
import AvatarWithText from 'common/components/AvatarWithText'

interface TopAppsTableProps {
  topApps: TopApp[]
}

const TopAppsTable = ({ topApps }: TopAppsTableProps) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('topapps.table.columns.name'),
      field: 'appName',
      // eslint-disable-next-line react/display-name
      render: ({ appName }: TopApp) => <AvatarWithText text={appName} />,
      style: { width: 'auto' },
    },
    {
      title: t('topapps.table.columns.count'),
      field: 'profileCount',
      style: { width: '120px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={topApps} />
    </>
  )
}

export default TopAppsTable
