export interface LoansByDate {
  loans: number
  date: string
}

export interface DashboardData {
  loansByDate: LoansByDate[]
  totalLoans: number
  totalLoanMinutes: number
  avgLoanMinutes: number
  medianLoanMinutes: number
}

export enum TimeUnit {
  Week,
  Month,
  Year,
}

export enum DataLevel {
  Global,
  Distributor,
  Organization,
  Site,
}
