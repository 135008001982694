import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Profile,
  type ProfileForm,
  type ProfilesContext,
} from 'App/Profiles/profiles-types'

class ProfilesApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchProfiles({
    organizationId,
    siteId,
  }: ProfilesContext): Promise<Profile[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles`
    const { data } = await axios.get(apiUrl)

    interface ProfilesRow {
      id: number
      configName: string
      configInfo: string
      siteId: number
      organizationId: number
      enableTelephony: boolean
      enableCamera: boolean
      enableSpeaker: boolean
      enableMicrophone: boolean
      image: string | null
      imageBg: string | null
      imageThumb: string | null
      loanDuration?: number
      audioDefaultOutputVolume: number
      audioMaxOutputVolume: number
      enablePrivateNetworks: boolean
    }

    const profiles: Profile[] = data.rows.map((row: ProfilesRow) => ({
      id: row.id,
      name: row.configName,
      description: row.configInfo,
      siteId: row.siteId,
      organizationId: row.organizationId,
      enableTelephony: row.enableTelephony,
      enableCamera: row.enableCamera,
      enableSpeaker: row.enableSpeaker,
      enableMicrophone: row.enableMicrophone,
      image: row.image,
      imageBg: row.imageBg,
      imageThumb: row.imageThumb,
      loanDuration: row.loanDuration,
      audioDefaultOutputVolume: row.audioDefaultOutputVolume,
      audioMaxOutputVolume: row.audioMaxOutputVolume,
      enablePrivateNetworks: row.enablePrivateNetworks,
    }))

    return profiles
  }

  static async createProfile({
    context,
    form,
  }: {
    context: ProfilesContext
    form: ProfileForm
  }): Promise<Profile> {
    const { organizationId, siteId } = context
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles`

    const postData = {
      configName: form.name,
      configInfo: form.description,
    }

    const { data } = await axios.post(apiUrl, postData)

    return data as Profile
  }

  static async deleteProfile({
    context,
    profileId,
  }: {
    context: ProfilesContext
    profileId: number
  }): Promise<void> {
    const { organizationId, siteId } = context
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}`
    await axios.delete(apiUrl)
  }

  static async updateProfile({
    context,
    form,
    profileId,
  }: {
    context: ProfilesContext
    form: ProfileForm
    profileId: number
  }): Promise<Profile> {
    const { organizationId, siteId } = context
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}`

    const putData = {
      configName: form.name,
      configInfo: form.description,
      enableTelephony: form.enableTelephony,
      enableCamera: form.enableCamera,
      enableSpeaker: form.enableSpeaker,
      enableMicrophone: form.enableMicrophone,
      image: form.image,
      loanDuration: form.loanDuration,
      audioDefaultOutputVolume: form.audioDefaultOutputVolume,
      audioMaxOutputVolume: form.audioMaxOutputVolume,
      enablePrivateNetworks: form.enablePrivateNetworks,
    }

    const { data } = await axios.put(apiUrl, putData)

    return data as Profile
  }

  static async uploadProfileImage(
    { organizationId, siteId }: ProfilesContext,
    imageFile: File,
    profileId: number
  ): Promise<string> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/image`
    const headers = { 'Content-Type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', imageFile)

    const { data } = await axios.post(apiUrl, formData, { headers })
    return data as string
  }

  static async fetchProfilesWithCodes({
    organizationId,
    siteId,
  }: ProfilesContext): Promise<number[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/with-codes`
    const { data } = await axios.get(apiUrl)

    return data
  }
}

export default ProfilesApi
