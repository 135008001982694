import React, { useEffect, useRef, useState } from 'react'

import { connect } from 'react-redux'
import { type RootState, useAppDispatch, useAppSelector } from 'store'

import { useTranslation } from 'react-i18next'

import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import PageActions from 'common/components/PageActions'
import PageEmpty from 'common/components/PageEmpty'
import Button from 'common/components/Button'
import Toast from 'common/components/Toast'

import { type Translation } from 'App/Translations/translations-types'
import {
  fetchAllLanguages,
  fetchTranslations,
  importTranslations,
  resetImportTranslations,
} from 'App/Translations/translations-state'
import TranslationsTable from 'App/Translations/components/TranslationsTable'
import AddTranslationDrawer from 'App/Translations/components/AddTranslationDrawer'
import { renderIf } from 'common/utils/render-utils'
import useApp from 'common/hooks/useApp'
import ButtonMenu from 'common/components/ButtonMenu'

import AndroidIcon from '@mui/icons-material/AndroidOutlined'
import VideoLabelIcon from '@mui/icons-material/VideoLabelOutlined'

interface TranslationsProps {
  data: Translation[]
  addedTranslation: boolean
  addingError: string
  updatedTranslation: boolean
  deletedTranslation: boolean
}

const Translations = ({
  data,
  addedTranslation,
  addingError,
  updatedTranslation,
  deletedTranslation,
}: TranslationsProps) => {
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { adminMode } = useApp()

  const fileRef = useRef<HTMLInputElement | null>(null)

  const { importedTranslations, importedTranslationsError } = useAppSelector(
    (state) => state.translations
  )

  const fetchedAllLanguages = useAppSelector(
    (state) => state.translations.fetchedAllLanguages
  )

  useEffect(() => {
    if (!fetchedAllLanguages) {
      dispatch(fetchAllLanguages())
    }
  }, [dispatch, fetchedAllLanguages])

  useEffect(() => {
    dispatch(fetchTranslations())
  }, [dispatch])

  const onClickAddTranslation = () => setOpenDrawer(true)
  const onCloseDrawer = () => setOpenDrawer(false)

  const i18nDebug = new URL(window.location.href).searchParams.get('i18nDebug')
  const androidStringsUrl =
    i18nDebug === null
      ? '/api/v2/web/main/strings/android'
      : '/api/v2/web/strings/android'
  const dockStringsUrl =
    i18nDebug === null
      ? '/api/v2/web/main/strings/dock'
      : '/api/v2/web/strings/dock'

  const exportTranslationsUrl = `/api/v2/web/translations/${i18n.language}/export`

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files === null || files.length === 0) {
      return
    }

    const file = files[0]
    dispatch(importTranslations({ language: i18n.language, file }))
  }

  return (
    <>
      {data.length === 0 && (
        <Page>
          <PageEmpty
            title={t('translations.empty.title')}
            description={t('translations.empty.description')}
            actionText={t('translations.empty.title')}
            onClickAction={onClickAddTranslation}
          />
        </Page>
      )}
      {data.length > 0 && (
        <Page>
          <PageHeader>
            <PageTitle title={t('translations.title')} />
            <PageActions>
              <input
                ref={fileRef}
                type="file"
                accept=".json"
                onChange={onChangeFile}
                hidden
              />
              <Button onClick={() => fileRef.current?.click()} small outlined>
                {t('translations.actions.importTranslations')}
              </Button>
              <Button
                externalTo={exportTranslationsUrl}
                small
                download={`hublet-manager-translations-${i18n.language}.json`}
                outlined
              >
                {t('translations.actions.exportTranslations')}
              </Button>
              {renderIf(adminMode, () => (
                <>
                  <ButtonMenu
                    label={t('translations.actions.download')}
                    actions={[
                      {
                        icon: AndroidIcon,
                        label: t(
                          'translations.actions.download.androidStrings'
                        ),
                        onClick: () => {
                          window.location.href = androidStringsUrl
                        },
                      },
                      {
                        icon: VideoLabelIcon,
                        label: t('translations.actions.download.dockStrings'),
                        onClick: () => {
                          window.location.href = dockStringsUrl
                        },
                      },
                    ]}
                    outlined
                  />
                  <Button onClick={onClickAddTranslation} small>
                    {t('translations.actions.addTranslation')}
                  </Button>
                </>
              ))}
            </PageActions>
          </PageHeader>
          <PageBody>
            <TranslationsTable />
          </PageBody>
        </Page>
      )}

      <AddTranslationDrawer
        open={openDrawer}
        onClose={onCloseDrawer}
        addedTranslation={addedTranslation}
      />

      <Toast open={addingError.length > 0} message={t(addingError)} />
      <Toast
        open={addedTranslation}
        message={t('translations.addTranslation.toast.success')}
      />
      <Toast
        open={updatedTranslation}
        message={t('translations.editTranslation.toast.success')}
      />
      <Toast
        open={deletedTranslation}
        message={t('translations.deleteTranslation.toast.success')}
      />
      <Toast
        open={importedTranslations}
        message={t('translations.importTranslations.toast.success')}
        onClose={() => dispatch(resetImportTranslations())}
      />
      <Toast
        open={importedTranslationsError}
        message={t('translations.importTranslations.toast.error')}
        onClose={() => dispatch(resetImportTranslations())}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  data: state.translations.data,
  addedTranslation: state.translations.addedTranslation,
  addingError: state.translations.addingError,
  updatedTranslation: state.translations.updatedTranslation,
  deletedTranslation: state.translations.deletedTranslation,
})

export default connect(mapStateToProps)(Translations)
