import React, { type ChangeEventHandler, type RefObject, useRef } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { renderIf } from 'common/utils/render-utils'
import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme: Theme) => ({
  FormImage: {
    alignItems: 'center',
  },
  FormImageCircle: {
    overflow: 'hidden',
    display: 'flex',
    width: '72px',
    height: '72px',
    backgroundColor: theme.palette.hublet.common.white,
    border: `1px solid ${theme.palette.hublet.common.gray}`,
    borderRadius: '26px',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.hublet.primary.main,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.hublet.primary.main,
    },
  },
  FormImageInfo: {
    paddingLeft: theme.spacing(2),
  },
  FormImageInfoTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.hublet.primary.main,
  },
  FormImageInfoDescription: {
    fontSize: '14px',
  },
  FormImagePreview: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  FormImageError: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold',
    color: theme.palette.hublet.error,
  },
}))

interface FormImageFileProps {
  title: string
  description?: string
  imageUrl?: string
  onChangeFile: (file: File) => void
  error?: boolean
}

const FormImageFile = ({
  title,
  description,
  imageUrl,
  onChangeFile,
  error,
}: FormImageFileProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const fileInput: RefObject<HTMLInputElement> = useRef(null)

  const onClickSelectFile = () => fileInput.current?.click()
  const onChangeFileInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files

    if (files === null || files.length === 0) {
      return
    }

    const file: File = files[0]
    onChangeFile(file)
  }

  return (
    <>
      <Grid container className={classes.FormImage}>
        <Grid item>
          <div
            className={classes.FormImageCircle}
            onClick={onClickSelectFile}
            title={t('common.formImageFile.selectImageFile')}
          >
            {!imageUrl ? (
              <CloudUploadIcon />
            ) : (
              <div
                className={classes.FormImagePreview}
                style={{ backgroundImage: `url('${imageUrl}')` }}
              />
            )}
          </div>
        </Grid>
        <Grid item className={classes.FormImageInfo}>
          <div className={classes.FormImageInfoTitle}>{title}</div>
          <div className={classes.FormImageInfoDescription}>{description}</div>
        </Grid>
      </Grid>
      {renderIf(error, () => (
        <div className={classes.FormImageError}>
          {t('common.formImageFile.error')}
        </div>
      ))}
      <input
        ref={fileInput}
        type="file"
        accept="image/png, image/jpeg"
        onChange={onChangeFileInput}
        hidden
      />
    </>
  )
}

export default FormImageFile
