import {
  type ProfileNetwork,
  type ProfileNetworkContext,
} from 'App/Profiles/ProfilesView/NetworkSettings/network-settings-types'
import axios from 'axios'
import { API_BASE_URL } from 'common/constants'

class ProfileNetworksApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchProfileNetworks({
    organizationId,
    siteId,
    profileId,
  }: ProfileNetworkContext): Promise<ProfileNetwork[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/networks`
    const { data } = await axios.get(apiUrl)

    return data as ProfileNetwork[]
  }

  static async createProfileNetwork({
    context,
    networkId,
  }: {
    context: ProfileNetworkContext
    networkId: number
  }): Promise<ProfileNetwork> {
    const { organizationId, siteId, profileId } = context
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/networks/${networkId}`
    const { data } = await axios.post(apiUrl)
    return data as ProfileNetwork
  }

  static async deleteProfileNetwork({
    context,
    networkId,
  }: {
    context: ProfileNetworkContext
    networkId: number
  }): Promise<void> {
    const { organizationId, siteId, profileId } = context
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/networks/${networkId}`
    await axios.delete(apiUrl)
  }
}

export default ProfileNetworksApi
