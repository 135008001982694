import React, { useEffect, useState } from 'react'

import FormField from 'common/components/FormField'

import {
  type Site,
  type SiteForm,
  resetUpdatedSite,
  updateSite,
  fetchSites,
} from 'App/Sites/sites-state'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import useApp from 'common/hooks/useApp'
import { useAppDispatch } from 'store'

interface EditSiteDrawerProps {
  site?: Site
  open: boolean
  onClose: () => void
  updatedSite: boolean
}

interface FieldErrors {
  name?: string
}

const initialSiteForm = {
  name: '',
}

const EditSiteDrawer = ({
  site,
  open,
  onClose,
  updatedSite,
}: EditSiteDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organization } = useApp()

  const [siteForm, setSiteForm] = useState<SiteForm>(initialSiteForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  useEffect(() => {
    if (updatedSite) {
      setSiteForm(initialSiteForm)
      onClose()
      dispatch(resetUpdatedSite())
      dispatch(fetchSites({ organizationId: organization.id }))
    }
  }, [dispatch, onClose, updatedSite, organization, site])

  useEffect(() => {
    if (site != null) {
      setSiteForm(site)
    }
  }, [site])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setSiteForm({ ...siteForm, [key]: e.target.value })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    if (site == null) {
      return false
    }

    let hasError = false
    const errors: FieldErrors = {}

    if (siteForm.name === '') {
      hasError = true
      errors.name = t('sites.editSite.errors.emptyField')
    }

    if (!hasError) {
      dispatch(
        updateSite({
          siteId: site.id,
          form: siteForm,
          organizationId: organization.id,
        })
      )
    }
    setFieldErrors(errors)
  }

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('sites.editSite.title')}
      submitText={t('sites.editSite.buttons.submit')}
    >
      <FormField
        label={t('sites.editSite.siteNameField.label')}
        placeholder={t('sites.editSite.siteNameField.placeholder')}
        value={siteForm.name}
        onChange={onChangeField('name')}
        autoFocus
        error={fieldErrors.name}
      />
    </DrawerForm>
  )
}

export default EditSiteDrawer
