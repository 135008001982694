import React, {
  useState,
  type PropsWithChildren,
  type FormEventHandler,
  useEffect,
} from 'react'

import { type Theme, Grid, Box, Link } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import EmailIcon from '@mui/icons-material/EmailOutlined'

import logo from 'common/hublet-manager-logo.svg'
import Button from 'common/components/Button'
import { Trans, useTranslation } from 'react-i18next'
import FormField from 'common/components/FormField'
import SelectLanguage from 'common/components/SelectLanguage'

import { Link as RouterLink, Route, Routes } from 'react-router-dom'
import {
  resetSentForgotPasswordEmail,
  sendForgotPasswordEmail,
} from 'App/ForgotPassword/forgot-password-state'
import { useAppDispatch, useAppSelector } from 'store'
import { renderIf } from 'common/utils/render-utils'
import ForgotPasswordReset from 'App/ForgotPassword/ForgotPasswordReset'
import useLocationQuery from 'common/hooks/useLocationQuery'

const useStyles = makeStyles()((theme: Theme) => ({
  ForgotPasswordContainer: {
    minHeight: '100vh',
    margin: '0 auto',
    '& > div': {
      maxWidth: '324px !important',
      width: '100%',
    },
  },
  ForgotPasswordLogo: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    lineHeight: '1',
    '& > img': {
      width: '152px',
      margin: '-4px',
    },
  },
  ForgotPasswordTitle: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  ForgotPasswordInfoText: {
    maxWidth: '100% !important',
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    fontSize: '16px',
  },
  ForgotPasswordInput: {
    marginBottom: theme.spacing(2),
  },
  ForgotPasswordGoBack: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  SelectLanguage: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

type ForgotPasswordGridProps = PropsWithChildren

const ForgotPasswordGrid = ({ children }: ForgotPasswordGridProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.ForgotPasswordContainer}
    >
      {children}
      <Grid className={classes.ForgotPasswordGoBack} item xs={12}>
        <Link component={RouterLink} to={'/login'} underline="hover">
          {t('forgotPassword.goBack')}
        </Link>
      </Grid>
    </Grid>
  )
}

const HubletLogo = () => <img src={logo} alt="Hublet" />

interface ForgotPasswordFormProps {
  email: string
  onChange: (value: string) => void
}

const ForgotPasswordForm = ({ email, onChange }: ForgotPasswordFormProps) => {
  const { classes } = useStyles()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const query = useLocationQuery()
  const [error, setError] = useState<string | undefined>(undefined)

  const onSubmitForm: FormEventHandler = (e) => {
    e.preventDefault()
    if (email === '') {
      setError(t('forgotPassword.errors.missingEmail'))
    } else {
      setError(undefined)
      dispatch(sendForgotPasswordEmail({ email, language: i18n.language }))
    }
  }

  useEffect(() => {
    if (email !== '') {
      return
    }

    const queryEmail = query.get('email')
    if (queryEmail !== null) {
      onChange(queryEmail)
    }
  }, [query, email, onChange])

  return (
    <form onSubmit={onSubmitForm}>
      <ForgotPasswordGrid>
        <Grid item xs={12} className={classes.ForgotPasswordLogo}>
          <HubletLogo />
        </Grid>
        <Grid item xs={12} className={classes.ForgotPasswordTitle}>
          {t('forgotPassword.title')}
        </Grid>
        <Grid item xs={12} className={classes.ForgotPasswordInfoText}>
          {t('forgotPassword.info')}
        </Grid>
        <Grid item xs={12} className={classes.ForgotPasswordInput}>
          <FormField
            placeholder={t('forgotPassword.placeholders.email')}
            value={email}
            onChange={(e) => onChange(e.target.value)}
            Icon={EmailIcon}
            autoFocus
            error={error}
            rounded
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit">{t('forgotPassword.button')}</Button>
        </Grid>
      </ForgotPasswordGrid>
    </form>
  )
}

interface ForgotPasswordSentProps {
  email: string
}

const ForgotPasswordSent = ({ email }: ForgotPasswordSentProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  useEffect(() => {
    return () => {
      dispatch(resetSentForgotPasswordEmail())
    }
  })

  return (
    <ForgotPasswordGrid>
      <Grid item xs={12} className={classes.ForgotPasswordLogo}>
        <HubletLogo />
      </Grid>
      <Grid item xs={12} className={classes.ForgotPasswordTitle}>
        {t('forgotPassword.sent.title')}
      </Grid>
      <Grid item xs={12} className={classes.ForgotPasswordInfoText}>
        <Trans i18nKey="forgotPassword.sent.info" t={t} values={{ email }} />
      </Grid>
    </ForgotPasswordGrid>
  )
}

const ForgotPassword = () => {
  const { classes } = useStyles()
  const query = useLocationQuery()

  const [email, setEmail] = useState('')

  const sentForgotPasswordEmail = useAppSelector(
    (state) => state.forgotPassword.sentForgotPasswordEmail
  )

  useEffect(() => {
    if (email !== '') {
      return
    }

    const queryEmail = query.get('email')
    if (queryEmail !== null) {
      setEmail(queryEmail)
    }
  }, [query, email])

  const onChangeEmail = (value: string) => setEmail(value)

  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Box className={classes.SelectLanguage}>
              <SelectLanguage />
            </Box>
            {renderIf(!sentForgotPasswordEmail, () => (
              <ForgotPasswordForm email={email} onChange={onChangeEmail} />
            ))}
            {renderIf(sentForgotPasswordEmail, () => (
              <ForgotPasswordSent email={email} />
            ))}
          </>
        }
      />
      <Route path="/reset" element={<ForgotPasswordReset />} />
    </Routes>
  )
}

export default ForgotPassword
