import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Code,
  type CodesContext,
  type CodeEditable,
} from 'App/Codes/codes-types'

const dataToCodeObject = (data: any): Code => ({
  id: data.id,
  siteId: data.siteId,
  name: data.name,
  code: data.code,
  expiration: new Date(data.expiration),
  createdAt: new Date(data.createdAt),
  permissionType: data.permissionType,
  profileId: data.profileId,
  profileName: data.profileName,
  useCount: data.useCount,
})

class CodesApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchCodes({
    organizationId,
    siteId,
  }: CodesContext): Promise<Code[]> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/codes`
    const { data } = await axios.get(apiUrl)

    return data.map(dataToCodeObject)
  }

  static async createCode(
    { organizationId, siteId }: CodesContext,
    body: CodeEditable
  ): Promise<Code> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/codes`
    const { data } = await axios.post(apiUrl, body)
    return dataToCodeObject(data)
  }

  static async deleteCode(
    { organizationId, siteId }: CodesContext,
    codeId: number
  ): Promise<void> {
    const apiUrl = `${API_BASE_URL}/organizations/${organizationId}/sites/${siteId}/codes/${codeId}`
    await axios.delete(apiUrl)
  }
}

export default CodesApi
