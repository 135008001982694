import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ClearIcon from '@mui/icons-material/Clear'
import { type Tablet } from 'App/Tablets/tablets-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useLoadFactoryResetCodeQuery } from 'App/Tablets/tablets-rtk-api'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogPaper: {
    width: '505px',
    maxWidth: '100%',
    padding: '36px',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  DialogTitle: {
    padding: 0,
    '& > h2': {
      lineHeight: 1,
      fontSize: '24px',
      fontWeight: 'bold',
      color: theme.palette.hublet.primary.main,
    },
  },
  DialogHeadClose: {
    textAlign: 'right',
    color: theme.palette.hublet.common.black,
  },
  DialogCloseIcon: {
    cursor: 'pointer',
  },
  DialogContent: {
    padding: 0,
    marginTop: '16px',
    marginBottom: '20px',
    '& > p': {
      fontSize: '16px',
      color: theme.palette.hublet.common.black,
    },
  },

  Loader: {
    display: 'block',
    margin: '0 auto',
    marginBottom: theme.spacing(5),
  },
}))

interface ResetTabletPopupProps {
  onClose: () => void
  tablet: Tablet
}

const ResetTabletPopup = ({ onClose, tablet }: ResetTabletPopupProps) => {
  // const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { siteId, organizationId } = useCurrentAccount()
  const { data: qrcode } = useLoadFactoryResetCodeQuery({
    siteId,
    organizationId,
    tabletId: tablet.id,
  })

  return (
    <Dialog
      open={true}
      onClose={onClose}
      PaperProps={{
        className: classes.DialogPaper,
      }}
    >
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid className={classes.DialogHeadClose} item xs={6}>
          <ClearIcon className={classes.DialogCloseIcon} onClick={onClose} />
        </Grid>
      </Grid>
      <DialogContent className={classes.DialogContent}>
        {qrcode != null ? (
          <div dangerouslySetInnerHTML={{ __html: qrcode.svg }} />
        ) : (
          <CircularProgress className={classes.Loader} />
        )}
        <DialogContentText>
          {t('tablets.addTablet.description')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default ResetTabletPopup
