import { type Locale } from 'date-fns'
import formatDistance from './_lib/formatDistance/index'
import formatLong from './_lib/formatLong/index'
import formatRelative from './_lib/formatRelative/index'
import localize from './_lib/localize/index'
import match from './_lib/match/index'

/**
 * @type {Locale}
 * @category Locales
 * @summary Irish locale.
 * @language Irish
 * @link https://www.unicode.org/cldr/charts/42/summary/ga.html
 * @iso-639-1 ga
 */
const locale: Locale = {
  code: 'ga',
  formatDistance,
  formatLong,
  formatRelative,
  localize,
  match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 4,
  },
}

export default locale
