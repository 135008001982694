import { type User } from 'App/Users/users-types'
import { listToQueryParams } from 'common/utils/api-utils'
import rtkApi from 'rtk-api'

interface UsersApi {
  withDeleted?: boolean
  organizationId: number
  siteId?: number
}
type UserApi = UsersApi & { userId: number }

const getPathContext = (organizationId: number, siteId?: number) => {
  let path = `/organizations/${organizationId}`

  if (siteId !== undefined && siteId !== -1) {
    path += `/sites/${siteId}`
  }

  return path
}

const usersRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], UsersApi>({
      query: ({ withDeleted = false, organizationId, siteId }) =>
        `${getPathContext(organizationId, siteId)}/users?withDeleted=${String(
          withDeleted
        )}`,
      transformResponse: (data: any[]) =>
        data.map((item) => ({
          ...item,
          siteId: item.siteId ?? undefined,
          deletedAt: item.deletedAt ?? undefined,
          resetPasswordSentAt: item.resetPasswordSentAt ?? undefined,
        })),
    }),
    deleteUsers: builder.mutation<null, UsersApi & { userIds: number[] }>({
      query: ({ organizationId, siteId, userIds }) => ({
        url: `${getPathContext(
          organizationId,
          siteId
        )}/users?${listToQueryParams('userIds', userIds)}`,
        method: 'DELETE',
      }),
    }),
    undeleteUsers: builder.mutation<null, UsersApi & { userIds: number[] }>({
      query: ({ organizationId, siteId, userIds }) => ({
        url: `${getPathContext(
          organizationId,
          siteId
        )}/users/undelete?${listToQueryParams('userIds', userIds)}`,
        method: 'POST',
      }),
    }),
    undeleteUser: builder.mutation<null, UserApi>({
      query: ({ organizationId, siteId, userId }) => ({
        url: `${getPathContext(
          organizationId,
          siteId
        )}/users/${userId}/undelete`,
        method: 'POST',
      }),
    }),
    unlockUser: builder.mutation<null, UserApi>({
      query: ({ organizationId, siteId, userId }) => ({
        url: `${getPathContext(organizationId, siteId)}/users/${userId}/unlock`,
        method: 'POST',
      }),
    }),
    unlockUsers: builder.mutation<null, UsersApi & { userIds: number[] }>({
      query: ({ organizationId, siteId, userIds }) => ({
        url: `${getPathContext(
          organizationId,
          siteId
        )}/users/unlock?${listToQueryParams('userIds', userIds)}`,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useDeleteUsersMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useUndeleteUsersMutation,
  useUndeleteUserMutation,
  useUnlockUserMutation,
  useUnlockUsersMutation,
} = usersRtkApi

export default usersRtkApi
