import React, { useRef } from 'react'
import { type Theme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  stack: {
    width: '100%',
    paddingBottom: '20px',
  },
  alert: {},
}))

interface WarningProps {
  content: string | React.ReactNode
  canClose?: boolean
}

const Warning = ({ content, canClose = false }: WarningProps) => {
  const warningRef = useRef<HTMLDivElement | null>(null)
  const { classes } = useStyles()

  const handleClose = () => {
    if (warningRef.current) {
      warningRef.current.remove()
    }
  }

  return (
    <Stack ref={warningRef} className={classes.stack}>
      <Alert
        className={classes.alert}
        onClose={canClose ? handleClose : undefined}
        severity="warning"
      >
        {content}
      </Alert>
    </Stack>
  )
}

export default Warning
