import React, { useEffect, useMemo, useState } from 'react'
import {
  type Integration,
  type IntegrationParameter,
  ParamDataType,
} from 'App/Integrations/integrations-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import {
  useGetIntegrationParametersQuery,
  useUpdateIntegrationParametersMutation,
} from 'App/Integrations/integrations-rtk-api'
import Panel, { EditState } from 'common/components/NewPanel'
import PanelField from 'common/components/PanelField'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import FormSwitch from 'common/components/FormSwitch'
import useApiErrors from 'common/hooks/useApiErrors'
import ErrorBox from 'common/components/ErrorBox'
import { useTranslation } from 'react-i18next'
import useProfiles from 'common/hooks/useProfiles'
import { type Profile } from 'App/Profiles/profiles-types'
import PasswordField from 'common/components/PasswordField'

interface IntegrationParameterFieldProps {
  param: IntegrationParameter
  profiles: Profile[]
  updatedValue?: string
  onChange: (paramKey: string, paramValue: string) => void
  disabled: boolean
  error?: string
}

const IntegrationParameterField = ({
  param: p,
  profiles,
  updatedValue,
  onChange,
  disabled,
  error,
}: IntegrationParameterFieldProps) => {
  const { t } = useTranslation()
  const info = p.hint === undefined ? undefined : { description: p.hint }
  const options = useMemo(() => {
    if (
      p.paramDataType === ParamDataType.SelectOne ||
      p.paramDataType === ParamDataType.SelectMulti
    ) {
      return p.values?.map((v) => ({ label: v.toString(), value: v })) ?? []
    } else if (p.paramDataType === ParamDataType.Profile) {
      const profileOptions = profiles.map((profile) => ({
        label: profile.name,
        value: profile.id.toString(),
      }))
      return [
        {
          label: t('integrations.integrationForm.fields.paramProfile.default'),
          value: '',
        },
        ...profileOptions,
      ]
    } else {
      return []
    }
  }, [t, p, profiles])

  const value = updatedValue ?? p.paramValue

  switch (p.paramDataType) {
    case ParamDataType.String:
      return (
        <FormField
          label={`${p.label}${p.required ? ' *' : ''}`}
          placeholder={t(
            'integrations.integrationForm.fields.paramString.placeholder'
          )}
          value={value !== '' ? value : (p.default as string | undefined)}
          info={info}
          onChange={(e) => onChange(p.paramKey, e.target.value)}
          disabled={disabled}
          error={error}
        />
      )
    case ParamDataType.Number:
      return (
        <FormField
          type="number"
          label={`${p.label}${p.required ? ' *' : ''}`}
          placeholder={t(
            'integrations.integrationForm.fields.paramNumber.placeholder'
          )}
          value={value !== '' ? value : (p.default as number | undefined)}
          info={info}
          onChange={(e) => onChange(p.paramKey, e.target.value)}
          disabled={disabled}
          error={error}
        />
      )
    case ParamDataType.Boolean:
      return (
        <FormSwitch
          title={`${p.label}${p.required ? ' *' : ''}`}
          checked={value !== '' ? value === 'true' : p.default === true}
          variant="drawer"
          onChange={(_e, checked) =>
            onChange(p.paramKey, checked ? 'true' : 'false')
          }
          disabled={disabled}
        />
      )
    case ParamDataType.Secure:
      return (
        <PasswordField
          label={`${p.label}${p.required ? ' *' : ''}`}
          placeholder={t(
            'integrations.integrationForm.fields.paramSecret.placeholder'
          )}
          value={value}
          info={info}
          onChange={(e) => onChange(p.paramKey, e.target.value)}
          disabled={disabled}
          error={error}
        />
      )
    case ParamDataType.SelectOne:
      return (
        <FormSelect
          label={`${p.label}${p.required ? ' *' : ''}`}
          placeholder={t(
            'integrations.integrationForm.fields.paramSelOne.placeholder'
          )}
          value={
            value !== '' ? value : (p.default as string | number | undefined)
          }
          options={options}
          info={info}
          onChange={(_e, v) => onChange(p.paramKey, v?.toString() ?? '')}
          disabled={disabled}
          error={error}
        />
      )
    case ParamDataType.SelectMulti:
      return (
        <FormSelect
          label={`${p.label}${p.required ? ' *' : ''}`}
          placeholder={t(
            'integrations.integrationForm.fields.paramSelMul.placeholder'
          )}
          value={(value !== ''
            ? value.split(',')
            : (p.default as string | undefined)?.split(',') ?? []
          )?.filter((v) => v !== '')}
          options={options}
          info={info}
          onChange={(e) => onChange(p.paramKey, e.target.value.join(','))}
          multiple
          disabled={disabled}
          error={error}
        />
      )
    case ParamDataType.Profile:
      return (
        <FormSelect
          label={`${p.label}${p.required ? ' *' : ''}`}
          placeholder={t(
            'integrations.integrationForm.fields.paramSelOne.placeholder'
          )}
          value={value}
          info={info}
          disabled={disabled}
          error={error}
          options={options}
          onChange={(_e, v) => onChange(p.paramKey, v?.toString() ?? '')}
        />
      )
  }
}

interface IntegrationParametersProps {
  integration: Integration
}

const IntegrationParameters = ({ integration }: IntegrationParametersProps) => {
  const { t } = useTranslation()
  const [editState, setEditState] = useState<EditState>()

  const { organizationId, siteId } = useCurrentAccount()
  const {
    data: parameters = [],
    isSuccess,
    error,
    refetch: refetchParams,
  } = useGetIntegrationParametersQuery({
    organizationId,
    siteId,
    integrationId: integration.id,
    url: integration.authApiUrl ?? '',
  })

  const [paramErrors, setParamErrors] = useState<Record<string, string>>({})
  const [paramValues, setParamValues] = useState<Record<string, string>>({})

  const [
    updateIntegrationParameters,
    { isSuccess: isUpdateSuccess, error: updateIntegrationParametersError },
  ] = useUpdateIntegrationParametersMutation()

  const [hasApiQueryErrors, apiQueryErrorsMsg] = useApiErrors([error])
  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateIntegrationParametersError,
  ])

  const hasProfileParameter = useMemo(() => {
    return parameters.some((p) => p.paramDataType === ParamDataType.Profile)
  }, [parameters])

  const profiles = useProfiles(hasProfileParameter)

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    }

    if (isUpdateSuccess) {
      setEditState(EditState.Success)
      refetchParams()
    }
  }, [hasApiMutationErrors, isUpdateSuccess, refetchParams])

  const handleChangeParamValue = (paramKey: string, paramValue: string) => {
    setParamValues((prevValues) => ({ ...prevValues, [paramKey]: paramValue }))
  }

  const validateParams = (): [boolean, Record<string, string>] => {
    const errors: Record<string, string> = {}
    let hasErrors = false

    parameters.forEach((p) => {
      const value = paramValues[p.paramKey] ?? p.paramValue
      if (p.required && value === '' && (p.default ?? '') === '') {
        errors[p.paramKey] = t('common.errors.emptyField')
        hasErrors = true
      }
    })

    return [hasErrors, errors]
  }

  const handleSave = () => {
    const [hasErrors, errors] = validateParams()

    setParamErrors(errors)

    if (hasErrors) {
      return
    }

    const params = Object.keys(paramValues).map((paramKey) => ({
      paramKey,
      paramValue: paramValues[paramKey],
    }))
    updateIntegrationParameters({
      organizationId,
      siteId,
      integrationId: integration.id,
      params,
    })
    setEditState(EditState.Pending)
  }

  const renderEditMode = () =>
    parameters?.map((param) => (
      <IntegrationParameterField
        key={param.paramKey}
        profiles={profiles}
        param={param}
        updatedValue={paramValues[param.paramKey]}
        onChange={handleChangeParamValue}
        disabled={editState === EditState.Pending}
        error={paramErrors[param.paramKey]}
      />
    ))

  if (hasApiQueryErrors) {
    return <ErrorBox>{apiQueryErrorsMsg}</ErrorBox>
  }

  const renderParamValue = (p: IntegrationParameter) => {
    if (p.paramValue !== '') {
      if (p.paramDataType === ParamDataType.Profile) {
        return profiles.find((profile) => profile.id === parseInt(p.paramValue))
          ?.name
      } else if (p.paramDataType === ParamDataType.Secure) {
        return p.paramValue
      } else {
        return p.paramValue
      }
    }

    if (p.paramDataType === ParamDataType.Profile) {
      return t('integrations.integrationForm.fields.paramProfile.default')
    }

    const defaultValue =
      p.default !== undefined && p.default !== ''
        ? p.default.toString()
        : undefined

    if (
      p.paramDataType === ParamDataType.Secure &&
      defaultValue !== undefined
    ) {
      return defaultValue
    }

    return defaultValue
  }

  return (
    <Panel
      title={t('integrations.integrationForm.parameters.label')}
      editable={parameters.length > 0}
      editState={editState}
      onEdit={() => setEditState(EditState.Edit)}
      onCancel={() => setEditState(undefined)}
      onSave={handleSave}
      renderEditMode={renderEditMode}
      error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
    >
      {isSuccess && parameters.length === 0 ? (
        <em>{t('integration.parameters.emptyParameters')}</em>
      ) : (
        parameters.map((p) => (
          <PanelField
            key={p.paramKey}
            title={p.label}
            value={renderParamValue(p)}
          />
        ))
      )}
    </Panel>
  )
}

export default IntegrationParameters
