import type React from 'react'
import { useEffect, useState } from 'react'
import { type Website, type WebsiteForm } from 'App/Categories/categories-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useTranslation } from 'react-i18next'
import {
  addWebsite,
  fetchContents,
  resetAddWebsite,
  resetUpdateWebsite,
  updateWebsite,
  uploadWebsiteCustomIcon,
  resetUploadWebsiteCustomIcon,
} from 'App/Categories/contents-state'
import { useAppDispatch, useAppSelector } from 'store'

export interface WebsiteFormErrors {
  name?: string
  url?: string
}

const initialWebsiteForm: WebsiteForm = {
  name: '',
  description: '',
  url: '',
  fullscreen: false,
}

const useWebsiteForm = (
  profileId: number,
  categoryId: number,
  website?: Website
): any => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { siteId, organizationId } = useCurrentAccount()
  const context = { categoryId, profileId, siteId, organizationId }
  const updatedWebsite = useAppSelector(
    (state) => state.contents.updatedWebsite
  )
  const addedWebsite = useAppSelector((state) => state.contents.addedWebsite)
  const uploadedWebsiteCustomIconUrl = useAppSelector(
    (state) => state.contents.uploadedWebsiteCustomIconUrl
  )

  const [websiteForm, setWebsiteForm] =
    useState<WebsiteForm>(initialWebsiteForm)
  const [fieldErrors, setFieldErrors] = useState<WebsiteFormErrors>({})

  useEffect(() => {
    if (website != null) {
      setWebsiteForm(website)
    }
  }, [website])

  useEffect(() => {
    if (
      uploadedWebsiteCustomIconUrl !== null &&
      uploadedWebsiteCustomIconUrl !== websiteForm.customIcon
    ) {
      setWebsiteForm({
        ...websiteForm,
        customIcon: uploadedWebsiteCustomIconUrl,
      })
      dispatch(resetUploadWebsiteCustomIcon())
    }
  }, [dispatch, uploadedWebsiteCustomIconUrl, websiteForm])

  useEffect(() => {
    if (updatedWebsite) {
      dispatch(resetUpdateWebsite())
      dispatch(fetchContents({ categoryId, profileId, siteId, organizationId }))
    }
  }, [dispatch, updatedWebsite, categoryId, profileId, siteId, organizationId])

  useEffect(() => {
    if (addedWebsite) {
      setWebsiteForm(initialWebsiteForm)
      dispatch(resetAddWebsite())
      dispatch(fetchContents({ categoryId, profileId, siteId, organizationId }))
    }
  }, [dispatch, addedWebsite, categoryId, profileId, siteId, organizationId])

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setWebsiteForm({ ...websiteForm, [key]: e.target.value })
    }

  const handleSwitchChange = (key: string) => (_e: any, checked: boolean) => {
    setWebsiteForm({ ...websiteForm, [key]: checked })
  }

  const validateForm = (): boolean => {
    let hasError = false
    const errors: WebsiteFormErrors = {}

    if (websiteForm.name === '') {
      hasError = true
      errors.name = t('contents.websiteForm.errors.emptyField')
    }

    if (websiteForm.url === '') {
      hasError = true
      errors.url = t('contents.websiteForm.errors.emptyField')
    }

    setFieldErrors(errors)

    return hasError
  }

  const handleUpdate = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (website == null || !website.id) {
      return
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(updateWebsite({ context, id: website.id, form: websiteForm }))
    }
  }

  const handleAdd = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (!websiteForm) {
      return false
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(addWebsite({ context, form: websiteForm }))
    }
  }

  const handleUploadCustomIcon = (imageFile: File) => {
    dispatch(uploadWebsiteCustomIcon({ context, imageFile }))
  }

  return {
    websiteForm,
    fieldErrors,
    handleFieldChange,
    handleSwitchChange,
    handleUploadCustomIcon,
    handleUpdate,
    handleAdd,
    updatedWebsite,
    addedWebsite,
  }
}

export default useWebsiteForm
