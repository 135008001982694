import { type Theme, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { type PropsWithChildren } from 'react'

const useStyles = makeStyles()((_theme: Theme) => ({
  FormFieldGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

interface FormFieldGroupProps extends PropsWithChildren {
  gap?: number
}

const FormFieldGroup = ({ gap = 4, children }: FormFieldGroupProps) => {
  const { classes: styles } = useStyles()
  const theme = useTheme()

  return (
    <div className={styles.FormFieldGroup} style={{ gap: theme.spacing(gap) }}>
      {children}
    </div>
  )
}

export default FormFieldGroup
