import rtkApi from 'rtk-api'

interface ProfilesApi {
  organizationId: number
  siteId: number
}
type ProfileApi = ProfilesApi & { profileId: number }

interface UpdateLoansConfigReq {
  loanDuration: number
}

const profilesRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    updateProfileLoansConfig: builder.mutation<
      null,
      ProfileApi & UpdateLoansConfigReq
    >({
      query: ({ organizationId, siteId, profileId, ...body }) => ({
        url: `/organizations/${organizationId}/sites/${siteId}/profiles/${profileId}/loans`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const { useUpdateProfileLoansConfigMutation } = profilesRtkApi

export default profilesRtkApi
