import React, { type PropsWithChildren, useState } from 'react'
import {
  Box,
  Grid,
  type Theme,
  List,
  ListItem,
  ListSubheader,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import logo from 'common/hublet-manager-logo.svg'
import { Link, NavLink } from 'react-router-dom'
import {
  type CurrentUser,
  invalidateCurrentUser,
  UserRole,
} from 'App/app-state'
import { connect } from 'react-redux'
import { type RootState, useAppDispatch } from 'store'
import { useTranslation } from 'react-i18next'

import MenuIcon from '@mui/icons-material/MenuOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import DashboardIcon from '@mui/icons-material/DashboardOutlined'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectoryOutlined'
import SplitIcon from '@mui/icons-material/CallSplitOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined'
import PersonIcon from '@mui/icons-material/PersonOutlined'
import WifiIcon from '@mui/icons-material/WifiOutlined'
import VideoLabelIcon from '@mui/icons-material/VideoLabelOutlined'
import TabletAndroidIcon from '@mui/icons-material/TabletAndroidOutlined'
import EventIcon from '@mui/icons-material/EventOutlined'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import KeyIcon from '@mui/icons-material/VpnKeyOutlined'
import MobileFriendyIcon from '@mui/icons-material/MobileFriendly'
import GroupIcon from '@mui/icons-material/GroupOutlined'
import BuildIcon from '@mui/icons-material/BuildOutlined'
import TranslateIcon from '@mui/icons-material/TranslateOutlined'
import InsightsIcon from '@mui/icons-material/Insights'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import HelpCenterIcon from '@mui/icons-material/HelpCenterOutlined'

import { renderItems } from 'common/utils/render-utils'
import IconAction from 'common/components/IconAction'
import useApp from 'common/hooks/useApp'
import UserAccount from 'common/components/UserAccount'
import AccountSelect from 'common/components/AccountSelect'
import SelectLanguage from 'common/components/SelectLanguage'
import { type SvgIconComponent } from '@mui/icons-material'
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined'

const useStyles = makeStyles()((theme: Theme) => ({
  PageHead: {
    height: '80px',
    padding: '0 24px',
    backgroundColor: theme.palette.hublet.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  AccountContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  Page: {
    height: '100%',
    minHeight: '100vh',
  },
  PageMain: {
    position: 'relative',
    padding: '48px 48px 96px 48px',
  },
  PageLeft: {
    position: 'relative',
    maxWidth: '280px',
    minWidth: '280px',
  },
  PageLeftHidden: {
    display: 'none',
  },
  PageLeftMenu: {
    flexGrow: 1,
    '& > nav': {
      marginTop: '36px',
      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  PageLeftContainer: {
    position: 'fixed',
    zIndex: 999,
    top: 0,
    left: 0,
    width: 'inherit',
    maxWidth: 'inherit',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.hublet.common.white,
    boxShadow: '0px 4px 30px rgba(108, 37, 118, 0.1)',
  },
  PageNavSubheader: {
    marginBottom: '8px',
    padding: '0 32px',
    fontSize: '14px',
    textTransform: 'uppercase',
    fontWeight: 'normal',
    color: theme.palette.hublet.secondary.light,
    lineHeight: '19px',
  },
  PageNavItem: {
    padding: '12px 0',
  },
  PageNavLink: {
    display: 'block',
    width: '100%',
    padding: '4px 32px 4px 28px',
    borderLeft: '4px solid transparent',
    lineHeight: '1',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 'bold',
    'a&': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.hublet.common.black,
      textTransform: 'capitalize',
    },
    '&:hover': {
      borderLeftColor: theme.palette.hublet.primary.main,
    },
  },
  PageNavLinkIcon: {
    display: 'inline-block',
    marginLeft: '-3px',
    marginRight: '15px',
    verticalAlign: 'middle',
    fontSize: '24px',
    color: theme.palette.hublet.common.black,
  },
  PageNavLinkText: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  PageNavExternalIcon: {
    marginLeft: '4px',
    float: 'right',
    fontSize: '14px',
  },
  PageNavActive: {
    'a&': {
      color: theme.palette.hublet.primary.main,
    },
    borderLeftColor: theme.palette.hublet.primary.main,
  },
  PageLogo: {
    textAlign: 'center',
    padding: '29px',
    lineHeight: '1',
    '& a': {
      display: 'inline-block',
    },
    '& img': {
      width: '111px',
      height: '70px',
      margin: '4px',
    },
  },
  PageSelectLanguage: {
    padding: theme.spacing(2),
  },
  PageRight: {
    flexGrow: 1,
  },
  PageRightContainer: {
    height: '100%',
    alignContent: 'flex-start',
  },
  PageBackgroundContainer: {
    position: 'relative',
    width: '100%',
  },
  PageBackground: {
    position: 'absolute',
    zIndex: -100,
    width: '100%',
    height: '550px',
    backgroundColor: '#ddd',
    backgroundSize: 'cover',
  },
  PageBackgroundGradient: {
    width: '100%',
    height: '100%',
    backgroundImage:
      'linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 55.62%)',
  },
  MenuIconAction: {
    verticalAlign: 'middle',
    marginRight: '13px',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
}))

interface MenuItem {
  title: string
  Icon: SvgIconComponent
  link: string
  visible: boolean
  isExternal?: boolean
}

const menuAccountItems = (role: UserRole, adminMode: boolean): MenuItem[] => [
  {
    title: 'app.nav.dashboard',
    Icon: DashboardIcon,
    link: '/dashboard',
    visible: true,
  },
  {
    title: 'app.nav.distributors',
    Icon: SplitIcon,
    link: '/distributors',
    visible: adminMode,
  },
  {
    title: 'app.nav.customers',
    Icon: StoreMallDirectoryIcon,
    link: '/customers',
    visible:
      role === UserRole.HubletAdmin || role === UserRole.DistributorAdmin,
  },
  {
    title: 'app.nav.sites',
    Icon: LocationOnIcon,
    link: '/sites',
    visible:
      role === UserRole.HubletAdmin ||
      role === UserRole.DistributorAdmin ||
      role === UserRole.OrgAdmin,
  },
  {
    title: 'app.nav.profiles',
    Icon: PersonIcon,
    link: '/profiles',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.posts',
    Icon: EventIcon,
    link: '/posts',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.loans',
    Icon: MobileFriendyIcon,
    link: '/loans',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.codes',
    Icon: KeyIcon,
    link: '/codes',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.topapps',
    Icon: AnalyticsIcon,
    link: '/top-apps',
    visible: adminMode,
  },
  {
    title: 'app.nav.helpCenter',
    Icon: HelpCenterIcon,
    link: '/zendesk',
    visible: true,
    isExternal: true,
  },
]

const menuAdministrationItems = (
  role: UserRole,
  adminMode: boolean
): MenuItem[] => [
  {
    title: 'app.nav.users',
    Icon: GroupIcon,
    link: '/users',
    visible:
      role === UserRole.HubletAdmin ||
      role === UserRole.DistributorAdmin ||
      role === UserRole.OrgAdmin ||
      role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.settings',
    Icon: SettingsIcon,
    link: '/site-settings',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.settings',
    Icon: SettingsIcon,
    link: '/distributor-settings',
    visible: role === UserRole.DistributorAdmin,
  },
  {
    title: 'app.nav.settings',
    Icon: SettingsIcon,
    link: '/customer-settings',
    visible: role === UserRole.OrgAdmin,
  },
  {
    title: 'app.nav.networks',
    Icon: WifiIcon,
    link: '/networks',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.integrations',
    Icon: BuildIcon,
    link: '/integrations',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.integrationStatistics',
    Icon: InsightsIcon,
    link: '/integration-statistics',
    visible: adminMode,
  },
  {
    title: 'app.nav.tablets',
    Icon: TabletAndroidIcon,
    link: '/tablets',
    visible: role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.docks',
    Icon: VideoLabelIcon,
    link: '/docks',
    visible:
      role === UserRole.HubletAdmin ||
      role === UserRole.DistributorAdmin ||
      role === UserRole.OrgAdmin ||
      role === UserRole.SiteAdmin,
  },
  {
    title: 'app.nav.translations',
    Icon: TranslateIcon,
    link: '/translations',
    visible: adminMode || role === UserRole.Translator,
  },
  {
    title: 'Super-admin',
    Icon: AssignmentIcon,
    link: '/admin',
    visible: adminMode,
  },
]

const menuManufacturerItems = (): MenuItem[] => [
  {
    title: 'app.nav.docks',
    Icon: VideoLabelIcon,
    link: '/manufacturer/docks',
    visible: true,
  },
]

interface PageProps extends PropsWithChildren {
  currentUser?: CurrentUser
  bgImageUrl?: string | null
}

const Page = ({ children, currentUser, bgImageUrl }: PageProps) => {
  const dispatch = useAppDispatch()
  const { classes, cx } = useStyles()
  const { t } = useTranslation()

  const [showSidebar, setShowSidebar] = useState(true)

  const { role, adminMode } = useApp()

  if (currentUser == null) {
    return null
  }

  if (currentUser.expiresAt < Date.now()) {
    dispatch(invalidateCurrentUser())
    return null
  }

  const onClickMenuIcon = () => setShowSidebar(!showSidebar)

  const pageLeftClassNames = [classes.PageLeft]

  const isManufacturerAdmin =
    currentUser.isManufacturer &&
    (role === UserRole.OrgAdmin || role === UserRole.DistributorAdmin)

  const handleNavLinkClassName = ({ isActive }: { isActive: boolean }) => {
    const classNames = [classes.PageNavLink]
    if (isActive) {
      classNames.push(classes.PageNavActive)
    }
    return cx(classNames)
  }

  if (!showSidebar) {
    pageLeftClassNames.push(classes.PageLeftHidden)
  }

  return (
    <>
      <Grid container className={classes.Page} wrap="nowrap">
        <Grid
          item
          container
          className={cx(pageLeftClassNames)}
          direction="column"
        >
          <Grid
            container
            className={classes.PageLeftContainer}
            direction="column"
            wrap="nowrap"
          >
            <Grid item className={classes.PageLogo}>
              <Link to="/">
                <img src={logo} alt="Hublet" />
              </Link>
            </Grid>
            <Grid item className={classes.PageLeftMenu}>
              <List
                component="nav"
                subheader={
                  <ListSubheader
                    className={classes.PageNavSubheader}
                    disableSticky
                  >
                    {t('app.nav.account')}
                  </ListSubheader>
                }
              >
                {renderItems(
                  menuAccountItems(role, adminMode),
                  ({ link, title, Icon, visible, isExternal }) => {
                    if (!visible) return null

                    return (
                      <ListItem className={classes.PageNavItem} key={link}>
                        <NavLink
                          to={link}
                          className={handleNavLinkClassName}
                          target={isExternal ? '_blank' : ''}
                          rel={isExternal ? 'noreferrer' : ''}
                        >
                          <Icon className={classes.PageNavLinkIcon} />
                          <Box className={classes.PageNavLinkText}>
                            {t(title)}
                          </Box>
                          {isExternal ? (
                            <LaunchIcon
                              className={classes.PageNavExternalIcon}
                            />
                          ) : null}
                        </NavLink>
                      </ListItem>
                    )
                  }
                )}
              </List>
              <List
                component="nav"
                subheader={
                  <ListSubheader
                    className={classes.PageNavSubheader}
                    disableSticky
                  >
                    {t('app.nav.administration')}
                  </ListSubheader>
                }
              >
                {renderItems(
                  menuAdministrationItems(role, adminMode),
                  ({ link, title, Icon, visible, isExternal }) => {
                    if (!visible) return null

                    return (
                      <ListItem className={classes.PageNavItem} key={link}>
                        <NavLink
                          to={link}
                          className={handleNavLinkClassName}
                          target={isExternal ? '_blank' : ''}
                          rel={isExternal ? 'noreferrer' : ''}
                        >
                          <Icon className={classes.PageNavLinkIcon} />
                          <Box className={classes.PageNavLinkText}>
                            {t(title)}
                          </Box>
                          {isExternal ? (
                            <LaunchIcon
                              className={classes.PageNavExternalIcon}
                            />
                          ) : null}
                        </NavLink>
                      </ListItem>
                    )
                  }
                )}
              </List>
              {(role === UserRole.HubletAdmin ||
                role === UserRole.Manufacturer ||
                isManufacturerAdmin) && (
                <List
                  component="nav"
                  subheader={
                    <ListSubheader
                      className={classes.PageNavSubheader}
                      disableSticky
                    >
                      {t('app.nav.manufacturer')}
                    </ListSubheader>
                  }
                >
                  {renderItems(
                    menuManufacturerItems(),
                    ({ link, title, Icon, visible, isExternal }) => {
                      if (!visible) return null

                      return (
                        <ListItem className={classes.PageNavItem} key={link}>
                          <NavLink
                            to={link}
                            className={handleNavLinkClassName}
                            target={isExternal ? '_blank' : ''}
                            rel={isExternal ? 'noreferrer' : ''}
                          >
                            <Icon className={classes.PageNavLinkIcon} />
                            <Box className={classes.PageNavLinkText}>
                              {t(title)}
                            </Box>
                            {isExternal ? (
                              <LaunchIcon
                                className={classes.PageNavExternalIcon}
                              />
                            ) : null}
                          </NavLink>
                        </ListItem>
                      )
                    }
                  )}
                </List>
              )}
              <List>
                <ListItem className={classes.PageNavItem}>
                  <a
                    href={window.location.origin}
                    className={classes.PageNavLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('app.nav.oldManager')}
                    <LaunchIcon className={classes.PageNavExternalIcon} />
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid
              item
              className={classes.PageSelectLanguage}
              container
              direction="column"
            >
              <SelectLanguage />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.PageRight}>
          <Grid className={classes.PageRightContainer} container>
            {bgImageUrl !== undefined && (
              <div className={classes.PageBackgroundContainer}>
                <Grid
                  className={classes.PageBackground}
                  style={{
                    backgroundImage:
                      bgImageUrl === null ? 'none' : `url('${bgImageUrl}')`,
                  }}
                >
                  <div className={classes.PageBackgroundGradient} />
                </Grid>
              </div>
            )}
            <Grid
              item
              xs={12}
              className={classes.PageHead}
              style={bgImageUrl ? { background: 'rgba(0,0,0,0.3)' } : {}}
            >
              <Grid item xs={6}>
                <IconAction
                  Icon={MenuIcon}
                  onClick={onClickMenuIcon}
                  variant="white"
                  className={classes.MenuIconAction}
                />
              </Grid>
              <Grid className={classes.AccountContainer}>
                <AccountSelect user={currentUser} />
                <UserAccount currentUser={currentUser} />
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.PageMain}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  currentUser: state.app.currentUser,
})

export default connect(mapStateToProps)(Page)
