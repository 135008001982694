import React, { useEffect, useMemo, useState } from 'react'

import Table from 'common/components/TableNew'

import { useTranslation } from 'react-i18next'
import { type Integration } from 'App/Integrations/integrations-types'
import IconMenu from 'common/components/IconMenu'

import EyeIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import ActionDialog from 'common/components/ActionDialog'
import {
  deleteIntegration,
  fetchIntegrations,
  resetDeleteIntegration,
  resetFetchedParameters,
} from 'App/Integrations/integrations-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import IntegrationDetailsDrawer from 'App/Integrations/components/IntegrationDetailsDrawer'
import StatusText from 'common/components/StatusText'
import { useAppDispatch, useAppSelector } from 'store'

interface IntegrationsRowActionsProps {
  integration: Integration
  onClickViewDetails: (integration: Integration) => void
  onClickDelete: (integration: Integration) => void
}

const IntegrationsRowActions = ({
  integration,
  onClickViewDetails,
  onClickDelete,
}: IntegrationsRowActionsProps) => {
  const actions = [
    {
      local: 'common.table.actions.viewDetails',
      fn: () => onClickViewDetails(integration),
      icon: EyeIcon,
    },
    {
      local: 'integrations.listIntegrations.actions.delete',
      fn: () => onClickDelete(integration),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const IntegrationsTable = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const integrations = useAppSelector(
    (state) => state.integrations.integrations
  )

  const [detailsIntegrationId, setDetailsIntegrationId] = useState<
    number | null
  >(null)
  const [deleteIntegrationValue, setDeleteIntegrationValue] =
    useState<Integration | null>(null)

  const deletedIntegration = useAppSelector(
    (state) => state.integrations.deletedIntegration
  )

  const { organizationId, siteId } = useCurrentAccount()

  const integrationDetailsValue = useMemo(() => {
    return integrations.find(({ id }) => id === detailsIntegrationId)
  }, [detailsIntegrationId, integrations])

  useEffect(() => {
    if (deletedIntegration) {
      dispatch(resetDeleteIntegration())
      dispatch(fetchIntegrations({ organizationId, siteId }))
    }
  }, [dispatch, deletedIntegration, organizationId, siteId])

  const handleClose = () => {
    setDetailsIntegrationId(null)
    dispatch(resetFetchedParameters())
  }

  const handleClickViewDetails = (integration: Integration) => {
    setDetailsIntegrationId(integration.id)
  }

  const handleClickDelete = (integration: Integration) => {
    setDeleteIntegrationValue(integration)
  }

  const handleActionDelete = () => {
    if (deleteIntegrationValue === null) {
      return
    }

    const data = {
      organizationId: deleteIntegrationValue.organizationId,
      siteId: deleteIntegrationValue.siteId,
      integrationId: deleteIntegrationValue.id,
    }

    dispatch(deleteIntegration(data))
  }

  const columns = [
    {
      title: t('integrations.listIntegrations.columns.label'),
      field: 'name',
    },
    {
      title: t('integrations.listIntegrations.columns.authMethod'),
      render: ({ templateKey }: Integration) =>
        templateKey === undefined
          ? t('integrations.integrationForm.fields.authMode.values.custom')
          : t(templateKey),
    },
    {
      title: t('integrations.listIntegrations.columns.status'),
      render: ({ isEnabled }: Integration) =>
        isEnabled ? (
          <StatusText
            status="green"
            text={t('common.status.enabled')}
            withIcon
          />
        ) : (
          <StatusText
            status="red"
            text={t('common.status.disabled')}
            withIcon
          />
        ),
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (integration: Integration) => {
        return (
          <IntegrationsRowActions
            integration={integration}
            onClickViewDetails={handleClickViewDetails}
            onClickDelete={handleClickDelete}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={integrations} />
      {deleteIntegrationValue != null && (
        <ActionDialog
          open={deleteIntegrationValue !== null}
          title={t('integrations.deleteIntegration.title')}
          description={t('integrations.deleteIntegration.description', {
            integration: deleteIntegrationValue.name,
          })}
          actionText={t('integrations.deleteIntegration.action')}
          onAction={handleActionDelete}
          onClose={() => setDeleteIntegrationValue(null)}
        />
      )}
      {integrationDetailsValue != null && (
        <IntegrationDetailsDrawer
          open={integrationDetailsValue !== null}
          onClose={handleClose}
          integration={integrationDetailsValue}
        />
      )}
    </>
  )
}

export default IntegrationsTable
