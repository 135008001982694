import { Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import WebsiteForm from 'App/Categories/components/WebsiteFields'
import Button from 'common/components/Button'
import Dialog from 'common/components/Dialog'
import Toast from 'common/components/Toast'
import useWebsiteForm from 'common/hooks/useWebsiteForm'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  Buttons: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: theme.spacing(4),
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))

interface AddWebsiteProps {
  open: boolean
  onClose: () => void
  profileId: number
  categoryId: number
}

const AddWebsite = ({
  open,
  onClose,
  profileId,
  categoryId,
}: AddWebsiteProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const {
    websiteForm,
    fieldErrors,
    handleFieldChange,
    handleSwitchChange,
    handleUploadCustomIcon,
    handleAdd,
  } = useWebsiteForm(profileId, categoryId)

  const addedWebsite = useAppSelector((state) => state.contents.addedWebsite)

  const handleClose = () => {
    // TODO should reset form?
    onClose()
  }

  useEffect(() => {
    if (addedWebsite) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedWebsite])

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        title={t('contents.add.website.title')}
      >
        <WebsiteForm
          websiteForm={websiteForm}
          fieldErrors={fieldErrors}
          onSwitchChange={handleSwitchChange}
          onFieldChange={handleFieldChange}
          onUploadCustomIcon={handleUploadCustomIcon}
        />
        <Grid className={classes.Buttons}>
          <Button small outlined onClick={handleClose}>
            {t('contents.cancelButton')}
          </Button>
          <Button small onClick={handleAdd}>
            {t('contents.add.website.button')}
          </Button>
        </Grid>
      </Dialog>

      <Toast
        open={addedWebsite}
        message={t('contents.add.website.toast.success')}
      />
    </>
  )
}

export default AddWebsite
