import React, { useEffect, useMemo, useState } from 'react'
import { fetchSite, type Site } from 'App/Sites/sites-state'
import Page from 'common/components/Page'
import { useParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import Panel, { EditState } from 'common/components/NewPanel'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import { CircularProgress, Divider, Grid, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import Button from 'common/components/Button'
import Axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { renderIf } from 'common/utils/render-utils'
import ActionDialog from 'common/components/ActionDialog'
import useProfiles from 'common/hooks/useProfiles'
import PanelField from 'common/components/PanelField'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import FormField from 'common/components/FormField'
import FormSelect, { type FormSelectOption } from 'common/components/FormSelect'
import timezones from 'App/Sites/SitesView/SiteSettings/timezones'
import {
  useGetSiteSettingsQuery,
  useUpdateSiteGeneralMutation,
  useUpdateSiteLoansMutation,
} from 'App/Sites/sites-rtk-api'
import { useGetCustomerSectorsQuery } from 'App/CustomerSectors/customer-sector-rtk-api'
import useApiErrors from 'common/hooks/useApiErrors'
import FormSwitch from 'common/components/FormSwitch'
import useApp from 'common/hooks/useApp'
import { setSite } from 'App/app-state'
import { useAppDispatch, useAppSelector } from 'store'

import countries from 'i18n-iso-countries'
import caCountryNames from 'i18n-iso-countries/langs/ca.json'
import cyCountryNames from 'i18n-iso-countries/langs/cy.json'
import daCountryNames from 'i18n-iso-countries/langs/da.json'
import deCountryNames from 'i18n-iso-countries/langs/de.json'
import enCountryNames from 'i18n-iso-countries/langs/en.json'
import esCountryNames from 'i18n-iso-countries/langs/es.json'
import fiCountryNames from 'i18n-iso-countries/langs/fi.json'
import frCountryNames from 'i18n-iso-countries/langs/fr.json'
import itCountryNames from 'i18n-iso-countries/langs/it.json'
import nlCountryNames from 'i18n-iso-countries/langs/nl.json'
import noCountryNames from 'i18n-iso-countries/langs/no.json'
import ptCountryNames from 'i18n-iso-countries/langs/pt.json'
import svCountryNames from 'i18n-iso-countries/langs/sv.json'
import koCountryNames from 'i18n-iso-countries/langs/ko.json'
import zhCountryNames from 'i18n-iso-countries/langs/zh.json'
import gaCountryNames from 'common/langs/ga.json'

countries.registerLocale(caCountryNames)
countries.registerLocale(cyCountryNames)
countries.registerLocale(daCountryNames)
countries.registerLocale(deCountryNames)
countries.registerLocale(enCountryNames)
countries.registerLocale(esCountryNames)
countries.registerLocale(fiCountryNames)
countries.registerLocale(frCountryNames)
countries.registerLocale(itCountryNames)
countries.registerLocale(nlCountryNames)
countries.registerLocale(noCountryNames)
countries.registerLocale(ptCountryNames)
countries.registerLocale(svCountryNames)
countries.registerLocale(koCountryNames)
countries.registerLocale(zhCountryNames)
countries.registerLocale(gaCountryNames)

const useStyles = makeStyles()((theme: Theme) => ({
  PanelContainer: {
    borderRadius: '10px',
    border: 0,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    marginBottom: theme.spacing(4),
  },
  PanelTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  PanelContent: {
    padding: '0',
  },
  PanelItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: theme.spacing(3),

    borderBottom: '1px solid #e5e5e5',
    '&:last-child': {
      borderBottom: 0,
    },
  },
  EnrolledInfo: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#5BC980',
  },
  UnenrolledInfo: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 'normal',
    color: theme.palette.hublet.error,
    alignItems: 'center',
    lineHeight: 1,
    '& > .MuiSvgIcon-root': {
      marginLeft: theme.spacing(2),
    },
  },
}))

const printAddress = ({ street, zipcode, city }: Site): string => {
  const parts = [street, zipcode, city].filter(
    (x) => x !== null && x !== undefined && x !== ''
  )
  return parts.join(', ')
}

const SiteSettingsGeneralPanel = ({ site }: { site: Site }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()

  const [editState, setEditState] = useState<EditState>(EditState.Default)

  const [name, setName] = useState(site.name)
  const [nameError, setNameError] = useState<string | undefined>()

  const [street, setStreet] = useState(site.street)
  const [customerSectorId, setCustomerSectorId] = useState(
    site.customerSectorId
  )
  const [street2, setStreet2] = useState(site.street2)
  const [zipcode, setZipcode] = useState(site.zipcode)
  const [city, setCity] = useState(site.city)
  const [country, setCountry] = useState(site.country)
  const [tzdbTimezone, setTzdbTimezone] = useState(site.tzdbTimezone)

  const { data: customerSectors } = useGetCustomerSectorsQuery()

  const customerSectorOptions = useMemo(() => {
    return customerSectors
      ? customerSectors.map((sector) => ({
          label: t(sector.customerSectorName),
          value: sector.id,
        }))
      : []
  }, [customerSectors, t])

  const timezoneOptions = useMemo(() => {
    return timezones.map((tz) => ({
      label: tz,
      value: tz,
    }))
  }, [])

  const storedLng = localStorage
    .getItem('i18nextLng')
    ?.substring(0, 2) as string
  const countryNames = countries.getNames(storedLng)
  const countryOptions = Object.keys(countryNames)
    .map((code) => ({
      value: code,
      label: countryNames[code],
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const [
    updateSiteGeneral,
    { isSuccess: isUpdateSuccess, error: updateError },
  ] = useUpdateSiteGeneralMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      dispatch(fetchSite({ organizationId, siteId }))
      setEditState(EditState.Success)
    }
  }, [isUpdateSuccess, hasApiMutationErrors, dispatch, organizationId, siteId])

  const renderEditMode = () => (
    <>
      <FormField
        label={t('sites.settings.general.form.name.label')}
        placeholder={t('sites.settings.general.form.name.placeholder')}
        value={name}
        onChange={(e) => setName(e.target.value)}
        autoFocus
        error={nameError}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('sites.settings.general.form.customerSector.label')}
        placeholder={t(
          'sites.settings.general.form.customerSector.placeholder'
        )}
        options={customerSectorOptions}
        value={customerSectorId}
        onChange={(e, v) => setCustomerSectorId(v as number)}
        disabled={editState === EditState.Pending}
      />
      <FormField
        label={t('sites.settings.general.form.street.label')}
        placeholder={t('sites.settings.general.form.street.placeholder')}
        value={street}
        onChange={(e) => setStreet(e.target.value)}
        disabled={editState === EditState.Pending}
      />
      <FormField
        label={t('sites.settings.general.form.street2.label')}
        placeholder={t('sites.settings.general.form.street2.placeholder')}
        value={street2}
        onChange={(e) => setStreet2(e.target.value)}
        disabled={editState === EditState.Pending}
      />
      <FormField
        label={t('sites.settings.general.form.zipcode.label')}
        placeholder={t('sites.settings.general.form.zipcode.placeholder')}
        value={zipcode}
        onChange={(e) => setZipcode(e.target.value)}
        disabled={editState === EditState.Pending}
      />
      <FormField
        label={t('sites.settings.general.form.city.label')}
        placeholder={t('sites.settings.general.form.city.placeholder')}
        value={city}
        onChange={(e) => setCity(e.target.value)}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('sites.settings.general.form.country.label')}
        placeholder={t('sites.settings.general.form.country.placeholder')}
        options={countryOptions}
        value={country}
        onChange={(_e, v) => setCountry(v as string)}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('sites.settings.general.form.timezone.label')}
        placeholder={t('sites.settings.general.form.timezone.placeholder')}
        options={timezoneOptions}
        value={tzdbTimezone}
        onChange={(_e, v) => setTzdbTimezone(v as string)}
        disabled={editState === EditState.Pending}
      />
    </>
  )

  const handleSave = () => {
    setNameError(undefined)

    if (name === '') {
      setNameError(t('sites.settings.general.errors.emptyField'))
      return
    }

    updateSiteGeneral({
      organizationId,
      siteId,
      name,
      customerSectorId,
      street,
      street2,
      zipcode,
      city,
      country,
      tzdbTimezone,
    })

    setEditState(EditState.Pending)
  }

  return (
    <>
      <Panel
        title={t('sites.settings.general.title')}
        variant="page"
        editable
        editState={editState}
        onEdit={() => setEditState(EditState.Edit)}
        onCancel={() => setEditState(EditState.Default)}
        onSave={handleSave}
        onSuccess={() => setEditState(EditState.Success)}
        renderEditMode={renderEditMode}
        error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
      >
        <PanelField
          title={t('sites.settings.general.fields.name')}
          value={site.name}
          variant="page"
        />
        <Divider light />
        <PanelField
          title={t('sites.settings.general.fields.customerSector')}
          value={t(
            customerSectors?.find(
              (sector) => sector.id === site.customerSectorId
            )?.customerSectorName ?? '-'
          )}
          variant="page"
        />
        <Divider light />
        <PanelField
          title={t('sites.settings.general.fields.address')}
          value={printAddress(site)}
          variant="page"
        />
        <Divider light />
        <PanelField
          title={t('sites.settings.general.fields.country')}
          value={countries.getName(site.country, storedLng)}
          variant="page"
        />
        <Divider light />
        <PanelField
          title={t('sites.settings.general.fields.timezone')}
          value={site.tzdbTimezone}
          variant="page"
        />
      </Panel>
    </>
  )
}

/**
 * Generate a random return code.
 * 5 random digits.
 */
function generateReturnCode(): string {
  const randomDigitString = Math.floor(Math.random() * 100000).toString()
  const returnCode = randomDigitString.padStart(5, '0')
  return returnCode
}

function isValidReturnCode(returnCode: unknown): boolean {
  if (typeof returnCode !== 'string') {
    return false
  }

  const returnCodePattern = /^\d{5}$/
  const isValid = returnCodePattern.test(returnCode)

  return isValid
}

const SiteSettingsLoansPanel = ({ site }: { site: Site }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { organizationId, siteId } = useCurrentAccount()
  const { adminMode } = useApp()

  const [editState, setEditState] = useState<EditState>(EditState.Default)

  const { data: siteSettings, refetch: refetchSiteSettings } =
    useGetSiteSettingsQuery({
      organizationId,
      siteId,
    })

  const [defaultLanguage, setDefaultLanguage] = useState(site.defaultLanguage)
  const [profileId, setProfileId] = useState(site.profileId ?? -1)
  const [allowAuthenticateFromTablet, setAllowAuthenticateFromTablet] =
    useState(false)
  const [enableReturnCode, setEnableReturnCode] = useState(false)
  const [returnCode, setReturnCode] = useState<string | undefined>()

  useEffect(() => {
    if (siteSettings?.allowAuthenticateFromTablet !== undefined) {
      setAllowAuthenticateFromTablet(siteSettings.allowAuthenticateFromTablet)
    }
  }, [siteSettings?.allowAuthenticateFromTablet])

  useEffect(() => {
    if (siteSettings?.enableReturnCode !== undefined) {
      setEnableReturnCode(siteSettings.enableReturnCode)
    }
  }, [siteSettings?.enableReturnCode])

  useEffect(() => {
    if (siteSettings != null) {
      const newReturnCode = isValidReturnCode(siteSettings.returnCode)
        ? siteSettings.returnCode
        : generateReturnCode()

      setReturnCode(newReturnCode)
    }
  }, [siteSettings])

  const languages = useAppSelector((state) => state.app.languages)
  const profiles = useProfiles()

  const [updateSiteLoans, { isSuccess: isUpdateSuccess, error: updateError }] =
    useUpdateSiteLoansMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  useEffect(() => {
    if (hasApiMutationErrors) {
      setEditState(EditState.Error)
    } else if (isUpdateSuccess) {
      dispatch(fetchSite({ organizationId, siteId }))
      refetchSiteSettings()
      setEditState(EditState.Success)
    }
  }, [
    isUpdateSuccess,
    refetchSiteSettings,
    hasApiMutationErrors,
    dispatch,
    organizationId,
    siteId,
  ])

  const siteProfile = useMemo(() => {
    if (site?.profileId === undefined) return undefined
    return profiles.find((p) => p.id === site.profileId)
  }, [site?.profileId, profiles])

  const languageOptions = useMemo(
    () =>
      languages.map((lng) => ({
        label: t(`languages.${lng}`),
        value: lng,
      })),
    [t, languages]
  )

  const siteProfileOptions = useMemo((): FormSelectOption[] => {
    const opts = profiles.map((p) => ({
      label: p.name,
      value: p.id,
    }))

    return [
      { label: t('sites.settings.profile.values.default'), value: -1 },
      ...opts,
    ]
  }, [profiles, t])

  if (siteSettings == null) {
    return <CircularProgress />
  }

  const renderEditMode = () => (
    <>
      <FormSelect
        label={t('sites.settings.general.form.defaultlanguage.label')}
        placeholder={t(
          'sites.settings.general.form.defaultlanguage.placeholder'
        )}
        options={languageOptions}
        value={defaultLanguage}
        onChange={(_e, v) => setDefaultLanguage(v as string)}
        disabled={editState === EditState.Pending}
      />
      <FormSelect
        label={t('sites.settings.general.form.profile.label')}
        placeholder={t('sites.settings.general.form.profile.placeholder')}
        options={siteProfileOptions}
        value={profileId ?? null}
        onChange={(_e, v) => setProfileId(v as number)}
        disabled={editState === EditState.Pending}
      />
      <FormSwitch
        title={t('siteSettings.borrow.enable')}
        checked={allowAuthenticateFromTablet}
        onChange={(_e, checked) => setAllowAuthenticateFromTablet(checked)}
        variant="drawer"
      />
      {adminMode && allowAuthenticateFromTablet && (
        <>
          <FormSwitch
            title={t('siteSettings.borrow.enableReturnCode')}
            checked={enableReturnCode}
            onChange={(_e, checked) => setEnableReturnCode(checked)}
            variant="drawer"
          />
          {enableReturnCode && (
            <FormField label={t('Return code')} value={returnCode} readOnly />
          )}
        </>
      )}
    </>
  )

  const handleSave = () => {
    updateSiteLoans({
      organizationId,
      siteId,
      defaultLanguage,
      profileId: profileId === -1 ? undefined : profileId,
      allowAuthenticateFromTablet,
      enableReturnCode,
      returnCode: returnCode ?? '',
    })

    setEditState(EditState.Pending)
  }

  return (
    <>
      <Panel
        title={t('sites.settings.loans.title')}
        variant="page"
        editable
        editState={editState}
        onEdit={() => setEditState(EditState.Edit)}
        onCancel={() => setEditState(EditState.Default)}
        onSave={handleSave}
        onSuccess={() => setEditState(EditState.Success)}
        renderEditMode={renderEditMode}
        error={hasApiMutationErrors ? apiMutationErrorsMsg : undefined}
      >
        <PanelField
          title={t('sites.settings.general.fields.defaultlanguage')}
          value={site.defaultLanguage && t(`languages.${site.defaultLanguage}`)}
          variant="page"
        />
        <Divider light />
        <PanelField
          title={t('sites.settings.general.fields.profile')}
          value={
            siteProfile?.name ?? t('sites.settings.profile.values.default')
          }
          variant="page"
        />
        <Divider light />
        <PanelField
          title={t('siteSettings.borrow.enable')}
          value={t(
            `common.boolean.${String(siteSettings.allowAuthenticateFromTablet)}`
          )}
          variant="page"
        />
        {adminMode && allowAuthenticateFromTablet && (
          <>
            <Divider light />
            <PanelField
              title={t('siteSettings.borrow.enableReturnCode')}
              value={t(
                `common.boolean.${String(siteSettings.enableReturnCode)}`
              )}
              variant="page"
            />
            {enableReturnCode && (
              <>
                <Divider light />
                <PanelField
                  title={t('Return code')}
                  value={returnCode}
                  variant="page"
                />
              </>
            )}
          </>
        )}
      </Panel>
    </>
  )
}

const SiteSettingsEMMPanel = ({ site }: { site: Site }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { organizationId } = useCurrentAccount()

  const [unenrollSite, setUnenrollSite] = useState<Site | undefined>()

  const handleClickOpen = () => {
    window.location.href = 'https://play.google.com/work'
  }

  const handleClickEnroll = () => {
    Axios.post(
      `${API_BASE_URL}/organizations/${organizationId}/sites/${site.id}/enroll`
    )
      .then(({ data }) => {
        if (data) {
          window.location.href = data
        }
      })
      .catch(() => {
        console.log('Could not enroll the site')
      })
  }

  const handleClickUnenroll = (site: Site) => setUnenrollSite(site)
  const handleCloseDialog = () => setUnenrollSite(undefined)
  const handleActionUnenroll = () => {
    if (unenrollSite !== undefined) {
      Axios.post(
        `${API_BASE_URL}/organizations/${organizationId}/sites/${unenrollSite.id}/unenroll`
      )
        .then(({ data }) => {
          if (data) {
            window.location.href = data
          }
        })
        .catch(() => {
          console.log('Could not unenroll the site')
        })
    }
  }

  return (
    <>
      <Panel
        title={
          <Grid className={classes.PanelTitle}>
            <Grid>{t('sites.settings.emm.title')}</Grid>
            <Grid>
              {site.enrolled ? (
                <Grid
                  className={classes.EnrolledInfo}
                  title={t('sites.settings.emm.siteIsEnrolled')}
                >
                  <CheckCircleIcon />
                </Grid>
              ) : (
                <Grid className={classes.UnenrolledInfo}>
                  {t('sites.settings.emm.warning')}
                  <ErrorIcon />
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        variant="page"
      >
        <PanelField
          title={t('sites.settings.emm.description')}
          value={
            site.enrolled ? (
              <Button small textOnly onClick={() => handleClickUnenroll(site)}>
                {t('sites.settings.emm.unenroll')}
              </Button>
            ) : (
              <Button small textOnly onClick={handleClickEnroll}>
                {t('sites.settings.emm.enroll')}
              </Button>
            )
          }
          variant="page"
        />
        {renderIf(site.enrolled, () => (
          <>
            <Divider light />
            <PanelField
              title={t('sites.settings.enterpriseId.label')}
              value={site.googleEnterpriseId}
              variant="page"
            />
          </>
        ))}
        {renderIf(site.enrolled, () => (
          <>
            <Divider light />
            <PanelField
              title={
                <Trans t={t} i18nKey="sites.settings.emm.playLink.label" />
              }
              value={
                <Button small textOnly onClick={handleClickOpen}>
                  {t('sites.settings.emm.playLink.open')}
                </Button>
              }
              variant="page"
            />
          </>
        ))}
      </Panel>
      {unenrollSite != null && (
        <ActionDialog
          open={unenrollSite !== undefined}
          title={t('sites.settings.unenroll.title')}
          description={t('sites.settings.unenroll.confirmation')}
          actionText={t('sites.settings.unenroll.action')}
          onAction={handleActionUnenroll}
          onClose={handleCloseDialog}
        />
      )}
    </>
  )
}

interface SitesViewProps {
  id?: number
}

const SitesView = ({ id }: SitesViewProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const site = useAppSelector((state) => state.sites.site)
  const params = useParams<{ id: string }>()

  const { site: activeSite } = useApp()

  if (id === undefined && params.id !== undefined) {
    id = parseInt(params.id)
  }

  const { organizationId } = useCurrentAccount()

  useEffect(() => {
    if ((site === null || site.id !== id) && id !== undefined) {
      dispatch(fetchSite({ organizationId, siteId: id }))
    }
  }, [dispatch, organizationId, site, id])

  useEffect(() => {
    if (activeSite !== undefined && activeSite.id === site?.id) {
      // Update active site data if site data has changed
      if (
        activeSite.name !== site.name ||
        activeSite.timezone !== site.tzdbTimezone
      ) {
        dispatch(
          setSite({
            id: site.id,
            name: site.name,
            timezone: site.tzdbTimezone,
          })
        )
      }
    }
  }, [site, dispatch, activeSite])

  if (site === null) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageTitle title={t('siteSettings.title')} />
        <PageBody>
          <SiteSettingsGeneralPanel site={site} />
          <SiteSettingsLoansPanel site={site} />
          <SiteSettingsEMMPanel site={site} />
        </PageBody>
      </Page>
    </>
  )
}

export default SitesView
