import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UsersApi from 'api/users-api'
import { type InvitedUser } from 'App/Users/users-types'

interface InvitationsState {
  fetchedInvitedUser: boolean
  invitedUser?: InvitedUser
  acceptedInvitation: boolean
}

const initialState: InvitationsState = {
  fetchedInvitedUser: false,
  invitedUser: undefined,
  acceptedInvitation: false,
}

export const fetchInvitedUser = createAsyncThunk(
  'invitations/fetchInvitedUser',
  async ({ token }: { token: string }) =>
    UsersApi.fetchInvitedUserByToken(token)
)

interface AcceptInvitationProps {
  token: string
  firstName: string
  lastName: string
  password: string
}

export const acceptInvitation = createAsyncThunk(
  'invitations/acceptInvitation',
  async ({ token, firstName, lastName, password }: AcceptInvitationProps) =>
    UsersApi.acceptInvitation(token, firstName, lastName, password)
)

const invitationsSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvitedUser.fulfilled, (state, { payload }) => {
      state.fetchedInvitedUser = true
      state.invitedUser = payload

      return state
    })

    builder.addCase(acceptInvitation.fulfilled, (state) => {
      state.acceptedInvitation = true
      return state
    })
  },
})

export default invitationsSlice.reducer
