import {
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import {
  type App,
  type AppForm,
  type AppPermission,
  type ManagedConfig,
} from 'App/Categories/categories-types'
import { useUpdateAppManagedConfigurationMutation } from 'App/Categories/contents-rtk-api'
import {
  fetchContents,
  fetchGoogleToken,
  loadAppPermissions,
} from 'App/Categories/contents-state'
import Avatar from 'common/components/Avatar'
import Button from 'common/components/Button'
import Dialog from 'common/components/Dialog'
import ErrorBox from 'common/components/ErrorBox'
import FormField from 'common/components/FormField'
import FormSelect from 'common/components/FormSelect'
import FormSwitch from 'common/components/FormSwitch'
import Tab from 'common/components/Tab'
import TabPanel from 'common/components/TabPanel'
import Tabs from 'common/components/Tabs'
import Toast from 'common/components/Toast'
import useApiErrors from 'common/hooks/useApiErrors'
import useApp from 'common/hooks/useApp'
import useAppForm from 'common/hooks/useAppForm'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  DialogTitle: {
    background: theme.palette.hublet.common.white,
    padding: '24px 36px',
  },
  DialogContent: {
    paddingTop: '36px',
  },
  DialogActions: {
    background: theme.palette.hublet.common.white,
  },
  TitleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  TitleIcon: {},
  Title: {
    flexGrow: 1,
  },
  TitleText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: theme.palette.hublet.primary.main,
    marginBottom: theme.spacing(1),
  },
  TitleType: {
    fontSize: '12px',
    color: theme.palette.hublet.common.black,
  },
  Buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  FormField: {
    marginBottom: '32px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  ManagedConfigurationsContainer: {
    '& > button:last-child': {
      marginTop: theme.spacing(2),
    },
  },
  ManagedConfigurations: {
    height: '350px',
    backgroundColor: theme.palette.hublet.common.white,
    border: `1px solid ${theme.palette.hublet.secondary.light}`,
  },
  AppPermissionsListItem: {
    paddingRight: 128,
  },
  AppPermissionsListItemAction: {
    right: 0,
  },
  AppPermissionsListItemSelect: {
    width: 120,
  },
}))

interface EditAppProps {
  open: boolean
  onClose: () => void
  profileId: number
  categoryId: number
  app?: App
}

interface ManagedConfigurationsProps {
  setMcmId: (mcmId: string | null) => void
  setName: (name: string | null) => void
  profileId: number
  categoryId: number
  appId: number
  packageName: string
  managedConfigId: string | null
}

const ManagedConfigurations = ({
  setMcmId,
  setName,
  profileId,
  categoryId,
  appId,
  packageName,
  managedConfigId,
}: ManagedConfigurationsProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const [
    updateAppManagedConfiguration,
    { isSuccess: isUpdated, error: updateError },
  ] = useUpdateAppManagedConfigurationMutation()

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    updateError,
  ])

  const [iframeContainer, setIframeContainer] = useState<HTMLDivElement | null>(
    null
  )
  const [iframeAction, setIframeAction] = useState<
    ['init' | 'rebuild' | 'none', string | null]
  >(['init', managedConfigId])

  const googleToken = useAppSelector((state) => state.contents.googleToken)

  const { organizationId, siteId } = useCurrentAccount()
  const { adminMode } = useApp()

  useEffect(() => {
    if (isUpdated) {
      dispatch(fetchContents({ categoryId, profileId, siteId, organizationId }))
    }
  }, [dispatch, isUpdated, categoryId, profileId, siteId, organizationId])

  useEffect(() => {
    if (googleToken === null) {
      dispatch(fetchGoogleToken({ organizationId, siteId }))
      return
    }

    if (iframeContainer === null) return

    const handleConfigUpdated = ({
      mcmId,
      name,
    }: {
      mcmId: string
      name: string
    }) => {
      const mcm: ManagedConfig = {
        managedConfigId: mcmId,
        managedConfigName: name,
      }

      updateAppManagedConfiguration({
        organizationId,
        siteId,
        profileId,
        categoryId,
        appId,
        ...mcm,
      })

      setMcmId(mcmId)
      setName(name)
      setIframeAction(['rebuild', mcmId])
    }

    const handleConfigDeleted = () => {
      const mcm: ManagedConfig = {
        managedConfigId: null,
        managedConfigName: null,
      }

      updateAppManagedConfiguration({
        organizationId,
        siteId,
        profileId,
        categoryId,
        appId,
        ...mcm,
      })

      setIframeAction(['rebuild', null])
      setMcmId(null)
      setName(null)
    }

    const buildIframe = (mcmId: string | null) => {
      let url = `https://play.google.com/managed/mcm?token=${googleToken}&packageName=${packageName}&canDelete=true`

      if (mcmId !== null) {
        url += `&mcmId=${mcmId}`
      }

      const options = {
        url,
        where: iframeContainer,
        attributes: { style: 'width: 100%; height: 100%', scrolling: 'yes' },
      }

      const iframe = window.gapi.iframes.getContext().openChild(options)
      iframe.register(
        'onconfigupdated',
        handleConfigUpdated,
        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
      )
      iframe.register(
        'onconfigdeleted',
        handleConfigDeleted,
        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
      )
    }

    if (iframeAction[0] === 'init') {
      window.gapi.load('gapi.iframes', () => {
        buildIframe(iframeAction[1])
        setIframeAction(['none', null])
      })
    } else if (iframeAction[0] === 'rebuild') {
      buildIframe(iframeAction[1])
      setIframeAction(['none', null])
    }
  }, [
    dispatch,
    managedConfigId,
    googleToken,
    packageName,
    iframeContainer,
    organizationId,
    siteId,
    profileId,
    categoryId,
    appId,
    updateAppManagedConfiguration,
    iframeAction,
    setMcmId,
    setName,
  ])

  const handleClickCreate = () => {
    const mcm: ManagedConfig = {
      managedConfigId: null,
      managedConfigName: null,
    }

    updateAppManagedConfiguration({
      organizationId,
      siteId,
      profileId,
      categoryId,
      appId,
      ...mcm,
    })

    setIframeAction(['rebuild', null])
    setMcmId(null)
    setName(null)
  }

  return (
    <div className={classes.ManagedConfigurationsContainer}>
      {hasApiMutationErrors && (
        <ErrorBox spacingBottom={2}>{apiMutationErrorsMsg}</ErrorBox>
      )}
      <div ref={setIframeContainer} className={classes.ManagedConfigurations} />
      {adminMode && (
        <Button small negative onClick={handleClickCreate}>
          {t('contents.appForm.managedConfig.create')}
        </Button>
      )}
    </div>
  )
}

interface EditAppPermissionsProps {
  profileId: number
  categoryId: number
  app: App
  appForm: AppForm
  setAppForm: (fn: (f: AppForm) => AppForm) => void
}

const EditAppPermissions = ({
  profileId,
  categoryId,
  app,
  appForm,
  setAppForm,
}: EditAppPermissionsProps) => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { organizationId, siteId } = useCurrentAccount()
  const context = { organizationId, siteId, profileId, categoryId }
  const { t } = useTranslation()

  const appPermissions = useAppSelector(
    (state) => state.contents.appPermissions
  )

  const appPermissionsGrantStates = [
    {
      value: 'default',
      label: t('contents.appForm.appPermissionStates.default'),
    },
    {
      value: 'prompt',
      label: t('contents.appForm.appPermissionStates.prompt'),
    },
    {
      value: 'granted',
      label: t('contents.appForm.appPermissionStates.granted'),
    },
    {
      value: 'denied',
      label: t('contents.appForm.appPermissionStates.denied'),
    },
  ]

  useEffect(() => {
    if (app?.id !== undefined) {
      dispatch(loadAppPermissions({ context, id: app.id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app])

  useEffect(() => {
    setAppForm((f) => ({ ...f, permissions: appPermissions }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appPermissions])

  const onPermissionChanged = (
    permission: AppPermission,
    state: 'denied' | 'granted' | 'default' | 'prompt'
  ) => {
    const permissions =
      appForm.permissions?.map((p) =>
        p.name === permission.name ? { ...p, state } : { ...p }
      ) ?? []
    setAppForm((f) => ({ ...f, permissions }))
  }

  return (
    <List>
      {appForm?.permissions != null &&
        appForm.permissions.map((p) => (
          <ListItem
            key={p.name}
            className={classes.AppPermissionsListItem}
            disableGutters
          >
            <ListItemText
              primary={p.name.split('.').slice(-1)[0]}
              secondary={p.description}
            />
            <ListItemSecondaryAction
              className={classes.AppPermissionsListItemAction}
            >
              <FormSelect
                className={classes.AppPermissionsListItemSelect}
                value={p.state}
                options={appPermissionsGrantStates}
                onChange={(e) => onPermissionChanged(p, e.target.value)}
                dense
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  )
}

const EditApp = ({
  open,
  onClose,
  profileId,
  categoryId,
  app,
}: EditAppProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { appForm, setAppForm, fieldErrors, handleFieldChange, handleUpdate } =
    useAppForm(profileId, categoryId, app)

  const updatedApp = useAppSelector((state) => state.contents.updatedApp)

  const handleClose = () => {
    // TODO should reset form?
    onClose()
  }

  useEffect(() => {
    if (updatedApp) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedApp])

  const [tabIndex, setTabIndex] = useState(0)

  const handleChangeTab = (_: any, newIndex: number) => setTabIndex(newIndex)
  const handleSwitchChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setAppForm((f) => ({ ...f, [key]: e.target.checked }))
    }
  const setAppFormValue = (key: string) => (value: string | null) => {
    setAppForm((f) => ({ ...f, [key]: value }))
  }

  if (app === undefined || app.id === undefined) {
    return null
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        title={
          <Grid className={classes.TitleContainer}>
            <Grid className={classes.TitleIcon}>
              <Avatar name={appForm.name} />
            </Grid>
            <Grid className={classes.Title}>
              <Grid className={classes.TitleText}>{appForm.name}</Grid>
              <Grid className={classes.TitleType}>
                {t('contents.type.app')}
              </Grid>
            </Grid>
          </Grid>
        }
        tabs={
          <Tabs value={tabIndex} onChange={handleChangeTab}>
            <Tab label={t('contents.appForm.tabs.general')} />
            <Tab label={t('contents.appForm.tabs.managedConfigurations')} />
            <Tab label={t('contents.appForm.tabs.permissions')} />
          </Tabs>
        }
        actions={
          tabIndex !== 1 && (
            <Grid className={classes.Buttons}>
              <Button small outlined onClick={handleClose}>
                {t('contents.cancelButton')}
              </Button>
              <Button small onClick={handleUpdate}>
                {t('contents.saveButton')}
              </Button>
            </Grid>
          )
        }
        classes={{
          title: classes.DialogTitle,
          content: classes.DialogContent,
          actions: classes.DialogActions,
        }}
      >
        <TabPanel p={0} value={tabIndex} index={0}>
          <FormField
            className={classes.FormField}
            label={t('contents.appForm.nameField.label')}
            placeholder={t('contents.appForm.nameField.placeholder')}
            value={appForm.name}
            onChange={handleFieldChange('name')}
            error={fieldErrors.name}
          />
          <FormField
            className={classes.FormField}
            label={t('contents.appForm.descriptionField.label')}
            placeholder={t('contents.appForm.descriptionField.placeholder')}
            value={appForm.description}
            onChange={handleFieldChange('description')}
          />
          <FormSwitch
            title={t('contents.appForm.lockedTaskModeField.label')}
            description={t('contents.appForm.lockedTaskModeField.label')}
            checked={appForm.isLockTaskMode !== null && appForm.isLockTaskMode}
            onChange={handleSwitchChange('isLockTaskMode')}
          />
        </TabPanel>
        <TabPanel p={0} value={tabIndex} index={1}>
          <ManagedConfigurations
            setMcmId={setAppFormValue('managedConfigId')}
            setName={setAppFormValue('managedConfigName')}
            profileId={profileId}
            categoryId={categoryId}
            appId={app.id}
            packageName={app.packageName}
            managedConfigId={appForm.managedConfigId}
          />
        </TabPanel>
        <TabPanel p={0} value={tabIndex} index={2}>
          <EditAppPermissions
            profileId={profileId}
            categoryId={categoryId}
            app={app}
            appForm={appForm}
            setAppForm={setAppForm}
          />
        </TabPanel>
      </Dialog>

      <Toast open={updatedApp} message={t('contents.edit.app.toast.success')} />
    </>
  )
}

export default EditApp
