import React from 'react'
import { useTranslation } from 'react-i18next'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { type CategoryForm } from 'App/Categories/categories-types'
import { type CategoryFormErrors } from 'common/hooks/useCategoryForm'
import FormField from 'common/components/FormField'

const useStyles = makeStyles()((theme: Theme) => ({
  Field: {
    marginBottom: '32px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
}))

interface CategoryFieldsProps {
  withLabels?: boolean
  form: CategoryForm
  errors: CategoryFormErrors
  onChangeField: (
    key: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CategoryFields = ({
  withLabels = true,
  form,
  errors,
  onChangeField,
}: CategoryFieldsProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <>
      <FormField
        className={classes.Field}
        label={
          withLabels ? t('categories.categoryForm.nameField.label') : undefined
        }
        placeholder={t('categories.categoryForm.nameField.placeholder')}
        value={form.name}
        onChange={onChangeField('name')}
        error={errors.name}
      />
      <FormField
        className={classes.Field}
        label={
          withLabels
            ? t('categories.categoryForm.descriptionField.label')
            : undefined
        }
        value={form.description}
        onChange={onChangeField('description')}
        error={errors.description}
      />
    </>
  )
}

export default CategoryFields
