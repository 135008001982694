import React, { type PropsWithChildren } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Grid, type Theme, Typography } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  SettingsDescriptionName: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  SettingsDescriptionDetail: {
    marginBottom: theme.spacing(2),
  },
}))

interface SettingsEntryProps {
  property: string
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse'
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
}

const SettingsEntry = ({
  property,
  children,
  wrap = 'nowrap',
  spacing = 0,
}: PropsWithChildren<SettingsEntryProps>) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap={wrap}
      spacing={spacing}
    >
      <Grid item>
        <Typography className={classes.SettingsDescriptionName}>
          {t(`${property}.name`)}
        </Typography>
        <Typography className={classes.SettingsDescriptionDetail}>
          <Trans t={t} i18nKey={`${property}.detail`} />
        </Typography>
      </Grid>
      {children}
    </Grid>
  )
}

export default SettingsEntry
