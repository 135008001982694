import React, { type ChangeEvent, useState } from 'react'
import FormField from 'common/components/FormField'
import { Visibility, VisibilityOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { type TooltipInfoContent } from 'common/components/TooltipInfo'

interface PasswordFieldProps {
  className?: string
  label?: string
  placeholder?: string
  value?: string
  error?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  info?: TooltipInfoContent
  autoComplete?: string
}

const PasswordField = ({
  className,
  label,
  placeholder,
  value,
  error,
  onChange,
  disabled = false,
  info,
  autoComplete = 'off',
}: PasswordFieldProps) => {
  const [showText, setShowText] = useState(false)

  return (
    <FormField
      className={className}
      label={label}
      placeholder={placeholder}
      value={value}
      type={showText ? 'text' : 'password'}
      onChange={onChange}
      error={error}
      disabled={disabled}
      info={info}
      endIcon={
        <IconButton
          onMouseDown={() => setShowText(true)}
          onMouseUp={() => setShowText(false)}
          style={{ marginRight: -14 }}
          size="large"
        >
          {showText ? <Visibility /> : <VisibilityOutlined />}
        </IconButton>
      }
      autoComplete={autoComplete}
    />
  )
}

export default PasswordField
