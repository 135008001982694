import type React from 'react'
import { useEffect, useState } from 'react'
import {
  type Category,
  type CategoryForm,
} from 'App/Categories/categories-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useTranslation } from 'react-i18next'
import {
  addCategory,
  fetchCategories,
  resetAddCategory,
  resetUpdateCategory,
  updateCategory,
} from 'App/Categories/categories-state'
import { useAppDispatch, useAppSelector } from 'store'

export interface CategoryFormErrors {
  name?: string
  description?: string
}

const initialCategoryForm = {
  name: '',
  description: '',
}

const useCategoryForm = (profileId: number, category?: Category): any => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { siteId, organizationId } = useCurrentAccount()
  const context = { profileId, siteId, organizationId }
  const updatedCategory = useAppSelector(
    (state) => state.categories.updatedCategory
  )
  const addedCategory = useAppSelector(
    (state) => state.categories.addedCategory
  )

  const [categoryForm, setCategoryForm] =
    useState<CategoryForm>(initialCategoryForm)
  const [fieldErrors, setFieldErrors] = useState<CategoryFormErrors>({})

  useEffect(() => {
    if (category != null) {
      setCategoryForm(category)
    }
  }, [category])

  useEffect(() => {
    if (updatedCategory) {
      dispatch(resetUpdateCategory())
      dispatch(fetchCategories({ profileId, siteId, organizationId }))
    }
  }, [dispatch, updatedCategory, profileId, siteId, organizationId])

  useEffect(() => {
    if (addedCategory) {
      setCategoryForm(initialCategoryForm)
      dispatch(resetAddCategory())
      dispatch(fetchCategories({ profileId, siteId, organizationId }))
    }
  }, [dispatch, addedCategory, profileId, siteId, organizationId])

  const handleFieldChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setCategoryForm({ ...categoryForm, [key]: e.target.value })
    }

  const validateForm = (): boolean => {
    let hasError = false
    const errors: CategoryFormErrors = {}

    if (categoryForm.name === '') {
      hasError = true
      errors.name = t('categories.categoryForm.errors.emptyField')
    }

    if (categoryForm.description === undefined) {
      hasError = true
      errors.description = t('categories.categoryForm.errors.emptyField')
    }

    setFieldErrors(errors)

    return hasError
  }

  const handleUpdate = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (category == null) {
      return
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(
        updateCategory({ context, categoryId: category.id, form: categoryForm })
      )
    }
  }

  const handleAdd = (e: React.FormEvent<Element>) => {
    e.preventDefault()

    if (!categoryForm) {
      return false
    }

    const hasError = validateForm()
    if (!hasError) {
      dispatch(addCategory({ context, form: categoryForm }))
    }
  }

  return {
    categoryForm,
    fieldErrors,
    handleFieldChange,
    handleUpdate,
    handleAdd,
    updatedCategory,
    addedCategory,
  }
}

export default useCategoryForm
