import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  type Category,
  type CategoryForm,
  type CategoryContext,
} from 'App/Categories/categories-types'
import CategoriesApi from 'api/categories-api'

interface CategoriesState {
  categories: Category[]
  fetchingCategories: boolean
  fetchedCategories: boolean
  addingCategory: boolean
  addedCategory: boolean
  updatingCategory: boolean
  updatedCategory: boolean
  deletingCategory: boolean
  deletedCategory: boolean
}

const initialState: CategoriesState = {
  categories: [],
  fetchingCategories: false,
  fetchedCategories: false,
  addingCategory: false,
  addedCategory: false,
  updatingCategory: false,
  updatedCategory: false,
  deletingCategory: false,
  deletedCategory: false,
}

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (context: CategoryContext) => CategoriesApi.fetchCategories(context)
)

export const addCategory = createAsyncThunk(
  'categories/addCategory',
  async ({ context, form }: { context: CategoryContext; form: CategoryForm }) =>
    CategoriesApi.createCategory(context, form)
)

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async ({
    context,
    categoryId,
    form,
  }: {
    context: CategoryContext
    categoryId: number
    form: CategoryForm
  }) => CategoriesApi.updateCategory(context, categoryId, form)
)

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async ({
    context,
    categoryId,
  }: {
    context: CategoryContext
    categoryId: number
  }) => CategoriesApi.deleteCategory(context, categoryId)
)

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    resetAddCategory: (state) => {
      state.addingCategory = false
      state.addedCategory = false
      return state
    },
    resetUpdateCategory: (state) => {
      state.updatingCategory = false
      state.updatedCategory = false
      return state
    },
    resetDeleteCategory: (state) => {
      state.deletedCategory = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state) => {
      state.fetchingCategories = true
      return state
    })
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      state.fetchingCategories = false
      state.fetchedCategories = true
      state.categories = payload
      return state
    })
    builder.addCase(fetchCategories.rejected, (state) => {
      state.fetchingCategories = false
      state.fetchedCategories = false
      return state
    })

    builder.addCase(addCategory.pending, (state) => {
      state.addingCategory = true
      return state
    })
    builder.addCase(addCategory.fulfilled, (state) => {
      state.addingCategory = false
      state.addedCategory = true
      return state
    })
    builder.addCase(addCategory.rejected, (state) => {
      state.addingCategory = false
      state.addedCategory = false
      return state
    })

    builder.addCase(updateCategory.pending, (state) => {
      state.updatingCategory = true
      return state
    })
    builder.addCase(updateCategory.fulfilled, (state) => {
      state.updatingCategory = false
      state.updatedCategory = true
      return state
    })
    builder.addCase(updateCategory.rejected, (state) => {
      state.updatingCategory = false
      state.updatedCategory = false
      return state
    })

    builder.addCase(deleteCategory.pending, (state) => {
      state.deletingCategory = true
      return state
    })
    builder.addCase(deleteCategory.fulfilled, (state) => {
      state.deletingCategory = false
      state.deletedCategory = true
      return state
    })
    builder.addCase(deleteCategory.rejected, (state) => {
      state.deletingCategory = false
      state.deletedCategory = false
      return state
    })
  },
})

export const { resetAddCategory, resetUpdateCategory, resetDeleteCategory } =
  categoriesSlice.actions

export default categoriesSlice.reducer
