import React from 'react'
import {
  Radio as MuiRadio,
  type RadioProps as MuiRadioProps,
  type Theme,
} from '@mui/material'

import { withStyles } from 'tss-react/mui'

interface RadioProps extends MuiRadioProps {}

export const Radio = withStyles(
  (props: RadioProps) => <MuiRadio {...props} />,
  (theme: Theme) => ({
    root: {
      color: theme.palette.hublet.primary.light,
      '&$checked': {
        color: theme.palette.hublet.primary.main,
      },
    },
    checked: {},
  })
)

export default Radio
