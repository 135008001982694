import { type CommunicationChannel } from 'App/Integrations/integrations-types'
import React, { useEffect, useState } from 'react'
import EditCommunicationChannelForm from './EditCommunicationChannelForm'
import Table from 'common/components/TableNew'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import IconMenu from 'common/components/IconMenu'
import {
  removeCommunicationChannel,
  fetchCommunicationChannels,
} from 'App/Integrations/integrations-state'
import ActionDialog from 'common/components/ActionDialog'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'store'

interface Props {
  channels: CommunicationChannel[]
  communicationConfigurationId: number
}

const CommunicationChannelTable = ({
  channels,
  communicationConfigurationId,
}: Props) => {
  const [editChannelModalOpen, setEditChannelModalOpen] =
    useState<boolean>(false)
  const [channel, setChannel] = useState<CommunicationChannel | null>(null)
  const [deleteChannelValue, setDeleteChannelValue] =
    useState<CommunicationChannel | null>(null)
  const { organizationId, siteId } = useCurrentAccount()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const removedCommunicationChannel = useAppSelector(
    (state) => state.integrations.removedCommunicationChannel
  )

  useEffect(() => {
    if (removedCommunicationChannel) {
      dispatch(
        fetchCommunicationChannels({
          organizationId,
          siteId,
          communicationConfigurationId: communicationConfigurationId || 0,
        })
      )
    }
  }, [
    removedCommunicationChannel,
    dispatch,
    communicationConfigurationId,
    organizationId,
    siteId,
  ])

  interface ActionsProps {
    c: CommunicationChannel
  }
  const Actions = ({ c }: ActionsProps) => {
    const actions = [
      {
        local: 'integrations.listIntegrations.actions.edit',
        fn: () => {
          setChannel(c)
          setEditChannelModalOpen(true)
        },
        icon: EditIcon,
      },
      {
        local: 'integrations.listIntegrations.actions.delete',
        fn: () => {
          setDeleteChannelValue(c)
        },
        icon: DeleteIcon,
      },
    ]

    return <IconMenu actions={actions} />
  }

  const onActionDelete = () => {
    if (deleteChannelValue != null) {
      dispatch(
        removeCommunicationChannel({
          organizationId,
          siteId,
          communicationChannel: deleteChannelValue,
        })
      )
    }
  }

  const columns = [
    {
      title: t('integrations.channels.fields.address'),
      field: 'proxyServerAddress',
    },
    {
      title: t('integrations.channels.fields.port'),
      field: 'proxyServerPort',
    },
    {
      title: t('integrations.channels.fields.protocol'),
      render: ({ proxyProtocol }: CommunicationChannel) => (
        <span>
          {t(
            `integrations.channels.form.fields.protocol.labels.${proxyProtocol}`
          )}
        </span>
      ),
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (c: CommunicationChannel) => {
        return <Actions c={c} />
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={channels} variant="panel" />
      <EditCommunicationChannelForm
        open={editChannelModalOpen}
        setModalOpen={setEditChannelModalOpen}
        channel={channel}
      />
      {deleteChannelValue != null && (
        <ActionDialog
          open={deleteChannelValue !== null}
          title={t('integrations.channels.deleteChannel.title')}
          description={t('integrations.channels.deleteChannel.description')}
          actionText={t('integrations.channels.action.delete')}
          onAction={onActionDelete}
          onClose={() => setDeleteChannelValue(null)}
        />
      )}
    </>
  )
}

export default CommunicationChannelTable
