import React from 'react'
import {
  Switch as MuiSwitch,
  type SwitchProps as MuiSwitchProps,
  type Theme,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<void, 'checked' | 'track'>()(
  (theme: Theme, _params, classes) => ({
    switchBase: {
      // color: theme.palette.hublet.primary.light,
      [`&.${classes.checked}`]: {
        color: theme.palette.hublet.primary.main,
      },
      [`&.${classes.checked} + .${classes.track}`]: {
        backgroundColor: theme.palette.hublet.primary.main,
      },
    },
    checked: {},
    track: {},
  })
)

const Switch = (props: MuiSwitchProps) => {
  const { classes } = useStyles()

  return <MuiSwitch {...props} classes={classes} />
}

export default Switch
