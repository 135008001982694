import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'

import { Provider as ReduxProvider } from 'react-redux'

import { store } from 'store'
import App from 'App'

import { ThemeProvider } from '@mui/material/styles'
import { createTheme, CssBaseline, LinearProgress } from '@mui/material'

import 'locales'
import { BrowserRouter as Router } from 'react-router-dom'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { TssCacheProvider } from 'tss-react'

const theme = createTheme({
  palette: {
    primary: {
      main: '#6C2576',
    },
    background: {
      default: '#F8F8FB',
    },
    // Theme palette type extended in `/common/types/create-palette.d.ts`
    hublet: {
      common: {
        black: '#534956',
        white: '#FFFFFF',
        gray: '#E5E5E5',
      },
      primary: {
        main: '#6C2576',
        dark: '#5F1869',
        light: '#895191',
        extraLight: '#DCD0DE',
      },
      secondary: {
        main: '#534956',
        light: '#949094',
      },
      error: '#F3777E',
      misc: {
        boxShadow: 'rgba(0, 0, 0, 0.1)',
        menuItemHover: '#F8F8FB',
      },
      help: '#F09E7B',
    },
  },
  typography: {
    fontFamily: ['Nunito', 'NotoSansKR'].join(','),
    h1: {
      fontFamily: 'Nunito',
      fontSize: '35pt',
      fontWeight: 500,
      color: '#6c2576',
    },
    h2: {
      fontFamily: 'Nunito',
      fontSize: '18pt',
      fontWeight: 600,
      color: '#534956',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          padding: '8px 24px',
          borderRadius: 32,
          boxShadow: '0 1px 1px #5A306A',
          lineHeight: 1,
          fontSize: 14,
          textTransform: 'none',
        },
      },
    },
  },
})

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

const tssCache = createCache({
  key: 'tss',
})

const Index = () => (
  <Router basename="/ui">
    <ReduxProvider store={store}>
      <CacheProvider value={muiCache}>
        <TssCacheProvider value={tssCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<LinearProgress />}>
              <App />
            </Suspense>
          </ThemeProvider>
        </TssCacheProvider>
      </CacheProvider>
    </ReduxProvider>
  </Router>
)

const container = document.getElementById('root')

if (container === null) {
  console.error('App container `#root` not found.')
} else {
  const root = createRoot(container)
  root.render(<Index />)
}
