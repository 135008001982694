import { useAppSelector } from 'store'
import { type CurrentUser } from 'App/app-state'

const useCurrentUser = (): CurrentUser => {
  const currentUser = useAppSelector((state) => state.app.currentUser)

  if (currentUser === undefined) {
    throw new Error('CurrentUser should not be undefined')
  }

  return currentUser
}

export default useCurrentUser
