import React, { useEffect, useMemo, useState } from 'react'

import {
  resetUpdatedUser,
  updateOrganizationUser,
  updateSiteUser,
} from 'App/Users/users-state'
import { useTranslation } from 'react-i18next'
import { type User, type UserEditable } from 'App/Users/users-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import DrawerForm from 'common/components/DrawerForm'
import { Box, type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import FormField from 'common/components/FormField'
import FormSwitch from 'common/components/FormSwitch'
import FormSelect from 'common/components/FormSelect'
import { UserRole } from 'App/app-state'
import { renderIf } from 'common/utils/render-utils'
import { fetchSites } from 'App/Sites/sites-state'
import useApp from 'common/hooks/useApp'
import Panel from 'common/components/Panel'
import { isGreaterOrEqRole } from 'common/utils/roles'
import useCurrentUser from 'common/hooks/useCurrentUser'
import { useLazyGetUsersQuery } from 'App/Users/users-rtk-api'
import { fetchCustomers } from 'App/Customers/customers-state'
import { fetchDistributors } from 'App/Distributors/distributors-state'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()((theme: Theme) => ({
  FormFields: {
    '& > div': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  SitePanelContainer: {
    marginTop: 0,
  },
}))

interface EditUserDrawerProps {
  user: User
  open: boolean
  onClose: () => void
}

const EditUserDrawer = ({ user, open, onClose }: EditUserDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()

  const updatedUser = useAppSelector((state) => state.users.updatedUser)
  const distributors = useAppSelector(
    (state) => state.distributors.distributors
  )
  const customers = useAppSelector((state) => state.customers.customers)
  const sites = useAppSelector((state) => state.sites.sites)
  const { organizationId, siteId, organizationDistributorId } =
    useCurrentAccount()
  const { role, site } = useApp()
  const { role: currentUserRole } = useCurrentUser()

  const [distributorId, setDistributorId] = useState(
    organizationDistributorId ?? organizationId
  )
  const [userForm, setUserForm] = useState<UserEditable>(user)

  const canEdit = isGreaterOrEqRole(currentUserRole, user.role)
  const rcvExpLoansMailSiteId = role === UserRole.SiteAdmin ? siteId : undefined

  const [fetchUsers] = useLazyGetUsersQuery()

  useEffect(() => {
    if (updatedUser) {
      onClose()
      dispatch(resetUpdatedUser())
      fetchUsers({ organizationId, siteId, withDeleted: true })
    }
  }, [dispatch, onClose, updatedUser, organizationId, siteId, fetchUsers])

  useEffect(() => {
    if (userForm.organizationId !== undefined) {
      dispatch(fetchSites({ organizationId: userForm.organizationId }))
    }
  }, [dispatch, userForm.organizationId])

  useEffect(() => {
    if (
      currentUserRole === UserRole.HubletAdmin ||
      role === UserRole.DistributorAdmin
    ) {
      dispatch(fetchCustomers(distributorId))
    }
  }, [dispatch, distributorId, currentUserRole, role])

  useEffect(() => {
    if (currentUserRole === UserRole.HubletAdmin) {
      dispatch(fetchDistributors())
    }
  }, [dispatch, currentUserRole])

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()
    if (user.role !== UserRole.SiteAdmin) {
      dispatch(
        updateOrganizationUser({
          organizationId,
          userId: user.id,
          form: { ...userForm, rcvExpLoansMailSiteId },
        })
      )
    } else {
      if (user.siteId === undefined) return
      dispatch(
        updateSiteUser({
          organizationId,
          siteId: user.siteId,
          userId: user.id,
          form: { ...userForm, rcvExpLoansMailSiteId },
        })
      )
    }
  }

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserForm({ ...userForm, [key]: e.target.value })
    }

  const userRoleOptions = [
    { label: t(`users.roles.${UserRole.OrgAdmin}`), value: UserRole.OrgAdmin },
    {
      label: t(`users.roles.${UserRole.SiteAdmin}`),
      value: UserRole.SiteAdmin,
    },
  ]

  const distributorOptions = useMemo(() => {
    return distributors.map((d) => ({
      value: d.id,
      label: d.name,
    }))
  }, [distributors])

  const customerOptions = useMemo(() => {
    return customers.map((c) => ({
      value: c.id,
      label: c.name,
    }))
  }, [customers])

  const siteOptions = useMemo(() => {
    return sites.map((s) => ({
      value: s.id,
      label: s.name,
    }))
  }, [sites])

  return (
    <DrawerForm
      title={t('users.editUser.title')}
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      submitText={t('users.editUser.buttons.submit')}
      disabledButton={!canEdit}
    >
      <Box className={classes.FormFields}>
        <FormField
          label={t('users.editUser.fields.firstName.label')}
          placeholder={t('users.editUser.fields.firstName.placeholder')}
          value={userForm.firstName}
          onChange={onChangeField('firstName')}
          error={undefined}
          disabled={!canEdit}
        />
        <FormField
          label={t('users.editUser.fields.lastName.label')}
          placeholder={t('users.editUser.fields.lastName.placeholder')}
          value={userForm.lastName}
          onChange={onChangeField('lastName')}
          error={undefined}
          disabled={!canEdit}
        />
        {renderIf(currentUserRole === UserRole.HubletAdmin, () => (
          <FormSelect
            label={t('users.editUser.fields.distributor.label')}
            value={distributorId}
            options={distributorOptions}
            onChange={(e) => {
              setDistributorId(e.target.value)
              setUserForm({
                ...userForm,
                organizationId: undefined,
                siteId: undefined,
              })
            }}
            disabled={!canEdit}
          />
        ))}
        {renderIf(
          currentUserRole === UserRole.HubletAdmin ||
            role === UserRole.DistributorAdmin,
          () => (
            <FormSelect
              label={t('users.editUser.fields.organization.label')}
              placeholder={t('users.editUser.fields.organization.placeholder')}
              value={userForm.organizationId}
              options={customerOptions}
              onChange={(e) =>
                setUserForm({
                  ...userForm,
                  organizationId: e.target.value,
                  siteId: undefined,
                })
              }
              disabled={!canEdit}
            />
          )
        )}
        {renderIf(
          currentUserRole === UserRole.HubletAdmin ||
            role === UserRole.DistributorAdmin ||
            role === UserRole.OrgAdmin,
          () => (
            <>
              <FormSelect
                label={t('users.editUser.fields.role.label')}
                placeholder={t('users.editUser.fields.role.placeholder')}
                value={userForm.role}
                options={userRoleOptions}
                onChange={(e) =>
                  setUserForm({
                    ...userForm,
                    role: e.target.value,
                    siteId: undefined,
                  })
                }
                disabled={!canEdit}
              />
              {renderIf(userForm.role === UserRole.SiteAdmin, () => (
                <FormSelect
                  label={t('users.editUser.fields.site.label')}
                  placeholder={t('users.editUser.fields.site.placeholder')}
                  value={userForm.siteId}
                  options={siteOptions}
                  onChange={(e) =>
                    setUserForm({ ...userForm, siteId: e.target.value })
                  }
                />
              ))}
            </>
          )
        )}
        {renderIf(role === UserRole.SiteAdmin, () => (
          <Panel
            title={site?.name}
            classes={{ container: classes.SitePanelContainer }}
          >
            <FormSwitch
              title={t('users.editUser.expiredEmails.label')}
              description={t('users.editUser.expiredEmails.switch')}
              checked={userForm.rcvExpLoansMail}
              onChange={() =>
                setUserForm({
                  ...userForm,
                  rcvExpLoansMail: !userForm.rcvExpLoansMail,
                })
              }
              variant="drawer"
              disabled={!canEdit}
            />
          </Panel>
        ))}
      </Box>
    </DrawerForm>
  )
}

export default EditUserDrawer
