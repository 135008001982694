import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import {
  type Category,
  type CategoryForm,
  type CategoryContext,
} from 'App/Categories/categories-types'

class CategoriesApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchCategories(context: CategoryContext): Promise<Category[]> {
    const { data } = await axios.get(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories`
    )
    return data as Category[]
  }

  static async createCategory(
    context: CategoryContext,
    form: CategoryForm
  ): Promise<Category> {
    const res = await axios.post(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories`,
      form
    )
    return res.data as Category
  }

  static async updateCategory(
    context: CategoryContext,
    categoryId: number,
    form: CategoryForm
  ): Promise<Category> {
    const res = await axios.put(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${categoryId}`,
      form
    )
    return res.data as Category
  }

  static async deleteCategory(
    context: CategoryContext,
    categoryId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/organizations/${context.organizationId}/sites/${context.siteId}/profiles/${context.profileId}/categories/${categoryId}`
    )
  }
}

export default CategoriesApi
