import { useAppSelector } from 'store'

const useUserTimeZone = () => {
  const orgTimezone = useAppSelector(
    (state) => state.app.organization?.timezone
  )
  const siteTimezone = useAppSelector((state) => state.app.site?.timezone)
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return siteTimezone ?? orgTimezone ?? localTimeZone
}

export default useUserTimeZone
