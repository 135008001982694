import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from '@reduxjs/toolkit'
import {
  type Translation,
  type SingleTranslation,
} from 'App/Translations/translations-types'
import TranslationsApi from 'api/translations-api'
import { type QueryParams, type QueryResult } from 'api/types'

interface TranslationsState {
  queryParams: QueryParams
  queryResult: QueryResult<Translation>
  data: Translation[]
  editingTranslation: Translation | null
  addingError: string
  addingTranslation: boolean
  addedTranslation: boolean
  updatedTranslation: boolean
  deletedTranslation: boolean
  fetchedTranslations: boolean
  importedTranslations: boolean
  importedTranslationsError: boolean
  suggestedTranslations: SingleTranslation[]
  fetchedAllLanguages: boolean
  allLanguages: string[]
}

const initialState: TranslationsState = {
  queryParams: { query: '' },
  queryResult: { rows: [], rowCount: 0 },
  data: [],
  editingTranslation: null,
  addingError: '',
  addingTranslation: false,
  addedTranslation: false,
  updatedTranslation: false,
  deletedTranslation: false,
  fetchedTranslations: false,
  importedTranslations: false,
  importedTranslationsError: false,
  suggestedTranslations: [],
  fetchedAllLanguages: false,
  allLanguages: [],
}

export const addTranslation = createAsyncThunk(
  'translations/addTranslation',
  async (translation: Translation, { rejectWithValue }) => {
    try {
      return await TranslationsApi.createTranslation(translation)
    } catch (err: any) {
      return rejectWithValue(err.response.data.message)
    }
  }
)

export const updateTranslation = createAsyncThunk(
  'translations/updateTranslation',
  async (translation: Translation) =>
    TranslationsApi.updateTranslation(translation)
)

export const deleteTranslation = createAsyncThunk(
  'translations/deleteTranslation',
  async (translation: Translation) =>
    TranslationsApi.deleteTranslation(translation)
)

export const fetchTranslations = createAsyncThunk(
  'translations/fetchTranslations',
  async () => TranslationsApi.fetchTranslations()
)

interface FetchTranslationProps {
  app: string
  key: string
}
export const fetchTranslation = createAsyncThunk(
  'translations/fetchTranslation',
  async ({ app, key }: FetchTranslationProps) =>
    TranslationsApi.fetchTranslation(app, key)
)

export const importTranslations = createAsyncThunk(
  'translations/importTranslations',
  async ({ language, file }: { language: string; file: File }) =>
    TranslationsApi.importTranslations(language, file)
)

export const translate = createAsyncThunk(
  'translations/translate',
  async ({ text, languages }: { text: string; languages: string[] }) =>
    TranslationsApi.translate(text, languages)
)

export const fetchAllLanguages = createAsyncThunk(
  'translations/fetchAllLanguages',
  async () => TranslationsApi.fetchAllLanguages()
)

interface ApplyQueryParamsPayload {
  params: QueryParams
  result: QueryResult<Translation>
}

export const translationsSlice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    resetAddTranslation: (state) => {
      state.addingError = ''
      state.addingTranslation = false

      state.addedTranslation = false
    },
    resetUpdatedTranslation: (state) => {
      state.editingTranslation = null
      state.updatedTranslation = false
    },
    resetDeletedTranslation: (state) => {
      state.deletedTranslation = false
    },
    resetImportTranslations: (state) => {
      state.importedTranslations = false
      state.importedTranslationsError = false
    },
    resetSuggestedTranslations: (state) => {
      state.suggestedTranslations = []
    },
    applyQueryParams: {
      reducer: (state, { payload }: PayloadAction<ApplyQueryParamsPayload>) => {
        const { params, result } = payload
        state.queryParams = params
        state.queryResult = result
      },
      prepare: (data: Translation[], params: QueryParams) => {
        const rows = data.filter((t) => {
          const q = new RegExp(params.query.trim(), 'i')
          return (
            t.key.match(q) != null ||
            t.translations?.some((t) => t.translation.match(q))
          )
        })
        return {
          payload: {
            params,
            result: {
              rows,
              rowCount: rows.length,
            },
          },
        }
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTranslations.fulfilled, (state, { payload }) => {
      state.fetchedTranslations = true
      state.queryResult = payload
      state.data = payload.rows
      return state
    })

    builder.addCase(fetchTranslation.fulfilled, (state, { payload }) => {
      state.editingTranslation = payload
      return state
    })

    builder.addCase(fetchTranslation.rejected, (state) => {
      state.editingTranslation = null
      return state
    })

    builder.addCase(addTranslation.pending, (state) => {
      state.addingError = ''
      state.addingTranslation = true
      state.addedTranslation = false
      return state
    })

    builder.addCase(addTranslation.fulfilled, (state) => {
      state.addingError = ''
      state.addingTranslation = false
      state.addedTranslation = true
      return state
    })

    builder.addCase(addTranslation.rejected, (state, { payload }) => {
      state.addingError = payload as string
      state.addingTranslation = false
      state.addedTranslation = false
      return state
    })

    builder.addCase(updateTranslation.fulfilled, (state) => {
      state.updatedTranslation = true
      return state
    })

    builder.addCase(deleteTranslation.fulfilled, (state) => {
      state.deletedTranslation = true
      return state
    })

    builder.addCase(importTranslations.fulfilled, (state) => {
      state.importedTranslations = true
      state.importedTranslationsError = false
      return state
    })

    builder.addCase(importTranslations.rejected, (state) => {
      state.importedTranslations = false
      state.importedTranslationsError = true
      return state
    })

    builder.addCase(translate.fulfilled, (state, { payload }) => {
      state.suggestedTranslations = payload
      return state
    })

    builder.addCase(fetchAllLanguages.fulfilled, (state, { payload }) => {
      state.fetchedAllLanguages = true
      state.allLanguages = payload
      return state
    })
  },
})

export const {
  resetAddTranslation,
  resetUpdatedTranslation,
  resetDeletedTranslation,
  resetImportTranslations,
  resetSuggestedTranslations,
  applyQueryParams,
} = translationsSlice.actions

export default translationsSlice.reducer
