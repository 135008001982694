import { UserRole } from 'App/app-state'

const {
  HubletAdmin,
  DistributorAdmin,
  OrgAdmin,
  SiteAdmin,
  // SiteUser,
  // Translator,
  Manufacturer,
} = UserRole

export const isGreaterOrEqRole = (roleA: UserRole, roleB: UserRole) => {
  // Checks if `roleA` is greater or equivalent to `roleB`

  switch (roleA) {
    case HubletAdmin:
      return true
    case DistributorAdmin:
      return roleB !== HubletAdmin
    case OrgAdmin:
      return roleB === OrgAdmin || roleB === SiteAdmin
    case SiteAdmin:
      return roleB === SiteAdmin
    default:
      return false
  }
}

type Section = 'distributors' | 'customers' | 'sites' | 'borrowers'

/**
 * This doesn't answer whether the user has access to
 * some specific section (e.g. site with id=`1`).
 *
 * This answers a more general, "Should a user with this
 * role have access to some section of this type?"
 */
export const roleShouldHaveAccess = (role: UserRole, section: Section) => {
  let accept = []

  switch (section) {
    case 'distributors':
      accept = [HubletAdmin, Manufacturer]
      break
    case 'customers':
      accept = [HubletAdmin, Manufacturer, DistributorAdmin]
      break
    case 'sites':
      accept = [HubletAdmin, Manufacturer, DistributorAdmin, OrgAdmin]
      break
    case 'borrowers':
      accept = [HubletAdmin, DistributorAdmin, OrgAdmin]
      break
  }

  return accept.includes(role)
}
