import React from 'react'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

const useStyles = makeStyles()((theme: Theme) => ({
  Status: {
    fontWeight: 'bold',
    '& > *': {
      verticalAlign: 'middle',
    },
    '& > svg': {
      fontSize: '1rem',
      marginRight: theme.spacing(0.5),
    },
  },
  StatusGreen: {
    color: '#64AB43',
  },
  StatusRed: {
    color: theme.palette.hublet.error,
  },
}))

interface StatusTextProps {
  status: 'green' | 'red'
  text: string
  withIcon?: boolean
}

const StatusText = ({ status, text, withIcon = false }: StatusTextProps) => {
  const { classes, cx } = useStyles()

  const statusClasses = [classes.Status]
  let icon = null

  if (status === 'green') {
    statusClasses.push(classes.StatusGreen)
    icon = <CheckCircleIcon />
  } else if (status === 'red') {
    statusClasses.push(classes.StatusRed)
    icon = <ErrorIcon />
  }

  return (
    <span className={cx(statusClasses)}>
      {withIcon && icon}
      <span>{text}</span>
    </span>
  )
}

export default StatusText
