import React, { useEffect, useMemo, useState } from 'react'

import Table from 'common/components/TableNew'

import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import IconMenu from 'common/components/IconMenu'

import { type Post } from 'App/Posts/posts-types'
import { deletePost, fetchPosts, resetDeletedPost } from 'App/Posts/posts-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import ActionDialog from 'common/components/ActionDialog'
import { useTranslation } from 'react-i18next'
import EditPostDrawer from 'App/Posts/components/EditPostDrawer'
import { useAppDispatch, useAppSelector } from 'store'

interface PostsRowActionsProps {
  post: Post
  onClickDelete: (post: Post) => void
  onClickEdit: (post: Post) => void
}

const PostsRowActions = ({
  post,
  onClickDelete,
  onClickEdit,
}: PostsRowActionsProps) => {
  const actions = [
    {
      local: 'posts.listPosts.actions.edit',
      fn: () => onClickEdit(post),
      icon: EditIcon,
    },
    {
      local: 'posts.listPosts.actions.delete',
      fn: () => onClickDelete(post),
      icon: DeleteIcon,
    },
  ]

  return <IconMenu actions={actions} />
}

const PostsTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [editPostValue, setEditPostvalue] = useState<Post | null>(null)
  const [deletePostValue, setDeletePostValue] = useState<Post | null>(null)

  const posts = useAppSelector((state) => state.posts.posts)
  const deletedPost = useAppSelector((state) => state.posts.deletedPost)

  const { organizationId, siteId } = useCurrentAccount()
  const context = useMemo(
    () => ({ organizationId, siteId }),
    [organizationId, siteId]
  )

  useEffect(() => {
    if (deletedPost) {
      dispatch(resetDeletedPost())
      dispatch(fetchPosts(context))
    }
  }, [dispatch, deletedPost, context])

  const onClickDelete = (post: Post) => setDeletePostValue(post)
  const onClickEdit = (post: Post) => setEditPostvalue(post)

  const onActionDelete = () => {
    if (deletePostValue != null) {
      dispatch(deletePost({ context, postId: deletePostValue.id }))
    }
  }

  const onCloseActionDeleteDialog = () => setDeletePostValue(null)
  const onCloseEditPostDrawer = () => setEditPostvalue(null)

  const columns = [
    {
      title: t('posts.listPosts.columns.title'),
      field: 'title',
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (post: Post) => {
        return (
          <PostsRowActions
            post={post}
            onClickDelete={onClickDelete}
            onClickEdit={onClickEdit}
          />
        )
      },
      style: { width: '24px' },
    },
  ]

  return (
    <>
      <Table columns={columns} data={posts} />
      {editPostValue != null && (
        <EditPostDrawer
          post={editPostValue}
          open={editPostValue !== null}
          onClose={onCloseEditPostDrawer}
        />
      )}
      {deletePostValue != null && (
        <ActionDialog
          open={deletePostValue !== null}
          title={t('posts.deletePost.title')}
          description={t('posts.deletePost.description', {
            post: deletePostValue.title,
          })}
          actionText={t('posts.deletePost.action')}
          onAction={onActionDelete}
          onClose={onCloseActionDeleteDialog}
        />
      )}
    </>
  )
}

export default PostsTable
