import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import PageBody from 'common/components/PageBody'
import Table from 'common/components/TableNew'
import useRenderDate from 'common/hooks/useRenderDate'
import { type Loan, LoanStatus } from 'App/Loans/loans-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchLoans } from 'App/Loans/loans-state'
import { useTranslation } from 'react-i18next'
import IconMenu from 'common/components/IconMenu'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import BlockIcon from '@mui/icons-material/BlockOutlined'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import AccessTimeIcon from '@mui/icons-material/AccessTimeOutlined'
import ViewLoanDrawer from 'App/Loans/components/ViewLoanDrawer'
import LoanStatusView from 'App/Loans/components/LoanStatus'
import ActionDialog from 'common/components/ActionDialog'
import Toast from 'common/components/Toast'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import {
  useEndLoanMutation,
  useExtendLoanMutation,
  useTerminateLoanMutation,
} from 'App/Loans/loans-rtk-api'
import { useAppDispatch, useAppSelector } from 'store'
import useApp from 'common/hooks/useApp'

interface LoansRowActionsProps {
  loan: Loan
  onClickViewDetails: (loan: Loan) => void
  onClickEnd: (loan: Loan) => void
  onClickTerminate: (loan: Loan) => void
  onClickExtend: (loan: Loan) => void
}

const LoansRowActions = ({
  loan,
  onClickViewDetails,
  onClickEnd,
  onClickTerminate,
  onClickExtend,
}: LoansRowActionsProps) => {
  const { adminMode } = useApp()

  const actions = [
    {
      local: 'loans.listLoans.actions.viewDetails',
      fn: () => onClickViewDetails(loan),
      icon: VisibilityIcon,
    },
  ]

  if (loan.status !== LoanStatus.RETURNED) {
    actions.push({
      local: 'loans.listLoans.actions.extend',
      fn: () => onClickExtend(loan),
      icon: AccessTimeIcon,
    })

    actions.push({
      local: 'loans.listLoans.actions.endLoan',
      fn: () => onClickEnd(loan),
      icon: BlockIcon,
    })

    if (adminMode) {
      actions.push({
        local: 'loans.listLoans.actions.terminateLoan',
        fn: () => onClickTerminate(loan),
        icon: CancelIcon,
      })
    }
  }

  return <IconMenu actions={actions} />
}

interface LoansTableProps {
  loans: Loan[]
}

const LoansTable = ({ loans }: LoansTableProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const renderDate = useRenderDate()

  const [extendLoan, { isSuccess: isExtendLoanSuccess }] =
    useExtendLoanMutation()

  const [viewLoanDetailsValue, setViewLoanDetailsValue] = useState<
    Loan | undefined
  >()
  const [endLoanValue, setEndLoanValue] = useState<Loan | undefined>()
  const [terminateLoanValue, setTerminateLoanValue] = useState<
    Loan | undefined
  >()

  const { organizationId, siteId } = useCurrentAccount()

  const [endLoan, { isSuccess: isEndLoanSuccess }] = useEndLoanMutation()
  const [terminateLoan, { isSuccess: isTerminateLoanSuccess }] =
    useTerminateLoanMutation()

  useEffect(() => {
    if (isTerminateLoanSuccess) {
      dispatch(fetchLoans({ organizationId, siteId }))
    }
  }, [dispatch, isTerminateLoanSuccess, organizationId, siteId])

  useEffect(() => {
    if (isEndLoanSuccess) {
      dispatch(fetchLoans({ organizationId, siteId }))
    }
  }, [dispatch, isEndLoanSuccess, organizationId, siteId])

  useEffect(() => {
    if (isExtendLoanSuccess) {
      dispatch(fetchLoans({ organizationId, siteId }))
    }
  }, [dispatch, isExtendLoanSuccess, organizationId, siteId])

  const columns = [
    {
      title: t('loans.listLoans.columns.started'),
      // eslint-disable-next-line react/display-name
      render: ({ startTime }: Loan) => (
        <DateWithRelativeTime datetime={startTime} />
      ),
      style: { width: 'auto' },
    },
    {
      title: t('loans.listLoans.columns.endTime'),
      // eslint-disable-next-line react/display-name
      render: ({ endTime, returnTime }: Loan) =>
        returnTime === undefined ? (
          <DateWithRelativeTime
            datetime={endTime}
            pastTimeText={t('loans.loanTimeLeft.expired')}
          />
        ) : (
          renderDate(returnTime)
        ),
      style: { width: '200px' },
    },
    {
      title: t('loans.listLoans.columns.tabletStatus'),
      // eslint-disable-next-line react/display-name
      render: ({ status, tabletActiveAt }: Loan) => (
        <LoanStatusView status={status} tabletActiveAt={tabletActiveAt} />
      ),
      style: { width: '175px' },
    },
    {
      title: t('loans.listLoans.columns.tablet'),
      // eslint-disable-next-line react/display-name
      render: ({ tabletSerialNumber }: Loan) => tabletSerialNumber ?? '-',
    },
    {
      title: t('loans.listLoans.columns.profile'),
      field: 'profileName',
    },
    {
      id: 'actions',
      // eslint-disable-next-line react/display-name
      render: (loan: Loan) => (
        <LoansRowActions
          loan={loan}
          onClickViewDetails={(loan: Loan) => setViewLoanDetailsValue(loan)}
          onClickEnd={(loan: Loan) => setEndLoanValue(loan)}
          onClickTerminate={(loan: Loan) => setTerminateLoanValue(loan)}
          onClickExtend={handleActionExtendLoan}
        />
      ),
      style: { width: '24px' },
    },
  ]

  const handleActionEnd = () => {
    if (endLoanValue == null) {
      return
    }

    endLoan({ organizationId, siteId, loanId: endLoanValue.id })
  }

  const handleActionTerminate = () => {
    if (terminateLoanValue == null) {
      return
    }

    terminateLoan({ organizationId, siteId, loanId: terminateLoanValue.id })
  }

  const handleActionExtendLoan = (loan: Loan) => {
    extendLoan({
      organizationId,
      siteId,
      loanId: loan.id,
    })
  }

  return (
    <>
      <Table columns={columns} data={loans} />
      {viewLoanDetailsValue != null && (
        <ViewLoanDrawer
          open={viewLoanDetailsValue !== undefined}
          loan={viewLoanDetailsValue}
          onClose={() => setViewLoanDetailsValue(undefined)}
        />
      )}
      {endLoanValue != null && (
        <ActionDialog
          open={endLoanValue != null}
          title={t('loans.end.title')}
          description={t('loans.end.description')}
          actionText={t('loans.end.action')}
          onAction={handleActionEnd}
          onClose={() => setEndLoanValue(undefined)}
        />
      )}
      {terminateLoanValue != null && (
        <ActionDialog
          open={terminateLoanValue != null}
          title={t('loans.terminate.title')}
          description={t('loans.terminate.description')}
          actionText={t('loans.terminate.action')}
          onAction={handleActionTerminate}
          onClose={() => setTerminateLoanValue(undefined)}
        />
      )}
      <Toast open={isEndLoanSuccess} message={t('loans.end.toast.success')} />
      <Toast
        open={isTerminateLoanSuccess}
        message={t('loans.terminate.toast.success')}
      />
      <Toast
        open={isExtendLoanSuccess}
        message={t('loans.extendLoan.toast.success')}
      />
    </>
  )
}

const Loans = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const loans = useAppSelector((state) => state.loans.loans)
  const fetchedLoans = useAppSelector((state) => state.loans.fetchedLoans)

  const { organizationId, siteId } = useCurrentAccount()

  useEffect(() => {
    dispatch(fetchLoans({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  if (!fetchedLoans) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageHeader>
          <PageTitle title={t('loans.title')} />
        </PageHeader>
        <PageBody>
          <LoansTable loans={loans} />
        </PageBody>
      </Page>
    </>
  )
}

export default Loans
