import React, { useEffect, useState } from 'react'
import { Snackbar, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  Toast: {
    '& > .MuiSnackbarContent-root': {
      minWidth: 'auto',
      padding: '10px 24px',
      boxShadow: 'none',
      borderRadius: '10px',
      backgroundColor: '#6EA7FD',
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.palette.hublet.common.white,
      '& > .MuiSnackbarContent-message': {
        padding: 0,
      },
    },
  },
}))

interface ToastProps {
  message: string
  open?: boolean
  onClose?: () => void
}

const Toast = ({ message, open, onClose }: ToastProps) => {
  const { classes } = useStyles()
  const [isOpen, setIsOpen] = useState(open)

  useEffect(() => {
    if (open) {
      setIsOpen(open)
    }
  }, [open])

  const handleClose = () => {
    setIsOpen(false)
    if (onClose !== undefined) {
      onClose()
    }
  }

  return (
    <Snackbar
      className={classes.Toast}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      onClose={handleClose}
      message={message}
      autoHideDuration={3000}
    />
  )
}

export default Toast
