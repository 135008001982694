import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { type Dock } from 'App/Docks/docks-types'
import DrawerWithTabs from 'common/components/DrawerWithTabs'
import DockConnectedTablets from 'App/Docks/components/DockConnectedTablets'
import DockGeneral from 'App/Docks/components/DockGeneral'
import DockLocation from 'App/Docks/components/DockLocation'
import DockActions from 'App/Docks/components/DockActions'
import useApp from 'common/hooks/useApp'
import DockLoans from 'App/Docks/components/DockLoans'
import DocksReactContext from 'App/Docks/docks-react-context'
import DockTest from 'App/Docks/components/DockTest'

interface DockDetailsDrawerProps {
  dock: Dock
  open: boolean
  onClose: () => void
}

const DockDetailsDrawer = ({ dock, open, onClose }: DockDetailsDrawerProps) => {
  const { t } = useTranslation()
  const { adminMode } = useApp()
  const context = useContext(DocksReactContext)

  const tabs = [
    {
      title: t('docks.details.tabs.general'),
      component: <DockGeneral dock={dock} />,
    },
    {
      title: t('docks.details.tabs.connectedTablets'),
      component: <DockConnectedTablets dock={dock} />,
    },
    {
      title: t('docks.details.tabs.location'),
      component: <DockLocation dock={dock} />,
    },
  ]

  if (context === 'site') {
    tabs.push({
      title: t('docks.details.tabs.loans'),
      component: <DockLoans dock={dock} />,
    })
  }

  if (adminMode) {
    tabs.push({
      title: t('docks.details.tabs.test'),
      component: <DockTest dock={dock} />,
    })
  }

  tabs.push({
    title: t('docks.details.tabs.actions'),
    component: <DockActions dock={dock} />,
  })

  return (
    <DrawerWithTabs
      open={open}
      onClose={onClose}
      title={t('docks.details.title')}
      tabs={tabs}
    />
  )
}

export default DockDetailsDrawer
