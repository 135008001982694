import React, { useEffect, useState } from 'react'

import FormField from 'common/components/FormField'

import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import { makeStyles } from 'tss-react/mui'
import {
  addProfile,
  fetchProfiles,
  resetAddProfile,
} from 'App/Profiles/profiles-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { useAppDispatch, useAppSelector } from 'store'

const useStyles = makeStyles()(() => ({
  FormField: {
    '&:not(:first-child)': {
      marginTop: '32px',
    },
  },
}))

interface AddProfileDrawerProps {
  open: boolean
  onClose: () => void
}

interface FieldErrors {
  name?: string
  description?: string
}

const initialProfileForm = {
  name: '',
  description: '',
}

const AddProfileDrawer = ({ open, onClose }: AddProfileDrawerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [profileForm, setProfileForm] = useState(initialProfileForm)
  const [fieldErrors, setFieldErrors] = useState<FieldErrors>({})

  const currentAccount = useCurrentAccount()
  const addedProfile = useAppSelector((state) => state.profiles.addedProfile)

  useEffect(() => {
    if (addedProfile) {
      const { organizationId, siteId } = currentAccount

      onClose()
      setProfileForm(initialProfileForm)
      dispatch(resetAddProfile())
      dispatch(fetchProfiles({ organizationId, siteId }))
    }
  }, [dispatch, onClose, addedProfile, currentAccount])

  const onChangeField =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setProfileForm({ ...profileForm, [key]: e.target.value })
    }

  const onSubmitForm: React.FormEventHandler = (e) => {
    e.preventDefault()

    let hasError = false
    const errors: FieldErrors = {}

    if (profileForm.name === '') {
      hasError = true
      errors.name = t('profiles.addProfile.errors.emptyField')
    }

    if (!hasError) {
      const context = {
        organizationId: currentAccount.organizationId,
        siteId: currentAccount.siteId,
      }
      dispatch(addProfile({ context, form: profileForm }))
    }

    setFieldErrors(errors)
  }

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={onSubmitForm}
      title={t('profiles.addProfile.title')}
      submitText={t('profiles.addProfile.buttons.submit')}
    >
      <FormField
        className={classes.FormField}
        label={t('profiles.addProfile.profileNameField.label')}
        placeholder={t('profiles.addProfile.profileNameField.placeholder')}
        value={profileForm.name}
        onChange={onChangeField('name')}
        autoFocus
        error={fieldErrors.name}
      />
      <FormField
        className={classes.FormField}
        label={t('profiles.addProfile.profileDescriptionField.label')}
        value={profileForm.description}
        onChange={onChangeField('description')}
        error={fieldErrors.description}
      />
    </DrawerForm>
  )
}

export default AddProfileDrawer
