import { useMemo } from 'react'

export interface UseSearchOptions<T> {
  data: T[]
  fields: Array<keyof T>
  query: string
  renderers?: {
    [Property in keyof T]+?: (v: T[Property]) => string
  }
  customFields?: Record<string, (v: T) => string>
}

/**
 *
 * Can be used to do a simple local text search against defined fields in a dataset.
 * `renderers` can be used to define custom renderers for field values. E.g.
 *  to make it not match against the stored value, but how it is shown to the user.
 * `customFields` can be used to define custom fields. E.g. combine "first name" and
 *  "last name" fields to a single "full name" field.
 */
const useSearch = <T>({
  data,
  fields,
  query,
  renderers,
  customFields,
}: UseSearchOptions<T>) => {
  return useMemo(() => {
    const q = query.trim()
    if (q === '') return data

    const qRegExp = new RegExp(q, 'i')

    return data.filter((item) => {
      const matchField = fields.some((field) => {
        const value = item[field]
        let valueStr: string | undefined
        const renderer = renderers?.[field]
        if (renderer !== undefined) {
          valueStr = renderer(value)
        } else if (value === undefined || value === null) {
          valueStr = ''
        } else {
          switch (typeof value) {
            case 'string':
              valueStr = value
              break
            case 'number':
              valueStr = value.toString()
              break
            default:
              throw new Error(
                `useSearch does not yet support filtering by ${typeof value} type`
              )
          }
        }

        return valueStr.match(qRegExp)
      })

      if (matchField) return true
      if (customFields === undefined) return false

      return Object.keys(customFields).some((name) => {
        const valueStr = customFields[name](item)
        return valueStr.match(qRegExp)
      })
    })
  }, [data, fields, query, customFields, renderers])
}

export default useSearch
