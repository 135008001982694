import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from 'common/components/Dialog'
import {
  fetchGoogleToken,
  resetAddApp,
  addApp,
  fetchContents,
} from 'App/Categories/contents-state'
import { useAppDispatch, useAppSelector } from 'store'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import useCurrentAccount from 'common/hooks/useCurrentAccount'

const useStyles = makeStyles()((theme: Theme) => ({
  AddAppIframeContainer: {
    width: '920px',
    maxWidth: '100%',
    height: '734px',
    maxHeight: '100%',
    '& > iframe': {
      width: '100%',
      height: '100%',
      border: 0,
    },
  },
  AddAppDialogContainer: {
    width: 'auto',
  },
  AddAppDialogTitle: {
    padding: '30px 36px',
    borderBottom: '1px solid #DADCE0',
    backgroundColor: theme.palette.hublet.common.white,
  },
  AddAppDialogContent: {
    padding: 0,
  },
  AddAppDialogActions: {
    borderTop: '1px solid #DADCE0',
    backgroundColor: theme.palette.hublet.common.white,
  },
}))

interface AddAppProps {
  open: boolean
  onClose: () => void
  profileId: number
  categoryId: number
}

const AddApp = ({ open, onClose, profileId, categoryId }: AddAppProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { classes } = useStyles()

  const [iframeContainer, setIframeContainer] = useState<HTMLDivElement | null>(
    null
  )
  const googleToken = useAppSelector((state) => state.contents.googleToken)

  const addedApp = useAppSelector((state) => state.contents.addedApp)

  const { organizationId, siteId } = useCurrentAccount()

  const context = useMemo(
    () => ({ organizationId, siteId, profileId, categoryId }),
    [organizationId, siteId, profileId, categoryId]
  )

  const onCancel = () => {
    onClose()
    dispatch(resetAddApp())
  }

  interface ProductSelect {
    action?: string
    packageName?: string
  }

  useEffect(() => {
    if (googleToken === null) {
      dispatch(fetchGoogleToken(context))
      return
    }

    if (!open || iframeContainer === null) {
      return
    }

    const onProductSelect = ({ action, packageName }: ProductSelect) => {
      if (action !== 'selected' || packageName === undefined) {
        console.log('onProductSelect error', action, packageName)
        return
      }

      dispatch(addApp({ context, packageName }))
    }

    const initIframe = () => {
      const options = {
        url: `https://play.google.com/work/embedded/search?token=${encodeURIComponent(
          googleToken
        )}&mode=SELECT`,
        where: iframeContainer,
        attributes: { style: 'width: 100%; height: 100%', scrolling: 'no' },
      }

      const iframe = window.gapi.iframes.getContext().openChild(options)
      iframe.register(
        'onproductselect',
        onProductSelect,
        window.gapi.iframes.CROSS_ORIGIN_IFRAMES_FILTER
      )
    }

    if (window.gapi.iframes === undefined) {
      window.gapi.load('gapi.iframes', initIframe)
    } else {
      initIframe()
    }
  }, [dispatch, open, googleToken, context, iframeContainer])

  useEffect(() => {
    if (addedApp) {
      onClose()
      dispatch(resetAddApp())
      dispatch(fetchContents(context))
    }
  }, [onClose, context, dispatch, addedApp])

  if (googleToken === null) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      title={t('contents.add.app.title')}
      classes={{
        container: classes.AddAppDialogContainer,
        title: classes.AddAppDialogTitle,
        content: classes.AddAppDialogContent,
        actions: classes.AddAppDialogActions,
      }}
    >
      <div
        ref={(e) => setIframeContainer(e)}
        className={classes.AddAppIframeContainer}
      />
    </Dialog>
  )
}

export default AddApp
