import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TopAppsTable from 'App/TopApps/components/TopAppsTable'
import { useGetTopAppsQuery } from 'App/TopApps/topapps-rtk-api'

const TopApps = () => {
  const { t } = useTranslation()
  const { data: topApps, isSuccess: fetchedTopApps } = useGetTopAppsQuery()

  if (!fetchedTopApps) {
    return <Page />
  }

  return (
    <Page>
      <PageHeader>
        <PageTitle title={t('topapps.title')} />
      </PageHeader>
      <PageBody>
        <TopAppsTable topApps={topApps.topApps} />
      </PageBody>
    </Page>
  )
}

export default TopApps
