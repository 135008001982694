import {
  type Dock,
  type DockLocation,
  type DockLocationEditable,
  type DocksContext,
} from 'App/Docks/docks-types'
import { getDocksPathContext } from 'App/Docks/docks-utils'
import { type HubletServer } from 'common/types/hublet-server'
import { listToQueryParams } from 'common/utils/api-utils'
import rtkApi from 'rtk-api'

interface DocksApi {
  organizationId: number
  siteId: number
  context: DocksContext
}
type DockApi = DocksApi & { dockId: number }

type TestCanBorrowReq = DockApi & {
  username: string
  password: string
  profileId: number | null
  usedVirtualKeyboard: boolean
}

const docksRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getDock: builder.query<Dock, DockApi>({
      query: ({ organizationId, siteId, dockId, context }) =>
        `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}`,
    }),
    getDockLocation: builder.query<DockLocation, DockApi>({
      query: ({ organizationId, siteId, dockId, context }) =>
        `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/location`,
    }),
    updateDockLocation: builder.mutation<
      null,
      DockApi & { data: DockLocationEditable }
    >({
      query: ({ organizationId, siteId, dockId, context, data }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/location`,
        method: 'PUT',
        body: data,
      }),
    }),
    unassignDockLocation: builder.mutation<null, DockApi>({
      query: ({ organizationId, siteId, dockId, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/location/unassign`,
        method: 'POST',
      }),
    }),
    deleteDock: builder.mutation<null, DockApi>({
      query: ({ organizationId, siteId, dockId, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}`,
        method: 'DELETE',
      }),
    }),
    undeleteDock: builder.mutation<null, DockApi>({
      query: ({ organizationId, siteId, dockId, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/undelete`,
        method: 'POST',
      }),
    }),
    updateDockLoans: builder.mutation<null, DockApi & { profileId?: number }>({
      query: ({ organizationId, siteId, dockId, context, profileId }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/loans`,
        method: 'PUT',
        body: { profileId },
      }),
    }),
    getDockBorrowingPriorities: builder.query<number[], DockApi>({
      query: ({ organizationId, siteId, dockId, context }) =>
        `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/borrowing-priorities`,
    }),
    deleteDocks: builder.mutation<null, DocksApi & { dockIds: number[] }>({
      query: ({ organizationId, siteId, dockIds, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks?${listToQueryParams('dockIds', dockIds)}`,
        method: 'DELETE',
      }),
    }),
    undeleteDocks: builder.mutation<null, DocksApi & { dockIds: number[] }>({
      query: ({ organizationId, siteId, dockIds, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/undelete?${listToQueryParams('dockIds', dockIds)}`,
        method: 'POST',
      }),
    }),
    testCanBorrow: builder.mutation<any, TestCanBorrowReq>({
      query: ({ organizationId, siteId, dockId, context, ...body }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/test/can-borrow`,
        method: 'POST',
        body,
      }),
    }),
    rebootDocks: builder.mutation<null, DocksApi & { dockIds: number[] }>({
      query: ({ organizationId, siteId, dockIds, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/reboot?${listToQueryParams('dockIds', dockIds)}`,
        method: 'POST',
      }),
    }),
    shutdownDocks: builder.mutation<null, DocksApi & { dockIds: number[] }>({
      query: ({ organizationId, siteId, dockIds, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/shutdown?${listToQueryParams('dockIds', dockIds)}`,
        method: 'POST',
      }),
    }),
    upgradeDocks: builder.mutation<null, DocksApi & { dockIds: number[] }>({
      query: ({ organizationId, siteId, dockIds, context }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/upgrade?${listToQueryParams('dockIds', dockIds)}`,
        method: 'POST',
      }),
    }),
    updateDockServer: builder.mutation<
      null,
      DockApi & { server: HubletServer | null }
    >({
      query: ({ organizationId, siteId, dockId, context, ...body }) => ({
        url: `${getDocksPathContext(
          organizationId,
          siteId,
          context
        )}/docks/${dockId}/server`,
        method: 'PUT',
        body,
      }),
    }),
  }),
})

export const {
  useGetDockQuery,
  useGetDockLocationQuery,
  useUpdateDockLocationMutation,
  useUnassignDockLocationMutation,
  useDeleteDockMutation,
  useUndeleteDockMutation,
  useUpdateDockLoansMutation,
  useGetDockBorrowingPrioritiesQuery,
  useDeleteDocksMutation,
  useUndeleteDocksMutation,
  useTestCanBorrowMutation,
  useRebootDocksMutation,
  useShutdownDocksMutation,
  useUpgradeDocksMutation,
  useUpdateDockServerMutation,
} = docksRtkApi

export default docksRtkApi
