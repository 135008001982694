import React from 'react'
import { Grid, type Theme } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  PanelField: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: 'center',
    '&:first-child': { paddingTop: 0 },
    '&:last-child': { paddingBottom: 0 },
    '& > *:first-child': { fontWeight: 'bold' },
  },
  PanelFieldTitle: {
    flexGrow: 1,
  },
  PanelFieldValue: {
    textAlign: 'right',
  },
  PagePanelField: {
    '& > *:first-child': { fontWeight: 'normal' },
  },
}))

interface PanelFieldProps {
  title: string | React.ReactNode
  value?: string | React.ReactNode
  variant?: 'default' | 'page'
}

const PanelField = ({ title, value, variant = 'default' }: PanelFieldProps) => {
  const { classes, cx } = useStyles()

  const panelFieldClasses = [classes.PanelField]

  if (variant === 'page') {
    panelFieldClasses.push(classes.PagePanelField)
  }

  return (
    <Grid container className={cx(panelFieldClasses)}>
      <Grid item className={classes.PanelFieldTitle}>
        {title}
      </Grid>
      <Grid item className={classes.PanelFieldValue}>
        {value ?? '-'}
      </Grid>
    </Grid>
  )
}

export default PanelField
