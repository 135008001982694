import React from 'react'
import { type Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((_theme: Theme) => ({
  DrawerPaddingForHelp: {
    height: '64px',
  },
}))

/*
  This adds some padding to the bottom of all drawer components,
  so that the footer wouldn't collide with the bottom right help button.
*/
const DrawerPaddingForHelp = () => {
  const { classes } = useStyles()

  return <div className={classes.DrawerPaddingForHelp} />
}

export default DrawerPaddingForHelp
