import Page from 'common/components/Page'
import PageBody from 'common/components/PageBody'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Redirect = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.replace(to)
  }, [to])

  return null
}

const Zendesk = () => {
  const { t } = useTranslation()

  const [token, setToken] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    fetch('/api/v2/web/user/zendesk/tokens/help-center').then(async (res) => {
      if (res.ok) {
        setToken(await res.text())
      } else {
        setToken(null)
        const err = await res.text()
        setError(err)
        console.error(
          `Could not fetch Zendesk help center token. Response: ${err}`
        )
      }
    })
  }, [])

  const zendeskUrl = `https://hublet.zendesk.com/access/jwt?jwt=${token ?? ''}`

  return (
    <Page>
      <PageHeader>
        <PageTitle title={t('helpCenter.title')} />
      </PageHeader>
      <PageBody>
        <div>
          {token != null ? (
            <Redirect to={zendeskUrl} />
          ) : (
            t('helpCenter.redirect.info')
          )}
        </div>
        <div>{error != null ? t('helpCenter.redirect.error') : ''}</div>
      </PageBody>
    </Page>
  )
}

export default Zendesk
