import React, { useEffect } from 'react'
import SitesView from 'App/Sites/SitesView'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchSites } from 'App/Sites/sites-state'
import { useAppDispatch } from 'store'

const SiteSettings = () => {
  const dispatch = useAppDispatch()

  const { organizationId, siteId } = useCurrentAccount()

  useEffect(() => {
    dispatch(fetchSites({ organizationId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SitesView id={siteId} />
}

export default SiteSettings
