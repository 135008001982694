import React, { useMemo, useState, useEffect, useCallback } from 'react'
import TableActionsBar from 'common/components/TableActionsBar'
import { useTranslation } from 'react-i18next'
import { type User } from 'App/Users/users-types'
import useApp from 'common/hooks/useApp'
import { isGreaterOrEqRole } from 'common/utils/roles'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrashOutlined'
import ActionDialog from 'common/components/ActionDialog'
import {
  useDeleteUsersMutation,
  useLazyGetUsersQuery,
  useUndeleteUsersMutation,
  useUnlockUsersMutation,
} from 'App/Users/users-rtk-api'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import ErrorBox from 'common/components/ErrorBox'
import useApiErrors from 'common/hooks/useApiErrors'
import Toast from 'common/components/Toast'
import useItemListToString from 'common/hooks/useItemListToString'
import UnlockIcon from '@mui/icons-material/LockOpenOutlined'

interface UsersUndeleteActionDialogProps {
  usersToUndelete: User[]
  onClose: () => void
}

const UsersUndeleteActionDialog = ({
  usersToUndelete,
  onClose,
}: UsersUndeleteActionDialogProps) => {
  const { t } = useTranslation()

  const { role: activeRole } = useApp()
  const { organizationId, siteId } = useCurrentAccount()

  const [fetchUsers] = useLazyGetUsersQuery()

  const [
    undeleteUsers,
    { isSuccess: isUndeleteUsersSuccess, error: undeleteUsersError },
  ] = useUndeleteUsersMutation()
  const [undeletingUsersCount, setDeletingUsersCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    undeleteUsersError,
  ])

  const userListToString = useItemListToString<User>(
    (u) => u.email,
    'users.undeleteUsers.remaining'
  )

  useEffect(() => {
    if (isUndeleteUsersSuccess) {
      fetchUsers({ organizationId, siteId, withDeleted: true })
      onClose()
    }
  }, [onClose, isUndeleteUsersSuccess, fetchUsers, organizationId, siteId])

  const handleActionDeleteSelectedUsers = () => {
    const userIds = usersToUndelete.map((user) => user.id)
    undeleteUsers({ organizationId, siteId, userIds })
    setDeletingUsersCount(usersToUndelete.length)
  }

  const notAllowedToUndeleteUsers = useMemo(() => {
    return usersToUndelete.filter(
      (user) => !isGreaterOrEqRole(activeRole, user.role)
    )
  }, [usersToUndelete, activeRole])

  return (
    <>
      {usersToUndelete.length > 0 && (
        <ActionDialog
          open={usersToUndelete.length > 0}
          title={t('users.undeleteUsers.title', {
            count: usersToUndelete.length,
          })}
          description={t('users.undeleteUsers.description', {
            users: userListToString(usersToUndelete),
          })}
          actionText={t('common.actions.undelete')}
          onAction={handleActionDeleteSelectedUsers}
          onClose={onClose}
          actionDisabled={notAllowedToUndeleteUsers.length > 0}
          closeOnAction={false}
        >
          {notAllowedToUndeleteUsers.length > 0 && (
            <ErrorBox>
              {t('users.undeleteUsers.errors.notAllowed', {
                users: userListToString(notAllowedToUndeleteUsers),
              })}
            </ErrorBox>
          )}
          {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
        </ActionDialog>
      )}
      <Toast
        message={t('users.undeleteUsers.toast', {
          count: undeletingUsersCount,
        })}
        open={isUndeleteUsersSuccess}
      />
    </>
  )
}

interface UsersDeleteActionDialogProps {
  usersToDelete: User[]
  onClose: () => void
}

const UsersDeleteActionDialog = ({
  usersToDelete,
  onClose,
}: UsersDeleteActionDialogProps) => {
  const { t } = useTranslation()

  const { role: activeRole } = useApp()
  const { organizationId, siteId } = useCurrentAccount()

  const [fetchUsers] = useLazyGetUsersQuery()

  const [
    deleteUsers,
    { isSuccess: isDeleteUsersSuccess, error: deleteUsersError },
  ] = useDeleteUsersMutation()
  const [deletingUsersCount, setDeletingUsersCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    deleteUsersError,
  ])

  const userListToString = useItemListToString<User>(
    (u) => u.email,
    'users.deleteUsers.remaining'
  )

  useEffect(() => {
    if (isDeleteUsersSuccess) {
      fetchUsers({ organizationId, siteId, withDeleted: true })
      onClose()
    }
  }, [onClose, isDeleteUsersSuccess, fetchUsers, organizationId, siteId])

  const handleActionDeleteSelectedUsers = () => {
    const userIds = usersToDelete.map((user) => user.id)
    deleteUsers({ organizationId, siteId, userIds })
    setDeletingUsersCount(usersToDelete.length)
  }

  const notAllowedToDeleteUsers = useMemo(() => {
    return usersToDelete.filter(
      (user) => !isGreaterOrEqRole(activeRole, user.role)
    )
  }, [usersToDelete, activeRole])

  return (
    <>
      {usersToDelete.length > 0 && (
        <ActionDialog
          open={usersToDelete.length > 0}
          title={t('users.deleteUsers.title', { count: usersToDelete.length })}
          description={t('users.deleteUsers.description', {
            users: userListToString(usersToDelete),
          })}
          actionText={t('common.actions.delete')}
          onAction={handleActionDeleteSelectedUsers}
          onClose={onClose}
          actionDisabled={notAllowedToDeleteUsers.length > 0}
          closeOnAction={false}
        >
          {notAllowedToDeleteUsers.length > 0 && (
            <ErrorBox>
              {t('users.deleteUsers.errors.notAllowed', {
                users: userListToString(notAllowedToDeleteUsers),
              })}
            </ErrorBox>
          )}
          {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
        </ActionDialog>
      )}
      <Toast
        message={t('users.deleteUsers.toast', { count: deletingUsersCount })}
        open={isDeleteUsersSuccess}
      />
    </>
  )
}

interface UsersUnlockActionDialogProps {
  usersToUnlock: User[]
  onClose: () => void
}

const UsersUnlockActionDialog = ({
  usersToUnlock,
  onClose,
}: UsersUnlockActionDialogProps) => {
  const { t } = useTranslation()

  const { role: activeRole } = useApp()
  const { organizationId, siteId } = useCurrentAccount()

  const [fetchUsers] = useLazyGetUsersQuery()

  const [
    unlockUsers,
    { isSuccess: isUnlockUsersSuccess, error: unlockUsersError },
  ] = useUnlockUsersMutation()
  const [unlockingUsersCount, setUnlockingUsersCount] = useState(0)

  const [hasApiMutationErrors, apiMutationErrorsMsg] = useApiErrors([
    unlockUsersError,
  ])

  const userListToString = useItemListToString<User>(
    (u) => u.email,
    'users.unlockUsers.remaining'
  )

  useEffect(() => {
    if (isUnlockUsersSuccess) {
      fetchUsers({ organizationId, siteId, withDeleted: true })
      onClose()
    }
  }, [onClose, isUnlockUsersSuccess, fetchUsers, organizationId, siteId])

  const handleActionUnlockSelectedUsers = () => {
    const userIds = usersToUnlock.map((user) => user.id)
    unlockUsers({ organizationId, siteId, userIds })
    setUnlockingUsersCount(usersToUnlock.length)
  }

  const notAllowedToUnlockUsers = useMemo(() => {
    return usersToUnlock.filter(
      (user) => !isGreaterOrEqRole(activeRole, user.role)
    )
  }, [usersToUnlock, activeRole])

  return (
    <>
      {usersToUnlock.length > 0 && (
        <ActionDialog
          open={usersToUnlock.length > 0}
          title={t('users.unlockUsers.title', { count: usersToUnlock.length })}
          description={t('users.unlockUsers.description', {
            users: userListToString(usersToUnlock),
          })}
          actionText={t('users.unlockUser.action')}
          onAction={handleActionUnlockSelectedUsers}
          onClose={onClose}
          actionDisabled={notAllowedToUnlockUsers.length > 0}
          closeOnAction={false}
        >
          {notAllowedToUnlockUsers.length > 0 && (
            <ErrorBox>
              {t('users.unlockUsers.errors.notAllowed', {
                users: userListToString(notAllowedToUnlockUsers),
              })}
            </ErrorBox>
          )}
          {hasApiMutationErrors && <ErrorBox>{apiMutationErrorsMsg}</ErrorBox>}
        </ActionDialog>
      )}
      <Toast
        message={t('users.unlockUsers.toast', { count: unlockingUsersCount })}
        open={isUnlockUsersSuccess}
      />
    </>
  )
}

interface UsersTableActionsBarProps {
  showDeleted: boolean
  onChangeShowDeleted: (showDeleted: boolean) => void
  selectedUsers: User[]
  searchQuery: string
  onSearch: (query: string) => void
}

const UsersTableActionsBar = ({
  showDeleted,
  onChangeShowDeleted,
  selectedUsers,
  searchQuery,
  onSearch,
}: UsersTableActionsBarProps) => {
  const { t } = useTranslation()

  const [selectedUsersToDelete, setSelectedUsersToDelete] = useState<User[]>([])
  const [selectedUsersToUndelete, setSelectedUsersToUndelete] = useState<
    User[]
  >([])
  const [selectedUsersToUnlock, setSelectedUsersToUnlock] = useState<User[]>([])

  const selectedUsersActions = useMemo(() => {
    const actions = []

    if (showDeleted) {
      actions.push({
        local: 'users.listUsers.actions.undelete',
        icon: RestoreFromTrashIcon,
        fn: () => setSelectedUsersToUndelete(selectedUsers),
      })
    }

    actions.push({
      local: 'users.listUsers.actions.delete',
      icon: DeleteIcon,
      fn: () => setSelectedUsersToDelete(selectedUsers),
    })

    actions.push({
      local: 'user.listUsers.actions.unlock',
      icon: UnlockIcon,
      fn: () => setSelectedUsersToUnlock(selectedUsers),
    })

    return actions
  }, [selectedUsers, showDeleted])

  const handleCancelDeleteAction = useCallback(() => {
    setSelectedUsersToDelete([])
  }, [])

  const handleCancelUndeleteAction = useCallback(() => {
    setSelectedUsersToUndelete([])
  }, [])

  const handleCancelUnlockAction = useCallback(() => {
    setSelectedUsersToUnlock([])
  }, [])

  return (
    <>
      <TableActionsBar
        showDeleted={showDeleted}
        onChangeShowDeleted={onChangeShowDeleted}
        searchQuery={searchQuery}
        onSearch={onSearch}
        selectedRows={selectedUsers}
        selectedRowsLabel={t('users.listUsers.selectedRows.label', {
          count: selectedUsers.length,
        })}
        selectedRowsActions={selectedUsersActions}
      />
      <UsersDeleteActionDialog
        usersToDelete={selectedUsersToDelete}
        onClose={handleCancelDeleteAction}
      />
      <UsersUndeleteActionDialog
        usersToUndelete={selectedUsersToUndelete}
        onClose={handleCancelUndeleteAction}
      />
      <UsersUnlockActionDialog
        usersToUnlock={selectedUsersToUnlock}
        onClose={handleCancelUnlockAction}
      />
    </>
  )
}

export default UsersTableActionsBar
