import React, { type PropsWithChildren } from 'react'
import { Box } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
  PageBody: {
    marginTop: '32px',
  },
}))

type PageBodyProps = PropsWithChildren

const PageBody = ({ children }: PageBodyProps) => {
  const { classes } = useStyles()

  return <Box className={classes.PageBody}>{children}</Box>
}

export default PageBody
