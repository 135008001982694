import React, { useEffect, useState } from 'react'
import Page from 'common/components/Page'
import PageHeader from 'common/components/PageHeader'
import PageBody from 'common/components/PageBody'
import PageTitle from 'common/components/PageTitle'
import { useTranslation } from 'react-i18next'
import {
  fetchIntegrations,
  resetAddIntegration,
} from 'App/Integrations/integrations-state'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import PageActions from 'common/components/PageActions'
import Button from 'common/components/Button'
import IntegrationsTable from 'App/Integrations/components/IntegrationsTable'
import AddIntegrationDrawer from 'App/Integrations/components/AddIntegrationDrawer'
import Toast from 'common/components/Toast'
import { useAppDispatch, useAppSelector } from 'store'

const Integrations = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [openAddIntegrationDrawer, setOpenAddIntegrationDrawer] =
    useState(false)

  const fetchedIntegrations = useAppSelector(
    (state) => state.integrations.fetchedIntegrations
  )
  const addedIntegration = useAppSelector(
    (state) => state.integrations.addedIntegration
  )
  const deletedIntegration = useAppSelector(
    (state) => state.integrations.deletedIntegration
  )
  const { organizationId, siteId } = useCurrentAccount()

  useEffect(() => {
    dispatch(fetchIntegrations({ organizationId, siteId }))
  }, [dispatch, organizationId, siteId])

  useEffect(() => {
    if (addedIntegration) {
      setOpenAddIntegrationDrawer(false)
      dispatch(fetchIntegrations({ organizationId, siteId }))
    }
  }, [dispatch, organizationId, siteId, addedIntegration])

  if (!fetchedIntegrations) {
    return <Page />
  }

  return (
    <>
      <Page>
        <PageHeader>
          <PageTitle title={t('integrations.title')} />
          <PageActions>
            <Button small onClick={() => setOpenAddIntegrationDrawer(true)}>
              {t('integrations.actions.addIntegration')}
            </Button>
          </PageActions>
        </PageHeader>
        <PageBody>
          <IntegrationsTable />
        </PageBody>
      </Page>
      <AddIntegrationDrawer
        open={openAddIntegrationDrawer}
        onClose={() => setOpenAddIntegrationDrawer(false)}
      />
      <Toast
        open={addedIntegration}
        message={t('integrations.addIntegration.toast.success')}
        onClose={() => dispatch(resetAddIntegration())}
      />
      <Toast
        open={deletedIntegration}
        message={t('integrations.deleteIntegration.toast.success')}
      />
    </>
  )
}

export default Integrations
