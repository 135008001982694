import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Table from 'common/components/TableNew'
import { type Tablet } from 'App/Tablets/tablets-types'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import LockIcon from '@mui/icons-material/LockOutlined'
import RebootIcon from '@mui/icons-material/RestartAltOutlined'
import QrCodeIcon from '@mui/icons-material/QrCode2Outlined'
import IconMenu from 'common/components/IconMenu'
import ViewTabletDrawer from 'App/Tablets/components/ViewTabletDrawer'
import DateWithRelativeTime from 'common/components/DateWithRelativeTime'
import MaybeDeletedRowCell from 'common/components/MaybeDeletedRowCell'

interface TabletsRowActionsProps {
  tablet: Tablet
  onClickView: (tablet: Tablet) => void
  onClickLock: (tablet: Tablet) => void
  onClickReboot: (tablet: Tablet) => void
  onClickRemove: (tablet: Tablet) => void
  onClickReset: (tablet: Tablet) => void
}

const TabletsRowActions = ({
  tablet,
  onClickView,
  onClickLock,
  onClickReboot,
  onClickRemove,
  onClickReset,
}: TabletsRowActionsProps) => {
  let actions

  if (tablet.deletedAt != null) {
    actions = [
      {
        local: 'tablets.viewTablet.settings.reset.resetButton',
        fn: () => onClickReset(tablet),
        icon: QrCodeIcon,
      },
    ]
  } else {
    actions = [
      {
        local: 'tablets.listTablets.actions.view',
        fn: () => onClickView(tablet),
        icon: VisibilityIcon,
      },
      {
        local: 'tablets.listTablets.actions.lock',
        fn: () => onClickLock(tablet),
        icon: LockIcon,
      },
      {
        local: 'tablets.listTablets.actions.reboot',
        fn: () => onClickReboot(tablet),
        icon: RebootIcon,
      },
      {
        local: 'tablets.listTablets.actions.remove',
        fn: () => onClickRemove(tablet),
        icon: DeleteIcon,
      },
    ]
  }

  return <IconMenu actions={actions} />
}

interface TabletTableProps {
  tablets: Tablet[]
  onClickLock: (tablet: Tablet) => void
  onClickReboot: (tablet: Tablet) => void
  onClickReset: (tablet: Tablet) => void
  onClickRemove: (tablet: Tablet) => void
  onSelectTablets: (tablets: Tablet[]) => void
}

const TabletTable = ({
  tablets,
  onClickLock,
  onClickReboot,
  onClickReset,
  onClickRemove,
  onSelectTablets = () => {},
}: TabletTableProps) => {
  const { t } = useTranslation()

  const [activeTabletId, setActiveTabletId] = useState<number | undefined>()
  const activeTablet = useMemo(() => {
    return activeTabletId === undefined
      ? undefined
      : tablets.find((tablet) => tablet.id === activeTabletId)
  }, [tablets, activeTabletId])

  const [viewOpen, setViewOpen] = useState(false)
  const onCloseViewDrawer = () => setViewOpen(false)

  const onClickView = (tablet: Tablet) => {
    setViewOpen(true)
    setActiveTabletId(tablet.id)
  }

  const columns = useMemo(
    () => {
      return [
        {
          title: t('tablets.table.columns.serialNumber'),
          field: 'serialNumber',
          render: ({ serialNumber, deletedAt }: Tablet) => (
            <MaybeDeletedRowCell deletedAt={deletedAt} content={serialNumber} />
          ),
          tyle: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.activeAt'),
          // eslint-disable-next-line react/display-name
          render: ({ activeAt }: Tablet) =>
            activeAt != null ? (
              <DateWithRelativeTime datetime={new Date(activeAt)} />
            ) : (
              '-'
            ),
          field: 'activeAt',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.macAddress'),
          field: 'macAddress',
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.modelCode'),
          field: 'modelCode',
          render: ({ modelCode, deletedAt, endOfLifeWarning }: Tablet) => (
            <div style={endOfLifeWarning ? { color: 'red' } : {}}>
              <MaybeDeletedRowCell deletedAt={deletedAt} content={modelCode} />
            </div>
          ),
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.hubletVersionName'),
          field: 'hubletVersionName',
          canSort: true,
          style: { width: 'auto' },
        },
        {
          title: t('tablets.table.columns.batteryLevelFromDeviceApi'),
          field: 'batteryLevelFromDeviceApi',
          canSort: true,
          style: { width: '160px' },
        },
        {
          id: 'actions',
          // eslint-disable-next-line react/display-name
          render: (tablet: Tablet) => (
            <TabletsRowActions
              tablet={tablet}
              onClickView={onClickView}
              onClickLock={onClickLock}
              onClickReboot={onClickReboot}
              onClickRemove={onClickRemove}
              onClickReset={onClickReset}
            />
          ),
          style: { width: '24px' },
        },
      ]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Table
        data={tablets}
        columns={columns}
        selectable
        onSelectionChange={onSelectTablets}
      />
      {activeTablet != null && (
        <ViewTabletDrawer
          tablet={activeTablet}
          open={viewOpen}
          onClose={onCloseViewDrawer}
          onClickLock={onClickLock}
          onClickReboot={onClickReboot}
          onClickReset={onClickReset}
          onClickRemove={onClickRemove}
        />
      )}
    </>
  )
}

export default TabletTable
