import { type AwsCognitoPreReleaseEmailUser } from 'App/SuperAdmin/AwsCognitoPreReleaseEmail'
import rtkApi from 'rtk-api'

const adminEmailsRtkApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getAwsCognitoPreReleaseEmailUsers: builder.query<
      AwsCognitoPreReleaseEmailUser[],
      void
    >({
      query: () => ({
        url: '/admin/aws-cognito/pre-release/users',
        method: 'GET',
      }),
    }),
    sendAwsCognitoPreReleaseEmail: builder.mutation<
      void,
      { userIds: number[] }
    >({
      query: ({ userIds }) => ({
        url: '/admin/aws-cognito/pre-release/send',
        method: 'POST',
        body: { userIds },
      }),
    }),
  }),
})

export const {
  useGetAwsCognitoPreReleaseEmailUsersQuery,
  useSendAwsCognitoPreReleaseEmailMutation,
} = adminEmailsRtkApi
