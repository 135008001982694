import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DrawerForm from 'common/components/DrawerForm'
import useCategoryForm from 'common/hooks/useCategoryForm'
import CategoryFields from 'App/Categories/components/CategoryFields'

interface AddCategoryDrawerProps {
  profileId: number
  open: boolean
  onClose: () => void
}

const AddCategoryDrawer = ({
  profileId,
  open,
  onClose,
}: AddCategoryDrawerProps) => {
  const { t } = useTranslation()

  const {
    categoryForm,
    fieldErrors,
    handleFieldChange,
    handleAdd,
    addedCategory,
  } = useCategoryForm(profileId)

  useEffect(() => {
    if (addedCategory) {
      onClose()
    }
  }, [onClose, addedCategory])

  return (
    <DrawerForm
      open={open}
      onClose={onClose}
      onSubmit={handleAdd}
      title={t('categories.addCategory.title')}
      submitText={t('categories.addCategory.buttons.submit')}
    >
      <CategoryFields
        form={categoryForm}
        errors={fieldErrors}
        onChangeField={handleFieldChange}
      />
    </DrawerForm>
  )
}

export default AddCategoryDrawer
