import React, {
  useState,
  type PropsWithChildren,
  type FormEventHandler,
  useEffect,
} from 'react'

import { type Theme, Grid, Link, Stack } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import EmailIcon from '@mui/icons-material/EmailOutlined'
import LockIcon from '@mui/icons-material/LockOutlined'

import logo from 'common/hublet-manager-logo.svg'
import { renderIf } from 'common/utils/render-utils'
import Axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import { postLogin, resetLogin } from 'App/Login/login-state'
import { type ThunkDispatchProp, type RootState } from 'store'
import { connect } from 'react-redux'
import { fetchCurrentUser } from 'App/app-state'
import Button from 'common/components/Button'
import { useTranslation } from 'react-i18next'
import FormField from 'common/components/FormField'
import SelectLanguage from 'common/components/SelectLanguage'

import { Link as RouterLink } from 'react-router-dom'
import ErrorBox from 'common/components/ErrorBox'

const useStyles = makeStyles()((theme: Theme) => ({
  LoginContainer: {
    minHeight: '100vh',
    margin: '0 auto',
    '& > div': {
      maxWidth: '324px !important',
      width: '100%',
    },
  },
  LoginLogo: {
    marginBottom: '24px',
    textAlign: 'center',
    lineHeight: '1',
    '& > img': {
      width: '152px',
      margin: '-4px',
    },
  },
  LoginInfoText: {
    maxWidth: '100% !important',
    marginBottom: '24px',
    textAlign: 'center',
    fontSize: '18px',
  },
  LoginInput: {
    marginBottom: '16px',
  },
  ChangeLink: {
    marginLeft: '8px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  LoginError: {
    marginBottom: theme.spacing(3),
  },
  SelectLanguage: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  ForgotPasswordLink: {
    height: '20px',
    marginTop: theme.spacing(3),
    marginBottom: '-44px',
    textAlign: 'center',
  },
}))

type LoginGridProps = PropsWithChildren

const LoginGrid = ({ children }: LoginGridProps) => {
  const { classes } = useStyles()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.LoginContainer}
    >
      {children}
    </Grid>
  )
}

const HubletLogo = () => <img src={logo} alt="Hublet" />

interface LoginUsernameProps {
  onNext: (value: string) => Promise<void> | void
}

const LoginUsername = ({ onNext }: LoginUsernameProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [username, setUsername] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)

  const onSubmitForm: FormEventHandler = (e) => {
    e.preventDefault()
    if (username === '') {
      setError(t('login.errors.missingUsername'))
    } else {
      setError(undefined)
      onNext(username)
    }
  }

  return (
    <form onSubmit={onSubmitForm}>
      <LoginGrid>
        <Grid item xs={12} className={classes.LoginLogo}>
          <HubletLogo />
        </Grid>
        <Grid item xs={12} className={classes.LoginInfoText}>
          {t('login.info.username')}
        </Grid>
        <Grid item xs={12} className={classes.LoginInput}>
          <FormField
            placeholder={t('login.placeholders.username')}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            Icon={EmailIcon}
            name="login-username"
            autoFocus
            error={error}
            rounded
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit">{t('login.buttons.usernameNext')}</Button>
        </Grid>
      </LoginGrid>
    </form>
  )
}

interface LoginPasswordProps {
  username: string
  onChangeUsername: () => void
  onNext: (value: string) => void
  loginError?: string
}

const LoginPassword = ({
  username,
  onChangeUsername,
  onNext,
  loginError,
}: LoginPasswordProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | undefined>()

  const onSubmitForm: FormEventHandler = (e) => {
    e.preventDefault()
    if (password === '') {
      setError(t('login.errors.missingPassword'))
    } else {
      setError(undefined)
      onNext(password)
    }
  }

  const onClickChangeUsername = () => {
    setPassword('')
    setError(undefined)
    onChangeUsername()
  }

  return (
    <form onSubmit={onSubmitForm}>
      <LoginGrid>
        <Grid item xs={12} className={classes.LoginLogo}>
          <HubletLogo />
        </Grid>
        {renderIf(loginError !== undefined, () => (
          <Grid item xs={12} className={classes.LoginError}>
            <ErrorBox>{loginError}</ErrorBox>
          </Grid>
        ))}
        <Grid item xs={12} className={classes.LoginInfoText}>
          {username}
          <Link
            className={classes.ChangeLink}
            onClick={onClickChangeUsername}
            underline="hover"
          >
            {t('login.info.change')}
          </Link>
        </Grid>
        <Grid item xs={12} className={classes.LoginInput}>
          <FormField
            type="password"
            placeholder={t('login.placeholders.password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            Icon={LockIcon}
            name="login-password"
            autoFocus
            error={error}
            rounded
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit">{t('login.buttons.passwordNext')}</Button>
        </Grid>
        <Grid className={classes.ForgotPasswordLink} item xs={12}>
          <Link
            component={RouterLink}
            to={`/forgot-password?email=${encodeURIComponent(username)}`}
            underline="hover"
          >
            {t('login.forgotPassword')}
          </Link>
        </Grid>
      </LoginGrid>
    </form>
  )
}

const usernameFromUrlHash = () => {
  const username = window.location.hash.slice(1)
  window.location.hash = ''
  return username
}

interface LoginProps extends ThunkDispatchProp {
  postingLogin: boolean
  postedLogin: boolean
  postLoginError?: string
}

const Login = ({
  dispatch,
  postingLogin,
  postedLogin,
  postLoginError,
}: LoginProps) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [username, setUsername] = useState('')
  const error =
    postLoginError != null ? t(`login.errors.${postLoginError}`) : undefined

  useEffect(() => {
    setUsername(usernameFromUrlHash())
  }, [])

  if (postedLogin && !postLoginError) {
    dispatch(fetchCurrentUser())
    window.zE('webWidget', 'helpCenter:reauthenticate')
  }

  const onChangeUsername = () => {
    dispatch(resetLogin())
    setUsername('')
  }

  const onNextFromUsername = async (value: string) => {
    const res = await Axios.post(`${API_BASE_URL}/user/host-for-user`, {
      username: value,
    })
    const currentHost = window.location.host

    const host: string = res.data.host
    const username: string = res.data.username

    if (host === currentHost) {
      setUsername(username)
    } else {
      window.location.href = `https://${host}/ui/login#${username}`
    }
  }

  const onNextFromPassword = (password: string) => {
    if (!postingLogin) {
      dispatch(postLogin({ username, password }))
    }
  }

  return (
    <>
      <Stack className={classes.SelectLanguage} gap={1}>
        <SelectLanguage />
      </Stack>
      {renderIf(username === '', () => (
        <LoginUsername onNext={onNextFromUsername} />
      ))}
      {renderIf(username !== '', () => (
        <LoginPassword
          username={username}
          onChangeUsername={onChangeUsername}
          onNext={onNextFromPassword}
          loginError={error}
        />
      ))}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  postingLogin: state.login.postingLogin,
  postedLogin: state.login.postedLogin,
  postLoginError: state.login.postLoginError,
})

export default connect(mapStateToProps)(Login)
