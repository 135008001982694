import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import NetworksApi from 'api/networks-api'
import { type Network, type NetworkForm } from 'App/Networks/networks-types'

interface NetworksState {
  networks: Network[]
  fetchingNetworks: boolean
  fetchedNetworks: boolean
  addingNetwork: boolean
  addedNetwork: boolean
  updatingNetwork: boolean
  updatedNetwork: boolean
  deletingNetwork: boolean
  deletedNetwork: boolean
}

const initialState: NetworksState = {
  networks: [],
  fetchingNetworks: false,
  fetchedNetworks: false,
  addingNetwork: false,
  addedNetwork: false,
  updatingNetwork: false,
  updatedNetwork: false,
  deletingNetwork: false,
  deletedNetwork: false,
}

export const fetchNetworks = createAsyncThunk(
  'networks/fetchNetworks',
  async ({
    siteId,
    organizationId,
  }: {
    siteId?: number
    organizationId?: number
  }) => {
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }

    return NetworksApi.fetchNetworks(siteId, organizationId)
  }
)

export const addNetwork = createAsyncThunk(
  'networks/addNetwork',
  async ({
    siteId,
    organizationId,
    form,
  }: {
    siteId?: number
    organizationId?: number
    form: NetworkForm
  }) => {
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }

    return NetworksApi.createNetwork(siteId, organizationId, form)
  }
)

export const updateNetwork = createAsyncThunk(
  'networks/updateNetwork',
  async ({
    networkId,
    siteId,
    organizationId,
    form,
  }: {
    networkId: number
    siteId?: number
    organizationId?: number
    form: NetworkForm
  }) => {
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }

    return NetworksApi.updateNetwork(networkId, siteId, organizationId, form)
  }
)

export const deleteNetwork = createAsyncThunk(
  'networks/deleteNetwork',
  async ({
    id,
    siteId,
    organizationId,
  }: {
    id: number
    siteId?: number
    organizationId?: number
  }) => {
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }

    return NetworksApi.deleteNetwork(id, siteId, organizationId)
  }
)

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    resetAddNetwork: (state) => {
      state.addingNetwork = false
      state.addedNetwork = false
    },
    resetUpdatedNetwork: (state) => {
      state.updatingNetwork = false
      state.updatedNetwork = false
    },
    resetDeletedNetwork: (state) => {
      state.deletingNetwork = false
      state.deletedNetwork = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNetworks.pending, (state) => {
      state.fetchingNetworks = true
      state.fetchedNetworks = false
      return state
    })
    builder.addCase(fetchNetworks.fulfilled, (state, { payload }) => {
      state.fetchingNetworks = false
      state.fetchedNetworks = true
      state.networks = payload
      return state
    })
    builder.addCase(fetchNetworks.rejected, (state) => {
      state.fetchingNetworks = false
      state.fetchedNetworks = false
      return state
    })

    builder.addCase(addNetwork.pending, (state) => {
      state.addingNetwork = true
      return state
    })
    builder.addCase(addNetwork.fulfilled, (state) => {
      state.addingNetwork = false
      state.addedNetwork = true
      return state
    })
    builder.addCase(addNetwork.rejected, (state) => {
      state.addingNetwork = false
      state.addedNetwork = false
      return state
    })

    builder.addCase(updateNetwork.pending, (state) => {
      state.updatingNetwork = true
      return state
    })
    builder.addCase(updateNetwork.fulfilled, (state) => {
      state.updatingNetwork = false
      state.updatedNetwork = true
      return state
    })
    builder.addCase(updateNetwork.rejected, (state) => {
      state.updatingNetwork = false
      state.updatedNetwork = false
      return state
    })

    builder.addCase(deleteNetwork.pending, (state) => {
      state.deletingNetwork = true
      return state
    })
    builder.addCase(deleteNetwork.fulfilled, (state) => {
      state.deletingNetwork = false
      state.deletedNetwork = true
      return state
    })
    builder.addCase(deleteNetwork.rejected, (state) => {
      state.deletingNetwork = false
      state.deletedNetwork = false
      return state
    })
  },
})

export const { resetAddNetwork, resetUpdatedNetwork, resetDeletedNetwork } =
  networksSlice.actions

export default networksSlice.reducer
