import { useContext, useEffect } from 'react'
import { type Tablet } from 'App/Tablets/tablets-types'
import useCurrentAccount from 'common/hooks/useCurrentAccount'
import { fetchUnpairedTablets } from 'App/Docks/docks-state'
import { type DocksContext } from 'App/Docks/docks-types'
import DocksReactContext from 'App/Docks/docks-react-context'
import { useAppDispatch, useAppSelector } from 'store'

const useUnpairedTablets = (explicitContext?: DocksContext): Tablet[] => {
  const dispatch = useAppDispatch()
  const implicitContext = useContext(DocksReactContext)
  const context = explicitContext ?? implicitContext

  const { siteId, organizationId } = useCurrentAccount()
  const unpairedTablets = useAppSelector((state) => state.docks.unpairedTablets)

  useEffect(() => {
    void dispatch(fetchUnpairedTablets({ siteId, organizationId, context }))
  }, [dispatch, siteId, organizationId, context])

  return unpairedTablets
}

export default useUnpairedTablets
