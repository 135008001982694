import React from 'react'
import DrawerWithTabs from 'common/components/DrawerWithTabs'
import { type Integration } from 'App/Integrations/integrations-types'
import IntegrationGeneral from 'App/Integrations/components/IntegrationGeneral'
import IntegrationParameters from 'App/Integrations/components/IntegrationParameters'
import IntegrationCommunication from 'App/Integrations/components/IntegrationCommunication'
import IntegrationTest from 'App/Integrations/components/IntegrationTest'
import { useTranslation } from 'react-i18next'

interface IntegrationDetailsDrawerProps {
  integration: Integration
  open: boolean
  onClose: () => void
}

const IntegrationDetailsDrawer = ({
  integration,
  open,
  onClose,
}: IntegrationDetailsDrawerProps) => {
  const { t } = useTranslation()

  const tabs = [
    {
      title: t('integrations.tab.general.label'),
      component: <IntegrationGeneral integration={integration} />,
    },
    {
      title: t('integrations.integrationForm.parameters.label'),
      component: <IntegrationParameters integration={integration} />,
    },
    {
      title: t('integrations.tab.communication.label'),
      component: <IntegrationCommunication integration={integration} />,
    },
    {
      title: t('integrations.tab.test.label'),
      component: <IntegrationTest integration={integration} />,
    },
  ]

  return (
    <DrawerWithTabs
      open={open}
      onClose={onClose}
      title="Integration"
      tabs={tabs}
    />
  )
}

export default IntegrationDetailsDrawer
