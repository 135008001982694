import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import SitesApi from 'api/sites-api'

export interface Site {
  id: number
  name: string
  info?: string
  organizationId: number
  enrolled: boolean
  numDocks: number
  numTablets: number
  googleEnterpriseId: null | string

  customerSectorId: number
  street: string
  street2: string
  zipcode: string
  city: string
  region: string
  country: string

  tzdbTimezone?: string
  defaultLanguage?: string
  profileId?: number
}

export interface SiteForm {
  name?: string
  customerSectorId?: number
  street?: string
  street2?: string
  zipcode?: string
  city?: string
  region?: string
  country?: string
  tzdbTimezone?: string
  defaultLanguage?: string
  profileId?: number
}

export enum SecurityChallenge {
  UNSPECIFIED = 'PASSWORD_QUALITY_UNSPECIFIED',
  BIOMETRIC = 'PASSWORD_QUALITY_BIOMETRIC_WEAK',
  SOMETHING = 'PASSWORD_QUALITY_SOMETHING',
  NUMERIC = 'PASSWORD_QUALITY_NUMERIC',
  NUMERIC_COMPLEX = 'PASSWORD_QUALITY_NUMERIC_COMPLEX',
  ALPHABETIC = 'PASSWORD_QUALITY_ALPHABETIC',
  ALPHANUMERIC = 'PASSWORD_QUALITY_ALPHANUMERIC',
  COMPLEX = 'PASSWORD_QUALITY_COMPLEX',
}

export enum SystemUpdatePolicy {
  AUTOMATIC = 'TYPE_INSTALL_AUTOMATIC',
  WINDOWED = 'TYPE_INSTALL_WINDOWED',
  POSTPONE = 'TYPE_POSTPONE',
}

export enum RuntimePermissionPolicy {
  PROMPT = 'PERMISSION_POLICY_PROMPT',
  AUTO_GRANT = 'PERMISSION_POLICY_AUTO_GRANT',
  AUTO_DENY = 'PERMISSION_POLICY_AUTO_DENY',
}

export interface SiteSettings {
  siteId?: number
  securityChallenge?: SecurityChallenge
  unknownSource?: boolean
  debugMode?: boolean
  safeMode?: boolean
  factoryReset?: boolean
  systemUpdatePolicy?: SystemUpdatePolicy
  runtimePermissionPolicy?: RuntimePermissionPolicy
  updateStartTime?: number
  updateEndTime?: number
  zone: number
  factoryResetAccounts?: string
  allowAuthenticateFromTablet: boolean
  enableReturnCode: boolean
  returnCode: string
}

export interface SiteSettingsForm {
  securityChallenge?: SecurityChallenge
  unknownSource?: boolean
  debugMode?: boolean
  safeMode?: boolean
  factoryReset?: boolean
  systemUpdatePolicy?: SystemUpdatePolicy
  runtimePermissionPolicy?: RuntimePermissionPolicy
  updateStartTime?: number
  updateEndTime?: number
  zone?: number
  factoryResetAccounts?: string
}

interface SitesState {
  sites: Site[]
  fetchingSites: boolean
  fetchedSites: boolean
  addingSite: boolean
  addedSite: boolean
  updatingSite: boolean
  updatedSite: boolean
  deletingSite: boolean
  deletedSite: boolean

  settings?: SiteSettings
  fetchingSettings: boolean
  fetchedSettings: boolean
  updatingSecurityChallenge: boolean
  updatedSecurityChallenge: boolean
  updatingUnknownSource: boolean
  updatedUnknownSource: boolean
  updatingDebugMode: boolean
  updatedDebugMode: boolean
  updatingSafeMode: boolean
  updatedSafeMode: boolean
  updatingFactoryReset: boolean
  updatedFactoryReset: boolean
  updatingSystemUpdatePolicy: boolean
  updatedSystemUpdatePolicy: boolean
  updatingRuntimePermissionPolicy: boolean
  updatedRuntimePermissionPolicy: boolean
  updatedUpdateStartTime: boolean
  updatedUpdateEndTime: boolean
  updatedFactoryResetAccounts: boolean

  site: Site | null
}

const initialState: SitesState = {
  sites: [],
  fetchingSites: false,
  fetchedSites: false,
  addingSite: false,
  addedSite: false,
  updatingSite: false,
  updatedSite: false,
  deletingSite: false,
  deletedSite: false,

  fetchingSettings: false,
  fetchedSettings: false,
  updatingSecurityChallenge: false,
  updatedSecurityChallenge: false,
  updatingUnknownSource: false,
  updatedUnknownSource: false,
  updatingDebugMode: false,
  updatedDebugMode: false,
  updatingSafeMode: false,
  updatedSafeMode: false,
  updatingFactoryReset: false,
  updatedFactoryReset: false,
  updatingSystemUpdatePolicy: false,
  updatedSystemUpdatePolicy: false,
  updatingRuntimePermissionPolicy: false,
  updatedRuntimePermissionPolicy: false,
  updatedUpdateStartTime: false,
  updatedUpdateEndTime: false,
  updatedFactoryResetAccounts: false,

  site: null,
}

export const fetchSites = createAsyncThunk(
  'sites/fetchSites',
  async ({ organizationId }: { organizationId?: number }) => {
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }
    return SitesApi.fetchSites(organizationId)
  }
)

export const fetchSite = createAsyncThunk(
  'sites/fetchSite',
  async ({
    organizationId,
    siteId,
  }: {
    organizationId: number
    siteId: number
  }) => {
    return SitesApi.fetchSite(organizationId, siteId)
  }
)

export const addSite = createAsyncThunk(
  'sites/addSite',
  async ({
    form,
    organizationId,
  }: {
    form: SiteForm
    organizationId?: number
  }) => {
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }
    return SitesApi.createSite(organizationId, form)
  }
)

export const updateSite = createAsyncThunk(
  'sites/updateSite',
  async ({
    siteId,
    form,
    organizationId,
  }: {
    siteId: number
    form: SiteForm
    organizationId?: number
  }) => {
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }
    return SitesApi.updateSite(siteId, organizationId, form)
  }
)

export const deleteSite = createAsyncThunk(
  'sites/deleteSite',
  async ({ id, organizationId }: Site) =>
    SitesApi.deleteSite(id, organizationId)
)

export const fetchSiteSettings = createAsyncThunk(
  'sites/fetchSiteSettings',
  async ({
    organizationId,
    siteId,
  }: {
    organizationId?: number
    siteId?: number
  }) => {
    if (organizationId === undefined) {
      throw new Error('organizationId should not be undefined')
    }
    if (siteId === undefined) {
      throw new Error('siteId should not be undefined')
    }
    return SitesApi.fetchSiteSettings(organizationId, siteId)
  }
)

export const updateSecurityChallenge = createAsyncThunk(
  'sites/updateSecurityChallenge',
  async ({
    siteId,
    organizationId,
    securityChallenge,
  }: {
    siteId: number
    organizationId: number
    securityChallenge: SecurityChallenge
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, {
      securityChallenge,
    })
  }
)

export const updateUnknownSource = createAsyncThunk(
  'sites/updateUnknownSource',
  async ({
    siteId,
    organizationId,
    unknownSource,
  }: {
    siteId: number
    organizationId: number
    unknownSource: boolean
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, {
      unknownSource,
    })
  }
)

export const updateDebugMode = createAsyncThunk(
  'sites/updateDebugMode',
  async ({
    siteId,
    organizationId,
    debugMode,
  }: {
    siteId: number
    organizationId: number
    debugMode: boolean
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, { debugMode })
  }
)

export const updateSafeMode = createAsyncThunk(
  'sites/updateSafeMode',
  async ({
    siteId,
    organizationId,
    safeMode,
  }: {
    siteId: number
    organizationId: number
    safeMode: boolean
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, { safeMode })
  }
)

export const updateFactoryReset = createAsyncThunk(
  'sites/updateFactoryReset',
  async ({
    siteId,
    organizationId,
    factoryReset,
  }: {
    siteId: number
    organizationId: number
    factoryReset: boolean
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, { factoryReset })
  }
)

export const updateSystemUpdatePolicy = createAsyncThunk(
  'sites/updateSystemUpdatePolicy',
  async ({
    siteId,
    organizationId,
    systemUpdatePolicy,
  }: {
    siteId: number
    organizationId: number
    systemUpdatePolicy: SystemUpdatePolicy
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, {
      systemUpdatePolicy,
    })
  }
)

export const updateRuntimePermissionPolicy = createAsyncThunk(
  'sites/updateRuntimePermissionPolicy',
  async ({
    siteId,
    organizationId,
    runtimePermissionPolicy,
  }: {
    siteId: number
    organizationId: number
    runtimePermissionPolicy: RuntimePermissionPolicy
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, {
      runtimePermissionPolicy,
    })
  }
)

export const updateUpdateStartTime = createAsyncThunk(
  'sites/updateUpdateStartTime',
  async ({
    siteId,
    organizationId,
    updateStartTime,
  }: {
    siteId: number
    organizationId: number
    updateStartTime: number
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, {
      updateStartTime,
    })
  }
)

export const updateUpdateEndTime = createAsyncThunk(
  'sites/updateUpdateEndTime',
  async ({
    siteId,
    organizationId,
    updateEndTime,
  }: {
    siteId: number
    organizationId: number
    updateEndTime: number
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, {
      updateEndTime,
    })
  }
)

export const updateAndroidSecurity = createAsyncThunk(
  'sites/updateAndroidSecurity',
  async ({
    siteId,
    organizationId,
    factoryResetAccounts,
  }: {
    siteId: number
    organizationId: number
    factoryResetAccounts: string
  }) => {
    return SitesApi.updateSiteSettings(siteId, organizationId, {
      factoryResetAccounts,
    })
  }
)

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    resetAddSite: (state) => {
      state.addingSite = false
      state.addedSite = false
      return state
    },
    resetUpdatedSite: (state) => {
      state.updatingSite = false
      state.updatedSite = false
      return state
    },
    resetDeletedSite: (state) => {
      state.deletingSite = false
      state.deletedSite = false
      return state
    },
    resetUpdatedSiteSettings: (state) => {
      state.updatedSecurityChallenge = false
      state.updatedUnknownSource = false
      state.updatedDebugMode = false
      state.updatedSafeMode = false
      state.updatedFactoryReset = false
      state.updatedSystemUpdatePolicy = false
      state.updatedRuntimePermissionPolicy = false
      state.updatedUpdateStartTime = false
      state.updatedUpdateEndTime = false
      state.updatedFactoryResetAccounts = false
      return state
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSites.pending, (state) => {
      state.fetchingSites = true
      return state
    })
    builder.addCase(fetchSites.fulfilled, (state, { payload }) => {
      state.fetchingSites = false
      state.fetchedSites = true
      state.sites = payload
      return state
    })

    builder.addCase(fetchSite.fulfilled, (state, { payload }) => {
      state.site = payload
      return state
    })

    builder.addCase(fetchSite.rejected, (state) => {
      state.site = null
      return state
    })

    builder.addCase(addSite.pending, (state) => {
      state.addingSite = true
      state.addedSite = false
      return state
    })
    builder.addCase(addSite.fulfilled, (state) => {
      state.addingSite = false
      state.addedSite = true
      return state
    })
    builder.addCase(addSite.rejected, (state) => {
      state.addingSite = false
      state.addedSite = false
      return state
    })

    builder.addCase(updateSite.pending, (state) => {
      state.updatingSite = true
      state.updatedSite = false
      return state
    })
    builder.addCase(updateSite.fulfilled, (state) => {
      state.updatingSite = false
      state.updatedSite = true
      return state
    })
    builder.addCase(updateSite.rejected, (state) => {
      state.updatingSite = false
      state.updatedSite = false
      return state
    })

    builder.addCase(deleteSite.pending, (state) => {
      state.deletingSite = true
      state.deletedSite = false
      return state
    })
    builder.addCase(deleteSite.fulfilled, (state) => {
      state.deletingSite = false
      state.deletedSite = true
      return state
    })
    builder.addCase(deleteSite.rejected, (state) => {
      state.deletingSite = false
      state.deletedSite = false
      return state
    })

    builder.addCase(fetchSiteSettings.pending, (state) => {
      state.fetchingSettings = true
      return state
    })
    builder.addCase(fetchSiteSettings.fulfilled, (state, { payload }) => {
      state.fetchingSettings = false
      state.fetchedSettings = true
      state.settings = payload
      return state
    })
    builder.addCase(fetchSiteSettings.rejected, (state) => {
      state.fetchingSettings = false
      state.fetchedSettings = false
      return state
    })

    builder.addCase(updateSecurityChallenge.pending, (state) => {
      state.updatingSecurityChallenge = true
      state.updatedSecurityChallenge = false
      return state
    })
    builder.addCase(updateSecurityChallenge.fulfilled, (state) => {
      state.updatingSecurityChallenge = false
      state.updatedSecurityChallenge = true
      return state
    })
    builder.addCase(updateSecurityChallenge.rejected, (state) => {
      state.updatingSecurityChallenge = false
      state.updatedSecurityChallenge = false
      return state
    })

    builder.addCase(updateUnknownSource.pending, (state) => {
      state.updatingUnknownSource = true
      state.updatedUnknownSource = false
      return state
    })
    builder.addCase(updateUnknownSource.fulfilled, (state) => {
      state.updatingUnknownSource = false
      state.updatedUnknownSource = true
      return state
    })
    builder.addCase(updateUnknownSource.rejected, (state) => {
      state.updatingUnknownSource = false
      state.updatedUnknownSource = false
      return state
    })

    builder.addCase(updateDebugMode.pending, (state) => {
      state.updatingDebugMode = true
      state.updatedDebugMode = false
      return state
    })
    builder.addCase(updateDebugMode.fulfilled, (state) => {
      state.updatingDebugMode = false
      state.updatedDebugMode = true
      return state
    })
    builder.addCase(updateDebugMode.rejected, (state) => {
      state.updatingDebugMode = false
      state.updatedDebugMode = false
      return state
    })

    builder.addCase(updateSafeMode.pending, (state) => {
      state.updatingSafeMode = true
      state.updatedSafeMode = false
      return state
    })
    builder.addCase(updateSafeMode.fulfilled, (state) => {
      state.updatingSafeMode = false
      state.updatedSafeMode = true
      return state
    })
    builder.addCase(updateSafeMode.rejected, (state) => {
      state.updatingSafeMode = false
      state.updatedSafeMode = false
      return state
    })

    builder.addCase(updateFactoryReset.pending, (state) => {
      state.updatingFactoryReset = true
      state.updatedFactoryReset = false
      return state
    })
    builder.addCase(updateFactoryReset.fulfilled, (state) => {
      state.updatingFactoryReset = false
      state.updatedFactoryReset = true
      return state
    })
    builder.addCase(updateFactoryReset.rejected, (state) => {
      state.updatingFactoryReset = false
      state.updatedFactoryReset = false
      return state
    })

    builder.addCase(updateSystemUpdatePolicy.pending, (state) => {
      state.updatingSystemUpdatePolicy = true
      state.updatedSystemUpdatePolicy = false
      return state
    })
    builder.addCase(updateSystemUpdatePolicy.fulfilled, (state) => {
      state.updatingSystemUpdatePolicy = false
      state.updatedSystemUpdatePolicy = true
      return state
    })
    builder.addCase(updateSystemUpdatePolicy.rejected, (state) => {
      state.updatingSystemUpdatePolicy = false
      state.updatedSystemUpdatePolicy = false
      return state
    })

    builder.addCase(updateRuntimePermissionPolicy.pending, (state) => {
      state.updatingRuntimePermissionPolicy = true
      state.updatedRuntimePermissionPolicy = false
      return state
    })
    builder.addCase(updateRuntimePermissionPolicy.fulfilled, (state) => {
      state.updatingRuntimePermissionPolicy = false
      state.updatedRuntimePermissionPolicy = true
      return state
    })
    builder.addCase(updateRuntimePermissionPolicy.rejected, (state) => {
      state.updatingRuntimePermissionPolicy = false
      state.updatedRuntimePermissionPolicy = false
      return state
    })
    builder.addCase(updateUpdateStartTime.fulfilled, (state) => {
      state.updatedUpdateStartTime = true
      return state
    })
    builder.addCase(updateUpdateEndTime.fulfilled, (state) => {
      state.updatedUpdateEndTime = true
      return state
    })
    builder.addCase(updateAndroidSecurity.fulfilled, (state) => {
      state.updatedFactoryResetAccounts = true
      return state
    })
    builder.addCase(updateAndroidSecurity.rejected, (state) => {
      state.updatedFactoryResetAccounts = false
      return state
    })
  },
})

export const {
  resetAddSite,
  resetUpdatedSite,
  resetDeletedSite,
  resetUpdatedSiteSettings,
} = sitesSlice.actions

export default sitesSlice.reducer
