export enum UserRole {
  HubletAdmin = 'hublet-admin',
  OrgAdmin = 'org-admin',
  SiteAdmin = 'site-admin',
  SiteUser = 'site-user',
  Translator = 'translator',
  Manufacturer = 'manufacturer',
}

export interface User {
  id: number
  organizationId: number
  siteId?: number
  email: string
  role: UserRole
  firstName: string
  lastName: string
  rcvExpLoansMail: boolean
  deletedAt?: number
  resetPasswordSentAt?: string
  invitationExpiresAt?: string
  loginSuccessLast?: string
  loginFailureLast?: string
  isLocked: boolean
}

export interface UserEditable {
  role?: UserRole
  organizationId?: number
  siteId?: number
  firstName?: string
  lastName?: string
  rcvExpLoansMail?: boolean
  rcvExpLoansMailSiteId?: number
}

export interface InvitedUser {
  id: number
  email: string
  role: UserRole
}
