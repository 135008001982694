import axios from 'axios'
import { API_BASE_URL } from 'common/constants'
import { type Customer, type CustomerForm } from 'App/Customers/customers-types'

class CustomersApi {
  // eslint-disable-next-line no-useless-constructor
  private constructor() {}

  static async fetchCustomers(distributorId: number): Promise<Customer[]> {
    const { data } = await axios.get(
      `${API_BASE_URL}/distributors/${distributorId}/customers`
    )
    return data as Customer[]
  }

  static async fetchCustomer(
    distributorId: number,
    customerId: number
  ): Promise<Customer> {
    const { data } = await axios.get(
      `${API_BASE_URL}/distributors/${distributorId}/customers/${customerId}`
    )
    return data as Customer
  }

  static async createCustomer(
    distributorId: number,
    form: CustomerForm
  ): Promise<Customer> {
    const res = await axios.post(
      `${API_BASE_URL}/distributors/${distributorId}/customers`,
      form
    )
    return res.data as Customer
  }

  static async createCustomerAsAdmin(
    distributorId: number,
    form: CustomerForm
  ): Promise<Customer> {
    const res = await axios.post(
      `${API_BASE_URL}/distributors/${distributorId}/customers/as-admin`,
      form
    )
    return res.data as Customer
  }

  static async updateCustomer(
    customerId: number,
    distributorId: number,
    form: CustomerForm
  ): Promise<Customer> {
    const res = await axios.put(
      `${API_BASE_URL}/distributors/${distributorId}/customers/${customerId}`,
      form
    )
    return res.data as Customer
  }

  static async updateCustomerAsAdmin(
    customerId: number,
    distributorId: number,
    form: CustomerForm
  ): Promise<Customer> {
    const res = await axios.put(
      `${API_BASE_URL}/distributors/${distributorId}/customers/${customerId}/as-admin`,
      form
    )
    return res.data as Customer
  }

  static async deleteCustomer(
    customerId: number,
    distributorId: number
  ): Promise<void> {
    await axios.delete(
      `${API_BASE_URL}/distributors/${distributorId}/customers/${customerId}`
    )
  }
}

export default CustomersApi
